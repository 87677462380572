import { useEffect } from 'react';

import PusherConfig from '@config/pusher';
import PusherDataHandler from '@handlers/pusherData';
import { EVENTS_TYPES } from '@handlers/pusherData/constHandlersPushers';
import { selectToken, selectUser } from '@services/auth/selectors';
import { useGetCompanyQuery } from '@services/companies/endpoints';
import { useAppDispatch, useAppSelector } from '@services/hooks';

export const useSubscribeToPusherChannel = (pusherChannel: string | undefined) => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken);

  const dispatchActions = (actions: any) => {
    if (actions?.length) {
      actions.forEach((action: any) => dispatch(action));
    }
  };

  useEffect(() => {
    if (!pusherChannel) {
      return () => {};
    }
    const pusher = PusherConfig.getInstance(token);
    const subscriptionChannel = pusher.subscribe(pusherChannel);
    subscriptionChannel.bind(EVENTS_TYPES.private, (data: any) => {
      const pusherDataHandler = new PusherDataHandler(data);
      const actions = pusherDataHandler.getActions();
      dispatchActions(actions);
    });
    return () => pusher.unsubscribe(pusherChannel);
  }, [pusherChannel]);
};

export const useSubscribeToInactiveCompanyPusherChannel = (companyUuid: string | undefined) => {
  const self = useAppSelector(selectUser);
  const shouldSubscribe = !!companyUuid && !!self?.activeCompanyUuid && companyUuid !== self?.activeCompanyUuid;

  const { company } = useGetCompanyQuery(companyUuid!, {
    skip: !shouldSubscribe,
    selectFromResult: ({ data }) => ({ company: data?.data }),
  });

  useSubscribeToPusherChannel(shouldSubscribe ? company?.generalPusherChannel : undefined);
};
