import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { cloneDeep } from 'lodash';
import { DeviceType } from 'models/device.enums';
import moment from 'moment';

import apiSlice from '@services/api';
import { selectDevicesBy } from '@services/devices/selectors';
import { useAppSelector } from '@services/hooks';
import { selectTransactions } from '@services/transactions/selectors';
import { getUnifiedConnectors } from '@views/Devices/connectorsUtil';

import { ChargerWithStats, LmcMultipointWithStats } from '../devicesAndGroupsTypes';

type UseChargersWithStats = {
  siteUuid?: string;
  companyUuid?: string;
  selectedDuration?: string;
  lmcMultipointsWithStats?: LmcMultipointWithStats[];
};

export const useChargersWithStats = ({
  siteUuid,
  companyUuid,
  selectedDuration,
  lmcMultipointsWithStats,
}: UseChargersWithStats) => {
  const { deviceUuid: selectedDeviceUuidFromUrl } = useParams();

  const rawSiteChargers = useAppSelector((state) => selectDevicesBy(state, DeviceType.CHARGER, siteUuid, companyUuid));
  const transactions = useAppSelector(selectTransactions);

  apiSlice.useTransactionsQuery({ siteUuid }, { refetchOnMountOrArgChange: true, skip: !siteUuid });

  const chargersWithStatsDeps = [transactions.list[0]?.transactionId, transactions.list.length, selectedDuration];

  const chargersWithStats = useMemo<ChargerWithStats[]>(() => {
    const duration =
      selectedDuration && selectedDuration !== 'all' ? moment.duration(...selectedDuration.split('-')) : null;
    const durationStartsAt = duration ? moment.utc().subtract(duration) : null;
    const clonedChargers = cloneDeep(rawSiteChargers)
      .filter((charger) => charger.type === DeviceType.CHARGER)
      .map((charger) => {
        const connectors = getUnifiedConnectors(charger);
        return {
          ...charger,
          computedStatus: connectors.map((connector) => connector.computedStatus).join(', '),
          numSessions: 0,
          consumption: 0,
          connectors,
          displayName: charger.name || charger.serialNumber,
        };
      });
    const chargersByUuid = Object.fromEntries(clonedChargers.map((charger) => [charger.uuid, charger]));
    transactions.list.forEach((transaction) => {
      const charger = chargersByUuid[transaction.deviceUuid];
      if (charger && (!durationStartsAt || moment.utc(transaction.startTime).isSameOrAfter(durationStartsAt))) {
        charger.numSessions += 1;
        charger.consumption += transaction.amount / 1000;
      }
    });
    return Object.values(chargersByUuid);
  }, [...chargersWithStatsDeps, rawSiteChargers, lmcMultipointsWithStats]);

  const selectedCharger = useMemo<ChargerWithStats | undefined>(
    () => chargersWithStats.find((device) => device.uuid === selectedDeviceUuidFromUrl),
    [selectedDeviceUuidFromUrl],
  );

  return {
    chargersWithStats,
    selectedCharger,
  };
};
