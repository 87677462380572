import { useTranslation } from 'react-i18next';

import * as yup from 'yup';

import { ChargerOption } from '@components/atoms/ChargerAutocomplete';
import useActiveCurrency from '@hooks/useActiveCurrency';

const isNumber = (value?: string | number) => value !== undefined && !Number.isNaN(+value);

const isPositiveNumber = (value?: string | number) => value !== undefined && isNumber(value) && +value > 0;

const isLessThan100 = (value?: string) => value !== undefined && isNumber(value) && +value < 100;

export type SiteAvailabilityForm = {
  isPublic: boolean;
  isFixed: boolean;
  priceInput: string;
  isReservationEnabled: boolean;
  freeReservationMinutes: string;
  reservationCentsPerMinute: string;
  isParkingEnabled: boolean;
  freeParkingMinutes: string;
  parkingCentsPerMinute: string;
  publicChargers: ChargerOption[];
};

export const siteAvailabilityFormDefaultValues: SiteAvailabilityForm = {
  isPublic: false,
  isFixed: false,
  priceInput: '0',
  isReservationEnabled: false,
  freeReservationMinutes: '0',
  reservationCentsPerMinute: '0',
  isParkingEnabled: false,
  freeParkingMinutes: '0',
  parkingCentsPerMinute: '0',
  publicChargers: [],
};

export const useSiteAvailabilitySchema = () => {
  const { t } = useTranslation();
  const currency = useActiveCurrency();
  return yup
    .object()
    .shape({
      isPublic: yup.boolean(),
      isFixed: yup.boolean(),
      priceInput: yup.number().when('isPublic', {
        is: true,
        then: yup
          .number()
          .typeError(t('mustBeNumber', 'Must be a number'))
          .required(t('required*', 'Required*'))
          .test('positive', t('mustBePositive', 'Must be positive'), isPositiveNumber),
      }),
      isReservationEnabled: yup.boolean(),
      freeReservationMinutes: yup.string().when('isReservationEnabled', {
        is: true,
        then: yup
          .string()
          .test('number', t('mustBeNumber', 'Must be a number'), isNumber)
          .test('notNegative', t('mustBeAtLeastZero', 'Must be at least zero'), isPositiveNumber)
          .default('0'),
      }),
      reservationCentsPerMinute: yup.string().when('isReservationEnabled', {
        is: true,
        then: yup
          .string()
          .required(t('required*', 'Required*'))
          .test('number', t('mustBeNumber', 'Must be a number'), isNumber)
          .test('positive', t('mustBePositive', 'Must be positive'), isPositiveNumber)
          .test(
            'maxValue',
            t('mustNotExceedCurrencySubunit/min', 'Must not exceed {{currencyValue}} {{currencySubunit}}/min', {
              currencyValue: 100,
              currencySubunit: currency.centsName,
            }),
            isLessThan100,
          ),
      }),
      isParkingEnabled: yup.boolean(),
      freeParkingMinutes: yup.string().when('isParkingEnabled', {
        is: true,
        then: yup
          .string()
          .test('number', t('mustBeNumber', 'Must be a number'), isNumber)
          .test('notNegative', t('mustBeAtLeastZero', 'Must be at least zero'), isPositiveNumber)
          .default('0'),
      }),
      parkingCentsPerMinute: yup.string().when('isParkingEnabled', {
        is: true,
        then: yup
          .string()
          .required(t('required*', 'Required*'))
          .test('number', t('mustBeNumber', 'Must be a number'), isNumber)
          .test('positive', t('mustBePositive', 'Must be positive'), isPositiveNumber)
          .test(
            'maxValue',
            t('mustNotExceedCurrencySubunit/min', 'Must not exceed {{currencyValue}} {{currencySubunit}}/min', {
              currencyValue: 100,
              currencySubunit: currency.centsName,
            }),
            isLessThan100,
          ),
      }),
      publicChargers: yup.array().when('isPublic', {
        is: true,
        then: yup
          .array()
          .min(1, t('pleaseSelectAtLeastOneDevice', 'Please select at least 1 device'))
          .required(t('required*', 'Required*')),
      }),
    })
    .required();
};
