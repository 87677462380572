import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';

import { max } from 'lodash';
import moment from 'moment';

import EnergySupplied from '@components/energySupplied';
import { useSubscribeToInactiveCompanyPusherChannel, useSubscribeToPusherChannel } from '@hooks/pusherChannelHooks';
import { selectInverterSummary } from '@services/inverters/selectors';
import { RadialChart } from '@views/Chargers/RadialChart';
import { InverterStatus } from '@views/Inverters/InverterStatus';

import { InverterOutletContextType } from './InverterOutletContextType';
import { InverterSummary } from './InverterSummary';

export const InverterOverview = () => {
  const { t } = useTranslation();
  const { inverter, site, selectedDuration, setSelectedDuration, timeFrame, isFetchingInverterSummary } =
    useOutletContext<InverterOutletContextType>();

  const inverterSummary = useSelector(selectInverterSummary);

  const { isProducing, productionRate } = inverter.data?.productionState ?? {};
  const activePower = productionRate || 0;
  const maxPower = max([activePower, 22]);

  const inverterSessionDiffTime = useMemo(() => {
    const [firstSession, secondSession] = inverterSummary?.sessions ?? [];
    return moment(secondSession?.timestamp).diff(moment(firstSession?.timestamp));
  }, [inverterSummary.sessions]);

  const inverterSessions = useMemo(
    () => [
      {
        name: t('inverterProduction', 'Inverter production'),
        data:
          inverterSummary.sessions?.map((session) => ({
            ...session,
            startTime: moment(session.timestamp).subtract(inverterSessionDiffTime),
            endTime: moment(session.timestamp),
            amount: session.totalProductionInKwh,
          })) || [],
      },
    ],
    [inverterSessionDiffTime],
  );

  const detailsData = [
    {
      label: t('brand', 'Brand'),
      value: inverter.brand ?? '-',
    },
    {
      label: t('serial', 'Serial'),
      value: inverter.externalId ?? '-',
    },
    {
      label: t('model', 'Model'),
      value: inverter.model ?? '-',
    },
    {
      label: t('site', 'Site'),
      value: site?.name || '-',
    },
  ];

  useSubscribeToInactiveCompanyPusherChannel(inverter?.companyUuid);
  useSubscribeToPusherChannel(inverter?.metricsPusherChannel);

  return (
    <div className="flex flex-col gap-y-10">
      <div className="flex flex-col gap-x-4 gap-y-2 md:flex-row">
        <InverterStatus inverter={inverter} />
        <div className="min-h-[136px] w-full md:max-w-[294px]">
          <RadialChart
            label="kW"
            max={maxPower}
            color="#eb4e20"
            value={activePower}
            offline={!isProducing}
            backgroundColor="bg-vermillion-200"
          />
        </div>
        <div className="w-full space-y-2 rounded-2xl bg-white p-4 font-poppins text-sm">
          <span>{t('details', 'Details')}</span>
          <div className="grid grid-cols-2 gap-x-4 gap-y-1">
            {detailsData.map((item) => (
              <div key={item.label} className="flex gap-x-1 font-semibold">
                {item.label}: <span className="font-normal">{item.value}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <InverterSummary selectedDuration={selectedDuration} setSelectedDuration={setSelectedDuration} />
        <EnergySupplied
          isFetching={isFetchingInverterSummary}
          noBorder
          noTitle
          compact
          noInfo
          categorizedTransactions={inverterSessions}
          filter={timeFrame}
        />
      </div>
    </div>
  );
};
