import { useTranslation } from 'react-i18next';

import { TFunction } from 'i18next';
import { Car } from 'models/car';
import { RFIDCardDto } from 'models/rfid-card';
import { User } from 'models/user';

import { Box } from '@mui/material';

import { ReactComponent as UserIcon } from '@images/icons/reporting/user.svg';
import { ReactComponent as VehicleIcon } from '@images/icons/reporting/vehicle.svg';

const getUserData = ({
  rfidCard,
  users,
  translations: t,
}: {
  rfidCard: RFIDCardDto;
  users: User[];
  translations: TFunction<'translation', undefined>;
}) => {
  const user = users.find((u) => u.uuid === rfidCard.userUuid);
  const getLabel = () => {
    if (!user) {
      return <em>{t('unknownItem', 'Unknown {{item}}', { item: t('user', 'User').toLowerCase() })}</em>;
    }
    const value = user.name || user.email;
    if (user.inviteFlag) {
      return <em>{value}</em>;
    }
    return <span>{value}</span>;
  };
  return {
    label: getLabel(),
    content: (
      <Box display="flex" alignItems="center" gap={2}>
        <UserIcon style={{ flexShrink: 0 }} />
        {getLabel()}
      </Box>
    ),
  };
};

const getCarData = ({
  rfidCard,
  vehicles,
  translations: t,
}: {
  rfidCard: RFIDCardDto;
  vehicles: Car[];
  translations: TFunction<'translation', undefined>;
}) => {
  const vehicle = vehicles.find((v) => v.uuid === rfidCard.carUuid);
  const brandModel = vehicle?.name || [vehicle?.brand, vehicle?.model].filter(Boolean).join(' ');
  const unknownCarLabel = t('unknownItem', 'Unknown {{item}}', { item: t('car', 'Car').toLowerCase() });
  const label = brandModel ?? unknownCarLabel;
  return {
    label,
    content: (
      <Box display="flex" alignItems="center" gap={2}>
        <VehicleIcon style={{ flexShrink: 0 }} />
        {brandModel ? <span>{brandModel}</span> : <em>{unknownCarLabel}</em>}
      </Box>
    ),
  };
};

export const useGetRfidCardAssigneeData = () => {
  const { t } = useTranslation();
  return ({ rfidCard, users, vehicles }: { rfidCard: RFIDCardDto; users: User[]; vehicles: Car[] }) => {
    if (rfidCard.userUuid) {
      return getUserData({ rfidCard, users, translations: t });
    }
    if (rfidCard.carUuid) {
      return getCarData({ rfidCard, vehicles, translations: t });
    }
    const label = rfidCard.name || '-';
    return {
      label,
      content: label,
    };
  };
};
