import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { Input } from '@components/atoms/Input';
import SaveModal from '@components/modals/SaveModal';
import { useCreateCompanyMutation } from '@services/companies/endpoints';
import { addToastMessage } from '@services/toastMessages';

const CreateWorkspaceModal = ({ isOpen, closeModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [name, setName] = useState('');

  const [createWorkspace, { isLoading }] = useCreateCompanyMutation();

  const handleSave = async () => {
    const response = await createWorkspace({ name: name.trim() });
    const toastMessage = response?.error
      ? {
          type: 'error',
          title: t('creatingTheWorkspaceFailed', 'Creating the workspace failed'),
          message: t('pleaseTryAgain', 'Please try again.'),
        }
      : { type: 'success', title: t('workspaceCreated', 'Workspace created') };
    dispatch(addToastMessage(toastMessage));
    closeModal();
  };

  const canSave = !isLoading && !!name.trim();

  useEffect(() => {
    if (isOpen) {
      setName('');
    }
  }, [isOpen]);

  return (
    <SaveModal
      title={t('createNewWorkspace', 'Create new workspace')}
      content={() => ''}
      saveModalContent={handleSave}
      buttonText={t('createWorkspace', 'Create workspace')}
      saveButtonDisabled={!canSave}
      closeModal={closeModal}
      open={isOpen}
    >
      <Input
        name="newName"
        label={t('workspaceName', 'Workspace name')}
        value={name}
        onChange={(e) => setName(e.target.value)}
        inputProps={{ onKeyDown: (e) => e.key === 'Enter' && canSave && handleSave() }}
      />
    </SaveModal>
  );
};

CreateWorkspaceModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
};

CreateWorkspaceModal.defaultProps = {
  isOpen: false,
  closeModal: () => {},
};

export default CreateWorkspaceModal;
