import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { CompanyRole } from 'models/role.enum';

import { Box, Typography } from '@mui/material';

import { Checkbox } from '@components/atoms/Checkbox';
import { RadioButton } from '@components/atoms/RadioButton';
import { UserEmailAutocomplete } from '@components/atoms/UserEmailAutocomplete';
import EditingSidebarBase from '@components/sidebars/EditingSidebarBase';
import { useAppDispatch } from '@services/hooks';
import { addToastMessage } from '@services/toastMessages';
import { useInviteUsersToWorkspaceMutation } from '@services/users/endpoints';

import { useWorkspaceGroupsAndRoles, useWorkspaceRolesDef } from '../workspaceHooks';

type WorkspaceInvitedUser = {
  label: string;
  email: string;
};

type WorkspaceRoleOption = {
  role: CompanyRole;
  label: string;
};

export const WorkspaceInviteSidebar = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const rolesDef = useWorkspaceRolesDef();
  const { groups } = useWorkspaceGroupsAndRoles({ refetchOnMountOrArgChange: true });

  const [workspaceInvitedUsers, setWorkspaceInvitedUsers] = useState<WorkspaceInvitedUser[]>([]);
  const [role, setRole] = useState<CompanyRole>();
  const [selectedGroupUuids, setSelectedGroupUuids] = useState<string[]>([]);

  const choosableRoles: WorkspaceRoleOption[] = Object.entries(rolesDef)
    .filter(([, roleDef]) => roleDef.choosable)
    .map(([key, roleDef]) => ({
      role: key as CompanyRole,
      label: roleDef.label,
    }));

  const [inviteUsersToWorkspace, { isLoading, isError }] = useInviteUsersToWorkspaceMutation();

  const sendInvites = async () => {
    try {
      await inviteUsersToWorkspace({
        emails: workspaceInvitedUsers.map((u) => u.email),
        role: role!,
        groupUuids: selectedGroupUuids,
      }).unwrap();

      dispatch(
        addToastMessage({
          type: 'success',
          title: t('invitationHasBeenSent', 'Invitation has been sent', {
            count: workspaceInvitedUsers.length,
          }),
        }),
      );
      return true;
    } catch (error) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('failedToSendInvitation', 'Failed to send invitation', {
            count: workspaceInvitedUsers.length,
          }),
          message: t('pleaseTryAgain', 'Please try again.'),
        }),
      );
      return false;
    }
  };

  const canInvite = !!workspaceInvitedUsers.length && !!role;

  return (
    <EditingSidebarBase
      title={t('invitePeopleToWorkspace', 'Invite people to workspace')}
      wide
      saveLabel={t('sendInvites', 'Send {{count}} invites', {
        count: canInvite ? workspaceInvitedUsers.length : 0,
      })}
      saveDisabled={!canInvite}
      updateFailed={isError}
      updateLoading={isLoading}
      anyDataChanged={!!workspaceInvitedUsers.length}
      containerClassName="flex flex-col"
      containerBottomPadding="pb-20"
      onSaveValues={sendInvites}
    >
      <UserEmailAutocomplete
        label={t('emailAddresses', 'E-mail addresses')}
        value={workspaceInvitedUsers}
        enterOnSpaceOrComma
        onChange={setWorkspaceInvitedUsers}
      />

      <Box display="flex" flexDirection="column" mt={3} mb={1} gap={1}>
        <Typography variant="p14b">{t('setTheRoleForTheInvitees', 'Set the role for the invitees')}</Typography>
        {choosableRoles.map((choosableRole) => (
          <RadioButton
            key={choosableRole.role}
            name="role"
            label={choosableRole.label}
            value={choosableRole.role}
            checked={role === choosableRole.role}
            onChange={() => setRole(choosableRole.role)}
          />
        ))}
      </Box>

      {!!groups?.length && role && [CompanyRole.PUBLIC_MEMBER, CompanyRole.MEMBER].includes(role) && (
        <Box display="flex" flexDirection="column" mt={3} mb={1} gap={1}>
          <Typography variant="p14b">{t('addInviteesToGroups', 'Add invitees to groups')}</Typography>
          {groups?.map((group) => (
            <Checkbox
              key={group.uuid}
              name="groups"
              label={group.name}
              checked={selectedGroupUuids.includes(group.uuid)}
              onChange={() => {
                setSelectedGroupUuids((prev) => {
                  if (prev?.includes(group.uuid)) {
                    return prev?.filter((g) => g !== group.uuid);
                  }
                  return [...(prev ?? []), group.uuid];
                });
              }}
            />
          ))}
        </Box>
      )}

      <Box flex={1} />
      {canInvite && (
        <div>
          <Trans
            i18nKey="personWillBeInvitedToWorkspaceText"
            defaults="<strong>1 person</strong> will be invited to <strong>workspace</strong> with the role of <strong>{{ role }}</strong>"
            values={{ role: rolesDef[role]?.label }}
            components={{ strong: <strong /> }}
            count={workspaceInvitedUsers.length}
          />
        </div>
      )}
    </EditingSidebarBase>
  );
};
