import { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { differenceWith, sortBy } from 'lodash';
import { GroupRole } from 'models/role.enum';
import { User } from 'models/user';

import { Box, Divider, Skeleton, Typography } from '@mui/material';

import { UserEmailAutocomplete, UserEmailOption } from '@components/atoms/UserEmailAutocomplete';
import { Avatar } from '@components/avatar/Avatar';
import { MenuComponent, MenuItem } from '@components/dropdown/MenuComponent';
import copyContent from '@helpers/copyContent';
import kebabIcon from '@images/icons/Kebab.svg';
import { useAppSelector } from '@services/hooks';
import { selectUsers } from '@services/users/selectors';

import MemberInfo from '../../MemberInfo';

export type GroupUserOption = UserEmailOption & { role: GroupRole };

type GroupMembersProps = {
  groupUsers: GroupUserOption[];
  isLoading?: boolean;
  isSaving?: boolean;
  onAddUsers: (newUsers: GroupUserOption[]) => void;
  onGroupUsersChange: Dispatch<SetStateAction<GroupUserOption[]>>;
};

export const GroupMembers = ({
  groupUsers,
  isLoading,
  isSaving,
  onAddUsers,
  onGroupUsersChange,
}: GroupMembersProps) => {
  const { t } = useTranslation();
  const companyUsers = useAppSelector(selectUsers);
  const sortedCompanyUsers = useMemo(
    () =>
      sortBy(
        differenceWith(companyUsers, groupUsers, (companyUser, groupUser) => companyUser.uuid === groupUser.uuid),
        (user) => (user.name ?? user.email).toLowerCase(),
      ),
    [companyUsers, groupUsers],
  );
  const sortedGroupUsers = useMemo(() => sortBy(groupUsers, (user) => user.label.toLowerCase()), [groupUsers]);

  const makeOptionFromUser = (user: User): GroupUserOption => ({
    ...user,
    label: user.name || user.email,
    role: GroupRole.MEMBER,
  });

  const toggleRole = (email: string) => {
    onGroupUsersChange((prev) =>
      prev.map((user) =>
        user.email === email
          ? { ...user, role: user.role === GroupRole.MEMBER ? GroupRole.MANAGER : GroupRole.MEMBER }
          : user,
      ),
    );
  };

  const removeFromGroup = (email: string) => {
    onGroupUsersChange((prev) => prev.filter((user) => user.email !== email));
  };

  const getMenuItemsForRole = (user: GroupUserOption): MenuItem[] => [
    {
      title:
        user.role === GroupRole.MANAGER
          ? t('changeToMember', 'Change to Member')
          : t('changeToGroupManager', 'Change to Group manager'),
      key: 'toggleRole',
      onClick: () => toggleRole(user.email),
    },
    {
      title: t('copyEmailAddress', 'Copy e-mail address'),
      key: 'copyEmailAddress',
      onClick: () => copyContent({ text: user.email }),
    },
    {
      title: t('removeFromGroup', 'Remove from group'),
      key: 'removeFromGroup',
      className: 'text-vermillion',
      onClick: () => removeFromGroup(user.email),
    },
  ];

  return (
    <Box display="flex" flexDirection="column" gap={3} mt={4}>
      <Typography variant="p16" color="#5E5E5E">
        {t(
          'inviteUsersToWorkspaceGroupText',
          'Users that are not part of the workspace are sent invite emails once the group is saved',
        )}
      </Typography>
      {isLoading ? (
        <Skeleton variant="rounded" animation="wave" height={39} />
      ) : (
        <UserEmailAutocomplete
          label={t('addOrInviteGroupMembers', 'Add or invite group members')}
          options={sortedCompanyUsers.map(makeOptionFromUser)}
          onChange={(values) => onAddUsers(values.map((value) => ({ ...value, role: value.role ?? GroupRole.MEMBER })))}
        />
      )}
      {isLoading ? (
        <Box>
          <Divider color="#F4F4F4" />
          <Box display="flex" flexDirection="column" gap={2} mt={5}>
            <Typography variant="p16b" sx={{ mt: 10 }}>
              {t('groupMembers', 'Group members')}
            </Typography>
            <Skeleton variant="rounded" animation="wave" height={39} />
            <Skeleton variant="rounded" animation="wave" height={39} />
            <Skeleton variant="rounded" animation="wave" height={39} />
          </Box>
        </Box>
      ) : (
        !!sortedGroupUsers.length && (
          <Box>
            <Divider color="#F4F4F4" />
            <Box display="flex" flexDirection="column" gap={2} mt={5}>
              <Typography variant="p16b" sx={{ mt: 10 }}>
                {t('groupMembers', 'Group members')}
              </Typography>
              {sortedGroupUsers.map((groupUser) => (
                <Box key={groupUser.uuid ?? groupUser.email} display="flex" alignItems="center" gap={2}>
                  <Avatar name={groupUser.name} email={groupUser.email} bigger />
                  <MemberInfo member={groupUser} flex={1} />
                  <Typography variant="p14">
                    {groupUser.role === GroupRole.MANAGER ? t('groupManager', 'Group manager') : t('member', 'Member')}
                  </Typography>
                  <MenuComponent
                    menuItems={getMenuItemsForRole(groupUser)}
                    buttonIcon={kebabIcon}
                    disabled={isSaving}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        )
      )}
    </Box>
  );
};
