import { Box } from '@mui/material';

import SearchOpenButton from '@components/search/SearchOpenButton';

import { UserProfileMenu } from './UserProfileMenu';

export const UserHeaderItems = () => (
  <Box display="flex" alignItems="center" justifyContent="end" gap={1} minWidth="40%">
    <SearchOpenButton />
    <UserProfileMenu />
  </Box>
);
