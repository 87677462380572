import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ReportingTransaction } from 'models/reporting-transaction';

import { StyledMenuItemProps } from '@components/atoms/StyledMenuItem';
import { compareMenuOptions } from '@components/filters';
import { isSubdomainPartner } from '@routesConfig/routesUtil';
import { useAppSelector } from '@services/hooks';
import { useGetAllUsersQuery, useGetUsersQuery } from '@services/users/endpoints';
import { selectBoUsers, selectUsers } from '@services/users/selectors';

export const useUserMenu = (transactions?: ReportingTransaction[]) => {
  const { companyUuid } = useParams();
  useGetUsersQuery({ companyUuid }, { skip: !companyUuid && isSubdomainPartner() });
  useGetAllUsersQuery(undefined, { skip: !!companyUuid || !isSubdomainPartner() });
  const users = useAppSelector(selectUsers);
  const allUsers = useAppSelector(selectBoUsers);
  const [userMenuOptions, setUserMenuOptions] = useState<StyledMenuItemProps[]>([]);

  const processedUsers = !companyUuid && isSubdomainPartner() ? allUsers : users;
  useEffect(() => {
    setUserMenuOptions(
      (prevState) =>
        processedUsers
          ?.map((user) => ({
            uuid: user.uuid,
            checked: prevState.find((option) => option.uuid === user.uuid)?.checked ?? false,
            avatar: user.name || user.email || '',
            label: user.name || user.email || '',
            resultCount: transactions?.filter((transaction) => transaction.userUuid === user.uuid).length,
            onClick: () => toggleOptionChecked(user.uuid),
          }))
          .sort(compareMenuOptions) ?? [],
    );
  }, [processedUsers, transactions]);

  const toggleOptionChecked = (selectedOptionUuid: string) => {
    setUserMenuOptions((prevOptions) =>
      prevOptions
        ?.map((option) => {
          if (option.uuid === selectedOptionUuid) {
            return { ...option, checked: !option.checked };
          }
          return option;
        })
        .sort(compareMenuOptions),
    );
  };

  return { userMenuOptions, setUserMenuOptions };
};
