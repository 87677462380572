import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import SaveModal from '@components/modals/SaveModal';
import { selectUser } from '@services/auth/selectors';

import UserSettingsForm from './UserSettingsForm';

const UserSettingsModal = ({ isOpen, closeModal }) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  return (
    <SaveModal
      closeModal={closeModal}
      open={isOpen}
      buttonText={t('saveChanges', 'Save changes')}
      title={t('userSettings', 'User settings')}
      content={() => ''}
      noActions
    >
      <UserSettingsForm self={user} closeModal={closeModal} />
    </SaveModal>
  );
};

UserSettingsModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
};

UserSettingsModal.defaultProps = {
  isOpen: false,
  closeModal: () => {},
};

export default UserSettingsModal;
