import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';

import { Permission } from 'models/permission.enum';

import { DEFAULT_PATH } from '@routesConfig/routesConst';
import { selectPermissionByName } from '@services/auth/selectors';
import { useAppSelector } from '@services/hooks';

import WorkspaceRenameModal from './WorkspaceRenameModal';

export const WorkspaceSettingsView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const canEditCompany = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_EDIT_COMPANY));

  const [renameModalOpen, setRenameModalOpen] = useState(false);

  const workspaceLabel = t('workspace', 'Workspace');
  const payoutDetailsLabel = t('itemDetails', '{{item}} details', { item: t('payout', 'Payout') });

  const sections = [
    {
      name: t('itemDetails', '{{item}} details', { item: workspaceLabel }),
      items: [
        {
          title: t('itemName', '{{item}} name', { item: workspaceLabel }),
          subtitle: t('updateYourItem', 'Update your {{item}}', {
            item: `${workspaceLabel} ${t('name', 'Name')}`.toLowerCase(),
          }),
          onClick: () => setRenameModalOpen(true),
          disabled: !canEditCompany,
        },
        {
          title: t('itemDetails', '{{item}} details', { item: t('company', 'Company') }),
          subtitle: t('updateYourItem', 'Update your {{item}}', {
            item: t('companyRegistrationInformation', 'Company registration information').toLowerCase(),
          }),
          onClick: () => navigate('companyDetails'),
          disabled: !canEditCompany,
        },
      ],
    },
    {
      name: t('billingAndPayout', 'Billing and Payout'),
      items: [
        {
          title: t('paymentMethod', 'Payment method'),
          subtitle: t('manageYourSubscriptionPaymentMethod', 'Manage your subscription payment method'),
          onClick: () => navigate('paymentMethod'),
          disabled: !canEditCompany,
        },
        {
          title: payoutDetailsLabel,
          subtitle: t('updateYourItem', 'Update your {{item}}', {
            item: payoutDetailsLabel.toLowerCase(),
          }),
          onClick: () => navigate('payoutDetails'),
          disabled: !canEditCompany,
        },
      ],
    },
  ];

  useEffect(() => {
    if (!canEditCompany) {
      navigate(DEFAULT_PATH);
    }
  }, [canEditCompany]);

  return (
    <div className="flex w-full flex-col gap-y-10 rounded-2xl bg-white p-8 font-poppins">
      {sections.map((section) => (
        <div key={section.name} className="gap-x-20 sm:flex">
          <div className="min-w-[124px] px-4 py-2 font-semibold sm:text-right lg:min-w-[320px]">{section.name}</div>
          <div className="flex w-full flex-col gap-y-6">
            {section.items.map((item) => (
              <button
                key={item.title}
                type="button"
                className="rounded-2xl px-4 py-2 text-left enabled:hover:bg-gray-100 disabled:cursor-default disabled:opacity-50"
                disabled={item.disabled}
                onClick={item.onClick}
              >
                <div>{item.title}</div>
                <div className="text-sm leading-6 text-gray-600">{item.subtitle}</div>
              </button>
            ))}
          </div>
        </div>
      ))}

      <WorkspaceRenameModal isOpen={renameModalOpen} closeModal={() => setRenameModalOpen(false)} />
      <Outlet />
    </div>
  );
};
