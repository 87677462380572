import { useTranslation } from 'react-i18next';

import EditingSidebarBase from '@components/sidebars/EditingSidebarBase';
import { CompanyDetails } from '@views/Workspace/sidebars/companyDetails/CompanyDetails';

import { useCompanyDetailsForm } from './useCompanyDetailsForm';

export const CompanyDetailsSidebarBase = () => {
  const { t } = useTranslation();

  const { form, submitCompanyDetails, isLoading, isSaving } = useCompanyDetailsForm();

  return (
    <EditingSidebarBase
      title={t('companyDetails', 'Company details')}
      containerClassName="space-y-7 font-poppins"
      saveDisabled={isLoading}
      updateLoading={isSaving}
      anyDataChanged={form.formState.isDirty}
      onSaveValues={submitCompanyDetails}
    >
      <CompanyDetails form={form} isLoading={isLoading} />
    </EditingSidebarBase>
  );
};
