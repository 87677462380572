import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Inverter } from 'models/inverter';

import { Input } from '@components/atoms/Input';
import SaveModal from '@components/modals/SaveModal';
import { useAppDispatch } from '@services/hooks';
import { useUpdateInverterMutation } from '@services/inverters/endpoints';
import { addToastMessage } from '@services/toastMessages';

type InverterRenameModalProps = {
  inverter: Inverter;
  isOpen: boolean;
  closeModal: () => void;
};

export const InverterRenameModal = ({ inverter, isOpen, closeModal }: InverterRenameModalProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [name, setName] = useState('');

  const [updateInverter, { isLoading }] = useUpdateInverterMutation();

  const inverterLabel = t('solarInverter', 'Solar inverter');

  useEffect(() => {
    if (isOpen) {
      setName(inverter?.name || '');
    }
  }, [inverter?.name, isOpen]);

  const handleSave = async () => {
    try {
      await updateInverter({ uuid: inverter.uuid, name: name.trim() }).unwrap();
      dispatch(
        addToastMessage({
          type: 'success',
          title: t('itemRenamed', '{{item}} renamed', { item: inverterLabel }),
        }),
      );
      closeModal();
    } catch (error) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('renamingTheItemFailed', 'Renaming the {{item}} failed', {
            item: inverterLabel.toLowerCase(),
          }),
          message: t('pleaseTryAgain', 'Please try again.'),
        }),
      );
    }
  };

  const canSave = !isLoading && !!name.trim();

  return (
    <SaveModal
      title={t('renameSolarInverter', 'Rename solar inverter')}
      content={() => ''}
      saveModalContent={handleSave}
      buttonText={t('renameSolarInverter', 'Rename solar inverter')}
      saveButtonDisabled={!canSave}
      closeModal={closeModal}
      open={isOpen}
    >
      <Input
        name="newName"
        label={t('solarInverterName', 'Solar inverter name')}
        value={name}
        onChange={(e) => setName(e.target.value)}
        inputProps={{ onKeyDown: (e) => e.key === 'Enter' && canSave && handleSave() }}
      />
    </SaveModal>
  );
};
