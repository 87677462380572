import { useTranslation } from 'react-i18next';

import { isBoolean, isNil } from 'lodash';
import { Device } from 'models/device';
import { DeviceStatus } from 'models/device.enums';
import { Permission } from 'models/permission.enum';
import { CompanyRole } from 'models/role.enum';

import { Divider } from '@mui/material';

import { SettingsSection } from '@components/settings/SettingsSection';
import { SettingsSectionItem } from '@components/settings/SettingsSectionItem';
import { DEVICE_CONFIGURATION_PARAMETERS } from '@handlers/device/deviceConst';
import { ReactComponent as CellularIcon } from '@images/icons/4g.svg';
import { ReactComponent as CanBusIcon } from '@images/icons/CanBus.svg';
import { ReactComponent as EthernetIcon } from '@images/icons/Ethernet.svg';
import { ReactComponent as RemoteIcon } from '@images/icons/Remote.svg';
import { ReactComponent as SyncIcon } from '@images/icons/Sync.svg';
import { ReactComponent as WifiIcon } from '@images/icons/Wifi.svg';
import { selectPermissionByName, selectUser } from '@services/auth/selectors';
import { useAppSelector } from '@services/hooks';

import {
  getControlChannelsData,
  getNetworksData,
  isChargerManagedByLmc,
  isChargerSupportingConnectivityConf,
} from './deviceConfigurationUtils';

type ChargerConnectivityProps = {
  charger: Device;
  isLoading: boolean;
};

export const ChargerConnectivity = ({ charger, isLoading }: ChargerConnectivityProps) => {
  const { t } = useTranslation();

  const user = useAppSelector(selectUser);
  const canEditDevices = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_EDIT_DEVICES));
  const isOffline = charger.status === DeviceStatus.OFFLINE;

  const { enabledNetworksMap, availableNetworks } = getNetworksData(charger);
  const controlChannelsData = getControlChannelsData(charger);

  const wifiSsid = charger.configuration?.[DEVICE_CONFIGURATION_PARAMETERS.WIFI_SSID.key]?.value;
  const wifiSignalStrength = charger.configuration?.[DEVICE_CONFIGURATION_PARAMETERS.WIFI_SIGNAL_STRENGTH.key]?.value;
  const gsmSignalStrength = charger.configuration?.[DEVICE_CONFIGURATION_PARAMETERS.GSM_SIGNAL_STRENGTH.key]?.value;

  const getValueComponent = (value: string | boolean | null) => {
    if (isLoading || isNil(value)) {
      return () => null;
    }
    if (isOffline) {
      return () => '-';
    }
    if (isBoolean(value)) {
      return () => (value ? t('on', 'On') : t('off', 'Off'));
    }
    return () => value;
  };

  return (
    <SettingsSection
      title={t('connectivity', 'Connectivity')}
      editPath={canEditDevices ? './connectivity' : undefined}
      editDisabled={isOffline}
    >
      {isChargerSupportingConnectivityConf(charger) && (
        <>
          <SettingsSectionItem
            Icon={CellularIcon}
            label={t('cellular', 'Cellular')}
            component={getValueComponent(enabledNetworksMap.GSM)}
            {...(enabledNetworksMap.GSM &&
              !isNil(gsmSignalStrength) && {
                subLabel: t('signalStrength', 'Signal strength'),
                subComponent: getValueComponent(`${gsmSignalStrength}%`),
              })}
          />
          <SettingsSectionItem
            Icon={WifiIcon}
            label={t('wifi', 'WiFi')}
            component={getValueComponent(enabledNetworksMap.WIFI ? wifiSsid : false)}
            {...(enabledNetworksMap.WIFI &&
              !isNil(wifiSignalStrength) && {
                subLabel: t('signalStrength', 'Signal strength'),
                subComponent: getValueComponent(`${wifiSignalStrength}%`),
              })}
          />
          <SettingsSectionItem
            Icon={EthernetIcon}
            label={t('ethernet', 'Ethernet')}
            component={getValueComponent(enabledNetworksMap.ETHERNET)}
          />
          <SettingsSectionItem
            Icon={SyncIcon}
            label={t('activeNetwork', 'Active network', { count: availableNetworks.length })}
            component={getValueComponent(availableNetworks.join(', '))}
            hidden={!availableNetworks.length}
          />
          <Divider />
        </>
      )}
      <SettingsSectionItem
        Icon={CanBusIcon}
        label={t('loadManagement', 'Load management')}
        component={getValueComponent(isChargerManagedByLmc(charger))}
      />
      <SettingsSectionItem
        Icon={CanBusIcon}
        label={t('lmcConnection', 'LMC connection')}
        component={getValueComponent(controlChannelsData.lmcConnectionType)}
        hidden={!controlChannelsData.isSyncWithLmcNetwork}
      />
      <SettingsSectionItem
        Icon={RemoteIcon}
        label={t('remoteControlOverLmc', 'Remote control over LMC')}
        component={getValueComponent(controlChannelsData.isRemoteControlOverLmc)}
        hidden={!controlChannelsData.isSyncWithLmcNetwork || (!user?.admin && user?.role !== CompanyRole.INSTALLER)}
      />
    </SettingsSection>
  );
};
