import { useTranslation } from 'react-i18next';

import { useMediaQuery, useTheme } from '@mui/material';

import { AmplitudeEvent } from '@handlers/amplitude/data';
import { ReactComponent as DevicesIcon } from '@images/icons/Charger.svg';
import { ReactComponent as CarIcon } from '@images/icons/nav/Car.svg';
import { ReactComponent as ChartIcon } from '@images/icons/nav/Chart.svg';
import { ReactComponent as DashboardIcon } from '@images/icons/nav/Dashboard.svg';
import { ReactComponent as LogsIcon } from '@images/icons/nav/Logs.svg';
import { ReactComponent as MapIcon } from '@images/icons/nav/Map.svg';
import { ReactComponent as SiteIcon } from '@images/icons/nav/Sites.svg';
import { ReactComponent as UserIcon } from '@images/icons/nav/User.svg';
import { selectUser } from '@services/auth/selectors';
import { useAppSelector } from '@services/hooks';
import { useWorkspaceGroupsAndRoles } from '@views/Workspace/workspaceHooks';

import { isSubdomainPartner } from './routesUtil';

export type NavigationItem = {
  key: string;
  name: string;
  path?: string;
  icon?: React.ElementType;
  children?: NavigationItem[];
  hidden?: boolean;
  amplitudeEvent?: AmplitudeEvent;
  isListedUnderMoreForMobile?: boolean;
};

export const useNavigationItems = (): NavigationItem[] => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const user = useAppSelector(selectUser);

  const isVoolAdmin = !!user?.admin;
  const isInstaller = !!user?.features?.isInstaller;
  const { isWorkspaceAdmin, isGroupManager } = useWorkspaceGroupsAndRoles();

  const partnerRoutes: NavigationItem[] = [
    {
      name: t('workspace', 'Workspace', { count: 2 }),
      key: 'workspaces',
      path: '/workspaces',
      icon: UserIcon,
    },
    {
      name: t('reporting', 'Reporting'),
      key: 'reporting',
      path: '/reporting',
      icon: ChartIcon,
    },
    {
      name: t('devices', 'Devices'),
      key: 'devices',
      path: '/partner-devices',
      icon: DevicesIcon,
    },
  ];

  const routes: NavigationItem[] = [
    {
      name: t('backoffice', 'Backoffice'),
      key: 'backoffice',
      path: '/backoffice',
      children: [
        {
          name: t('devices', 'Devices'),
          key: 'backoffice-devices',
          path: '/devices',
          icon: DevicesIcon,
          amplitudeEvent: AmplitudeEvent.OPEN_BACKOFFICE_DEVICES_VIEW,
          isListedUnderMoreForMobile: true,
        },
        {
          name: t('users', 'Users'),
          key: 'backoffice-users',
          path: '/users',
          icon: UserIcon,
          amplitudeEvent: AmplitudeEvent.OPEN_BACKOFFICE_USERS_VIEW,
          isListedUnderMoreForMobile: true,
          hidden: !isVoolAdmin,
        },
      ],
      hidden: !isVoolAdmin && !isInstaller,
    },
    {
      name: t('dashboard', 'Dashboard'),
      key: 'dashboard',
      path: '/dashboard',
      icon: DashboardIcon,
      hidden: isVoolAdmin,
      amplitudeEvent: AmplitudeEvent.OPEN_DASHBOARD_VIEW,
    },
    {
      name: t('map', 'Map'),
      key: 'map',
      path: '/map',
      icon: MapIcon,
      hidden: true,
      amplitudeEvent: AmplitudeEvent.OPEN_MAP_VIEW,
      isListedUnderMoreForMobile: true,
    },
    {
      name: t('sites', 'Sites'),
      key: 'sites',
      path: '/sites',
      icon: SiteIcon,
      amplitudeEvent: AmplitudeEvent.OPEN_SITES_VIEW,
    },
    {
      name: t('vehicles', 'Vehicles'),
      key: 'vehicles',
      path: '/vehicles',
      icon: CarIcon,
      amplitudeEvent: AmplitudeEvent.OPEN_VEHICLES_VIEW,
    },
    {
      name: t('reporting', 'Reporting'),
      key: 'reporting',
      path: '/reporting',
      icon: ChartIcon,
      amplitudeEvent: AmplitudeEvent.OPEN_REPORTING_VIEW,
    },
    {
      name: t('accessManagement', 'Access Management'),
      key: 'access-management',
      path: '/access-management',
      icon: UserIcon,
      hidden: !isVoolAdmin && !isWorkspaceAdmin && !isGroupManager,
      amplitudeEvent: AmplitudeEvent.OPEN_WORKSPACE_VIEW,
    },
    {
      name: t('logs', 'Logs'),
      key: 'logs',
      path: '/logs',
      icon: LogsIcon,
      amplitudeEvent: AmplitudeEvent.OPEN_LOGS_VIEW,
      isListedUnderMoreForMobile: true,
    },
  ];

  const enabledRoutes = (isSubdomainPartner() ? partnerRoutes : routes)
    .map((route) => ({
      ...route,
      ...(route.children && {
        children: route.children
          .filter((item) => !item.hidden)
          .map((childRoute) => ({
            ...childRoute,
            path: `${route.path}${childRoute.path}`,
          })),
      }),
    }))
    .filter((item) => !item.hidden);

  if (isMobile) {
    const allRoutes = enabledRoutes.flatMap((route) => route.children ?? route);
    const primaryMobileRoutes = allRoutes.filter((route) => !route.isListedUnderMoreForMobile);
    const moreRoutes = allRoutes.filter((route) => route.isListedUnderMoreForMobile);

    return [
      ...primaryMobileRoutes,
      {
        name: 'More',
        key: 'more',
        children: moreRoutes,
      },
    ];
  }

  return enabledRoutes;
};
