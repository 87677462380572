import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useOutletContext } from 'react-router-dom';

import { RFIDCard, RFIDCardDto } from 'models/rfid-card';

import { useUpdateRfidCardMutation } from '@services/rfidCards/endpoints';
import { addToastMessage } from '@services/toastMessages';

import RfidCardDetailsSidebarBase from './RfidCardDetailsSidebarBase';

const RfidCardDetailsSidebar = () => {
  const { t } = useTranslation();
  const { rfidCard } = useOutletContext<{ rfidCard?: RFIDCard }>();
  const dispatch = useDispatch();

  const [updateRfidCard, { isLoading }] = useUpdateRfidCardMutation();

  const rfidCardLabel = t('rfidCard', 'RFID card');

  const saveValues = async (attrs: RFIDCardDto) => {
    try {
      await updateRfidCard(attrs).unwrap();
      dispatch(
        addToastMessage({
          type: 'success',
          title: t('itemUpdated', '{{item}} updated', { item: rfidCardLabel }),
          message: t('youCanSeeTheCardInTheList', 'You can see the card in your RFID cards list.'),
        }),
      );
      return true;
    } catch (error) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('updatingTheItemFailed', 'Updating the {{item}} failed', { item: rfidCardLabel }),
          message: t('pleaseTryAgain', 'Please try again.'),
        }),
      );
      return false;
    }
  };

  return (
    <RfidCardDetailsSidebarBase
      rfidCard={rfidCard}
      isIdentifierImmutable
      title={`${rfidCardLabel} ${t('details', 'Details').toLowerCase()}`}
      isSaveLoading={isLoading}
      onSaveValues={saveValues}
    />
  );
};

export default RfidCardDetailsSidebar;
