import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { Site } from 'models/site';

import EditingSidebarBase from '@components/sidebars/EditingSidebarBase';
import { ReactComponent as ArrowLeftIcon } from '@images/icons/arrow_left.svg';
import { ReactComponent as CheckImage } from '@images/icons/check.svg';
import { useAppDispatch } from '@services/hooks';
import { useUpdateSiteMutation } from '@services/sites/endpoints';
import { addToastMessage } from '@services/toastMessages';
import { PayoutDetails } from '@views/Workspace/sidebars/payoutDetails/PayoutDetails';
import { usePayoutDetailsForm } from '@views/Workspace/sidebars/payoutDetails/usePayoutDetailsForm';

export const WorkspaceSetupPayoutSidebar = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { site } = useOutletContext<{ site: Site }>();
  const [updateSite] = useUpdateSiteMutation();
  const { form, isLoading, isSaving, saveDisabled, submitPayoutDetails } = usePayoutDetailsForm();

  const saveLabel = (
    <div className="flex items-center justify-center">
      {t('finish', 'Finish')}
      <ArrowLeftIcon className="h-4 rotate-180 pr-2 [&_path]:fill-white" />
    </div>
  );

  const onSaveValues = async () => {
    try {
      const success = await submitPayoutDetails();
      if (success) {
        await updateSite({
          uuid: site.uuid,
          publicFlag: 1,
        }).unwrap();
        dispatch(
          addToastMessage({
            type: 'success',
            title: t('publicChargingEnabled', 'Public charging is enabled for {{siteName}}', { siteName: site.name }),
          }),
        );
      }
      return success;
    } catch (error: any) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('updatingThePublicChargingFailed', 'Updating the public charging failed'),
          message: error?.data?.message,
        }),
      );
      return false;
    }
  };

  return (
    <EditingSidebarBase
      title={t('workspaceSetup', 'Workspace setup')}
      discardLabel={t('cancel', 'Cancel')}
      saveLabel={saveLabel}
      containerClassName="space-y-7 font-poppins"
      saveDisabled={saveDisabled}
      updateLoading={isSaving}
      anyDataChanged={form.formState.isDirty}
      onSaveValues={onSaveValues}
    >
      <div className="flex pb-4">
        <div className="flex w-full justify-center space-y-2 border-b-2 border-primary pb-2 font-poppins">
          <CheckImage className="mr-1 h-5 w-5 pt-1 [&_path]:fill-black" />
          {t('details', 'Details')}
        </div>
        <div className="flex w-full justify-center border-b-2 border-primary font-poppins font-semibold">
          2. {t('payout', 'Payout')}
        </div>
      </div>
      <PayoutDetails form={form} isLoading={isLoading} />
    </EditingSidebarBase>
  );
};
