import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { Tab, TabsWithMenu } from '@components/tabs/TabsWithMenu';
import { useSubscribeToInactiveCompanyPusherChannel } from '@hooks/pusherChannelHooks';
import { useGetDevicesQuery } from '@services/devices/endpoints';
import { useAppSelector } from '@services/hooks';
import { useGetInvertersQuery } from '@services/inverters/endpoints';
import { useGetRfidCardsQuery } from '@services/rfidCards/endpoints';
import { selectRfidCardsByCompanyUuid } from '@services/rfidCards/selectors';
import { useGetSitesQuery } from '@services/sites/endpoints';
import { useGetUsersQuery } from '@services/users/endpoints';
import { useWorkspaceGroupsAndRoles } from '@views/Workspace/workspaceHooks';

export const PartnerWorkspaceView = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { companyUuid } = useParams();

  const { data: sites } = useGetSitesQuery({ companyUuid });
  const { data: devicesData } = useGetDevicesQuery({ companyUuid });
  const { data: inverters } = useGetInvertersQuery({ companyUuid });
  const { groups } = useWorkspaceGroupsAndRoles({ withUsers: true, companyUuid });
  const { data: users } = useGetUsersQuery({ companyUuid }, { refetchOnMountOrArgChange: true });
  useGetRfidCardsQuery({ companyUuid }, { refetchOnMountOrArgChange: true });
  const rfidCards = useAppSelector((state) => selectRfidCardsByCompanyUuid(state, companyUuid));

  const totalDevices = (devicesData?.devices?.length ?? 0) + (inverters?.length ?? 0);

  useSubscribeToInactiveCompanyPusherChannel(companyUuid);

  const tabs: Tab[] = [
    {
      label: t('sites', 'Sites', { count: sites?.length }),
      path: 'sites',
    },
    {
      label: t('devices', 'Devices', { count: totalDevices }),
      path: 'devices',
    },
    {
      label: t('rfidCards', 'RFID Cards', { count: rfidCards.length }),
      path: 'rfidCards',
    },
    {
      label: t('members', 'Members', { count: users?.length }),
      path: 'members',
    },
    {
      label: t('groups', 'Groups', { count: groups?.length }),
      path: 'groups',
    },
  ];
  const activeTab = tabs.find((tab) => pathname.includes(tab.path)) ?? tabs[0];
  const areTabsVisible = pathname.split('/').length < 5;

  return (
    <>
      {areTabsVisible && <TabsWithMenu activeTab={activeTab} tabs={tabs} />}
      <Outlet />
    </>
  );
};
