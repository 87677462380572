import { Dispatch, ReactNode, SetStateAction, createContext, useMemo, useRef } from 'react';

import dayjs from 'dayjs';
import { EventLogDto } from 'models/event-log';

import { StyledMenuItemProps } from '@components/atoms/StyledMenuItem';
import { FilterMenuOption } from '@components/filters';
import {
  FiltersBaseContextType,
  FiltersDataContextType,
  getFiltersBaseContextDefaultValues,
} from '@components/filters/context/FiltersContext';
import { useDataFiltering } from '@components/filters/hooks/useDataFiltering';
import { useLazyEventLogDtosQuery } from '@services/eventLog/endpoints';
import { useChargerMenu } from '@views/reporting/hooks/useChargerMenu';
import { useSiteMenu } from '@views/reporting/hooks/useSiteMenu';

import { useEventLogTypeMenu } from '../hooks/useEventLogTypeMenu';

type EventLogFilterMenuOption = Extract<FilterMenuOption, 'site' | 'charger' | 'category'>;

type EventLogContextType = FiltersBaseContextType<EventLogFilterMenuOption> & FiltersDataContextType<EventLogDto>;

export const EventLogContext = createContext<EventLogContextType>({
  ...getFiltersBaseContextDefaultValues<EventLogDto>(),
  filterOptionsMap: {
    site: [],
    charger: [],
    category: [],
  },
});

export const EventLogProvider = ({ deviceUuid, children }: { deviceUuid?: string; children?: ReactNode }) => {
  const [fetchEventLogs, { isFetching: dataLoading }] = useLazyEventLogDtosQuery();
  const { siteMenuOptions, setSiteMenuOptions } = useSiteMenu();
  const { chargerMenuOptions, setChargerMenuOptions } = useChargerMenu();
  const { typeMenuOptions, setTypeMenuOptions } = useEventLogTypeMenu();

  const preventLoading = useRef(!!deviceUuid);

  const filterOptionsMap: Record<EventLogFilterMenuOption, StyledMenuItemProps[]> = useMemo(() => {
    if (deviceUuid && chargerMenuOptions.length && preventLoading.current) {
      const option = chargerMenuOptions.find((option) => option.uuid === deviceUuid);
      if (option) {
        option.checked = true;
        preventLoading.current = false;
      }
    }
    return {
      site: siteMenuOptions,
      charger: chargerMenuOptions,
      category: typeMenuOptions,
    };
  }, [siteMenuOptions, chargerMenuOptions, typeMenuOptions]);

  const filterMap: Record<EventLogFilterMenuOption, Dispatch<SetStateAction<StyledMenuItemProps[]>>> = {
    site: setSiteMenuOptions,
    charger: setChargerMenuOptions,
    category: setTypeMenuOptions,
  };

  const uuidFilterMap: Record<EventLogFilterMenuOption, keyof EventLogDto> = {
    site: 'siteUuid',
    charger: 'deviceUuid',
    category: 'type',
  };

  const dataFiltering = useDataFiltering<EventLogDto, EventLogFilterMenuOption>(
    {
      filterOptionsMap,
      uuidFilterMap,
      filterMap,
      pageSize: 50,
      initialOrderBy: 'addTime',
      preventLoading: preventLoading.current,
      customDateRange: {
        startDate: dayjs().subtract(1, 'week').startOf('day'),
        endDate: dayjs().endOf('day'),
      },
    },
    fetchEventLogs,
  );

  const value = useMemo(
    () => ({
      ...dataFiltering,
      dataLoading,
      filterOptionsMap,
    }),
    [filterOptionsMap, dataFiltering],
  );

  return <EventLogContext.Provider value={value}>{children}</EventLogContext.Provider>;
};
