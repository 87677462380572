import { CompanyWithUser } from 'models/company';

import apiSlice from '@services/api';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPartnerCompanies: builder.query<CompanyWithUser[], void>({
      query: () => ({
        url: `partners/companies`,
        method: 'GET',
      }),
      transformResponse: (response: { data: CompanyWithUser[] }) => response.data,
      providesTags: ['PartnerContext'],
    }),
  }),
});

export const { useGetPartnerCompaniesQuery } = extendedApi;
export const partnerApi = extendedApi;
