import { Fragment } from 'react';

import PropTypes from 'prop-types';

import { Dialog, Transition } from '@headlessui/react';

const SidebarBase = ({ widthClass, children, isOpen, onClose }) => (
  <Transition.Root show={isOpen} as={Fragment}>
    <Dialog as="div" className="fixed inset-0 z-40" onClose={() => {}}>
      <Transition.Child
        as={Fragment}
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Dialog.Overlay className="fixed inset-0 cursor-pointer bg-gray-100/60" onClick={onClose} />
      </Transition.Child>
      <Transition.Child
        as={Fragment}
        enter="transition ease-in-out duration-300 transform"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        {/* TODO: improve/change shadow? */}
        <div className={`fixed bottom-0 right-0 top-0 w-full bg-white shadow ${widthClass}`}>{children}</div>
      </Transition.Child>
    </Dialog>
  </Transition.Root>
);

SidebarBase.propTypes = {
  widthClass: PropTypes.string,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

SidebarBase.defaultProps = {
  widthClass: 'sm:max-w-[397px]',
};

export default SidebarBase;
