export enum ErrorCode {
  CAR_NOT_FOUND = 'car_not_found',
  COMPANY_NOT_FOUND = 'company_not_found',
  DEVICE_NOT_CONNECTED = 'device_not_connected',
  DEVICE_NOT_FOUND = 'device_not_found',
  DEVICE_NOT_ONLINE = 'device_not_online',
  WRONG_DEVICE_TYPE = 'wrong_device_type',
  NOT_FOUND = 'not_found',
  OTHER_ERROR = 'other_error',
  SITE_MARKET_PRICE_NOT_FOUND = 'site_market_price_not_found',
  SITE_NOT_FOUND = 'site_not_found',
  SITE_PRICING_MODEL_NOT_FOUND = 'site_pricing_model_not_found',
  SITE_PRICING_MODEL_NOT_VALID = 'site_pricing_model_not_valid',
  SITE_TIMEZONE_NOT_FOUND = 'site_timezone_not_found',
  UNAUTHENTICATED = 'unauthenticated',
  UNAUTHORIZED = 'unauthorized',
  UNAUTHORIZED_PARTNER = 'unauthorized_partner',
  USER_IS_NOT_INSTALLER = 'user_is_not_installer',
  VALIDATION_ERROR = 'validation_error',
}
