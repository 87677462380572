import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { BreadcrumbsBase } from '@components/breadcrumbs/BreadcrumbsBase';
import { selectActiveCompany } from '@services/companies/selectors';
import { selectDeviceByUuid } from '@services/devices/selectors';
import { useAppSelector } from '@services/hooks';
import { selectInverterByUuid } from '@services/inverters/selectors';
import { useGetPartnerCompaniesQuery } from '@services/partners/endpoints';
import { selectSiteByUuid } from '@services/sites/selectors';
import { makeSelectVehicleByUuid } from '@services/vehicles/selectors';

export const SiteBreadcrumbs = () => {
  const { t } = useTranslation();
  const { siteUuid } = useParams();
  const site = useAppSelector((state) => (siteUuid ? selectSiteByUuid(state, siteUuid) : null));

  return (
    <BreadcrumbsBase
      breadcrumbsItems={[
        { label: t('sites', 'Sites'), path: '/sites' },
        ...(site?.uuid
          ? [
              {
                label: site.name,
                path: `/sites/${siteUuid}`,
              },
            ]
          : []),
      ]}
    />
  );
};

export const DeviceBreadcrumbs = () => {
  const { t } = useTranslation();
  const { deviceUuid } = useParams();
  const device = useAppSelector((state) => (deviceUuid ? selectDeviceByUuid(state, deviceUuid) : null));
  const site = useAppSelector((state) => (device?.siteUuid ? selectSiteByUuid(state, device.siteUuid) : null));

  return (
    <BreadcrumbsBase
      breadcrumbsItems={[
        { label: t('sites', 'Sites'), path: '/sites' },
        ...(site?.uuid && device?.uuid
          ? [
              { label: site.name, path: `/sites/${site.uuid}` },
              { label: device.name || device.serialNumber, path: `/devices/${deviceUuid}` },
            ]
          : []),
      ]}
    />
  );
};

export const InverterBreadcrumbs = () => {
  const { t } = useTranslation();
  const { inverterUuid } = useParams();
  const inverter = useAppSelector((state) => (inverterUuid ? selectInverterByUuid(state, inverterUuid) : null));
  const site = useAppSelector((state) => (inverter?.siteUuid ? selectSiteByUuid(state, inverter.siteUuid) : null));

  return (
    <BreadcrumbsBase
      breadcrumbsItems={[
        { label: t('sites', 'Sites'), path: '/sites' },
        ...(site?.uuid && inverter?.uuid
          ? [
              { label: site.name, path: `/sites/${site.uuid}` },
              { label: inverter.name || inverter.serialNumber, path: `/devices/${inverterUuid}` },
            ]
          : []),
      ]}
    />
  );
};

export const VehicleBreadcrumbs = () => {
  const { t } = useTranslation();
  const { vehicleUuid } = useParams();
  const selectVehicleByUuid = useMemo(makeSelectVehicleByUuid, []);
  const vehicle = useAppSelector((state) => (vehicleUuid ? selectVehicleByUuid(state, vehicleUuid) : null));
  const vehicleName = vehicle?.name || [vehicle?.brand, vehicle?.model].filter(Boolean).join(' - ');

  return (
    <BreadcrumbsBase
      breadcrumbsItems={[
        { label: t('vehicles', 'Vehicles'), path: '/vehicles' },
        ...(vehicle?.uuid ? [{ label: vehicleName, path: `/vehicles/${vehicleUuid}` }] : []),
      ]}
    />
  );
};

export const WorkspaceBreadcrumbs = () => {
  const { t } = useTranslation();
  const activeCompany = useAppSelector(selectActiveCompany);
  const { pathname } = useLocation();
  const pathSegments = pathname.split('/').filter((path) => path !== '');
  const [path] = pathSegments;

  const label = activeCompany?.name || t('workspace', 'Workspace');

  return <BreadcrumbsBase breadcrumbsItems={[{ label, path }]} />;
};

export const BackofficeBreadcrumbs = () => {
  const { pathname } = useLocation();
  const pathSegments = pathname.split('/').filter((path) => path !== '');
  const pathSegment = pathSegments[1]?.toLowerCase() || '';
  const { t } = useTranslation();

  const labels: Record<string, string> = {
    devices: t('devices', 'Devices'),
    users: t('users', 'Users'),
  };

  return (
    <BreadcrumbsBase
      breadcrumbsItems={[
        { label: t('backoffice', 'Backoffice'), path: '/backoffice' },
        ...(labels[pathSegment]
          ? [
              {
                label: labels[pathSegment],
                path: `/backoffice/${pathSegment}`,
              },
            ]
          : []),
      ]}
    />
  );
};

export const ReportingBreadcrumbs = () => {
  const { pathname } = useLocation();
  const pathSegments = pathname.split('/').filter((path) => path !== '');
  const pathSegment = pathSegments[1]?.toLowerCase() || '';
  const { t } = useTranslation();

  const labels: Record<string, string> = {
    sessions: t('sessions', 'Sessions'),
    scheduler: t('scheduler', 'Scheduler'),
  };

  return (
    <BreadcrumbsBase
      breadcrumbsItems={[
        { label: t('reporting', 'Reporting'), path: '/reporting' },
        ...(labels[pathSegment]
          ? [
              {
                label: labels[pathSegment],
                path: `/reporting/${pathSegment}`,
              },
            ]
          : []),
      ]}
    />
  );
};

export const WorkspacesBreadcrumbs = () => {
  const { t } = useTranslation();
  const { companyUuid, siteUuid, deviceUuid } = useParams();
  const { data: partnerCompanies } = useGetPartnerCompaniesQuery();
  const partnerCompany = partnerCompanies?.find((company) => company?.uuid === companyUuid);
  const device = useAppSelector((state) => (deviceUuid ? selectDeviceByUuid(state, deviceUuid) : undefined));
  const site = useAppSelector((state) =>
    siteUuid || device?.siteUuid ? selectSiteByUuid(state, siteUuid || device?.siteUuid) : undefined,
  );

  return (
    <BreadcrumbsBase
      breadcrumbsItems={[
        { label: t('workspace', 'Workspace', { count: 2 }), path: '/workspaces' },
        ...(partnerCompany?.uuid ? [{ label: partnerCompany.name, path: `/workspaces/${partnerCompany.uuid}` }] : []),
        ...(site?.uuid ? [{ label: site.name, path: `/workspaces/${companyUuid}/sites/${site.uuid}` }] : []),
        ...(device?.uuid
          ? [{ label: device.name || device.serialNumber, path: `/workspaces/${companyUuid}/devices/${device.uuid}` }]
          : []),
      ]}
    />
  );
};
