import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { skipToken } from '@reduxjs/toolkit/query';
import { DeviceStatus, DeviceType } from 'models/device.enums';

import { isDeviceFullyLoaded } from '@helpers/deviceHelper';
import { useSubscribeToInactiveCompanyPusherChannel, useSubscribeToPusherChannel } from '@hooks/pusherChannelHooks';
import { useGetDeviceQuery, useGetDeviceStatusQuery } from '@services/devices/endpoints';
import { selectDeviceByUuid } from '@services/devices/selectors';
import { useAppSelector } from '@services/hooks';
import { useGetSiteQuery } from '@services/sites/endpoints';
import { selectSiteByUuid } from '@services/sites/selectors';
import { SingleChargerBase } from '@views/Chargers/SingleCharger/SingleChargerBase';
import { SingleLmc } from '@views/LMC/SingleLmc';

import DeviceEditModal from './deviceEditModal/DeviceEditModal';

export const SingleDevice = () => {
  const { deviceUuid } = useParams();

  const device = useAppSelector((state) => (deviceUuid ? selectDeviceByUuid(state, deviceUuid) : undefined));
  const site = useAppSelector((state) => (device ? selectSiteByUuid(state, device.siteUuid) : undefined));

  const [pullDeviceAgain, setPullDeviceAgain] = useState(0);
  const [showEditDevice, setShowEditDevice] = useState(false);
  const [skipStatus, setSkipStatus] = useState(true);

  useGetDeviceQuery(
    { uuid: deviceUuid, pullDeviceAgain },
    {
      skip: isDeviceFullyLoaded(device) && !pullDeviceAgain,
      refetchOnMountOrArgChange: true,
    },
  );

  useGetDeviceStatusQuery(deviceUuid, {
    refetchOnMountOrArgChange: true,
    skip: skipStatus,
  });

  useGetSiteQuery(device?.siteUuid ?? skipToken, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => setSkipStatus(!device?.status || device?.status === DeviceStatus.OFFLINE), [device]);

  useSubscribeToInactiveCompanyPusherChannel(device?.companyUuid);
  useSubscribeToPusherChannel(device?.metricsPusherChannel);

  return (
    device &&
    site && (
      <>
        {device.type === DeviceType.CHARGER && (
          <SingleChargerBase charger={device} site={site} openEditModal={() => setShowEditDevice(true)} />
        )}
        {device.type === DeviceType.LMC && (
          <SingleLmc lmc={device} site={site} openEditModal={() => setShowEditDevice(true)} />
        )}
        {showEditDevice && (
          <DeviceEditModal
            open={showEditDevice}
            closeModal={() => setShowEditDevice(false)}
            pullDeviceAgain={() => setPullDeviceAgain(pullDeviceAgain + 1)}
            device={device}
            site={site}
          />
        )}
      </>
    )
  );
};
