import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams, useSearchParams } from 'react-router-dom';

import { orderBy } from 'lodash';
import { Permission } from 'models/permission.enum';
import { User } from 'models/user';

import { Box, useMediaQuery, useTheme } from '@mui/material';

import { Avatar } from '@components/avatar/Avatar';
import { MenuComponent } from '@components/dropdown/MenuComponent';
import SortableTable from '@components/tables/SortableTable';
import kebabIcon from '@images/icons/Kebab.svg';
import { selectPermissionByName, selectUser } from '@services/auth/selectors';
import { useAppDispatch, useAppSelector } from '@services/hooks';
import { addToastMessage } from '@services/toastMessages';
import { useRemoveUserFromWorkspaceMutation, useResendInviteForUserMutation } from '@services/users/endpoints';
import { selectUsers } from '@services/users/selectors';

import { MemberGroupsAssignment } from './MemberGroupsAssignment';
import MemberInfo from './MemberInfo';
import { MemberRoleMenu } from './MemberRoleMenu';
import { useWorkspaceGroupsAndRoles } from './workspaceHooks';

type WorkspaceMembersDataTableColumn = {
  key: string;
  label: string;
  tdClassName: string;
  sortingDisabled?: boolean;
  getTdClassName?: (member: User) => string;
  value: (member: User) => ReactNode;
};

export const WorkspaceMembersView = () => {
  const { t } = useTranslation();
  const { companyUuid } = useParams();
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();
  const highlightedMemberUuid = searchParams.get('highlighted');
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const self = useAppSelector(selectUser);
  const users = useAppSelector(selectUsers);
  const canEditUsers = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_EDIT_USERS));
  const canDeleteUsers = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_DELETE_USERS));
  const { groups } = useWorkspaceGroupsAndRoles({ withUsers: true, companyUuid });

  const sortedMembers = useMemo(() => orderBy(users, ['inviteFlag'], ['desc']), [users]);

  const [resendInviteForUser] = useResendInviteForUserMutation();
  const [removeUserFromWorkspace] = useRemoveUserFromWorkspaceMutation();

  const [highlightedMemberScrollTargetElem, setHighlightedMemberScrollTargetElem] = useState<HTMLElement>();

  useEffect(() => highlightedMemberScrollTargetElem?.scrollIntoView?.(), [highlightedMemberScrollTargetElem]);

  const handleResendInviteForUser = async (uuid: string) => {
    try {
      await resendInviteForUser(uuid).unwrap();
      dispatch(addToastMessage({ type: 'success', title: t('theInviteHasBeenResent', 'The invite has been resent') }));
    } catch (error) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('resendingTheInviteFailed', 'Resending the invite failed'),
          message: t('pleaseTryAgain', 'Please try again.'),
        }),
      );
    }
  };

  const handleRevokeInviteForUser = async (uuid: string) => {
    try {
      await removeUserFromWorkspace(uuid).unwrap();
      dispatch(
        addToastMessage({ type: 'success', title: t('theInviteHasBeenRevoked', 'The invite has been revoked') }),
      );
    } catch (error) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('revokingTheInviteFailed', 'Revoking the invite failed'),
          message: t('pleaseTryAgain', 'Please try again.'),
        }),
      );
    }
  };

  const handleRemoveUserFromWorkspace = async (uuid: string) => {
    try {
      await removeUserFromWorkspace(uuid).unwrap();
      dispatch(addToastMessage({ type: 'success', title: t('theUserHasBeenRemoved', 'The user has been removed') }));
    } catch (error) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('removingTheUserFailed', 'Removing the user failed'),
          message: t('pleaseTryAgain', 'Please try again.'),
        }),
      );
    }
  };

  const getMenuOptionsForInvitation = (member: User) => [
    {
      title: t('resendInvite', 'Resend invite'),
      key: 'resendInvite',
      hidden: !canEditUsers,
      onClick: () => handleResendInviteForUser(member.uuid),
    },
    {
      title: t('revokeInvite', 'Revoke invite'),
      key: 'revokeInvite',
      className: 'text-vermillion',
      hidden: !canDeleteUsers,
      onClick: () => handleRevokeInviteForUser(member.uuid),
    },
  ];
  const getMenuOptionsForMember = (member: User) => [
    {
      title: t('removeFromWorkspace', 'Remove from workspace'),
      key: 'removeFromWorkspace',
      className: 'text-vermillion',
      hidden: !canDeleteUsers,
      onClick: () => handleRemoveUserFromWorkspace(member.uuid),
    },
  ];

  const getTdClassName = (member: User) =>
    highlightedMemberUuid && member.uuid === highlightedMemberUuid ? 'bg-gray-100' : '';

  const tableColumns: WorkspaceMembersDataTableColumn[] = [
    {
      key: 'name',
      label: t('name', 'Name'),
      tdClassName: 'flex gap-4 py-2 px-4',
      getTdClassName,
      value: (member) => (
        <Box display="flex" alignItems="center" gap={1} py={1}>
          <Avatar name={member.name} email={member.email} bigger hidden={!isDesktop} />
          <MemberInfo
            member={member}
            ref={
              highlightedMemberUuid && member.uuid === highlightedMemberUuid
                ? setHighlightedMemberScrollTargetElem
                : undefined
            }
          />
        </Box>
      ),
    },
    {
      key: 'role',
      label: t('role', 'Role'),
      sortingDisabled: true,
      tdClassName: 'py-2 px-4',
      getTdClassName,
      value: (member) => <MemberRoleMenu member={member} />,
    },
    ...(groups?.length
      ? [
          {
            key: 'group',
            label: t('group', 'Group'),
            tdClassName: 'w-1/3 py-2 px-4',
            getTdClassName,
            value: (member: User) => <MemberGroupsAssignment member={member} companyUuid={companyUuid} />,
          },
        ]
      : []),
    {
      key: 'menu',
      label: '',
      sortingDisabled: true,
      tdClassName: 'w-10 pr-1.5',
      getTdClassName,
      value: (member) =>
        member.uuid !== self?.uuid && (
          <MenuComponent
            menuItems={member.inviteFlag ? getMenuOptionsForInvitation(member) : getMenuOptionsForMember(member)}
            buttonIcon={kebabIcon}
          />
        ),
    },
  ];

  return (
    <>
      <SortableTable columns={tableColumns} data={sortedMembers} keyAttr="uuid" sortingStorageKey="workspaceMembers" />
      <Outlet />
    </>
  );
};
