import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';

import Card from '@components/atoms/Card';
import ComponentError from '@components/atoms/ComponentError';
import { SummaryCard } from '@components/cards/SummaryCard';
import TimePeriodSelectorHeader from '@components/filterComponents/TimePeriodSelectorHeader';
import useActiveCurrency from '@hooks/useActiveCurrency';
import apiSlice from '@services/api';
import { selectCompanyDashboard } from '@services/dashboard/selectors';
import { useGetSitesQuery } from '@services/sites/endpoints';
import { selectActiveCompanySites } from '@services/sites/selectors';

import SitesTable from './SitesTable';

const SiteListView = () => {
  const { t } = useTranslation();
  const currency = useActiveCurrency();
  const setLoading = useOutletContext();
  const sites = useSelector(selectActiveCompanySites);
  const companyDashboard = useSelector(selectCompanyDashboard);

  const [selectedDuration, setSelectedDuration] = useState(null);

  const { isSuccess, isError, error } = useGetSitesQuery({});

  apiSlice.useGetCompanyDashboardQuery(
    { duration: selectedDuration },
    {
      skip: !selectedDuration,
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    setLoading(!sites.length);
    if (isSuccess || isError) {
      setLoading(false);
    }

    return () => {
      setLoading(false);
    };
  }, [isSuccess, isError]);

  if (isError) {
    return (
      <Card title={t('sites', 'Sites')} className="w-full">
        <ComponentError error={error} />
      </Card>
    );
  }

  return (
    <div className="relative">
      <TimePeriodSelectorHeader
        selectedDuration={selectedDuration}
        setSelectedDuration={setSelectedDuration}
        labelPrefix={t('allSitesOverviewFor', 'All sites overview for')}
        storageKey="dashboardDuration"
      />
      <div className="mb-10 flex items-stretch gap-4">
        <SummaryCard
          label={t('revenue', 'Revenue')}
          unit={currency.symbol}
          value={companyDashboard?.totalEnergyRevenue}
          valueDecimalPlaces={2}
          trend={companyDashboard?.totalEnergyRevenueTrend}
          tooltip={t('vatNotIncluded', 'VAT not included')}
        />
        <SummaryCard
          label={companyDashboard?.totalEnergyProfit < 0 ? t('loss', 'Loss') : t('profit', 'Profit')}
          unit={currency.symbol}
          value={companyDashboard?.totalEnergyProfit}
          valueDecimalPlaces={2}
          trend={companyDashboard?.totalEnergyProfitTrend}
          tooltip={t('vatNotIncluded', 'VAT not included')}
        />
        <SummaryCard
          label={t('sessions', 'Sessions')}
          value={companyDashboard?.totalTransactions}
          trend={companyDashboard?.totalTransactionsTrend}
        />
        <SummaryCard
          label={t('consumption', 'Consumption')}
          unit="kWh"
          value={companyDashboard?.totalEnergyKwh}
          valueDecimalPlaces={2}
          trend={companyDashboard?.totalEnergyKwhTrend}
        />
      </div>

      <SitesTable />
    </div>
  );
};

export default SiteListView;
