import { EventLogDto } from 'models/event-log';

import apiSlice from '@services/api';
import { PagedDataQuery } from '@services/reporting/endpoints';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    eventLog: builder.query({
      query: (filters) => ({
        url: 'eventLog',
        method: 'GET',
        params: filters,
      }),
      providesTags: ['CompanyContext'],
    }),
    eventLogDtos: builder.query<EventLogDto[], PagedDataQuery<EventLogDto>>({
      query: (body) => ({
        url: 'reporting/event-log',
        method: 'POST',
        body,
      }),
      transformResponse: (response: { data: EventLogDto[] }) => response.data,
      providesTags: ['CompanyContext'],
    }),
  }),
});

export const { useEventLogQuery, useLazyEventLogDtosQuery } = extendedApi;
export const eventLogApi = extendedApi;
