import React from 'react';
import ReactApexChart from 'react-apexcharts';

import { isNumber } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import useActiveCurrency from '@hooks/useActiveCurrency';

const TableRowGraph = ({ options, prices }) => {
  const currency = useActiveCurrency();
  const state = {
    series: [
      { name: '1', data: options.map((v) => v.value / 1000) },
      { name: '2', data: prices.map((v) => v.value) },
    ],
    options: {
      legend: {
        show: false,
      },
      stroke: {
        show: true,
        width: 1,
        dashArray: 0,
      },
      chart: {
        toolbar: {
          show: false,
        },
        type: 'bar',
        height: 350,
        stacked: false,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '80%',
        },
        endingShape: 'rounded',
      },
      yaxis: [
        {
          labels: {
            formatter: (val) => Number((val || 0).toFixed(2)),
          },
          title: {
            text: 'kWh',
            rotate: 0,
            offsetX: -10,
          },
        },
        {
          labels: {
            formatter: (val) => Number((val || 0).toFixed(2)),
          },
          opposite: true,
          title: {
            text: currency.symbol,
            rotate: 0,
            offsetX: 10,
          },
        },
      ],
      xaxis: {
        categories: options.map((s) => moment.utc(s.time).local().format('HH')),
      },
      tooltip: {
        shared: false,
        intersect: true,
        enabledOnSeries: [0, 1],
        x: {
          show: false,
        },
        y: {
          formatter: (val, { seriesIndex }) => {
            const labelUnit = ['kWh', currency.symbol];
            return `${isNumber(val) ? val.toFixed(val < 0.01 ? 3 : 2) : '-'} ${labelUnit[seriesIndex]}`;
          },
        },
      },
    },
  };

  return <ReactApexChart options={state.options} series={state.series} height={200} type="bar" />;
};

TableRowGraph.propTypes = {
  options: PropTypes.array.isRequired,
  prices: PropTypes.array.isRequired,
};

export default TableRowGraph;
