import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { ChargerWithStats, LmcMultipointWithStats } from '../devicesAndGroupsTypes';
import { LmcChargerCommunicationStatus } from './LmcChargerCommunicationStatus';

type ChargerControllerGroupProps = {
  charger: ChargerWithStats;
  lmcMultipoints: LmcMultipointWithStats[];
};

export const ChargerControllerGroup = ({ charger, lmcMultipoints }: ChargerControllerGroupProps) => {
  const { t } = useTranslation();

  const chargerMultipoint = lmcMultipoints.find(
    ({ lmcMultipointUuid }) => lmcMultipointUuid === charger.lmcMultipointUuid,
  );

  return (
    <Box display="flex" alignItems="center" gap={1}>
      {chargerMultipoint?.displayName ?? t('noGroup', 'No group')}
      <LmcChargerCommunicationStatus charger={charger} lmcMultipoints={lmcMultipoints} />
    </Box>
  );
};
