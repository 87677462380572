import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useEffect, useMemo } from 'react';

import { ReportingTransaction } from 'models/reporting-transaction';

import { StyledMenuItemProps } from '@components/atoms/StyledMenuItem';
import { FiltersBaseContextType, getFiltersBaseContextDefaultValues } from '@components/filters/context/FiltersContext';
import { useDataFiltering } from '@components/filters/hooks/useDataFiltering';
import { ColumnFilter } from '@services/reporting/endpoints';

import { useChargerMenu } from '../hooks/useChargerMenu';
import { useRfidMenu } from '../hooks/useRfidMenu';
import { useSiteMenu } from '../hooks/useSiteMenu';
import { useTransactionTypeMenu } from '../hooks/useTransactionTypeMenu';
import { useUserMenu } from '../hooks/useUserMenu';
import { useVehicleMenu } from '../hooks/useVehicleMenu';
import { useWorkspaceMenu } from '../hooks/useWorkspaceMenu';
import { ReportingTransactionsContext, ReportingTransactionsFilterMenuOption } from './ReportingTransactionsProvider';

type ReportScheduleContextType = FiltersBaseContextType<ReportingTransactionsFilterMenuOption> & {
  buildColumnFilters: () => ColumnFilter<ReportingTransaction>[];
  loadFilters: (filters: ColumnFilter<ReportingTransaction>[]) => void;
};

export const ReportScheduleContext = createContext<ReportScheduleContextType>({
  ...getFiltersBaseContextDefaultValues<ReportingTransaction>(),
  filterOptionsMap: {
    workspace: [],
    site: [],
    charger: [],
    vehicle: [],
    user: [],
    rfid: [],
    type: [],
  },
  loadFilters: () => {},
});

type ReportScheduleProviderProps = {
  children?: ReactNode;
};

export const ReportScheduleProvider = ({ children }: ReportScheduleProviderProps) => {
  const { filterOptionsMap: sessionsFilterOptionsMap, filterInclusivityMap } = useContext(ReportingTransactionsContext);
  const { workspaceMenuOptions, setWorkspaceMenuOptions } = useWorkspaceMenu();
  const { siteMenuOptions, setSiteMenuOptions } = useSiteMenu();
  const { chargerMenuOptions, setChargerMenuOptions } = useChargerMenu();
  const { vehicleMenuOptions, setVehicleMenuOptions } = useVehicleMenu();
  const { userMenuOptions, setUserMenuOptions } = useUserMenu();
  const { rfidMenuOptions, setRfidMenuOptions } = useRfidMenu();
  const { typeMenuOptions, setTypeMenuOptions } = useTransactionTypeMenu();

  const filterOptionsMap: Record<ReportingTransactionsFilterMenuOption, StyledMenuItemProps[]> = useMemo(() => {
    return {
      workspace: workspaceMenuOptions,
      site: siteMenuOptions,
      charger: chargerMenuOptions,
      vehicle: vehicleMenuOptions,
      user: userMenuOptions,
      rfid: rfidMenuOptions,
      type: typeMenuOptions,
    };
  }, [
    workspaceMenuOptions,
    siteMenuOptions,
    chargerMenuOptions,
    vehicleMenuOptions,
    userMenuOptions,
    rfidMenuOptions,
    typeMenuOptions,
  ]);

  const uuidFilterMap: Record<ReportingTransactionsFilterMenuOption, keyof ReportingTransaction> = {
    workspace: 'companyUuid',
    site: 'siteUuid',
    charger: 'deviceUuid',
    vehicle: 'carUuid',
    user: 'userUuid',
    rfid: 'rfidCardUuid',
    type: 'transactionType',
  };

  const filterMap: Record<ReportingTransactionsFilterMenuOption, Dispatch<SetStateAction<StyledMenuItemProps[]>>> = {
    workspace: setWorkspaceMenuOptions,
    site: setSiteMenuOptions,
    charger: setChargerMenuOptions,
    vehicle: setVehicleMenuOptions,
    user: setUserMenuOptions,
    rfid: setRfidMenuOptions,
    type: setTypeMenuOptions,
  };

  const dataFiltering = useDataFiltering<ReportingTransaction, ReportingTransactionsFilterMenuOption>({
    filterOptionsMap,
    uuidFilterMap,
    filterMap,
    initialOrderBy: 'startTime',
  });

  useEffect(() => {
    Object.entries(filterMap).forEach(([filter, setOptions]) => {
      setOptions((prevOptions) =>
        prevOptions.map((option) => {
          return {
            ...option,
            checked: sessionsFilterOptionsMap[filter as ReportingTransactionsFilterMenuOption].some(
              (sessionOption) => sessionOption.uuid === option.uuid && sessionOption.checked,
            ),
          };
        }),
      );
    });

    dataFiltering.setFilterInclusivityMap((prevMap) => {
      return {
        ...prevMap,
        ...filterInclusivityMap,
      };
    });
  }, [sessionsFilterOptionsMap, filterInclusivityMap]);

  const loadFilters = (filters: ColumnFilter<ReportingTransaction>[]) => {
    filters.forEach((filter) => {
      const key = Object.entries(uuidFilterMap).find(([, value]) => value === filter.column)?.[0];
      const setOptions = filterMap[key as ReportingTransactionsFilterMenuOption];
      setOptions((prevOptions) =>
        prevOptions.map((option) => {
          return {
            ...option,
            checked: filter.values.includes(option.uuid),
          };
        }),
      );

      dataFiltering.setFilterInclusivityMap((prevMap) => {
        return {
          ...prevMap,
          [key as keyof typeof prevMap]: filter.inclusive,
        };
      });
    });
  };

  const value = useMemo(
    () => ({
      ...dataFiltering,
      filterOptionsMap,
      loadFilters,
    }),
    [filterOptionsMap, dataFiltering],
  );

  return <ReportScheduleContext.Provider value={value}>{children}</ReportScheduleContext.Provider>;
};
