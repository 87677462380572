import { useTranslation } from 'react-i18next';

import { ReportScheduleRecurrence } from 'models/report-schedule';
import * as yup from 'yup';

export const useReportScheduleSchema = () => {
  const { t } = useTranslation();
  const requiredLabel = t('required*', 'Required*');

  return yup
    .object({
      name: yup.string(),
      fileTypes: yup
        .array()
        .of(
          yup.object({
            fileType: yup.string().required(requiredLabel),
            checked: yup.boolean().required(requiredLabel),
          }),
        )
        .test('at-least-one-file-type', requiredLabel, (array) => array?.some((fileType) => fileType.checked) ?? false)
        .required(requiredLabel),
      recurrence: yup.string().oneOf<ReportScheduleRecurrence>(['daily', 'weekly', 'monthly']).required(requiredLabel),
      recipients: yup
        .array()
        .of(
          yup.object({
            userUuid: yup.string(),
            email: yup.string().required(requiredLabel),
          }),
        )
        .min(1, requiredLabel)
        .required(requiredLabel),
      filters: yup.array(),
    })
    .required();
};
