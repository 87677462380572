import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';

import TimePeriodSelectorHeader from '@components/filterComponents/TimePeriodSelectorHeader';
import { selectUser } from '@services/auth/selectors';

import ChargingSessionsSummary from './ChargingSessionsSummary';
import DevicesSummary from './DevicesSummary';
import Summary from './Summary';

const DashboardView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAdmin = useSelector(selectUser)?.admin;

  const [selectedDuration, setSelectedDuration] = useState(null);

  const durationToStartDate = {
    '24-hours': moment().subtract(1, 'days'),
    '1-week': moment().subtract(1, 'weeks'),
    '1-months': moment().subtract(1, 'months'),
    '6-months': moment().subtract(6, 'months'),
    '1-year': moment().subtract(1, 'years'),
  };

  const periodStartTime = useMemo(
    () =>
      durationToStartDate[selectedDuration]
        ? moment(durationToStartDate[selectedDuration]).utc().format('YYYY-MM-DDTHH:mm[Z]')
        : null,
    [selectedDuration],
  );

  const durationToTimeFrame = {
    '24-hours': 'today',
    '1-week': 'week',
    '1-months': 'month',
    '6-months': 'sixMonths',
    '1-year': 'year',
  };

  const timeFrame = durationToTimeFrame[selectedDuration] || durationToTimeFrame['1-months'];

  useEffect(() => {
    if (isAdmin) {
      navigate('/sites');
    }
  }, [isAdmin]);

  return (
    <>
      <Summary />
      <TimePeriodSelectorHeader
        selectedDuration={selectedDuration}
        setSelectedDuration={setSelectedDuration}
        labelPrefix={t('overviewFor', 'Overview for')}
        storageKey="dashboardDuration"
        withoutAll
      />
      <div className="flex flex-col gap-y-4">
        <ChargingSessionsSummary timeFrame={timeFrame} periodStartTime={periodStartTime} />
        <DevicesSummary timeFrame={timeFrame} periodStartTime={periodStartTime} />
      </div>
    </>
  );
};

export default DashboardView;
