import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import DeleteModal from './DeleteModal';

const DiscardChangesModal = ({ open, onDiscard, onClose }) => {
  const { t } = useTranslation();
  return (
    <DeleteModal
      title={t('unsavedChanges', 'Unsaved changes')}
      deleteButtonText={t('yesDiscardChanges', 'Yes, discard changes')}
      cancelButtonText={t('no', 'No')}
      open={open}
      onDelete={onDiscard}
      closeModal={onClose}
    >
      <div>{t('areYouSureDiscardChanges', 'Are you sure you want to discard the changes?')}</div>
    </DeleteModal>
  );
};

DiscardChangesModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onDiscard: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DiscardChangesModal;
