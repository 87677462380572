import { Box, BoxProps } from '@mui/material';

export const OutlinedButton = ({ children, ...props }: BoxProps) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    padding="8px 12px"
    border="1px solid #D6D6D6"
    borderRadius="8px"
    height="32px"
    {...props}
    sx={props.onClick && { cursor: 'pointer', ...props.sx }}
  >
    {children}
  </Box>
);
