import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { RadioButton } from '@components/atoms/RadioButton';
import EditingSidebarBase from '@components/sidebars/EditingSidebarBase';

import { useControlChannelCombinations } from '../deviceConfigurationUtils';
import { ConnectivityOutletContext } from './ChargerConnectivityFormSidebar';

export const ChargerConnectivityChoiceSidebar = () => {
  const { t } = useTranslation();
  const {
    device,
    controlChannels: initialControlChannels,
    onValuesChange,
  } = useOutletContext<ConnectivityOutletContext>();
  const { defs: baseDefs, getCombinationDef, getCustomCombinationDef } = useControlChannelCombinations();

  const initialCombinationKey = getCombinationDef(initialControlChannels).key ?? null;
  const [selectedCombinationKey, setSelectedCombinationKey] = useState(initialCombinationKey);
  const combinationDefs = [
    ...baseDefs,
    ...(initialControlChannels.length && initialCombinationKey === 'custom'
      ? [getCustomCombinationDef(initialControlChannels)]
      : []),
  ];

  const controlChannels = combinationDefs.find((def) => def.key === selectedCombinationKey)?.channels;

  const handleSaveValues = () => {
    if (controlChannels) {
      onValuesChange({ controlChannels });
      return true;
    }
    return false;
  };

  return (
    <EditingSidebarBase
      title={t('connectivityMode', 'Connectivity Mode')}
      subtitle={device.name || device.serialNumber}
      skipDisablingSaveIfNoDataChanged={!!controlChannels}
      saveLabel={t('choose', 'Choose')}
      onSaveValues={handleSaveValues}
      isSubSidebar
    >
      <div className="space-y-8">
        {combinationDefs.map((def) => (
          <RadioButton
            key={def.key}
            name="mode"
            label={def.name}
            value={def.key}
            helpText={def.description}
            helpTextClassName="ml-6"
            checked={selectedCombinationKey === def.key}
            onChange={() => setSelectedCombinationKey(def.key)}
          />
        ))}
      </div>
    </EditingSidebarBase>
  );
};
