import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { startCase } from 'lodash';
import PropTypes from 'prop-types';

import DeleteModal from '@components/modals/DeleteModal';
import ErrorModal from '@components/modals/ErrorModal';
import { useDeleteDeviceMutation, useDeleteLmcMultipointMutation } from '@services/devices/endpoints';
import { useDeleteInverterMutation } from '@services/inverters/endpoints';
import { addToastMessage } from '@services/toastMessages';

const DeleteDeviceModal = ({ device, open, closeModal, refetchDevices }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  const initiallyTransactionOngoing = useMemo(
    () =>
      device?.status === 'online' &&
      device.stateOfConnectors?.some((c) => c.transactionStatus && c.transactionStatus !== 'none'),
    [device?.uuid, open],
  );

  const deviceLabel = (device.multipointId ? t('group', 'Group') : t('device', 'Device')).toLowerCase();

  const [deleteDevice, { isLoading: deleteDeviceLoading, isError: deleteDeviceError, error: deleteDeviceErrorData }] =
    useDeleteDeviceMutation();

  const [
    deleteLmcMultipoint,
    { isLoading: deleteLmcMultipointLoading, isError: deleteLmcMultipointError, error: deleteLmcMultipointErrorData },
  ] = useDeleteLmcMultipointMutation();

  const [
    deleteInverter,
    { isLoading: deleteInverterLoading, isError: deleteInverterError, error: deleteInverterErrorData },
  ] = useDeleteInverterMutation();

  const isLoading = deleteDeviceLoading || deleteLmcMultipointLoading || deleteInverterLoading;
  const isError = deleteDeviceError || deleteLmcMultipointError || deleteInverterError;
  const deleteErrorData = deleteDeviceErrorData || deleteLmcMultipointErrorData || deleteInverterErrorData;

  const handleNavigateToSite = (siteId) => !window.location.pathname.includes('sites') && navigate(`/sites/${siteId}`);

  const handleDeleteDevice = async ({ deviceUuid, siteUuid, multipointId, inverterId, lmcMultipointUuid }) => {
    try {
      let response;

      if (multipointId) {
        response = await deleteLmcMultipoint(lmcMultipointUuid);
      } else if (inverterId) {
        response = await deleteInverter(deviceUuid);
      } else {
        response = await deleteDevice(deviceUuid);
      }

      const toastMessage = response?.error
        ? {
            type: 'error',
            title: t('deletingTheItemFailed', 'Deleting the {{item}} failed', {
              item: deviceLabel,
            }),
            message: t('pleaseTryAgain', 'Please try again.'),
          }
        : {
            type: 'success',
            title: t('itemDeleted', '{{item}} deleted', { item: startCase(deviceLabel) }),
            message: t('historicalDataIsStillAvailable', 'Historical data is still available in Reporting.'),
          };

      dispatch(addToastMessage(toastMessage));

      if (response.error) {
        throw new Error(response.error.data?.message || deleteErrorData?.data?.message);
      } else {
        closeModal();
        handleNavigateToSite(siteUuid);
        refetchDevices();
      }
    } catch (e) {
      setErrorMessage(e?.message || t('somethingWentWrong', 'Something went wrong'));
    }
  };

  if (initiallyTransactionOngoing) {
    return (
      <ErrorModal title="Unable to delete device" open={open} closeModal={closeModal}>
        <p className="text-vermillion">
          It is not possible to delete a device while there is an ongoing charging session.
        </p>
        <br />
        <p>If possible, please end the session and try again.</p>
      </ErrorModal>
    );
  }

  return (
    <DeleteModal
      open={open}
      closeModal={closeModal}
      onDelete={() =>
        handleDeleteDevice({
          deviceUuid: device.uuid,
          siteUuid: device.siteUuid,
          multipointId: device.multipointId,
          inverterId: device.inverterId,
          lmcMultipointUuid: device.lmcMultipointUuid,
        })
      }
      loading={isLoading}
      deleteButtonText={`${t('yes', 'Yes')}, ${t('deleteTheItem', 'Delete the {{item}}', {
        item: deviceLabel,
      }).toLowerCase()}`}
      cancelButtonText="No"
      title={`${t('delete', 'Delete')} ${deviceLabel}`}
      {...(isError && {
        error: errorMessage,
      })}
    >
      <p>
        {t('areYouSureDeleteItem', ' Are you sure you want to delete the {{item}}', { item: deviceLabel })}
        {(device?.displayName ?? device.name) && (
          <span className="font-semibold"> {device?.displayName ?? device.name}</span>
        )}
        ?
      </p>
    </DeleteModal>
  );
};

DeleteDeviceModal.propTypes = {
  device: PropTypes.object.isRequired,
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  refetchDevices: PropTypes.func,
};

DeleteDeviceModal.defaultProps = {
  open: false,
  closeModal: () => {},
  refetchDevices: () => {},
};

export default DeleteDeviceModal;
