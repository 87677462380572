import { useTranslation } from 'react-i18next';

import { capitalize } from 'lodash';
import { PaymentMethod } from 'models/paymentMethod';

import { Box, Typography } from '@mui/material';

import Mastercard from '@images/icons/Mastercard.svg';
import Visa from '@images/icons/Visa.svg';

export const PaymentMethodItemCard = ({ card }: PaymentMethod) => {
  const { t } = useTranslation();

  const isExpired = () => {
    const today = new Date();
    const expDate = new Date(card.exp_year, card.exp_month);
    return expDate < today;
  };

  const cardExpired = isExpired();

  return (
    <>
      <Box>
        <img src={card.brand === 'visa' ? Visa : Mastercard} alt="Card icon" />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        flex={1}
        color={(theme) => (cardExpired ? theme.palette.mediumVermillion : undefined)}
      >
        <Typography variant="p14b">
          {capitalize(card.brand)}
          {cardExpired && ` (${t('expired', 'expired')})`}
        </Typography>
        <Typography variant="p14">**** {card.last4}</Typography>
        <Typography
          variant="p14"
          color={(theme) => (cardExpired ? theme.palette.mediumVermillion : theme.palette.darkGray)}
        >
          Exp. {card.exp_month}/{card.exp_year.toString().slice(2)}
        </Typography>
      </Box>
    </>
  );
};
