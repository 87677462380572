import { useSelector } from 'react-redux';

import { TableCell, TableCellProps } from '@mui/material';

import { getApiUrl } from '@config/api';
import { ReactComponent as InvoiceDownloadIcon } from '@images/icons/invoiceDownload.svg';
import { selectToken } from '@services/auth/selectors';
import { useLazyGetInvoiceUrlQuery } from '@services/payments/endpoints';

type InvoiceDownloadCellProps = TableCellProps & {
  invoiceId?: string;
};

export const InvoiceDownloadCell = ({ invoiceId, ...props }: InvoiceDownloadCellProps) => {
  const [getInvoiceUrlById] = useLazyGetInvoiceUrlQuery();
  const token = useSelector(selectToken);

  const handleDownloadInvoice = async () => {
    if (!invoiceId) {
      return;
    }

    try {
      const url = await getInvoiceUrlById(invoiceId).unwrap();
      if (!url.includes('invoices/pdf?invoiceNumber=')) {
        window.open(url, '_blank');
      } else {
        const response = await fetch(`${getApiUrl()}${url}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/pdf',
            Authorization: `Bearer ${token}`,
          },
        });
        const downloadUrl = window.URL.createObjectURL(await response.blob());
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = `${invoiceId}.pdf`;
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (!invoiceId) {
    return <TableCell {...props} />;
  }

  return (
    <TableCell {...props} sx={{ cursor: 'pointer' }} onClick={handleDownloadInvoice}>
      <InvoiceDownloadIcon />
    </TableCell>
  );
};
