import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Device } from 'models/device';
import { DeviceModalOption } from 'models/device.enums';
import { Permission } from 'models/permission.enum';

import Button from '@components/atoms/Button';
import NoItemsCard from '@components/cards/NoItemsCard';
import DeleteDeviceModal from '@components/devices/DeleteDeviceModal';
import DeviceOcppSetupInfoModal from '@components/devices/DeviceOcppSetupInfoModal';
import { selectPermissionByName } from '@services/auth/selectors';
import { useAppSelector } from '@services/hooks';
import { selectSiteByUuid } from '@services/sites/selectors';
import { DeviceOcppFirmwareModal } from '@views/Devices/DeviceOcppFirmwareModal';
import DeviceOcppVariablesModal from '@views/Devices/OcppVariablesModal';
import DeviceEditModal from '@views/Devices/deviceEditModal/DeviceEditModal';
import { InverterRenameModal } from '@views/Inverters/InverterRenameModal';

import { SiteChargersMaterialTable } from './siteChargesTables/SiteChargersMaterialTable';
import { isInverter } from './siteDevicesAndGroupsHelpers/siteDevicesUtils';
import { useDevicesAndGroups } from './siteDevicesAndGroupsHelpers/useDevicesAndGroups';
import { SiteGroupsMaterialTable } from './siteGroupsTables/SiteGroupsMaterialTable';
import { SiteInvertersMaterialTable } from './siteInvertersTables/SiteInvertersMaterialTable';

type SiteDevicesAndGroupsProps = {
  siteUuid?: string;
  companyUuid?: string;
  selectedDuration?: string;
};

export const DevicesAndGroups = ({ siteUuid, companyUuid, selectedDuration }: SiteDevicesAndGroupsProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const isDevicesView = location.pathname.includes('devices');
  const canAddDevices =
    useAppSelector((state) => selectPermissionByName(state, Permission.CAN_ADD_DEVICES)) && !isDevicesView;

  const {
    lmcMultipointsWithStats,
    chargersWithStats,
    invertersWithStats,
    isLoading,
    isFetchingGetSiteInvertersSummary,
    selectedCharger,
    selectedLmcMultipoint,
    refetchDevices,
    refetchInverters,
  } = useDevicesAndGroups({ siteUuid, companyUuid, selectedDuration });

  const [selectedDevice, setSelectedDevice] = useState<Device>();
  const [modalOption, setModalOption] = useState<DeviceModalOption>();

  const site = useAppSelector((state) => selectSiteByUuid(state, siteUuid ?? selectedDevice?.siteUuid)) ?? {};

  const navigateToAddDevice = () => navigate('./addDevice');

  return (
    <>
      {(!!lmcMultipointsWithStats.length || !!chargersWithStats.length || !!invertersWithStats.length) &&
        canAddDevices && (
          <Button variant="primary" className="float-right -mt-0.5" onClick={navigateToAddDevice}>
            {t('addDevice', 'Add a device')}
          </Button>
        )}
      <h2 className="mb-4 mt-8 font-poppins text-lg font-semibold leading-8">
        {t('devicesAndGroups', 'Devices and groups')}
      </h2>

      {!lmcMultipointsWithStats.length && !chargersWithStats.length && !invertersWithStats.length && !isLoading ? (
        <NoItemsCard
          message={t('noDevicesAddedText', 'There are currently no devices added to this site.')}
          {...(canAddDevices && {
            buttonLabel: t('addDevice', 'Add a device'),
            onButtonClick: navigateToAddDevice,
          })}
        />
      ) : (
        <div className="flex flex-col gap-y-4">
          <SiteGroupsMaterialTable
            lmcMultipoints={lmcMultipointsWithStats}
            chargers={chargersWithStats}
            selectedDevice={selectedDevice || selectedLmcMultipoint}
            isLoading={isLoading}
            hiddenColumns={
              siteUuid ? ['siteUuid', 'firmwareVersion'] : ['gridConnection', 'numSessions', 'consumption']
            }
            onDeviceSelected={setSelectedDevice}
            onModalOptionSelected={setModalOption}
          />
          <SiteChargersMaterialTable
            lmcMultipoints={lmcMultipointsWithStats}
            chargers={chargersWithStats}
            selectedDevice={selectedDevice || selectedCharger}
            isLoading={isLoading}
            hiddenColumns={
              siteUuid
                ? ['siteUuid', 'firmwareVersion', 'communicatesViaLmc']
                : ['connectors', 'activePrograms', 'lmcMultipointUuid', 'numSessions', 'consumption']
            }
            onDeviceSelected={setSelectedDevice}
            onModalOptionSelected={setModalOption}
          />
          <SiteInvertersMaterialTable
            inverters={invertersWithStats}
            selectedInverter={selectedDevice}
            isLoading={isLoading}
            isFetchingSummary={isFetchingGetSiteInvertersSummary}
            hiddenColumns={!siteUuid ? ['energyProducedKwh'] : []}
            onInverterSelected={setSelectedDevice}
            onModalOptionSelected={setModalOption}
          />
        </div>
      )}

      {selectedDevice && (
        <>
          <DeleteDeviceModal
            open={modalOption === DeviceModalOption.DELETE}
            closeModal={() => {
              setModalOption(undefined);
              setSelectedDevice(undefined);
            }}
            refetchDevices={selectedDevice?.inverterId ? refetchInverters : refetchDevices}
            device={selectedDevice}
          />
          {isInverter(selectedDevice) ? (
            <InverterRenameModal
              inverter={selectedDevice}
              isOpen={modalOption === DeviceModalOption.RENAME}
              closeModal={() => {
                setModalOption(undefined);
                setSelectedDevice(undefined);
              }}
            />
          ) : (
            <>
              <DeviceEditModal
                open={modalOption === DeviceModalOption.EDIT}
                closeModal={() => {
                  setModalOption(undefined);
                  setSelectedDevice(undefined);
                }}
                pullDeviceAgain={refetchDevices}
                device={selectedDevice}
                site={site}
              />
              <DeviceOcppSetupInfoModal
                open={modalOption === DeviceModalOption.OCPP_SETUP}
                closeModal={() => {
                  setModalOption(undefined);
                  setSelectedDevice(undefined);
                }}
                device={selectedDevice}
              />
              <DeviceOcppVariablesModal
                isOpen={modalOption === DeviceModalOption.OCPP_VARIABLES}
                closeModal={() => {
                  setModalOption(undefined);
                  setSelectedDevice(undefined);
                }}
                device={selectedDevice}
              />
              <DeviceOcppFirmwareModal
                isOpen={[DeviceModalOption.OCPP_RESET, DeviceModalOption.OCPP_UPDATE].includes(modalOption!)}
                option={modalOption}
                closeModal={() => {
                  setModalOption(undefined);
                  setSelectedDevice(undefined);
                }}
                device={selectedDevice}
              />
            </>
          )}
        </>
      )}
    </>
  );
};
