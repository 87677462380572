import { useTranslation } from 'react-i18next';

import { startCase, sum, uniq } from 'lodash';
import { DeviceStatus } from 'models/device.enums';
import PropTypes from 'prop-types';

import offlineIcon from '@images/icons/DeviceStatus/Status_Offline.svg';
import unknownIcon from '@images/icons/Unknown.svg';
import checkIcon from '@images/icons/check.svg';
import { getUnifiedConnectors } from '@views/Devices/connectorsUtil';

import { LmcStatusPill } from './LmcStatusPill';

const LmcStatus = ({ lmc, smaller }) => {
  const { t } = useTranslation();

  const connectors = getUnifiedConnectors(lmc);
  const currents = uniq(
    connectors.flatMap((connector) =>
      Object.entries(connector)
        .filter(([key]) => key.startsWith('current_'))
        .map(([, value]) => value),
    ),
  );
  const currentLoadPercentage = (sum(currents) / ((lmc?.gridConnection || 32) * currents.length)) * 100 || 0;
  const { status } = lmc;

  const getStatusIcon = () => {
    if (status === DeviceStatus.ONLINE) {
      return checkIcon;
    }
    if (status === DeviceStatus.OFFLINE) {
      return offlineIcon;
    }
    return unknownIcon;
  };

  const statusBackgroundStyle =
    status === DeviceStatus.ONLINE
      ? 'bg-forest-200 border-forest text-forest [&_.scheme-color_path]:fill-forest'
      : 'bg-gray-200 border-gray-600 text-gray-600 [&_.scheme-color_path]:fill-gray-600';

  return (
    <div
      className={`w-full rounded-2xl ${
        smaller ? `flex py-4 pl-4 pr-2 ${statusBackgroundStyle} gap-x-2` : 'min-w-[248px] bg-forest-200 p-4 md:w-1/3'
      }`}
    >
      {smaller && (
        <div className={`flex h-10 w-10 items-center justify-center border-2 ${statusBackgroundStyle} rounded-full`}>
          <img src={getStatusIcon()} alt="statusIcon" className="h-5 w-5" />
        </div>
      )}
      <div className={`flex ${smaller ? 'flex-col-reverse' : 'flex-col'}`}>
        {smaller ? (
          <div className="text-xs leading-4 text-gray-600">
            {currentLoadPercentage.toFixed()}% {t('ofTotalConnection', 'Of total connection')}
          </div>
        ) : (
          <>
            <div className="font-everett text-2xl text-black">{currentLoadPercentage.toFixed()}%</div>
            <div className="pb-6 text-sm text-gray-600">{t('ofTotalConnection', 'Of total connection')}</div>
          </>
        )}
        {smaller ? (
          <div className="text-sm font-semibold leading-6 text-black">{startCase(status)}</div>
        ) : (
          <LmcStatusPill status={status} />
        )}
      </div>
    </div>
  );
};

LmcStatus.propTypes = {
  lmc: PropTypes.object,
  smaller: PropTypes.bool,
};

LmcStatus.defaultProps = {
  lmc: null,
  smaller: false,
};

export default LmcStatus;
