import { Device } from 'models/device';

import { Tooltip } from '@mui/material';

import chipIcon from '@images/icons/Chip.svg';
import { isChargerManagedByLmc } from '@views/Devices/DeviceDetailsSidebar/deviceConfigurationUtils';

export const LmcManagedIndicator = ({ device }: { device: Device }) =>
  isChargerManagedByLmc(device) && (
    <Tooltip title="LMC managed" placement="top">
      <img src={chipIcon} alt="LMC managed" />
    </Tooltip>
  );
