import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { DeviceStatus } from 'models/device.enums';

import { SummaryCard } from '@components/cards/SummaryCard';
import apiSlice from '@services/api';
import { selectCompanies } from '@services/companies/selectors';
import { useGetDevicesQuery } from '@services/devices/endpoints';
import { selectDevices } from '@services/devices/selectors';
import { selectVehicles } from '@services/vehicles/selectors';

const Summary = () => {
  const { t } = useTranslation();
  const devices = useSelector(selectDevices);
  const vehicles = useSelector(selectVehicles);
  const companies = useSelector(selectCompanies);

  const onlineDevices = useMemo(() => devices.filter((device) => device.status === DeviceStatus.ONLINE), [devices]);

  const data = [
    {
      label: t('totalNrOfDevices', 'Total nr of devices'),
      value: devices.length,
    },
    {
      label: t('currentlyOnlineDevices', 'Currently online devices'),
      value: onlineDevices.length,
    },
    {
      label: t('vehicles', 'Vehicles'),
      value: vehicles.length,
    },
    {
      label: t('activeWorkspaces', 'Active workspaces'),
      value: companies.length,
    },
  ];

  apiSlice.useVehiclesQuery({});
  useGetDevicesQuery({});

  return (
    <div className="mb-10 grid grid-cols-2 gap-4 sm:grid-cols-4">
      {data.map(({ label, value }) => (
        <SummaryCard key={label} label={label} value={value} />
      ))}
    </div>
  );
};

export default Summary;
