import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';

import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { QueryDefinition } from '@reduxjs/toolkit/query';
import dayjs, { Dayjs } from 'dayjs';

import { useMaterialTableSorting } from '@components/atoms/MaterialTable';
import { StyledMenuItemProps } from '@components/atoms/StyledMenuItem';
import { useDebounce } from '@hooks/useDebounce';
import { ColumnFilter, DbCursor, PagedDataQuery } from '@services/reporting/endpoints';

import { BuildQueryOptions, FilterMenuOption } from '..';
import { FiltersBaseContextType, FiltersDataContextType } from '../context/FiltersContext';
import { useFilterInclusivity } from './useFilterInclusivity';

type useDataFilteringProps<ContextDataType, ExtractedType extends FilterMenuOption> = {
  filterOptionsMap: Record<ExtractedType, StyledMenuItemProps[]>;
  uuidFilterMap: Record<ExtractedType, keyof ContextDataType>;
  filterMap: Record<ExtractedType, Dispatch<SetStateAction<StyledMenuItemProps[]>>>;
  initialOrderBy: keyof ContextDataType;
  pageSize?: number;
  dataFilters?: ColumnFilter<ContextDataType>[];
  preventLoading?: boolean;
  customDateRange?: {
    startDate: Dayjs;
    endDate?: Dayjs;
  };
};

type UseDataFiltering<ContextDataType extends { id: number }, ExtractedType extends FilterMenuOption> = Omit<
  FiltersBaseContextType<ExtractedType>,
  'filterOptionsMap'
> &
  Omit<FiltersDataContextType<ContextDataType>, 'dataLoading'>;

export const useDataFiltering = <ContextDataType extends { id: number }, ExtractedType extends FilterMenuOption>(
  props: useDataFilteringProps<ContextDataType, ExtractedType>,
  query?: LazyQueryTrigger<QueryDefinition<PagedDataQuery<ContextDataType>, any, any, any>>,
): UseDataFiltering<ContextDataType, ExtractedType> => {
  const {
    filterOptionsMap,
    uuidFilterMap,
    filterMap,
    pageSize,
    dataFilters,
    initialOrderBy,
    preventLoading,
    customDateRange,
  } = props;
  const [filter, setFilter] = useState('');
  const [startDate, setStartDate] = useState(customDateRange?.startDate ?? dayjs().startOf('month'));
  const [endDate, setEndDate] = useState<Dayjs | undefined>(customDateRange?.endDate ?? dayjs().endOf('month'));
  const {
    filterInclusivityMap,
    setFilterInclusivityMap,
    setFilterInclusive,
    reset: resetFilterInclusivity,
  } = useFilterInclusivity();
  const [data, setData] = useState<ContextDataType[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const { order, orderBy, handleRequestSort } = useMaterialTableSorting<ContextDataType>(initialOrderBy);

  const optionChecked = (option: StyledMenuItemProps) => !!option.checked;

  const activeFiltersLength = useMemo(() => {
    return Object.values<StyledMenuItemProps[]>(filterOptionsMap).reduce(
      (acc, options) => acc + options.filter(optionChecked).length,
      0,
    );
  }, [filterOptionsMap]);

  const resetOptions = (prevOptions: StyledMenuItemProps[]) => {
    return prevOptions.map((option) => {
      return { ...option, checked: false };
    });
  };

  const reset = (filter?: FilterMenuOption) => {
    if (filter) {
      filterMap[filter as keyof typeof filterMap](resetOptions);
      return;
    }
    Object.values<Dispatch<SetStateAction<StyledMenuItemProps[]>>>(filterMap).forEach((setOptions) =>
      setOptions(resetOptions),
    );
    resetFilterInclusivity();
  };

  const buildColumnFilters = () => {
    const activeFilters = Object.entries<StyledMenuItemProps[]>(filterOptionsMap)
      .filter(([, options]) => options.some(optionChecked))
      .map(([filter, options]) => generateFilter(filter as ExtractedType, options));

    return activeFilters.concat(dataFilters ?? []);
  };

  const generateFilter = (filter: ExtractedType, options: StyledMenuItemProps[]): ColumnFilter<ContextDataType> => {
    return {
      column: uuidFilterMap[filter],
      values: options.filter(optionChecked).map((option) => option.uuid!),
      inclusive: filterInclusivityMap[filter],
    };
  };

  const buildQuery = (options?: BuildQueryOptions): PagedDataQuery<ContextDataType> => {
    const { cursor, omitPageSize } = options ?? {};
    return {
      queryRange: {
        startDate: startDate.toISOString(),
        endDate: endDate?.toISOString(),
      },
      cursor,
      pageSize: omitPageSize ? undefined : pageSize,
      columnFilters: buildColumnFilters(),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      sortOrder: {
        column: orderBy,
        order,
      },
    };
  };

  const fetchData = async (cursor?: DbCursor) => {
    if (preventLoading || !query) return;
    try {
      const response = await query(buildQuery({ cursor })).unwrap();
      if (!pageSize || response.length < pageSize) {
        setHasMore(false);
      }
      setData((prevData) => (cursor ? [...prevData, ...response] : response));
    } catch (error) {
      setData([]);
      console.error('Error fetching transactions', error);
    }
  };

  const loadMore = () => {
    if (hasMore) {
      const last = data[data.length - 1];
      fetchData({ after: last?.id, value: last[orderBy] });
    }
  };

  const resetAndFetchData = () => {
    setData([]);
    setHasMore(true);
    fetchData();
  };

  const debouncedRequest = useDebounce(() => {
    resetAndFetchData();
  }, 500);

  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      debouncedRequest();
    }
  }, [activeFiltersLength, filterInclusivityMap, order, orderBy]);

  useEffect(() => {
    resetAndFetchData();
  }, [startDate, endDate]);

  return {
    filter,
    setFilter,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    filterInclusivityMap,
    setFilterInclusivityMap,
    setFilterInclusive,
    data,
    handleRequestSort,
    loadMore,
    reset,
    optionChecked,
    activeFiltersLength,
    anyActiveFilters: activeFiltersLength > 0,
    order,
    orderBy,
    buildQuery,
    buildColumnFilters,
  };
};
