import { useTranslation } from 'react-i18next';

import { Group } from 'models/group.dto';

import { Typography } from '@mui/material';

import DeleteModal from '@components/modals/DeleteModal';
import { useDeleteGroupMutation } from '@services/groups/endpoints';
import { useAppDispatch } from '@services/hooks';
import { addToastMessage } from '@services/toastMessages';

export type DeleteWorkspaceGroupModalProps = {
  group: Group;
  isOpen: boolean;
  closeModal: () => void;
};

export const DeleteWorkspaceGroupModal = ({ group, isOpen, closeModal }: DeleteWorkspaceGroupModalProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [deleteGroup, { isLoading }] = useDeleteGroupMutation();

  const handleDeleteGroup = async () => {
    try {
      await deleteGroup(group.uuid).unwrap();
      dispatch(addToastMessage({ type: 'success', title: t('theGroupHasBeenDeleted', 'The group has been deleted') }));
      closeModal();
    } catch (error) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('deletingTheGroupFailed', 'Deleting the group failed'),
          message: t('pleaseTryAgain', 'Please try again.'),
        }),
      );
    }
  };

  return (
    <DeleteModal
      open={isOpen}
      closeModal={closeModal}
      onDelete={handleDeleteGroup}
      loading={isLoading}
      deleteButtonText={`${t('yes', 'Yes')}, ${t('deleteTheGroup', 'Delete the group').toLowerCase()}`}
      cancelButtonText={t('no', 'No')}
      title={t('deleteGroup', 'Delete group')}
    >
      <Typography variant="p16">
        {t('deleteGroupModalText', 'Are you sure you want to delete the group')}
        {group?.name && <Typography variant="p16b"> {group.name}</Typography>}?
      </Typography>
    </DeleteModal>
  );
};
