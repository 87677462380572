import { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Site } from 'models/site';

import { Box, TableCell, Typography } from '@mui/material';

import { ColumnData, MaterialTable } from '@components/atoms/MaterialTable';
import { StyledTableRow } from '@components/atoms/StyledTableRow';
import { isSubdomainPartner } from '@routesConfig/routesUtil';
import { MenuCell } from '@views/reporting/table/MenuCell';

import { useGetChargingRulesLabelsForSite, useGetRevenueModeLabelForSite } from '../siteLabelUtils';
import { useGetSiteMenuItems } from '../useGetSiteMenuItems';
import { SiteContractContent, SiteOccupancyContent } from './SiteDataComponents';
import { SitesTableRowSkeleton, SitesTableSkeleton } from './SitesTableSkeleton';

type sitesColumnData = ColumnData<SiteWithStats>;

const useColumns = (): sitesColumnData[] => {
  const { t } = useTranslation();

  const columns: sitesColumnData[] = [
    {
      width: 180,
      label: t('name', 'Name'),
      dataKey: 'name',
      sortable: true,
    },
    {
      width: 160,
      label: t('contract', 'Contract'),
      dataKey: 'contract',
      sortable: true,
    },
    {
      width: 160,
      label: t('occupancy', 'Occupancy'),
      dataKey: 'devices',
      sortable: false,
      hidden: isSubdomainPartner(),
    },
    {
      width: 160,
      label: t('chargingRules', 'Charging rules'),
      dataKey: 'chargingModel',
      sortable: true,
    },
    {
      width: 160,
      label: 'Public charging',
      dataKey: 'publicCharging',
      sortable: true,
    },
    {
      width: 20,
      label: '',
      dataKey: 'menu',
      sortable: false,
    },
  ];

  return columns;
};

type RenderRowsProps = {
  rows: SiteWithStats[];
  columns: sitesColumnData[];
  selectedSite?: Site;
  onOpenDeleteSiteModal: Dispatch<SetStateAction<Site | undefined>>;
};
const renderRows = ({ rows, ...rest }: RenderRowsProps) =>
  rows.map((row) => <TableRow key={row.uuid} row={row} {...rest} />);

type TableRowProps = Omit<RenderRowsProps, 'rows'> & {
  row: SiteWithStats;
};
const TableRow = ({ row, columns, selectedSite, onOpenDeleteSiteModal }: TableRowProps) => {
  const getSiteMenuItems = useGetSiteMenuItems({ onOpenDeleteSiteModal });

  return (
    <StyledTableRow
      key={row.uuid}
      sx={{
        bgcolor: row.uuid === selectedSite?.uuid ? '#F4F4F4' : 'background.paper',
      }}
    >
      {columns.map((column) => {
        switch (column.dataKey) {
          case 'name':
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                <Link to={row.uuid} style={{ textDecoration: 'none', width: '100%' }}>
                  <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
                    <Typography variant="p16b" component="div" gutterBottom>
                      {row.name}
                    </Typography>
                    <Typography variant="p14" color="text.secondary">
                      {row.address}
                    </Typography>
                  </Box>
                </Link>
              </TableCell>
            );
          case 'contract':
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                <SiteContractContent site={row} />
              </TableCell>
            );
          case 'devices':
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                <SiteOccupancyContent site={row} />
              </TableCell>
            );
          case 'chargingModel':
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                <>
                  {row.chargingModel}
                  {row.chargingTiming && <Typography variant="p14">{row.chargingTiming}</Typography>}
                </>
              </TableCell>
            );
          case 'publicCharging':
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                {row.publicCharging}
              </TableCell>
            );
          case 'menu':
            return (
              <MenuCell key={column.dataKey} align="right" width={column.width} menuOptions={getSiteMenuItems(row)} />
            );
          default:
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                <Typography variant="p14">{String(row[column.dataKey])}</Typography>
              </TableCell>
            );
        }
      })}
    </StyledTableRow>
  );
};

type SiteWithStats = Site & {
  contract: string;
  chargingModel: string;
  chargingTiming?: string;
  publicCharging?: string;
};

type SortedSitesTableProps = {
  sites: Site[];
  selectedSite?: Site;
  isLoading?: boolean;
  onOpenDeleteSiteModal: Dispatch<SetStateAction<Site | undefined>>;
};

export const SitesMaterialTable = ({
  sites,
  selectedSite,
  isLoading,
  onOpenDeleteSiteModal,
}: SortedSitesTableProps) => {
  const { t, i18n } = useTranslation();

  const sitesWithStatsDeps = [sites, i18n.language];
  const getChargingRulesLabelsForSite = useGetChargingRulesLabelsForSite();
  const getRevenueModeLabelForSite = useGetRevenueModeLabelForSite();

  const columns = useColumns();

  const sitesWithStats = useMemo<SiteWithStats[]>(
    () =>
      sites.map((site) => {
        const { chargingModeLabel, chargingTimingLabel } = getChargingRulesLabelsForSite(site);
        return {
          ...site,
          contract: site.pricingModel?.isAutomatic ? t('market', 'Market') : t('fixed', 'Fixed'),
          chargingModel: chargingModeLabel,
          chargingTiming: chargingTimingLabel,
          publicCharging: site.publicFlag ? getRevenueModeLabelForSite(site) : t('disabled', 'Disabled'),
        };
      }),
    sitesWithStatsDeps,
  );

  return (
    <MaterialTable
      sortingStorageKey="sitesTable"
      data={sitesWithStats}
      isLoading={isLoading}
      headerProps={{
        columns,
        orderBy: 'name',
      }}
      renderRows={(rows, columns) => renderRows({ rows, columns, selectedSite, onOpenDeleteSiteModal })}
      loaders={{
        TableLoader: SitesTableSkeleton,
        RowLoader: SitesTableRowSkeleton,
      }}
    />
  );
};
