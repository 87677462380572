import { StyledMenuItemProps } from '@components/atoms/StyledMenuItem';
import { DbCursor } from '@services/reporting/endpoints';

export type FilterMenuOption = 'workspace' | 'site' | 'charger' | 'vehicle' | 'user' | 'rfid' | 'type' | 'category';

export type BuildQueryOptions = {
  cursor?: DbCursor;
  omitPageSize?: boolean;
};

export const compareMenuOptions = (a: StyledMenuItemProps, b: StyledMenuItemProps) => {
  const aChecked = !!a.checked;
  const bChecked = !!b.checked;
  if (aChecked === bChecked) {
    return (a.label ?? '').localeCompare(b.label ?? '');
  }
  return aChecked ? -1 : 1;
};
