import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import { Dialog, Transition } from '@headlessui/react';

import Button from '@components/atoms/Button';
import { OverflowSpinner } from '@components/spinner/OverflowSpinner';
import { ReactComponent as CloseIcon } from '@images/icons/Close.svg';

const SaveModal = ({
  open,
  closeModal,
  saveModalContent,
  title,
  content: Content,
  buttonText,
  loading,
  error,
  children,
  persistent,
  invisible,
  buttonCancelText,
  noActions,
  saveButtonDisabled,
  saveButtonVariant,
}) => {
  const { t } = useTranslation();
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className={`fixed inset-0 z-50 overflow-y-auto rounded-2xl transition duration-500 ${
          invisible ? 'opacity-0' : ''
        }`}
        onClose={persistent ? () => {} : closeModal}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className="relative inline-block w-full transform rounded-2xl bg-white text-left
              align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[480px] sm:align-middle"
            >
              <Dialog.Title
                as="h3"
                className="border-b border-b-gray-100 p-4 font-everett text-2xl font-normal leading-8 text-black sm:px-6"
              >
                {!persistent && (
                  <button
                    type="button"
                    className="float-right -m-2 -mt-1 rounded-full p-2 sm:hover:bg-gray-200 sm:active:bg-gray-400"
                    onClick={closeModal}
                  >
                    <CloseIcon />
                  </button>
                )}
                {title}
              </Dialog.Title>
              <div className="pb-5 pt-3 sm:px-6">
                {error && <div className="text-xs text-vermillion">{error}</div>}
                <div className="mt-2">
                  <p className="font-poppins text-xs text-gray-500">
                    <Content />
                  </p>
                </div>
                <div className="text-center sm:text-left">{children}</div>
              </div>
              {!noActions && (
                <div className="w-full rounded-b-2xl border-t border-t-gray-100 px-4 py-4 sm:flex sm:justify-between sm:gap-2 sm:px-6">
                  <Button variant="secondary" className="w-full" onClick={closeModal}>
                    {buttonCancelText ?? t('cancel', 'Cancel')}
                  </Button>
                  <Button
                    variant={saveButtonVariant}
                    className="mt-3 w-full sm:mt-0"
                    disabled={saveButtonDisabled}
                    onClick={saveModalContent}
                  >
                    {buttonText}
                  </Button>
                </div>
              )}

              {loading && <OverflowSpinner className="m-1 rounded-2xl" />}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

SaveModal.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.func,
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,
  saveModalContent: PropTypes.func,
  buttonText: PropTypes.string.isRequired,
  buttonCancelText: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.string,
  children: PropTypes.node.isRequired,
  persistent: PropTypes.bool,
  invisible: PropTypes.bool,
  noActions: PropTypes.bool,
  saveButtonDisabled: PropTypes.bool,
  saveButtonVariant: PropTypes.string,
};

SaveModal.defaultProps = {
  buttonCancelText: null,
  content: () => '',
  closeModal: () => {},
  saveModalContent: () => {},
  loading: false,
  error: undefined,
  persistent: false,
  invisible: false,
  noActions: false,
  saveButtonDisabled: false,
  saveButtonVariant: 'primary',
};

export default SaveModal;
