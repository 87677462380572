import Card from '../atoms/Card';
import SingleCardWrapper from '../atoms/SingleCardWrapper';
import { OverflowSpinner } from './OverflowSpinner';

const CardSpinner = () => (
  <SingleCardWrapper>
    <Card className="h-36">
      <OverflowSpinner />
    </Card>
  </SingleCardWrapper>
);

export default CardSpinner;
