import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PaymentMethod } from 'models/paymentMethod';

import { Box, Collapse } from '@mui/material';

import { Spinner } from '@components/atoms/Spinner';
import ArrowDown from '@images/icons/ArrowDown.svg';
import { useGetCompanyPaymentMethodsQuery } from '@services/billing/endpoints';

import { AddPaymentMethodForm } from './AddPaymentMethodForm';
import { PaymentMethodItem } from './PaymentMethodItem';

type PaymentMethodSelectorProps = {
  paymentMethodId?: string;
  savePaymentMethodId: (paymentMethodId: string) => void;
  isLoading: boolean;
};

export const PaymentMethodSelector = ({
  paymentMethodId,
  savePaymentMethodId,
  isLoading,
}: PaymentMethodSelectorProps) => {
  const { t } = useTranslation();
  const [showAddForm, setShowAddForm] = useState(false);
  const [cardListOpen, setCardListOpen] = useState(false);
  const { isSuccess, isError, isFetching, data, refetch } = useGetCompanyPaymentMethodsQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const paymentMethods = (data as { paymentMethods?: PaymentMethod[] })?.paymentMethods ?? [];
  const selectedPaymentMethod = paymentMethods.find((method) => method.id === paymentMethodId);

  if (showAddForm) {
    return (
      <AddPaymentMethodForm
        onSuccess={(setupIntent) => {
          setShowAddForm(false);
          refetch();
          if (typeof setupIntent.payment_method === 'string') {
            savePaymentMethodId(setupIntent.payment_method);
          }
        }}
        onCancel={() => setShowAddForm(false)}
      />
    );
  }

  return (
    <div>
      <Box
        className={`flex h-20 rounded-2xl transition-all duration-500 ${cardListOpen ? 'bg-gray-200' : 'bg-gray-100'}`}
        onClick={() => setCardListOpen(!cardListOpen)}
      >
        {isFetching && (
          <div className="flex w-full items-center justify-center">
            <Spinner size={14} />
          </div>
        )}
        {selectedPaymentMethod && !isFetching && <PaymentMethodItem paymentMethod={selectedPaymentMethod} isSelected />}
        {isSuccess && !isFetching && paymentMethods.length === 0 && (
          <span className="flex w-full items-center justify-center">
            {t('noPaymentMethod', 'No payment method added')}
          </span>
        )}
        {isSuccess && !isFetching && !selectedPaymentMethod && paymentMethods.length > 0 && (
          <div className="flex w-full cursor-pointer items-center justify-between p-4">
            <span>{t('selectExistingPaymentMethod', 'Select an existing payment method')}</span>
            <img src={ArrowDown} alt="Arrow down" />
          </div>
        )}
        {isError && (
          <span className="flex w-full items-center justify-center">
            {t('errorLoadingPaymentMethods', 'Error loading payment methods')}
          </span>
        )}
      </Box>
      {!isFetching && (
        <div className="flex flex-col gap-2">
          <Collapse in={cardListOpen}>
            <div className="mt-2 w-full rounded-2xl bg-white shadow">
              {paymentMethods.map((paymentMethod) => (
                <PaymentMethodItem
                  key={paymentMethod.id}
                  paymentMethod={paymentMethod}
                  preventActions={isLoading}
                  checkIcon={paymentMethod.id === paymentMethodId}
                  onClick={() => !isLoading && savePaymentMethodId(paymentMethod.id)}
                  onPaymentMethodDeleted={refetch}
                />
              ))}
            </div>
          </Collapse>
          <button type="button" className="text-start text-primary" onClick={() => setShowAddForm(true)}>
            {t('addPaymentMethod', 'Add payment method')}
          </button>
        </div>
      )}
    </div>
  );
};
