import { Context, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ReportingTransactionsSummary } from 'models/reporting-transaction';

import { Box, Skeleton, Tooltip, Typography } from '@mui/material';

import { CardContainer } from '@components/atoms/CardContainer';
import useActiveCurrency from '@hooks/useActiveCurrency';

export type SessionsContextType = {
  summary?: ReportingTransactionsSummary;
  summaryLoading: boolean;
};

const TotalSkeleton = () => (
  <Typography variant="h3">
    <Skeleton animation="wave" width="50%" />
  </Typography>
);

type SessionsTotalCardsProps<ContextType> = {
  context: Context<ContextType & SessionsContextType>;
};

export const SessionsTotalCards = <ContextType,>({ context }: SessionsTotalCardsProps<ContextType>) => {
  const { t } = useTranslation();
  const currency = useActiveCurrency();
  const { summary, summaryLoading } = useContext(context);

  return (
    <Box display="flex" flexWrap="wrap" gap={2}>
      <CardContainer display="flex" flexDirection="column" flex={1}>
        <Typography variant="p14" color="#5E5E5E">
          {t('totalConsumption', 'Total consumption')}
        </Typography>
        {summaryLoading ? (
          <TotalSkeleton />
        ) : (
          <Typography variant="h3" display="flex" alignItems="end" gap={0.5}>
            {(summary && summary.totalConsumption !== null ? summary.totalConsumption / 1000 : 0).toFixed(2)}
            <Typography variant="p14" color="#5E5E5E">
              kWh
            </Typography>
          </Typography>
        )}
      </CardContainer>
      <Tooltip title={<Typography variant="p12b">{t('vatNotIncluded', 'VAT not included')}</Typography>}>
        <CardContainer display="flex" flexDirection="column" flex={1}>
          <Typography variant="p14" color="#5E5E5E">
            {t('totalCost', 'Total cost')}
          </Typography>
          {summaryLoading ? (
            <TotalSkeleton />
          ) : (
            <Typography variant="h3" display="flex" alignItems="end" gap={0.5}>
              {summary && summary.totalCost !== null ? summary.totalCost.toFixed(2) : 0}
              <Typography variant="p14" color="#5E5E5E">
                {currency.symbol}
              </Typography>
            </Typography>
          )}
        </CardContainer>
      </Tooltip>
      <CardContainer display="flex" flexDirection="column" flex={1}>
        <Typography variant="p14" color="#5E5E5E">
          {t('totalSessions', 'Total sessions')}
        </Typography>
        {summaryLoading ? (
          <TotalSkeleton />
        ) : (
          <Typography variant="h3" display="flex" alignItems="end" gap={0.5}>
            {summary && summary.totalSessions !== null ? summary.totalSessions : 0}
          </Typography>
        )}
      </CardContainer>
    </Box>
  );
};
