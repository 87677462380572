import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { groupBy } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import MetricsCard from '@components/cards/MetricsCard';
import LineChart from '@components/charts/LineChart';
import { formatDecimals } from '@helpers/formatDecimals';
import { useEventLogQuery } from '@services/eventLog/endpoints';
import { selectEventLog } from '@services/eventLog/selectors';

const DevicesSummary = ({ timeFrame, periodStartTime }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const eventLog = useSelector(selectEventLog);
  const logsByCategory = useMemo(
    () =>
      groupBy(
        eventLog.map((logEntry) => ({
          ...logEntry,
          startTime: moment.utc(logEntry.timestamp * 1000).local(),
          endTime: moment.utc(logEntry.timestamp * 1000).local(),
        })),
        'type',
      ),
    [eventLog],
  );

  const { isFetching: isFetchingLogs } = useEventLogQuery(
    {
      periodStartTime,
      type: ['warning', 'error'],
    },
    {
      skip: !periodStartTime,
      refetchOnMountOrArgChange: true,
    },
  );

  return (
    <div className="grid w-full gap-4 lg:grid-cols-2">
      <MetricsCard
        title={t('devicesInfo', 'Devices info')}
        metrics={[
          {
            label: t('totalWarningLogs', 'Total warnings logs'),
            value: formatDecimals(logsByCategory.warning?.length || 0, 0),
          },
          {
            label: t('totalErrorLogs', 'Total errors logs'),
            value: formatDecimals(logsByCategory.error?.length || 0, 0),
          },
        ]}
        onClick={() => navigate('/logs')}
      />
      <LineChart
        noBorder
        title={t('devicesAlerts', 'Devices alerts')}
        series={[
          {
            name: t('errors', 'Errors'),
            data: logsByCategory?.error || [],
          },
          {
            name: t('warnings', 'Warnings'),
            data: logsByCategory?.warning || [],
            color: '#D97706',
          },
        ]}
        isFetching={isFetchingLogs}
        filter={timeFrame}
      />
    </div>
  );
};

DevicesSummary.propTypes = {
  timeFrame: PropTypes.string,
  periodStartTime: PropTypes.string,
};

DevicesSummary.defaultProps = {
  timeFrame: null,
  periodStartTime: null,
};

export default DevicesSummary;
