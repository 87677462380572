import { useEffect, useState } from 'react';

import { ButtonGroup, ClickAwayListener, Grow, MenuList, Paper, Popper, Typography } from '@mui/material';

import { StyledMenuItem } from '@components/atoms/StyledMenuItem';
import { ReactComponent as MenuArrowImage } from '@images/icons/MenuArrow.svg';

export type TimePeriodSelectorProps = {
  storageKey: string;
  selectedDuration?: string;
  withoutAll?: boolean;
  isMobile?: boolean;
  setSelectedDuration?: (duration: string) => void;
};

export const TimePeriodSelector = ({
  storageKey,
  selectedDuration,
  withoutAll,
  isMobile,
  setSelectedDuration,
}: TimePeriodSelectorProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const shorthandToDuration = {
    '24h': '24-hours',
    '1W': '1-week',
    '1M': '1-months',
    '6M': '6-months',
    '1Y': '1-year',
    ...(!withoutAll && { ALL: 'all' }),
  };
  const selectedShorthand = Object.entries(shorthandToDuration).find(
    ([, duration]) => duration === selectedDuration,
  )?.[0];

  const getTimePeriodItemSx = (isSelected: boolean) => ({
    width: anchorEl?.clientWidth,
    color: isSelected ? 'white' : '#282828',
    backgroundColor: isSelected ? '#282828' : 'transparent',
    borderRadius: '12px',
    height: '32px',
    '&:hover': {
      backgroundColor: isSelected ? '#282828' : '#F4F4F4',
    },
  });

  const handleDurationChange = (duration: string) => {
    setAnchorEl(undefined);
    setSelectedDuration?.(duration);
  };

  const getTimePeriodOptions = () =>
    Object.entries(shorthandToDuration).map(([shorthand, duration]) => (
      <StyledMenuItem
        key={duration}
        label={shorthand}
        sx={getTimePeriodItemSx(selectedDuration === duration)}
        onClick={() => {
          handleDurationChange(duration);
        }}
      />
    ));

  useEffect(() => {
    const localStorageDuration = localStorage.getItem(storageKey) ?? '';
    const defaultDuration = withoutAll ? shorthandToDuration['1Y'] : shorthandToDuration['1M'];
    setSelectedDuration?.(
      Object.values(shorthandToDuration).includes(localStorageDuration) ? localStorageDuration : defaultDuration,
    );
  }, []);

  useEffect(() => {
    if (selectedDuration) {
      localStorage.setItem(storageKey, selectedDuration);
    }
  }, [selectedDuration]);

  useEffect(() => {
    setAnchorEl(undefined);
  }, [isMobile]);

  return (
    <>
      <ButtonGroup
        sx={{
          display: 'flex',
          alignItems: 'center',
          border: '1px solid #D6D6D6',
          borderRadius: '16px',
          minWidth: '65px',
          ...(isMobile
            ? {
                bgcolor: '#282828',
                px: '12px',
                gap: '4px',
                cursor: 'pointer',
              }
            : {
                bgcolor: '#D6D6D6',
              }),
        }}
        onClick={(e) => isMobile && setAnchorEl(e.currentTarget)}
      >
        {isMobile ? (
          <>
            <Typography variant="p14" lineHeight="32px" color="#FFFFFF" align="center" minWidth="25px">
              {selectedShorthand}
            </Typography>
            <MenuArrowImage className={`[&_path]:stroke-white ${anchorEl ? 'rotate-180' : ''}`} />
          </>
        ) : (
          getTimePeriodOptions()
        )}
      </ButtonGroup>
      <Popper
        open={!!anchorEl}
        anchorEl={anchorEl}
        transition
        disablePortal
        sx={{
          minWidth: '65px',
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              marginTop: '4px',
            }}
          >
            <Paper
              sx={{
                bgcolor: '#D6D6D6',
                borderRadius: '16px',
              }}
            >
              <ClickAwayListener onClickAway={() => setAnchorEl(undefined)}>
                <MenuList
                  sx={{
                    padding: '0',
                    textAlign: 'center',
                  }}
                  autoFocusItem
                >
                  {getTimePeriodOptions()}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
