import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, Outlet, useNavigate } from 'react-router-dom';

import { DeviceModalOption, DeviceType } from 'models/device.enums';
import moment from 'moment';

import { Spinner } from '@components/atoms/Spinner';
import { MenuComponent } from '@components/dropdown/MenuComponent';
import SortableTable from '@components/tables/SortableTable';
import kebabIcon from '@images/icons/Kebab.svg';
import { useGetCompaniesQuery } from '@services/companies/endpoints';
import { selectCompanies } from '@services/companies/selectors';
import { useGetAllDevicesQuery } from '@services/devices/endpoints';
import { selectDevices } from '@services/devices/selectors';
import { useGetAllSitesQuery } from '@services/sites/endpoints';
import { selectSites } from '@services/sites/selectors';
import { ConnectorTypeIcon } from '@views/Chargers/ConnectorTypeIcon';
import { DeviceOcppFirmwareModal } from '@views/Devices/DeviceOcppFirmwareModal';
import { getUnifiedConnectors } from '@views/Devices/connectorsUtil';
import { UsageLocation, useGetDeviceMenuItems } from '@views/Devices/useGetDeviceMenuItems';
import { ConnectorStatusContent } from '@views/Sites/SiteDevicesAndGroups/siteDevicesAndGroupsHelpers/ConnectorStatusContent';
import { DeviceIcon } from '@views/Sites/SiteDevicesAndGroups/siteDevicesAndGroupsHelpers/DeviceIcon';
import { DeviceVendorModel } from '@views/Sites/SiteDevicesAndGroups/siteDevicesAndGroupsHelpers/DeviceVendorModel';
import { LmcManagedIndicator } from '@views/Sites/SiteDevicesAndGroups/siteDevicesAndGroupsHelpers/LmcManagedIndicator';
import { LmcStatusContent } from '@views/Sites/SiteDevicesAndGroups/siteDevicesAndGroupsHelpers/LmcStatusContent';

import DeviceFilters from './DeviceFilters';
import DeviceOwner from './DeviceOwner';

const BackofficeDevicesView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [selectedDevice, setSelectedDevice] = useState(null);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [activeModal, setActiveModal] = useState(null);

  const devices = useSelector(selectDevices);
  const sites = useSelector(selectSites);
  const companies = useSelector(selectCompanies);
  const getDeviceMenuItems = useGetDeviceMenuItems({
    onModalOptionSelected: setActiveModal,
    onDeviceSelected: setSelectedDevice,
    usageLocation: UsageLocation.BACKOFFICE_DEVICES,
  });

  const uuidToCompany = useMemo(
    () => Object.fromEntries(companies.map((company) => [company.uuid, company])),
    [companies],
  );
  const uuidToSite = useMemo(() => Object.fromEntries(sites.map((site) => [site.uuid, site])), [sites]);

  useGetAllSitesQuery();
  useGetCompaniesQuery();
  const { isLoading } = useGetAllDevicesQuery(
    { forBackoffice: true },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const getOwnerData = (device) => {
    const deviceSite = uuidToSite[device.siteUuid];
    const deviceCompany = uuidToCompany[device.companyUuid];

    return {
      siteName: deviceSite?.name,
      companyName: deviceCompany?.name,
      email: device?.userEmail,
    };
  };

  const devicesWithStats = useMemo(
    () =>
      devices.map((device) => {
        const ownerData = getOwnerData(device);
        const connectors = getUnifiedConnectors(device);
        return {
          ...device,
          displayName: device.name || device.serialNumber,
          owner: ownerData.siteName || ownerData.companyName || ownerData.email || '-',
          ownerData,
          connectorStatus: connectors.map((connector) => connector.computedStatus).join(', '),
          connectors,
        };
      }),
    [devices, sites, companies],
  );

  const getLinkToDevice = (device) => `/devices/${device.uuid}`;

  const devicesDataColumns = [
    {
      key: 'displayName',
      label: t('name', 'Name'),
      tdClassName: 'py-2 px-4',
      value: (device) => (
        <Link to={getLinkToDevice(device)} className="flex w-full text-left lg:min-w-[200px]">
          <div className="flex flex-col items-center justify-center gap-y-2">
            <DeviceIcon device={device} />
            <LmcManagedIndicator device={device} />
          </div>
          <div className="flex flex-col items-start justify-center text-sm leading-6 lg:pl-4">
            <span className="pt-1 text-base font-bold lg:pt-0">{device.displayName}</span>
            <DeviceVendorModel device={device} />
            {device.name && <span className="text-gray-600">{device.serialNumber}</span>}
          </div>
        </Link>
      ),
    },
    {
      key: 'owner',
      label: t('owner', 'Owner'),
      tdClassName: 'py-2 px-4',
      value: ({ ownerData }) => <DeviceOwner {...ownerData} />,
    },
    {
      key: 'connectorStatus',
      label: t('status', 'Status'),
      tdClassName: 'py-2 px-4',
      value: (device) => (
        <div className="flex items-center gap-x-5">
          <div className="flex w-28 flex-col justify-center">
            {device.type === DeviceType.LMC ? (
              <LmcStatusContent status={device.status} />
            ) : (
              device.connectors.map((connector) => (
                <div key={connector.connectorId || device.uuid} className="text-sm leading-6 text-forest">
                  <ConnectorStatusContent connector={connector} />
                </div>
              ))
            )}
          </div>
          <div className="flex flex-col items-start justify-center">
            {device.connectors.map(
              (connector) =>
                device.type === DeviceType.CHARGER && (
                  <ConnectorTypeIcon
                    key={connector.connectorId || device.uuid}
                    className="flex-row items-center"
                    connectorId={connector.isTheOnlyConnector ? null : connector.connectorId}
                    connectorType={connector.connectorType}
                  />
                ),
            )}
          </div>
        </div>
      ),
    },
    {
      key: 'firmwareVersion',
      label: t('firmware', 'Firmware'),
      tdClassName: 'py-2 px-4',
      value: (device) => device?.firmwareVersion || '-',
    },
    {
      key: 'addTime',
      label: t('dateAdded', 'Date added'),
      tdClassName: 'py-2 px-4',
      value: (device) => {
        const date = moment.utc(device.addTime).local();

        return (
          <div className="text-sm leading-6">
            <div>{date.format('HH:mm')}</div>
            <div>{date.format('DD MMM YYYY')}</div>
          </div>
        );
      },
    },
    {
      key: 'menu',
      label: '',
      sortingDisabled: true,
      tdClassName: 'w-10 pr-1.5',
      value: (device) => <MenuComponent menuItems={getDeviceMenuItems(device)} buttonIcon={kebabIcon} />,
    },
  ];

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-col gap-y-5">
      <DeviceFilters devices={devicesWithStats} onFilteredDevicesChange={setFilteredDevices} />
      <SortableTable
        columns={devicesDataColumns}
        data={filteredDevices}
        keyAttr="uuid"
        sortingStorageKey="devicesTable"
        selectedItem={selectedDevice}
        onRowClick={(device) => navigate(getLinkToDevice(device))}
      />
      <Outlet />
      {selectedDevice && (
        <>
          <DeviceOcppFirmwareModal
            isOpen={[DeviceModalOption.OCPP_RESET, DeviceModalOption.OCPP_UPDATE].includes(activeModal)}
            option={activeModal}
            closeModal={() => setActiveModal(null)}
            device={selectedDevice}
          />
        </>
      )}
    </div>
  );
};

export default BackofficeDevicesView;
