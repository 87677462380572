import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import EditingSidebarBase from '@components/sidebars/EditingSidebarBase';
import { useSaveCompanyPaymentMethodMutation } from '@services/companies/endpoints';
import { selectActiveCompany } from '@services/companies/selectors';
import { addToastMessage } from '@services/toastMessages';

import { PaymentMethodSelector } from './PaymentMethodSelector';

export const PaymentMethodSidebar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [saveCompanyPaymentMethod, { isLoading: saveLoading }] = useSaveCompanyPaymentMethodMutation();
  const activeCompany = useSelector(selectActiveCompany);
  const [stripePaymentMethodId, setStripePaymentMethodId] = useState<string>();

  useEffect(() => {
    setStripePaymentMethodId(activeCompany?.stripePaymentMethodId);
  }, [activeCompany]);

  const handleSave = async () => {
    try {
      if (!stripePaymentMethodId) return false;
      await saveCompanyPaymentMethod({ stripePaymentMethodId }).unwrap();
      dispatch(addToastMessage({ type: 'success', title: t('paymentMethodSaved', 'Payment method saved') }));
    } catch (error) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('addingPaymentMethodFailed', 'Adding payment method failed'),
          message: t('pleaseTryAgain', 'Please try again.'),
        }),
      );

      return false;
    }

    return true;
  };

  return (
    <EditingSidebarBase
      title="Payment method"
      containerClassName="space-y-8 font-poppins"
      onSaveValues={handleSave}
      saveDisabled={saveLoading}
      anyDataChanged={stripePaymentMethodId !== activeCompany?.stripePaymentMethodId}
    >
      <span className="text-gray-600">
        {t('companyPaymentMethodDescription', 'Set the payment method to use for your monthly plan.')}
      </span>
      <PaymentMethodSelector
        paymentMethodId={stripePaymentMethodId}
        savePaymentMethodId={(paymentMethodId) => {
          setStripePaymentMethodId(paymentMethodId);
        }}
        isLoading={saveLoading}
      />
    </EditingSidebarBase>
  );
};
