import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { isEmpty } from 'lodash';
import {
  DeviceStatus,
  MainsMeteringType,
  PowerMeterType,
  PowerSharingMethod,
  SolarInverterType,
} from 'models/device.enums';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import Autocomplete from '@components/atoms/Autocomplete';
import { Checkbox } from '@components/atoms/Checkbox';
import { Input } from '@components/atoms/Input';
import { RadioButton } from '@components/atoms/RadioButton';
import EditingSidebarBase from '@components/sidebars/EditingSidebarBase';
import { DEVICE_CONFIGURATION_PARAMETERS } from '@handlers/device/deviceConst';
import { useForm } from '@hooks';
import { selectUser } from '@services/auth/selectors';
import { isVoolDevice } from '@views/Sites/SiteDevicesAndGroups/siteDevicesAndGroupsHelpers/siteDevicesUtils';

const useSchema = ({ device, isAdmin, isInstaller }) => {
  const { t } = useTranslation();
  const requiredLabel = t('required*', 'Required*');
  const validateIp = (value) => {
    const segments = value.split('.');
    return (
      segments.length === 4 &&
      segments.every((segment) => segment.length && Number(segment) >= 0 && Number(segment) <= 255)
    );
  };
  return yup
    .object()
    .shape({
      deviceName: yup.string(),
      gridConnection: yup
        .number()
        .typeError(t('mustBeNumber', 'Must be a number'))
        .required(requiredLabel)
        .test('positive', t('mustBePositive', 'Must be positive'), (value) => value > 0),
      ...(isVoolDevice(device) && {
        powerSharingMethod: yup.string().required(requiredLabel),
        powerMeterType: yup.string().required(requiredLabel),
        powerMeterIpAddress: yup
          .string()
          .required(requiredLabel)
          .test('ipAddress', t('mustBeValidIpAddress', 'Must be a valid IP address'), validateIp),
        solarInverterType: yup.string().required(requiredLabel),
        solarInverterIpAddress: yup
          .string()
          .required(requiredLabel)
          .test('ipAddress', t('mustBeValidIpAddress', 'Must be a valid IP address'), validateIp),
        ...(isAdmin && {
          ocppHostAddress: yup.string().required(requiredLabel),
          ocppServerReconnectTime: yup
            .number()
            .typeError(t('mustBeNumber', 'Must be a number'))
            .required(requiredLabel)
            .test('positive', t('mustBePositive', 'Must be positive'), (value) => value > 0),
        }),
        ...((isAdmin || isInstaller) && {
          mainsMeteringMode: yup.string().required(requiredLabel),
        }),
        externalAntennaEnabled: yup.bool(),
      }),
    })
    .required();
};

const LmcSettingsFormSidebar = ({ device, isUpdateLoading, isUpdateError, updateFailedMessage, onSaveValues }) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  const isAdmin = !!user?.admin;
  const isInstaller = !!user?.features?.isInstaller;
  const deviceConfiguration = device.configuration ?? {};
  const isOffline = device.status === DeviceStatus.OFFLINE;

  const schema = useSchema({ device, isAdmin, isInstaller });
  const { formState, reset, register, handleSubmitAndResolve, watch, clearErrors, setValue } = useForm({
    schema,
  });

  const {
    powerMeterType: selectedPowerMeterType,
    solarInverterType: selectedSolarInverterType,
    powerSharingMethod: selectedPowerSharingMethod,
    mainsMeteringMode: selectedMainsMeteringMode,
  } = watch();

  const getOptions = (array) =>
    array.map((item) => ({
      label: item,
      value: item,
    }));

  const powerMeterOptions = getOptions(
    deviceConfiguration[DEVICE_CONFIGURATION_PARAMETERS.POWER_METER_TYPE_OPTIONS.key]?.value ?? [],
  );
  const solarInverterOptions = getOptions(
    deviceConfiguration[DEVICE_CONFIGURATION_PARAMETERS.SOLAR_INVERTER_TYPE_OPTIONS.key]?.value ?? [],
  );

  const saveValues = async ({
    deviceName,
    gridConnection,
    powerSharingMethod,
    powerMeterType,
    powerMeterIpAddress,
    solarInverterType,
    solarInverterIpAddress,
    ocppHostAddress,
    ocppServerReconnectTime,
    mainsMeteringMode,
    externalAntennaEnabled,
  }) => {
    const result = {
      deviceUpdateData: {
        uuid: device.uuid,
        name: deviceName?.trim() || null,
        ...(!!Number(gridConnection) && { gridConnection }),
      },
    };

    if (isVoolDevice(device)) {
      const parameters = [
        {
          key: DEVICE_CONFIGURATION_PARAMETERS.POWER_SHARING_METHOD.key,
          value: powerSharingMethod,
        },
        {
          key: DEVICE_CONFIGURATION_PARAMETERS.POWER_METER_TYPE.key,
          value: powerMeterType,
        },
        {
          key: DEVICE_CONFIGURATION_PARAMETERS.POWER_METER_IP.key,
          value: powerMeterIpAddress,
        },
        {
          key: DEVICE_CONFIGURATION_PARAMETERS.SOLAR_INVERTER_TYPE.key,
          value: solarInverterType,
        },
        {
          key: DEVICE_CONFIGURATION_PARAMETERS.SOLAR_INVERTER_IP.key,
          value: solarInverterIpAddress,
        },
        {
          key: DEVICE_CONFIGURATION_PARAMETERS.EXTERNAL_ANTENNA_ENABLED.key,
          value: externalAntennaEnabled,
        },
      ];

      if (isAdmin) {
        parameters.push({
          key: DEVICE_CONFIGURATION_PARAMETERS.OCPP_SERVER_ADDRESS.key,
          value: ocppHostAddress,
        });
        parameters.push({
          key: DEVICE_CONFIGURATION_PARAMETERS.OCPP_SERVER_RECONNECT_TIME_S.key,
          value: ocppServerReconnectTime,
        });
      }
      if (isAdmin || isInstaller) {
        parameters.push({
          key: DEVICE_CONFIGURATION_PARAMETERS.MAINS_METERING_TYPE.key,
          value: mainsMeteringMode,
        });
      }
      result.configurationUpdateData = {
        uuid: device.uuid,
        deviceConfig: parameters,
      };
    }

    return onSaveValues(result);
  };

  const applyIpMask = (event) => {
    const { value } = event.target;
    const segments = value.split('.');
    const output = segments.map((segment) => segment.slice(0, 3)).join('.');
    event.target.value = output;
  };

  useEffect(() => {
    if (isEmpty(device)) {
      reset();
    } else {
      reset({
        deviceName: device.name || device.serialNumber,
        gridConnection: device.gridConnection ?? '',
        ...(isVoolDevice(device) && {
          powerSharingMethod: deviceConfiguration[DEVICE_CONFIGURATION_PARAMETERS.POWER_SHARING_METHOD.key]?.value,
          powerMeterType: deviceConfiguration[DEVICE_CONFIGURATION_PARAMETERS.POWER_METER_TYPE.key]?.value,
          powerMeterIpAddress: deviceConfiguration[DEVICE_CONFIGURATION_PARAMETERS.POWER_METER_IP.key]?.value,
          solarInverterType: deviceConfiguration[DEVICE_CONFIGURATION_PARAMETERS.SOLAR_INVERTER_TYPE.key]?.value,
          solarInverterIpAddress: deviceConfiguration[DEVICE_CONFIGURATION_PARAMETERS.SOLAR_INVERTER_IP.key]?.value,
          ...(isAdmin && {
            ocppHostAddress: deviceConfiguration[DEVICE_CONFIGURATION_PARAMETERS.OCPP_SERVER_ADDRESS.key]?.value,
            ocppServerReconnectTime:
              deviceConfiguration[DEVICE_CONFIGURATION_PARAMETERS.OCPP_SERVER_RECONNECT_TIME_S.key]?.value,
          }),
          ...((isAdmin || isInstaller) && {
            mainsMeteringMode: deviceConfiguration[DEVICE_CONFIGURATION_PARAMETERS.MAINS_METERING_TYPE.key]?.value,
          }),
          externalAntennaEnabled:
            deviceConfiguration[DEVICE_CONFIGURATION_PARAMETERS.EXTERNAL_ANTENNA_ENABLED.key]?.value,
        }),
      });
    }
  }, [device.configuration]);

  return (
    <EditingSidebarBase
      title={t('settings', 'Settings')}
      subtitle={device.name || device.serialNumber}
      saveLabel={t('update', 'Update')}
      discardLabel={t('cancel', 'Cancel')}
      containerClassName="flex flex-col"
      containerBottomPadding="pb-36"
      anyDataChanged={formState.isDirty}
      onSaveValues={handleSubmitAndResolve(saveValues)}
      updateLoading={isUpdateLoading}
      updateFailed={isUpdateError}
      updateFailedMessage={updateFailedMessage}
      isSubSidebar
    >
      <div className="flex flex-col gap-y-12 font-poppins">
        <div className="flex flex-col gap-y-6">
          <Input
            name="deviceName"
            label={`${t('deviceName', 'Device name')} (${t('optional', 'Optional').toLowerCase()})`}
            type="text"
            error={Boolean(formState.errors?.deviceName)}
            helpText={formState.errors?.deviceName?.message}
            {...register('deviceName')}
          />
          <Input
            label={t('gridConnection', 'Grid connection')}
            name="gridConnection"
            trailingText="A"
            type="number"
            isInteger
            error={Boolean(formState.errors?.gridConnection)}
            helpText={formState.errors?.gridConnection?.message}
            disabled={isOffline}
            {...register('gridConnection')}
          />
        </div>
        {isVoolDevice(device) && (
          <>
            {isAdmin && (
              <div className="flex flex-col gap-y-4">
                <div className="flex items-center gap-x-2 font-semibold">{t('ocppHost', 'OCPP host')}</div>
                <div className="flex flex-col gap-y-6">
                  <Input
                    label={t('ocppHostAddress', 'OCPP host address')}
                    name="ocppHostAddress"
                    disabled={isOffline}
                    error={Boolean(formState.errors?.ocppHostAddress)}
                    helpText={formState.errors?.ocppHostAddress?.message}
                    {...register('ocppHostAddress')}
                  />
                  <Input
                    label={t('ocppServerReconnectTime', 'OCPP server reconnect time')}
                    name="ocppServerReconnectTime"
                    disabled={isOffline}
                    error={Boolean(formState.errors?.ocppServerReconnectTime)}
                    helpText={formState.errors?.ocppServerReconnectTime?.message}
                    {...register('ocppServerReconnectTime')}
                  />
                </div>
              </div>
            )}
            {(isAdmin || isInstaller) && (
              <div className="flex flex-col gap-y-6">
                <div className="flex items-center gap-x-2 font-semibold">
                  {t('mainsMeteringMode', 'Mains metering mode')}
                </div>
                <RadioButton
                  value={MainsMeteringType.HOUSE_AND_CHARGER_CONSUMPTION}
                  checked={selectedMainsMeteringMode === MainsMeteringType.HOUSE_AND_CHARGER_CONSUMPTION}
                  label={t('buildingAndCharger', 'Building and charger')}
                  name="buildingAndCharger"
                  helpTextClassName="text-sm !text-gray-600"
                  helpText={t(
                    'buildingAndChargerMainsMeteringTypeText',
                    'The LMC meters both the building and charger current.',
                  )}
                  disabled={isOffline}
                  onChange={(event) => {
                    clearErrors();
                    setValue('mainsMeteringMode', event.target.value, { shouldDirty: true });
                  }}
                />
                <RadioButton
                  value={MainsMeteringType.HOUSE_CONSUMPTION}
                  checked={selectedMainsMeteringMode === MainsMeteringType.HOUSE_CONSUMPTION}
                  label={t('buildingOnly', 'Building only')}
                  name="buildingOnly"
                  helpTextClassName="text-sm !text-gray-600"
                  helpText={t('buildingOnlyMainsMeteringTypeText', 'The LMC meters only the building current.')}
                  disabled={isOffline}
                  onChange={(event) => {
                    clearErrors();
                    setValue('mainsMeteringMode', event.target.value, { shouldDirty: true });
                  }}
                />
              </div>
            )}
            <div className="flex flex-col gap-y-6">
              <div className="flex items-center gap-x-2 font-semibold">
                {t('loadManagementMode', 'Load management mode')}
              </div>
              <RadioButton
                value={PowerSharingMethod.EQUAL}
                checked={selectedPowerSharingMethod === PowerSharingMethod.EQUAL}
                label={t('equal', 'Equal')}
                name="equal"
                helpTextClassName="text-sm !text-gray-600"
                helpText={t(
                  'equalLoadManagementModeText',
                  'Share available power equally between all active devices connected through this LMC.',
                )}
                disabled={isOffline}
                onChange={(event) => {
                  clearErrors();
                  setValue('powerSharingMethod', event.target.value, { shouldDirty: true });
                }}
              />
              <RadioButton
                value={PowerSharingMethod.PRIORITIZED}
                checked={selectedPowerSharingMethod === PowerSharingMethod.PRIORITIZED}
                label={t('prioritized', 'Prioritized')}
                name="prioritized"
                helpTextClassName="text-sm !text-gray-600"
                helpText={t(
                  'prioritizedLoadManagementModeText',
                  'The first vehicles to start charging will be given higher priority.',
                )}
                disabled={isOffline}
                onChange={(event) => {
                  clearErrors();
                  setValue('powerSharingMethod', event.target.value, { shouldDirty: true });
                }}
              />
            </div>
            <div className="flex flex-col gap-y-4">
              <div className="flex items-center gap-x-2 font-semibold">{t('powerMetering', 'Power metering')}</div>
              <div className="flex flex-col gap-y-2">
                <div className="text-sm text-gray-600">
                  {t(
                    'powerMeteringText',
                    'Currently the only supported model is the Siemens PAC3220, but other Modbus compliant models may work.',
                  )}
                </div>
                <Autocomplete
                  label={t('powerMeter', 'Power meter')}
                  options={powerMeterOptions}
                  value={selectedPowerMeterType}
                  onChange={(newPowerMeterType) => {
                    clearErrors();
                    setValue('powerMeterType', newPowerMeterType, { shouldDirty: true });
                  }}
                  isError={Boolean(formState.errors?.powerMeterType)}
                  helpText={formState.errors?.powerMeterType?.message}
                  disabled={isOffline}
                />
                <Input
                  label={t('powerMeterIpAddress', 'Power meter IP address')}
                  name="powerMeterIpAddress"
                  disabled={selectedPowerMeterType === PowerMeterType.NONE || isOffline}
                  inputProps={{
                    onInput: applyIpMask,
                  }}
                  error={Boolean(formState.errors?.powerMeterIpAddress)}
                  helpText={formState.errors?.powerMeterIpAddress?.message}
                  {...register('powerMeterIpAddress')}
                />
              </div>
            </div>
            <div className="flex flex-col gap-y-4">
              <div className="flex items-center gap-x-2 font-semibold">{t('solarInverter', 'Solar inverter')}</div>
              <div className="flex flex-col gap-y-2">
                <div className="text-sm text-gray-600">
                  {t(
                    'solarInverterText',
                    'Currently the only supported model is the Huawei Smart Logger 3000, but other Modbus compliant models may work.',
                  )}
                </div>
                <Autocomplete
                  label={t('solarInverter', 'Solar inverter')}
                  options={solarInverterOptions}
                  value={selectedSolarInverterType}
                  onChange={(newSolarInverterType) => {
                    clearErrors();
                    setValue('solarInverterType', newSolarInverterType, { shouldDirty: true });
                  }}
                  isError={Boolean(formState.errors?.solarInverterType)}
                  helpText={formState.errors?.solarInverterType?.message}
                  disabled={isOffline}
                />
                <Input
                  label={t('solarInverterIpAddress', 'Solar inverter IP address')}
                  name="solarInverterIpAddress"
                  disabled={selectedSolarInverterType === SolarInverterType.NONE || isOffline}
                  inputProps={{
                    onInput: applyIpMask,
                  }}
                  error={Boolean(formState.errors?.solarInverterIpAddress)}
                  helpText={formState.errors?.solarInverterIpAddress?.message}
                  {...register('solarInverterIpAddress')}
                />
              </div>
            </div>
            <div className="flex flex-col gap-y-4">
              <Checkbox
                label={t('externalAntennaEnabled', 'External antenna enabled')}
                name="externalAntennaEnabled"
                disabled={isOffline}
                {...register('externalAntennaEnabled')}
              />
              <div className="text-sm text-gray-600">
                {t(
                  'externalAntennaEnabledText',
                  'Before enabling, verify that external external antenna is present and correctly connected.',
                )}
                <br />
                <strong>{t('restartLmcToApplyChanges', 'Restart LMC to apply changes to this setting.')}</strong>
              </div>
            </div>
          </>
        )}
      </div>
    </EditingSidebarBase>
  );
};

LmcSettingsFormSidebar.propTypes = {
  device: PropTypes.object,
  isUpdateLoading: PropTypes.bool,
  isUpdateError: PropTypes.bool,
  updateFailedMessage: PropTypes.string,
  onSaveValues: PropTypes.func,
};

LmcSettingsFormSidebar.defaultProps = {
  device: {},
  isUpdateLoading: false,
  isUpdateError: false,
  updateFailedMessage: null,
  onSaveValues: () => {},
};

export default LmcSettingsFormSidebar;
