import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { isFinite } from 'lodash';
import { Car } from 'models/car';

import { MenuComponent } from '@components/dropdown/MenuComponent';
import SortableTable from '@components/tables/SortableTable';
import VehicleHandler from '@handlers/vehicle';
import { VEHICLE_STATUS_OPTIONS } from '@handlers/vehicle/constVehicle';
import useActiveCurrency from '@hooks/useActiveCurrency';
import kebabIcon from '@images/icons/Kebab.svg';
import { ReactComponent as VehicleIcon } from '@images/icons/Vehicle_Avatar.svg';
import { ReactComponent as PrivateVehicleIcon } from '@images/icons/Vehicle_Avatar_Private.svg';
import { useAppSelector } from '@services/hooks';
import { useGetSitesQuery } from '@services/sites/endpoints';
import { selectSites } from '@services/sites/selectors';

import { UseGetVehicleMenuItemsProps, useGetVehicleMenuItems } from '../useGetVehicleMenuItems';
import { VehicleBatteryInfo, VehicleStatusInfo } from './vehicleDataComponents';

type VehiclesDataTableColumn = {
  key: keyof VehiclesWithStats | 'menu';
  label: string;
  tdClassName?: string;
  className?: string;
  sortingDisabled?: boolean;
  extraData?: string;
  extraDataClassName?: string;
  tooltip?: string;
  value: (vehicle: VehiclesWithStats) => React.ReactNode;
};

type VehiclesWithStats = Car & {
  batteryLevel: number;
  status: string;
  consumption: string;
  costAvgPer100km: string;
  consumptionAvgPer100km: string;
  totalCost: string;
  mileage: string;
  siteName: string;
};

type SortedVehiclesTableProps = {
  selectedVehicle?: Car;
  vehicles: Car[];
  onVehicleModalOptionSelected: UseGetVehicleMenuItemsProps['onVehicleModalOptionSelected'];
};

export const SortedVehiclesTable = ({
  selectedVehicle,
  vehicles,
  onVehicleModalOptionSelected,
}: SortedVehiclesTableProps) => {
  const { t } = useTranslation();
  const currency = useActiveCurrency();
  const sites = useAppSelector(selectSites);

  useGetSitesQuery({});

  const isGuestCar = (vehicle: Car) => vehicle.connectionType === 'guest-car';

  const vehiclesWithStats = useMemo<VehiclesWithStats[]>(
    () =>
      vehicles.map((vehicle) => {
        const totalKilometers = vehicle?.odometerSummary?.totalKilometers ?? 0;
        const { totalMonetaryCost = 0, totalKwhAmount = 0 } = vehicle?.chargingSessionsSummary || {};
        return {
          ...vehicle,
          batteryLevel: vehicle.data?.format?.batteryLevel || 0,
          status: new VehicleHandler(vehicle).getStatusDataByOption(VEHICLE_STATUS_OPTIONS.chargeStatus),
          consumption: totalKwhAmount?.toFixed(2) ?? '-',
          costAvgPer100km:
            isFinite(totalMonetaryCost / totalKilometers) && !isGuestCar(vehicle)
              ? ((totalMonetaryCost / totalKilometers) * 100).toFixed(2)
              : '-',
          consumptionAvgPer100km:
            isFinite(totalKwhAmount / totalKilometers) && !isGuestCar(vehicle)
              ? ((totalKwhAmount / totalKilometers) * 100).toFixed(2)
              : '-',
          totalCost: totalMonetaryCost?.toFixed(2) ?? '-',
          mileage: !isGuestCar(vehicle)
            ? (totalKilometers ?? 0).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : '-',
          siteName: (vehicle.siteUuid && sites.find((site) => site.uuid === vehicle.siteUuid)?.name) || '-',
        };
      }),
    [vehicles, sites],
  );

  const anyVehicleHasSite = vehicles.some((vehicle) => vehicle.siteUuid);

  const getVehicleMenuItems = useGetVehicleMenuItems({
    onVehicleModalOptionSelected,
  });

  const vehicleDataTableColumns: VehiclesDataTableColumn[] = [
    {
      key: 'model',
      label: t('name', 'Name'),
      value: (vehicle) => {
        const VehicleAvatar = vehicle.privateFlag ? PrivateVehicleIcon : VehicleIcon;
        return (
          <Link to={`/vehicles/${vehicle.uuid}`} className="flex w-full py-2 pl-4 font-poppins">
            <VehicleAvatar className="hidden h-12 w-12 min-w-[48px] lg:block" />
            <div className="flex flex-col pl-4 text-left">
              <span className="text-base font-semibold">{vehicle?.name || vehicle.model}</span>
              <span className="text-sm font-normal">{vehicle.brand}</span>
            </div>
          </Link>
        );
      },
    },
    {
      key: 'batteryLevel',
      label: t('estimateRange', 'Est. range'),
      tdClassName: 'py-2 pl-4',
      value: (vehicle) => (!isGuestCar(vehicle) ? <VehicleBatteryInfo vehicle={vehicle} /> : '-'),
    },
    {
      key: 'status',
      label: t('status', 'Status'),
      tdClassName: 'py-2 pl-4',
      value: (vehicle) => (!isGuestCar(vehicle) ? <VehicleStatusInfo vehicle={vehicle} /> : '-'),
    },
    {
      key: 'consumption',
      label: t('consumption', 'Consumption'),
      extraData: 'kWh',
      extraDataClassName: 'text-gray-400',
      className: 'justify-end text-right',
      tdClassName: 'py-2 px-4 pr-8 text-right',
      value: (vehicle) => vehicle.consumption,
    },
    {
      key: 'consumptionAvgPer100km',
      label: `${t('averageAbbreviation', 'Avg.')} kWh/100km`,
      extraData: 'kWh',
      extraDataClassName: 'text-gray-400',
      className: 'justify-end text-right',
      tdClassName: 'py-2 px-4 pr-8 text-right',
      value: (vehicle) => vehicle.consumptionAvgPer100km,
    },
    {
      key: 'costAvgPer100km',
      label: `${t('averageAbbreviation', 'Avg.')} ${currency.symbol}/100km`,
      extraData: currency.symbol,
      extraDataClassName: 'text-gray-400',
      className: 'justify-end text-right',
      tdClassName: 'py-2 px-4 pr-8 text-right',
      value: (vehicle) => vehicle.costAvgPer100km,
      tooltip: t('vatNotIncluded', 'VAT not included'),
    },
    {
      key: 'totalCost',
      label: t('totalCost', 'Total cost'),
      extraData: currency.symbol,
      extraDataClassName: 'text-gray-400',
      className: 'justify-end text-right',
      tdClassName: 'py-2 px-4 pr-8 text-right',
      value: (vehicle) => vehicle.totalCost,
      tooltip: t('vatNotIncluded', 'VAT not included'),
    },
    {
      key: 'mileage',
      label: t('mileage', 'Mileage'),
      extraData: 'km',
      extraDataClassName: 'text-gray-400',
      className: 'justify-end text-right',
      tdClassName: 'py-2 px-4 pr-8 text-right',
      value: (vehicle) => vehicle.mileage,
    },
    ...((anyVehicleHasSite
      ? [
          {
            key: 'siteName',
            label: t('site', 'Site'),
            className: 'justify-end text-right',
            tdClassName: 'py-2 px-4 pr-8 text-right',
            value: (vehicle) => vehicle.siteName,
          },
        ]
      : []) as VehiclesDataTableColumn[]),
    {
      key: 'menu',
      label: '',
      sortingDisabled: true,
      tdClassName: 'w-10 pr-1.5',
      value: (vehicle) => <MenuComponent menuItems={getVehicleMenuItems(vehicle)} buttonIcon={kebabIcon} />,
    },
  ];

  return (
    <div className="overflow-x-auto rounded-2xl pb-28">
      <SortableTable
        columns={vehicleDataTableColumns}
        data={vehiclesWithStats}
        selectedItem={selectedVehicle}
        keyAttr="uuid"
        sortingStorageKey="vehiclesTable"
      />
    </div>
  );
};
