import {
  AppleLoginDto,
  ChangeResetPasswordDto,
  GoogleLoginDto,
  LoginDto,
  LoginResponseDto,
  RegisterDto,
  ResetPasswordDto,
  ResetPasswordResponseDto,
} from 'models/auth';
import { InviteData } from 'models/company';
import { UpdateUserDto, UpdateUserResponseDto, UserSelfResponseDto } from 'models/user';

import apiSlice from '@services/api';

export const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    register: builder.mutation<LoginResponseDto, RegisterDto>({
      query: ({ name, email, inviteToken, password }) => ({
        url: 'auth/register',
        method: 'POST',
        body: { name, email, inviteToken, password },
      }),
    }),
    self: builder.query<UserSelfResponseDto, void>({
      query: () => ({
        url: 'users/self',
        method: 'GET',
      }),
      providesTags: ['CompanyContext'],
    }),
    login: builder.mutation<LoginResponseDto, LoginDto>({
      query: ({ email, inviteToken, password }) => ({
        url: 'auth/login',
        method: 'POST',
        body: { email, inviteToken, password },
      }),
    }),
    resetPassword: builder.mutation<ResetPasswordResponseDto, ResetPasswordDto>({
      query: (body) => ({
        url: 'auth/reset-password',
        method: 'POST',
        body,
      }),
    }),
    checkPasswordHash: builder.query<ResetPasswordResponseDto, { token: string }>({
      query: ({ token }) => ({
        url: 'auth/check-password-hash',
        method: 'POST',
        body: { token },
      }),
    }),
    changeResetPassword: builder.mutation<ResetPasswordResponseDto, ChangeResetPasswordDto>({
      query: ({ token, password }) => ({
        url: 'auth/change-reset-password',
        method: 'POST',
        body: { token, password },
      }),
    }),
    googleLogin: builder.mutation<LoginResponseDto, GoogleLoginDto>({
      query: ({ email, inviteToken, name, password, idToken }) => ({
        url: 'auth/google-login',
        method: 'POST',
        body: { email, inviteToken, name, password, idToken },
      }),
    }),
    appleLogin: builder.mutation<LoginResponseDto, AppleLoginDto>({
      query: ({ code, bundleId, firstName, lastName, inviteToken }) => ({
        url: `auth/apple-login`,
        method: 'POST',
        params: {
          code,
          bundleId,
          firstName,
          lastName,
          inviteToken,
        },
      }),
    }),
    getInviteData: builder.query<{ data: InviteData }, string>({
      query: (inviteToken) => ({
        url: `auth/inviteData/${inviteToken}`,
        method: 'GET',
      }),
    }),
    updateUser: builder.mutation<UpdateUserResponseDto, UpdateUserDto>({
      query: (body) => ({
        url: 'users/self',
        method: 'PUT',
        body,
      }),
    }),
    updateUserAndInvalidateCompany: builder.mutation<UpdateUserResponseDto, UpdateUserDto>({
      query: (body) => ({
        url: 'users/self',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['CompanyContext'],
    }),
  }),
});

export const {
  useRegisterMutation,
  useSelfQuery,
  useLoginMutation,
  useResetPasswordMutation,
  useCheckPasswordHashQuery,
  useChangeResetPasswordMutation,
  useGoogleLoginMutation,
  useAppleLoginMutation,
  useGetInviteDataQuery,
  useUpdateUserMutation,
  useUpdateUserAndInvalidateCompanyMutation,
} = authApi;
