import { Context, useContext, useEffect, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';

import { Box, InputAdornment, TextField } from '@mui/material';

import { MenuContainer, MenuContainerProps } from '@components/atoms/MenuContainer';
import { StyledMenuItem, StyledMenuItemProps } from '@components/atoms/StyledMenuItem';
import { useFilterMenu } from '@components/filters/hooks/useFilterMenu';
import { ReactComponent as SearchIcon } from '@images/icons/reporting/search.svg';

import { FilterMenuOption } from '.';
import { FiltersBaseContextType } from './context/FiltersContext';

type FilterMenuProps<ContextType, ExtractedType extends FilterMenuOption> = Omit<MenuContainerProps, 'children'> & {
  context: Context<ContextType & FiltersBaseContextType<ExtractedType>>;
  selectedFilter?: FilterMenuOption;
  options: StyledMenuItemProps[];
};

export const FilterMenu = <ContextType, ExtractedType extends FilterMenuOption>({
  context,
  anchorEl,
  marginTop,
  horizontalOrigin,
  selectedFilter,
  options,
  onClose,
}: FilterMenuProps<ContextType, ExtractedType>) => {
  const { filter, setFilter, filterOptionsMap } = useContext(context);
  const { filterLabel } = useFilterMenu(filterOptionsMap);
  const filterMenuOptions = (options: StyledMenuItemProps[]) => {
    return options?.filter((option) => option.label?.toLowerCase().includes(filter.toLowerCase()));
  };

  const [filteredMenuOptions, setFilteredMenuOptions] = useState<StyledMenuItemProps[]>(filterMenuOptions(options));

  useEffect(() => {
    setFilteredMenuOptions(filterMenuOptions(options));
  }, [filter, options]);

  return (
    <MenuContainer
      anchorEl={anchorEl}
      width="343px"
      marginTop={marginTop}
      horizontalOrigin={horizontalOrigin}
      onClose={() => {
        setFilter('');
        onClose();
      }}
    >
      {selectedFilter && (
        <TextField
          name="vool-filter"
          autoComplete="off"
          placeholder={filterLabel[selectedFilter]}
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          fullWidth
          variant="standard"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ color: '#282828', cursor: 'default' }}>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{
            '& .MuiInput-root': {
              '&:hover:not(.Mui-disabled, .Mui-error):before, ::before, ::after': { border: 'none' },
              '&.Mui-disabled:before': { borderBottomStyle: 'none' },
            },
            padding: '8px 12px',
          }}
        />
      )}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        px={0.5}
        sx={selectedFilter && { ':not(:empty)': { borderTop: '1px solid #F4F4F4', paddingTop: '4px' } }}
      >
        {filteredMenuOptions.length < 20 ? (
          filteredMenuOptions?.map((option) => <StyledMenuItem key={option.uuid} {...option} />)
        ) : (
          <Virtuoso
            style={{ height: '600px' }}
            totalCount={filteredMenuOptions.length}
            itemContent={(index) => (
              <StyledMenuItem key={filteredMenuOptions[index].uuid} {...filteredMenuOptions[index]} />
            )}
          />
        )}
      </Box>
    </MenuContainer>
  );
};
