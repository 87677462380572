import PropTypes from 'prop-types';

import Card from '@components/atoms/Card';
import ComponentError from '@components/atoms/ComponentError';

import { OverflowSpinner } from '../spinner/OverflowSpinner';

const CardItem = ({ children, footer: Footer, tools: Tools, loading, error, expandable, closedProp, ...props }) => {
  if (loading) {
    return (
      <Card {...props}>
        <OverflowSpinner size="14" className="p-5" />
      </Card>
    );
  }

  if (error) {
    return (
      <Card {...props}>
        <ComponentError />
      </Card>
    );
  }

  return (
    <Card expandable={expandable} initiallyClosed={closedProp} {...props}>
      {Tools && (
        <div className="absolute right-0 top-0 z-10 pr-5 pt-3">
          <Tools />
        </div>
      )}
      {children}

      {Footer && (
        <div className="absolute bottom-0 w-full p-4 pb-7 pl-7 pr-7">
          <Footer />
        </div>
      )}
    </Card>
  );
};

CardItem.propTypes = {
  children: PropTypes.node,
  footer: PropTypes.func,
  tools: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  expandable: PropTypes.bool,
  closedProp: PropTypes.bool,
};

CardItem.defaultProps = {
  children: null,
  footer: null,
  tools: null,
  loading: false,
  error: false,
  expandable: false,
  closedProp: false,
};

export default CardItem;
