import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { isEmpty, isNil } from 'lodash';
import { DeviceStatus } from 'models/device.enums';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import Autocomplete from '@components/atoms/Autocomplete';
import { Checkbox } from '@components/atoms/Checkbox';
import { Input } from '@components/atoms/Input';
import { RadioButton } from '@components/atoms/RadioButton';
import EditingSidebarBase from '@components/sidebars/EditingSidebarBase';
import { DEVICE_CONFIGURATION_PARAMETERS } from '@handlers/device/deviceConst';
import { useForm } from '@hooks';
import { selectUser } from '@services/auth/selectors';
import { selectLmcMultipointsBySiteUuid } from '@services/devices/selectors';
import { isVoolDevice } from '@views/Sites/SiteDevicesAndGroups/siteDevicesAndGroupsHelpers/siteDevicesUtils';

const useSchema = ({ lmcMultipoints }) => {
  const { t } = useTranslation();
  return yup
    .object()
    .shape({
      deviceName: yup.string(),
      lmcMultipointUuid: yup.string().nullable(),
      communicatesViaLmc: yup.number(),
      gridConnection: yup.lazy((value, { parent }) => {
        if (value?.trim?.() === '') {
          return yup.string();
        }
        const gridConnectionLimit =
          parent.lmcMultipointUuid &&
          lmcMultipoints.find((lmcMultipoint) => lmcMultipoint.uuid === parent.lmcMultipointUuid)?.currentLimit;
        return yup
          .number()
          .typeError(t('mustBeNumber', 'Must be a number'))
          .required(t('required*', 'Required*'))
          .test('positive', t('mustBePositive', 'Must be positive'), () => value > 0)
          .test(
            'max',
            t('mustNotExceedValue', 'Must not exceed {{value}}', { value: ` ${gridConnectionLimit}A` }),
            () => !gridConnectionLimit || value <= gridConnectionLimit,
          );
      }),
      chargeEnabled: yup.bool(),
      autoStart: yup.bool(),
    })
    .required();
};

const ChargerSettingsFormSidebar = ({ device, isUpdateLoading, isUpdateError, updateFailedMessage, onSaveValues }) => {
  const { t } = useTranslation();

  const lmcMultipoints = useSelector((state) => selectLmcMultipointsBySiteUuid(state, device.siteUuid));
  const user = useSelector(selectUser);
  const isAdmin = !!user.admin;
  const isInstaller = !!user?.features?.isInstaller;
  const canChangeCommunicatesViaLmc = isAdmin || isInstaller;

  const isOffline = device.status === DeviceStatus.OFFLINE;

  const deviceConfiguration = device.configuration ?? {};

  const schema = useSchema({ lmcMultipoints });
  const { formState, reset, setValue, clearErrors, register, handleSubmitAndResolve, watch } = useForm({
    schema,
  });
  const selectedLmcMultipointUuid = watch('lmcMultipointUuid');
  const selectedCommunicatesViaLmc = watch('communicatesViaLmc');

  const lmcMultipointOptions = useMemo(
    () => [
      { label: t('noGroup', 'No group'), value: null, italic: true },
      ...lmcMultipoints.map(({ name, uuid }) => ({
        label: name,
        value: uuid,
      })),
    ],
    [lmcMultipoints],
  );

  const saveValues = async ({
    chargeEnabled,
    deviceName,
    gridConnection,
    lmcMultipointUuid,
    communicatesViaLmc,
    autoStart,
  }) => {
    const result = {
      deviceUpdateData: {
        uuid: device.uuid,
        name: deviceName?.trim() || null,
        ...(isVoolDevice(device) && { lmcMultipointUuid }),
        ...(!!Number(gridConnection) && { gridConnection }), // FIXME? BE also changes this with MAXIMUM_CHARGING_CURRENT
        ...(canChangeCommunicatesViaLmc && !isNil(communicatesViaLmc) && { communicatesViaLmc }),
      },
    };

    if (isVoolDevice(device)) {
      const parameters = [
        {
          key: DEVICE_CONFIGURATION_PARAMETERS.MAXIMUM_CHARGING_CURRENT.key,
          value: gridConnection,
        },
        {
          key: DEVICE_CONFIGURATION_PARAMETERS.CHARGER_ENABLED.key,
          value: chargeEnabled,
        },
        {
          key: DEVICE_CONFIGURATION_PARAMETERS.AUTOMATICALLY_START_CHARGING.key,
          value: autoStart,
        },
      ];
      result.configurationUpdateData = {
        uuid: device.uuid,
        deviceConfig: parameters,
      };
    }
    return onSaveValues(result);
  };

  useEffect(() => {
    if (isEmpty(device)) {
      reset();
    } else {
      reset({
        chargeEnabled: !!deviceConfiguration[DEVICE_CONFIGURATION_PARAMETERS.CHARGER_ENABLED.key]?.value,
        deviceName: device.name || device.serialNumber,
        gridConnection: device.gridConnection ?? '',
        lmcMultipointUuid: device.lmcMultipointUuid ?? null,
        communicatesViaLmc: device.communicatesViaLmc ?? 1,
        autoStart: !!deviceConfiguration[DEVICE_CONFIGURATION_PARAMETERS.AUTOMATICALLY_START_CHARGING.key]?.value,
      });
    }
  }, [device.configuration]);

  const getCurrentLimitQuestionMarkText = () => {
    if (!isVoolDevice(device)) {
      return t(
        'visualEffectRadialChartsText',
        'Changing this setting will only have a visual effect on radial charts.',
      );
    }
    return null;
  };

  return (
    <EditingSidebarBase
      title={t('settings', 'Settings')}
      subtitle={device.name || device.serialNumber}
      saveLabel={t('update', 'Update')}
      discardLabel={t('cancel', 'Cancel')}
      containerClassName="flex flex-col"
      containerBottomPadding="pb-36"
      anyDataChanged={formState.isDirty}
      onSaveValues={handleSubmitAndResolve(saveValues)}
      updateLoading={isUpdateLoading}
      updateFailed={isUpdateError}
      updateFailedMessage={updateFailedMessage}
      isSubSidebar
    >
      <div className="flex flex-col gap-y-12 font-poppins">
        <div className="flex flex-col gap-y-6">
          <Input
            name="deviceName"
            label={`${t('deviceName', 'Device name')} (${t('optional', 'Optional').toLowerCase()})`}
            type="text"
            error={Boolean(formState.errors?.deviceName)}
            helpText={formState.errors?.deviceName?.message}
            {...register('deviceName')}
          />
          <Input
            label={t('currentLimit', 'Current limit')}
            name="currentLimit"
            trailingText="A"
            type="number"
            isInteger
            error={Boolean(formState.errors?.gridConnection)}
            helpText={
              formState.errors?.gridConnection?.message ??
              (!isVoolDevice(device) ? t('forVisualPurposesOnly', 'For visual purposes only.') : null)
            }
            questionMark={getCurrentLimitQuestionMarkText()}
            disabled={isVoolDevice(device) && isOffline}
            {...register('gridConnection')}
          />
          {isVoolDevice(device) && (
            <>
              <Checkbox
                label={t('enabled', 'Enabled')}
                name="chargeEnabled"
                disabled={isOffline}
                {...register('chargeEnabled')}
              />
              <Checkbox
                label={t('autoStart', 'Auto start')}
                name="autoStart"
                disabled={isOffline}
                {...register('autoStart')}
              />
            </>
          )}
        </div>
        {isVoolDevice(device) && (
          <div className="flex flex-col gap-y-4">
            <div className="font-semibold leading-6">{t('loadManagement', 'Load management')}</div>
            <Autocomplete
              label={t('controller/group', 'Controller/Group')}
              options={lmcMultipointOptions}
              value={selectedLmcMultipointUuid}
              onChange={(lmcMultipointUuid) => {
                clearErrors();
                setValue('lmcMultipointUuid', lmcMultipointUuid, { shouldDirty: true });
              }}
              isError={Boolean(formState.errors?.pricingModel?.region)}
              helpText={formState.errors?.pricingModel?.region?.message}
            />
          </div>
        )}
        {canChangeCommunicatesViaLmc && !!selectedLmcMultipointUuid && (
          <div className="flex flex-col gap-y-4">
            <div className="font-semibold leading-6">{t('ocppSource', 'OCPP source')}</div>
            <RadioButton
              value="0"
              checked={!selectedCommunicatesViaLmc}
              label={t('internal', 'Internal')}
              name="communicatesViaLmc"
              helpText={t(
                'ocppSourceInternalText',
                'Charger itself is connected to the Internet, and it uses OCPP 1.6.',
              )}
              onChange={() => {
                clearErrors();
                setValue('communicatesViaLmc', 0, { shouldDirty: true });
              }}
            />
            <RadioButton
              value="1"
              checked={!!selectedCommunicatesViaLmc}
              label={t('lmc', 'LMC')}
              name="communicatesViaLmc"
              helpText={t(
                'ocppSourceLmcText',
                'Charger is not connected to Internet, LMC proxies OCPP 2.0 connection for the charger.',
              )}
              onChange={() => {
                clearErrors();
                setValue('communicatesViaLmc', 1, { shouldDirty: true });
              }}
            />
          </div>
        )}
      </div>
    </EditingSidebarBase>
  );
};

ChargerSettingsFormSidebar.propTypes = {
  device: PropTypes.object,
  isUpdateLoading: PropTypes.bool,
  isUpdateError: PropTypes.bool,
  updateFailedMessage: PropTypes.string,
  onSaveValues: PropTypes.func,
};

ChargerSettingsFormSidebar.defaultProps = {
  device: {},
  isUpdateLoading: false,
  isUpdateError: false,
  updateFailedMessage: null,
  onSaveValues: () => {},
};

export default ChargerSettingsFormSidebar;
