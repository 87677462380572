import { useTranslation } from 'react-i18next';

import { Device } from 'models/device';
import { DeviceType } from 'models/device.enums';

import { DEVICE_CONFIGURATION_PARAMETERS } from '@handlers/device/deviceConst';
import { isVoolDevice } from '@views/Sites/SiteDevicesAndGroups/siteDevicesAndGroupsHelpers/siteDevicesUtils';

type DeviceDetailsProps = {
  device: Device;
};

export const DeviceDetails = ({ device }: DeviceDetailsProps) => {
  const { t } = useTranslation();

  const deviceConfiguration = device.configuration ?? {};

  const firmwareVersionLabel = Object.values(DEVICE_CONFIGURATION_PARAMETERS)
    .filter((value) => value.key.startsWith(DEVICE_CONFIGURATION_PARAMETERS.FIRMWARE_VERSION.key))
    .map((value) => {
      const firmwareVersionValue =
        value.key === DEVICE_CONFIGURATION_PARAMETERS.FIRMWARE_VERSION.key
          ? device.firmwareVersion
          : deviceConfiguration[value.key]?.value ?? '-';
      return value.letter ? `${value.letter}: ${firmwareVersionValue}` : firmwareVersionValue;
    })
    .join(' | ');

  const deviceDetailsData = [
    {
      label: t('brand', 'Brand'),
      value: isVoolDevice(device) ? 'VOOL' : device.vendor || '-',
    },
    {
      label: t('model', 'Model'),
      value: device.model,
    },
    {
      label: t('serial', 'Serial'),
      value: device.serialNumber,
    },
    {
      label: t('ocppVersion', 'OCPP Version'),
      value: device.ocppVersion,
    },
    {
      label: t('firmware', 'Firmware'),
      value: isVoolDevice(device) && device.type === DeviceType.CHARGER ? firmwareVersionLabel : device.firmwareVersion,
    },
  ];

  return (
    <div className="flex flex-col gap-y-2 font-poppins text-sm">
      {deviceDetailsData.map((item) => (
        <div key={item.label} className="flex gap-x-1">
          <div className="font-semibold">{item.label}:</div>
          <div className="text-gray-600">{item.value || '-'}</div>
        </div>
      ))}
    </div>
  );
};
