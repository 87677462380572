import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Skeleton, Typography } from '@mui/material';

import Autocomplete from '@components/atoms/Autocomplete';
import { FormTextField } from '@components/atoms/FormTextField';
import apiSlice from '@services/api';
import { selectCountries } from '@services/countries/selectors';
import { useAppSelector } from '@services/hooks';

import { PayoutDetailsForm } from './usePayoutDetailsSchema';

type PayoutDetailsProps = {
  form: UseFormReturn<PayoutDetailsForm>;
  isLoading: boolean;
};

const PayoutDetailsLoading = () => {
  const { t } = useTranslation();
  return (
    <Box display="flex" flexDirection="column" gap={3} paddingTop={4}>
      <Skeleton variant="rounded" animation="wave" height={39} />
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography variant="p16b">{t('accountDetails', 'Account details')}</Typography>
        <Skeleton variant="rounded" animation="wave" height={39} />
        <Skeleton variant="rounded" animation="wave" height={39} />
      </Box>
    </Box>
  );
};

export const PayoutDetails = ({ form, isLoading }: PayoutDetailsProps) => {
  const { t } = useTranslation();
  const { watch, trigger, setValue, formState, control } = form;

  const [country] = watch(['bankAccountDetails.addressJson.country']);
  const countries = useAppSelector(selectCountries) as [{ name: string }];
  apiSlice.useGetCountriesQuery({});
  const optionCountries = countries.map((country) => ({
    label: country.name,
    value: country.name,
  }));

  return (
    <>
      <span className="text-gray-600">
        {t('companyPayoutDetailsDescription', 'Please provide your bank account details here for payout.')}
      </span>

      {isLoading ? (
        <PayoutDetailsLoading />
      ) : (
        <>
          <Autocomplete
            label={t('country', 'Country')}
            options={optionCountries}
            value={country}
            onChange={(country) => {
              setValue('bankAccountDetails.addressJson.country', country, { shouldDirty: true });
              trigger('bankAccountDetails.addressJson.country');
            }}
            isError={Boolean(formState.errors?.bankAccountDetails?.addressJson?.country)}
            helpText={formState.errors?.bankAccountDetails?.addressJson?.country?.message}
          />
          <Box display="flex" flexDirection="column" gap={3}>
            <Typography variant="p16b">{t('accountDetails', 'Account details')}</Typography>
            <Box display="flex" flexDirection="column" gap={4}>
              <FormTextField
                name="bankAccountDetails.accountName"
                label={t('accountName', 'Account name')}
                control={control}
              />
              <FormTextField name="bankAccountDetails.iban" label={t('iban', 'IBAN')} control={control} />
              {!!country && country !== 'Estonia' && (
                <FormTextField
                  name="bankAccountDetails.swift"
                  label={t('swiftBic', 'SWIFT/BIC code')}
                  control={control}
                />
              )}
            </Box>
          </Box>
          {!!country && country !== 'Estonia' && (
            <Box display="flex" flexDirection="column" gap={3}>
              <Typography variant="p16b">{t('bankAddress', 'Bank address')}</Typography>
              <Box display="flex" flexDirection="column" gap={4}>
                <FormTextField
                  name="bankAccountDetails.addressJson.streetAddress"
                  label={t('streetAddress', 'Street address')}
                  control={control}
                />
                <FormTextField
                  name="bankAccountDetails.addressJson.apt"
                  label={`${t('aptSuiteEtc', 'Apt, suite, etc.')} (${t('optional', 'Optional').toLowerCase()})`}
                  control={control}
                />
                <FormTextField name="bankAccountDetails.addressJson.city" label={t('city', 'City')} control={control} />
                <Box display="flex" gap={2}>
                  <FormTextField
                    name="bankAccountDetails.addressJson.state"
                    label={t('county', 'County/state')}
                    control={control}
                  />
                  <FormTextField
                    name="bankAccountDetails.addressJson.zipCode"
                    label={t('zipPostalCode', 'ZIP/postal code')}
                    control={control}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
};
