import { Site } from 'models/site';

import { createAppSelector } from '@services/hooks';
import { RootState } from '@services/store';

import { selectActiveCompanyUuid } from '../companies/selectors';

export const selectSlice = (state: RootState) => state.sites;

export const selectSites = createAppSelector(selectSlice, (slice) => slice.list);
export const selectActiveCompanySites = createAppSelector(
  selectSites,
  selectActiveCompanyUuid,
  (sites, activeCompanyUuid) => sites.filter((s) => s.companyUuid === activeCompanyUuid),
);
export const selectSitesByCompanyUuid = createAppSelector(
  selectSites,
  (state: RootState, companyUuid: string) => companyUuid,
  (sites, companyUuid) => sites.filter((s) => s.companyUuid === companyUuid),
);
export const selectSiteByUuid = createAppSelector(
  selectSites,
  (state: RootState, uuid: string | undefined) => uuid,
  (sites, uuid) => (uuid ? sites.find((site) => site.uuid === uuid) : ({} as Site)),
);
