import { Company } from 'models/company';
import { Permission } from 'models/permission.enum';
import { CompanyRole } from 'models/role.enum';

import apiSlice from '@services/api';
import { CompanyDetailsForm } from '@views/Workspace/sidebars/companyDetails/useCompanyDetailsSchema';
import { CompanyPayoutDetails } from '@views/Workspace/sidebars/payoutDetails/usePayoutDetailsForm';

export type GetCompaniesResponse = {
  success: boolean;
  data: Company[];
};

export type CompanyResponse = {
  success: boolean;
  data: Company;
};

type CompanyDetailsResponse = {
  success: boolean;
  data: CompanyDetailsForm;
};

type PayoutDetailsResponse = {
  success: boolean;
  data: CompanyPayoutDetails;
};

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCompanies: builder.query<GetCompaniesResponse, void>({
      query: () => ({
        url: '/companies',
        method: 'GET',
      }),
    }),
    createCompany: builder.mutation<CompanyResponse, { name: string }>({
      query: ({ name }) => ({
        url: '/companies',
        method: 'POST',
        body: { name },
      }),
      invalidatesTags: ['CompanyContext'],
    }),
    getCompany: builder.query<CompanyResponse, string>({
      query: (uuid) => ({
        url: `/companies/${uuid}`,
        method: 'GET',
      }),
    }),
    updateActiveCompany: builder.mutation<CompanyResponse, { name: string }>({
      query: ({ name }) => ({
        url: '/companies/active',
        method: 'PUT',
        body: { name },
      }),
    }),
    joinByInvite: builder.mutation<{ success: boolean }, string>({
      query: (inviteToken) => ({
        url: '/companies/joinByInvite',
        method: 'POST',
        body: { inviteToken },
      }),
      invalidatesTags: ['CompanyContext'],
    }),
    saveCompanyDetails: builder.mutation<CompanyDetailsResponse, CompanyDetailsForm>({
      query: (body) => ({
        url: `/companies/details`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CompanyDetails'],
    }),
    getCompanyDetails: builder.query<CompanyDetailsForm, void>({
      query: () => ({
        url: `/companies/details`,
        method: 'GET',
      }),
      transformResponse: (response: CompanyDetailsResponse) => response.data,
      providesTags: ['CompanyDetails', 'CompanyContext'],
    }),
    getCompanyPayoutDetails: builder.query<CompanyPayoutDetails, void>({
      query: () => ({
        url: `/companies/payoutDetails`,
        method: 'GET',
      }),
      transformResponse: (response: PayoutDetailsResponse) => response.data,
      providesTags: ['CompanyPayoutDetails', 'CompanyContext'],
    }),
    saveCompanyPayoutDetails: builder.mutation<PayoutDetailsResponse, CompanyPayoutDetails>({
      query: (body) => ({
        url: `/companies/payoutDetails`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CompanyPayoutDetails'],
    }),
    saveCompanyPaymentMethod: builder.mutation<CompanyResponse, { stripePaymentMethodId: string }>({
      query: (body) => ({
        url: `/companies/paymentMethod`,
        method: 'POST',
        body,
      }),
    }),
    getCompanyUserPermissions: builder.query<{ role: CompanyRole; permissions: Permission[] }, string>({
      query: (companyUuid) => ({
        url: `/companies/${companyUuid}/selfPermissions`,
        method: 'GET',
      }),
      transformResponse: (response: { data: { role: CompanyRole; permissions: Permission[] } }) => response.data,
    }),
  }),
});

export const {
  useGetCompaniesQuery,
  useCreateCompanyMutation,
  useGetCompanyQuery,
  useUpdateActiveCompanyMutation,
  useJoinByInviteMutation,
  useSaveCompanyDetailsMutation,
  useGetCompanyDetailsQuery,
  useGetCompanyPayoutDetailsQuery,
  useSaveCompanyPayoutDetailsMutation,
  useSaveCompanyPaymentMethodMutation,
  useLazyGetCompanyUserPermissionsQuery,
} = extendedApi;
export const companiesApi = extendedApi;
