import { useState } from 'react';
import AppleLogin from 'react-apple-login';
import { useTranslation } from 'react-i18next';

import { ErrorCode } from 'models/errorCode.enum';
import PropTypes from 'prop-types';

import { Box, ButtonBase, Typography } from '@mui/material';

import { appleLoginBundleId } from '@config/consts';
import { trackAmplitudeEvent } from '@handlers/amplitude';
import { AmplitudeEvent, AmplitudeLoginMethod, getAmplitudeEventPropertiesForUser } from '@handlers/amplitude/data';
import appleIcon from '@images/icons/apple.svg';
import apiSlice from '@services/api';

const AppleAuth = ({ onSuccess, inviteData }) => {
  const [appleLoginError, setAppleLoginError] = useState();

  const [appleLogin] = apiSlice.useAppleLoginMutation();
  const { t } = useTranslation();

  const handleAppleSignIn = async (response) => {
    try {
      if (response.authorization) {
        const { code } = response.authorization;
        const bundleId = appleLoginBundleId;
        const { firstName, lastName } = response?.user?.name || {};
        const responseAppleLogin = await appleLogin({
          code,
          bundleId,
          firstName,
          lastName,
          ...(inviteData && { inviteToken: inviteData.inviteToken }),
        }).unwrap();

        onSuccess();
        trackAmplitudeEvent(AmplitudeEvent.LOGIN, {
          ...getAmplitudeEventPropertiesForUser(responseAppleLogin.user),
          method: AmplitudeLoginMethod.APPLE,
        });
      }
    } catch (error) {
      if (error?.data?.message?.toLowerCase()?.includes('login failed')) {
        setAppleLoginError(t('emailOrPasswordIncorrectErrorText', 'Entered email or password is incorrect'));
      } else if (error?.data?.errorCode === ErrorCode.UNAUTHORIZED_PARTNER) {
        setAppleLoginError(t('partnerErrorText', 'You are not a partner'));
      } else {
        setAppleLoginError(t('appleLoginError', 'Apple login error'));
      }
    }
  };

  return (
    <>
      {appleLoginBundleId && (
        <AppleLogin
          clientId={appleLoginBundleId}
          redirectURI="https://app.vool.com"
          responseType="code"
          responseMode="query"
          state="apple-auth"
          usePopup
          scope="name email"
          callback={handleAppleSignIn}
          render={(renderProps) => (
            <Box display="flex" justifyContent="center" width={1}>
              <ButtonBase
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                sx={{ backgroundColor: 'black', borderRadius: '150px', height: '56px', width: '100%' }}
              >
                <img src={appleIcon} alt="apple" />
                <span
                  className={`
                w-auto pl-3 font-poppins text-base font-semibold text-white sm:flex
            `}
                >
                  {t('continueWithApple', 'Continue with Apple')}
                </span>
              </ButtonBase>
            </Box>
          )}
        />
      )}
      {appleLoginError && (
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Typography variant="p14" color="error" gutterBottom>
            {t('somethingWentWrong', 'Something went wrong')}.
          </Typography>
          <Typography variant="p12" color="GrayText">
            {`${t('error', 'Error')}: ${appleLoginError}`}
          </Typography>
        </Box>
      )}
    </>
  );
};

AppleAuth.propTypes = {
  inviteData: PropTypes.shape({
    email: PropTypes.string,
    inviteToken: PropTypes.string,
  }),
  onSuccess: PropTypes.func,
};

AppleAuth.defaultProps = {
  inviteData: null,
  onSuccess: () => {},
};

export default AppleAuth;
