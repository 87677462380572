import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom';

import { Permission } from 'models/permission.enum';

import { Tab, TabsWithMenu } from '@components/tabs/TabsWithMenu';
import apiSlice from '@services/api';
import { selectPermissionByName } from '@services/auth/selectors';
import { useGetCompaniesQuery } from '@services/companies/endpoints';
import { selectActiveCompanyUuid } from '@services/companies/selectors';
import { useAppSelector } from '@services/hooks';
import { useGetRfidCardsQuery } from '@services/rfidCards/endpoints';
import { selectRfidCardsByCompanyUuid } from '@services/rfidCards/selectors';
import { useGetUsersQuery } from '@services/users/endpoints';

import { useWorkspaceGroupsAndRoles } from './workspaceHooks';

export const WorkspaceBaseView = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const setLoading = useOutletContext<Dispatch<SetStateAction<boolean>>>();

  const { pathname } = useLocation();

  const activeCompanyUuid = useAppSelector(selectActiveCompanyUuid);
  const rfidCards = useAppSelector((state) => selectRfidCardsByCompanyUuid(state, activeCompanyUuid));
  const canSeeUsers = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_SEE_USERS));
  const canAddUsers = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_ADD_USERS));
  const canSeeRfidCards = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_SEE_RFID_CARDS));
  const canAddRfidCards = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_ADD_RFID_CARDS));
  const canSeeCompanyReports = useAppSelector((state) =>
    selectPermissionByName(state, Permission.CAN_SEE_COMPANY_REPORTS),
  );
  const canEditCompany = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_EDIT_COMPANY));

  const { isWorkspaceAdmin, isGroupManager, groupsLoading } = useWorkspaceGroupsAndRoles();

  const { isFetching: rfidCardsFetching } = useGetRfidCardsQuery();
  const { isFetching: usersFetching } = useGetUsersQuery();
  useGetCompaniesQuery();
  apiSlice.useVehiclesQuery({});

  const isAccessManagement = pathname.startsWith('/access-management');

  const tabs: Tab[] = isAccessManagement
    ? [
        {
          label: t('members', 'Members'),
          path: 'members',
          hidden: !isWorkspaceAdmin || !canSeeUsers,
          actionButton: {
            label: 'Invite to workspace',
            path: 'members/invite',
            hidden: !canAddUsers,
          },
        },
        {
          label: t('groups', 'Groups'),
          path: 'groups',
          hidden: !isWorkspaceAdmin && !isGroupManager,
          actionButton: {
            label: t('createAGroup', 'Create a group'),
            path: 'groups/create',
            hidden: !isWorkspaceAdmin,
          },
        },
        {
          label: t('rfidCards', 'RFID Cards'),
          path: 'rfidCards',
          hidden: !isWorkspaceAdmin || !canSeeRfidCards,
          actionButton: {
            label: t('addAnItem', 'Add an {{item}}', { item: t('rfidCard', 'RFID card') }),
            path: 'rfidCards/add',
            hidden: !rfidCards.length || !canAddRfidCards,
          },
        },
      ]
    : [
        {
          label: t('settings', 'Settings'),
          path: 'settings',
          hidden: !isWorkspaceAdmin || !canEditCompany,
        },
        {
          label: t('invoices', 'Invoices'),
          path: 'invoices',
          hidden: !isWorkspaceAdmin || !canSeeCompanyReports,
        },
      ];

  const [membersTab] = tabs;
  const activeTab = tabs.find((tab) => pathname.includes(tab.path)) ?? membersTab;

  useEffect(() => {
    setLoading(usersFetching || rfidCardsFetching || groupsLoading);

    return () => {
      setLoading(false);
    };
  }, [usersFetching, rfidCardsFetching, groupsLoading]);

  useEffect(() => {
    if (!isWorkspaceAdmin && !groupsLoading && isAccessManagement) {
      if (!isGroupManager) {
        navigate('/dashboard');
      } else if (!pathname.includes('groups')) {
        navigate('groups');
      }
    }
  }, [isWorkspaceAdmin, groupsLoading, isGroupManager, pathname]);

  return (
    <>
      <TabsWithMenu activeTab={activeTab} tabs={tabs} />
      <Outlet />
    </>
  );
};
