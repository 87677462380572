import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, TableCell, TableCellProps } from '@mui/material';

import { MenuContainer } from '@components/atoms/MenuContainer';
import { StyledMenuItem, StyledMenuItemProps } from '@components/atoms/StyledMenuItem';
import { getApiUrl } from '@config/api';
import { ReactComponent as KebabIcon } from '@images/icons/reporting/kebab.svg';
import { selectToken } from '@services/auth/selectors';
import { useLazyGetInvoiceUrlQuery } from '@services/payments/endpoints';

type MenuCellProps = TableCellProps & {
  invoiceId?: string;
  menuOptions?: StyledMenuItemProps[];
};

export const MenuCell = ({ invoiceId, menuOptions, ...props }: MenuCellProps) => {
  const { t } = useTranslation();
  const [getInvoiceUrlById] = useLazyGetInvoiceUrlQuery();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const token = useSelector(selectToken);

  const handleDownloadInvoice = async () => {
    if (!invoiceId) {
      return;
    }

    try {
      const url = await getInvoiceUrlById(invoiceId).unwrap();
      if (!url.includes('invoices/pdf?invoiceNumber=')) {
        window.open(url, '_blank');
      } else {
        const response = await fetch(`${getApiUrl()}${url}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/pdf',
            Authorization: `Bearer ${token}`,
          },
        });
        const downloadUrl = window.URL.createObjectURL(await response.blob());
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = `${invoiceId}.pdf`;
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (!invoiceId && !menuOptions?.length) {
    return <TableCell {...props} />;
  }

  return (
    <>
      <TableCell
        {...props}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        sx={{
          cursor: 'pointer',
        }}
      >
        <Box display="flex" justifyContent={props.align}>
          <KebabIcon />
        </Box>
      </TableCell>
      <MenuContainer anchorEl={anchorEl} marginTop="-24px" onClose={() => setAnchorEl(undefined)}>
        {menuOptions?.map((option, index) => (
          <StyledMenuItem
            key={index}
            width="auto"
            {...option}
            px={4}
            onClick={(e) => {
              setAnchorEl(undefined);
              option.onClick && option.onClick(e);
            }}
          />
        ))}
        {invoiceId && (
          <StyledMenuItem
            width="auto"
            title={t('downloadInvoice', 'Download invoice')}
            onClick={() => {
              setAnchorEl(undefined);
              handleDownloadInvoice();
            }}
          />
        )}
      </MenuContainer>
    </>
  );
};
