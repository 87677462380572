import { Fragment } from 'react';

import PropTypes from 'prop-types';

import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';

import Button from '@components/atoms/Button';
import { OverflowSpinner } from '@components/spinner/OverflowSpinner';
import { ReactComponent as CloseIcon } from '@images/icons/Close.svg';

const DeleteModal = ({
  open,
  closeModal,
  onDelete,
  title,
  children,
  deleteButtonText,
  deleteButtonDisabled,
  cancelButtonText,
  loading,
  error,
  persistent,
}) => (
  <Transition.Root show={open} as={Fragment}>
    <Dialog
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto rounded-2xl transition duration-500"
      onClose={() => !persistent && closeModal()}
    >
      <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
          &#8203;
        </span>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            className="relative inline-block w-full transform rounded-2xl bg-white text-left 
              align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle"
          >
            <div className="rounded-2xl bg-white p-4 sm:p-6 sm:py-4">
              <button type="button" className="float-right -mr-2 -mt-1 p-2" onClick={closeModal}>
                <CloseIcon />
              </button>
              <div className="text-left">
                <Dialog.Title
                  as="h3"
                  className="border-b border-b-gray-100 pb-4 font-everett text-2xl font-normal leading-8 text-black"
                >
                  {title}
                </Dialog.Title>
                {error && <div className="text-xs text-vermillion">{error}</div>}
              </div>
              <div className="space-y-8 pb-2 pt-6 text-center font-poppins sm:text-left">
                {children}
                <div className="flex justify-center gap-2 text-base text-vermillion sm:justify-start">
                  <ExclamationIcon className="h-6 w-6" /> This action cannot be undone.
                </div>
              </div>
            </div>
            <div className="w-full rounded-b-2xl border-t border-t-gray-100 px-6 py-4 sm:flex sm:justify-between sm:gap-2">
              <Button variant="secondary" className="w-full" onClick={closeModal}>
                {cancelButtonText}
              </Button>
              <Button className="mt-3 w-full sm:mt-0" disabled={deleteButtonDisabled} onClick={onDelete}>
                {deleteButtonText}
              </Button>
            </div>
            {loading && <OverflowSpinner className="m-1 rounded-2xl" />}
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition.Root>
);

DeleteModal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  deleteButtonText: PropTypes.string.isRequired,
  deleteButtonDisabled: PropTypes.bool,
  cancelButtonText: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.string,
  persistent: PropTypes.bool,
};

DeleteModal.defaultProps = {
  onDelete: () => {},
  deleteButtonDisabled: false,
  cancelButtonText: 'Cancel',
  loading: false,
  error: undefined,
  persistent: false,
};

export default DeleteModal;
