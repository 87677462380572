import { useTranslation } from 'react-i18next';

import { cloneDeep, set } from 'lodash';
import PropTypes from 'prop-types';

import { Input } from '@components/atoms/Input';
import useActiveCurrency from '@hooks/useActiveCurrency';

import { PeriodicalCostInputs } from './PeriodicalCostInputs';

const FixedPriceInputs = ({
  name,
  costItemLabel,
  isSinglePrice,
  singlePrice,
  dualPrice,
  onIsSinglePriceChange,
  onSinglePriceChange,
  onDualPriceChange,
  errors,
}) => {
  const { t } = useTranslation();
  const currency = useActiveCurrency();
  const onDualPriceAttrUpdate = (path, value) => onDualPriceChange(set(cloneDeep(dualPrice), path, value));
  const costLabel = t('priceComponentCost', '{{priceComponent}} cost', {
    priceComponent: costItemLabel,
  });

  return (
    <>
      {isSinglePrice ? (
        <Input
          name={name}
          type="number"
          label={costLabel}
          value={singlePrice}
          trailingText={`${t('currencySubunit', '{{currencySubunit}}', {
            currencySubunit: currency.centsName,
          }).toLowerCase()}/kWh`}
          onChange={(e) => onSinglePriceChange(e.target.value)}
          error={Boolean(errors?.[name])}
          helpText={errors?.[name]?.message}
        />
      ) : (
        <div className="space-y-5">
          <PeriodicalCostInputs
            periodLabel={t('day', 'Day')}
            costLabel={costLabel}
            startTime={dualPrice.dayHours.start}
            endTime={dualPrice.dayHours.end}
            cost={dualPrice.price.day}
            onStartTimeChange={(value) => onDualPriceAttrUpdate('dayHours.start', value)}
            onEndTimeChange={(value) => onDualPriceAttrUpdate('dayHours.end', value)}
            onCostChange={(value) => onDualPriceAttrUpdate('price.day', value)}
            errors={errors?.[name]}
          />
          <PeriodicalCostInputs
            periodLabel={t('night', 'Night')}
            periodInputsDisabled
            costLabel={costLabel}
            startTime={dualPrice.dayHours.end}
            endTime={dualPrice.dayHours.start}
            cost={dualPrice.price.night}
            onCostChange={(value) => onDualPriceAttrUpdate('price.night', value)}
            errors={errors?.[name]}
          />
        </div>
      )}
      <button
        type="button"
        className="mt-2 text-forest underline"
        onClick={() => onIsSinglePriceChange(!isSinglePrice)}
      >
        {isSinglePrice
          ? t('singlePriceText', 'Use day/night {{priceComponent}} prices', {
              priceComponent: costItemLabel.toLowerCase(),
            })
          : t('multiplePriceText', 'Use a single {{priceComponent}} price', {
              priceComponent: costItemLabel.toLowerCase(),
            })}
      </button>
    </>
  );
};

FixedPriceInputs.propTypes = {
  name: PropTypes.string.isRequired,
  costItemLabel: PropTypes.string.isRequired,
  isSinglePrice: PropTypes.bool.isRequired,
  singlePrice: PropTypes.string.isRequired,
  dualPrice: PropTypes.shape({
    dayHours: PropTypes.shape({
      start: PropTypes.string.isRequired,
      end: PropTypes.string.isRequired,
    }).isRequired,
    price: PropTypes.shape({
      day: PropTypes.string.isRequired,
      night: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onIsSinglePriceChange: PropTypes.func.isRequired,
  onSinglePriceChange: PropTypes.func.isRequired,
  onDualPriceChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

FixedPriceInputs.defaultProps = {
  errors: null,
};

export default FixedPriceInputs;
