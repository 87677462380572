import { Group, GroupDto } from 'models/group.dto';

import apiSlice from '@services/api';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGroups: builder.query<Group[], { withUsers?: boolean; companyUuid?: string }>({
      query: (params) => ({
        url: 'groups',
        params,
      }),
      transformResponse: (response: { data: Group[] }) => response.data,
      providesTags: ['CompanyContext', 'Groups'],
    }),
    getGroup: builder.query<Group, string>({
      query: (uuid: string) => `groups/${uuid}`,
      providesTags: ['CompanyContext', 'Groups'],
    }),
    createGroup: builder.mutation<Group, GroupDto>({
      query: ({ name, description, users, siteUuids, deviceUuids }) => ({
        url: 'groups',
        method: 'POST',
        body: { name, description, users, siteUuids, deviceUuids },
      }),
      invalidatesTags: ['CompanyUsers', 'Groups'],
    }),
    deleteGroup: builder.mutation<{ success: boolean }, string>({
      query: (uuid: string) => ({
        url: `groups/${uuid}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Groups'],
    }),
    updateGroupResources: builder.mutation<{ success: boolean }, GroupDto & { uuid: string }>({
      query: ({ uuid, ...body }) => ({
        url: `groups/${uuid}/resources`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['CompanyUsers', 'Groups'],
    }),
    updateGroupUsers: builder.mutation<{ success: boolean }, Pick<GroupDto, 'users'> & { uuid: string }>({
      query: ({ uuid, users }) => ({
        url: `groups/${uuid}/users`,
        method: 'PUT',
        body: { users },
      }),
      invalidatesTags: ['CompanyUsers', 'Groups'],
    }),
  }),
});

export const {
  useGetGroupsQuery,
  useGetGroupQuery,
  useCreateGroupMutation,
  useDeleteGroupMutation,
  useUpdateGroupResourcesMutation,
  useUpdateGroupUsersMutation,
} = extendedApi;

export const groupsApi = extendedApi;
