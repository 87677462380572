import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Skeleton } from '@mui/material';

import { Checkbox } from '@components/atoms/Checkbox';
import { CountryAutocomplete } from '@components/atoms/CountryAutocomplete';
import { FormTextField } from '@components/atoms/FormTextField';
import apiSlice from '@services/api';

import { CompanyDetailsForm } from './useCompanyDetailsSchema';

type CompanyDetailsProps = {
  form: UseFormReturn<CompanyDetailsForm>;
  isLoading: boolean;
};

const CompanyDetailsLoading = () => (
  <>
    <Skeleton variant="rounded" animation="wave" height={39} />
    <Skeleton variant="rounded" animation="wave" height={39} />
    <Skeleton variant="rounded" animation="wave" height={39} />
    <Skeleton variant="rounded" animation="wave" height={24} width={24} />
    <Skeleton variant="rounded" animation="wave" height={39} />
    <Skeleton variant="rounded" animation="wave" height={39} />
    <Skeleton variant="rounded" animation="wave" height={39} />
    <Skeleton variant="rounded" animation="wave" height={39} />
  </>
);

export const CompanyDetails = ({ form, isLoading }: CompanyDetailsProps) => {
  const { t } = useTranslation();

  apiSlice.useGetCountriesQuery({});

  const { control, watch, register } = form;

  const vatRegisteredFlag = watch('vatRegisteredFlag');

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <span className="pb-4 text-gray-600">
        {t(
          'pleaseProvideCompanyDetailsText',
          'Please provide your company details. These details will appear on invoices sent to your public charging customers.',
        )}
      </span>
      {isLoading ? (
        <CompanyDetailsLoading />
      ) : (
        <>
          <FormTextField control={control} name="legalName" label={t('legalName', 'Legal name')} />
          <CountryAutocomplete control={control} name="addressJson.country" />
          <FormTextField
            control={control}
            name="registrationNumber"
            label={t('registrationNumber', 'Registration number')}
          />
          <Checkbox label={t('vatRegistered', 'VAT registered')} {...register('vatRegisteredFlag')} />
          {!!vatRegisteredFlag && (
            <FormTextField control={control} name="vatNumber" label={`${t('vatNumberAbbreviation', 'VAT number')}`} />
          )}
          <FormTextField
            control={control}
            name="addressJson.streetAddress"
            label={t('streetAddress', 'Street address')}
          />
          <FormTextField
            control={control}
            name="addressJson.apt"
            label={`${t('aptSuiteEtc', 'Apt, suite, etc.')} (${t('optional', 'Optional').toLowerCase()})`}
          />
          <FormTextField control={control} name="addressJson.city" label={t('city', 'City')} />
          <Box display="flex" gap={2}>
            <FormTextField control={control} name="addressJson.state" label={t('country/state', 'County/state')} />
            <FormTextField control={control} name="addressJson.zipCode" label={t('zipPostalCode', 'ZIP/postal code')} />
          </Box>
        </>
      )}
    </Box>
  );
};
