import { useEffect, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';

import PropTypes from 'prop-types';
import usePlacesAutocomplete, { getDetails, getGeocode, getLatLng } from 'use-places-autocomplete';

import { getAddressComponent, getAddressDetails } from '../../helpers/placesHelper';
import { Input } from '../atoms/Input';
import Map from './Map';

const GoogleMapsAddressInput = ({
  setFormValue,
  isError,
  helpText,
  clearError,
  setError,
  address,
  addressJson,
  required,
  ...props
}) => {
  const [oldValue, setOldValue] = useState(address);
  const [localAddressObject, setLocalAddressObject] = useState(addressJson);
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {
    clearSuggestions(false);
  });

  const handleInput = (e) => {
    const newValue = e.target.value;
    setLocalAddressObject(null);
    setOldValue(undefined);
    setValue(e.target.value);

    if (!newValue && required) {
      setError('address', {
        type: 'manual',
        message: 'Address is required',
      });
    } else {
      clearError('address');
    }
  };

  const handleSelect = (addressObject) => async () => {
    setFormValue('address', addressObject.description);

    setValue(addressObject.description, false);
    clearSuggestions();

    try {
      const geoCode = await getGeocode({ placeId: addressObject.place_id });
      const { lat, lng } = await getLatLng(geoCode[0]);

      const addressDetailsArray = await getDetails({
        placeId: addressObject.place_id,
        fields: ['address_components'],
      });

      const addressDetails = getAddressDetails(addressDetailsArray);
      const addressCountryCode = getAddressComponent(addressDetailsArray, 'country', { useShortName: true });

      const combinedAddressObject = { ...addressDetails, lat, lng, country_code: addressCountryCode };
      setLocalAddressObject(combinedAddressObject);
      setFormValue('addressJson', combinedAddressObject);
    } catch (error) {
      setError('address', {
        type: 'manual',
        message: error,
      });
    }
  };

  useEffect(() => {
    if (addressJson) {
      setLocalAddressObject(addressJson);
    } else {
      setLocalAddressObject(null);
    }
  }, [addressJson]);

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id: placeId,
        structured_formatting: { main_text: mainText, secondary_text: secondaryText },
      } = suggestion;

      return (
        <li key={placeId} className="overflow-hidden">
          <button
            type="button"
            onClick={handleSelect(suggestion)}
            onKeyDown={() => {}}
            className="w-full truncate whitespace-nowrap px-2 py-1 text-left text-sm"
          >
            <span>{mainText}</span> <span>{secondaryText}</span>
          </button>
        </li>
      );
    });

  useEffect(() => {
    if (address) {
      setOldValue(address);
    }
  }, [address]);

  return (
    <div className="relative space-y-4" ref={ref}>
      <div>
        <Input
          value={oldValue || value}
          onChange={handleInput}
          disabled={!ready}
          inputProps={{
            autoComplete: 'off',
          }}
          error={isError}
          helpText={helpText}
          {...props}
        />
        {status === 'OK' && (
          <ul className="mh-48 absolute z-50 mt-1 w-full overflow-hidden overflow-y-scroll rounded-md border border-gray-300 bg-white shadow-md dark:bg-truegray-700">
            {renderSuggestions()}
          </ul>
        )}
      </div>
      <div>
        <Map
          location={localAddressObject}
          setFormValue={(pos) => setFormValue('addressJson', { ...localAddressObject, ...pos })}
        />
      </div>
    </div>
  );
};

GoogleMapsAddressInput.propTypes = {
  setFormValue: PropTypes.func.isRequired,
  isError: PropTypes.bool,
  helpText: PropTypes.string,
  clearError: PropTypes.func,
  setError: PropTypes.func,
  address: PropTypes.string,
  addressJson: PropTypes.object,
  required: PropTypes.bool,
};

GoogleMapsAddressInput.defaultProps = {
  isError: false,
  helpText: '',
  clearError: () => {},
  setError: () => {},
  address: undefined,
  addressJson: null,
  required: false,
};

export default GoogleMapsAddressInput;
