import { Device } from 'models/device';
import { DeviceType } from 'models/device.enums';

import { ReactComponent as BrandGenericIcon } from '@images/icons/DeviceBrand/Device_GenericCharger.svg';
import { ReactComponent as BrandVoolIcon } from '@images/icons/DeviceBrand/Device_VOOL.svg';
import { ReactComponent as SolarIcon } from '@images/icons/Solar.svg';

import { isInverter, isMultipoint, isVoolDevice } from './siteDevicesUtils';

export type DeviceWithMultipointAttrs = Device & {
  multipointId?: number;
  isMainLmc?: boolean;
  inverterId?: string;
};

export const DeviceIcon = ({ device }: { device: DeviceWithMultipointAttrs }) => {
  const className = `hidden h-12 w-12 min-w-[48px] rounded-lg lg:block
  ${isMultipoint(device) ? '[&_path]:fill-queen' : ''}
  ${isVoolDevice(device) && device.type === DeviceType.CHARGER ? 'bg-black' : ''}
  ${isVoolDevice(device) ? 'bg-gray-100' : 'bg-gray-200'}
  ${device.isMainLmc ? 'border border-vermillion' : ''}`;

  if (isVoolDevice(device)) {
    return <BrandVoolIcon className={className} />;
  }
  if (isInverter(device)) {
    return (
      <div className={className}>
        <div className="flex h-full items-center justify-center">
          <SolarIcon />
        </div>
      </div>
    );
  }
  return <BrandGenericIcon className={className} />;
};
