import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { VehicleModalOption } from 'models/car.enum';
import moment from 'moment';

import { TabsWithMenu } from '@components/tabs/TabsWithMenu';
import apiSlice from '@services/api';
import { useAppSelector } from '@services/hooks';
import { makeSelectVehicleByUuid } from '@services/vehicles/selectors';
import DeleteVehicleModal from '@views/Vehicles/VehicleView/DeleteVehicleModal';
import VehicleChangelogModal from '@views/Vehicles/VehicleView/VehicleChangelogModal';

import { useGetVehicleMenuItems } from '../useGetVehicleMenuItems';

export const VehicleView = () => {
  const { t } = useTranslation();
  const { vehicleUuid } = useParams();
  const { pathname } = useLocation();

  const selectVehicleByUuid = useMemo(makeSelectVehicleByUuid, []);
  const vehicle = useAppSelector((state) => (vehicleUuid ? selectVehicleByUuid(state, vehicleUuid) : undefined));

  const [selectedDuration, setSelectedDuration] = useState<string>();
  const [modalOption, setModalOption] = useState<VehicleModalOption>();

  const tabs = [
    {
      label: t('overview', 'Overview'),
      path: `/vehicles/${vehicle?.uuid}/overview`,
    },
    {
      label: t('sessions', 'Sessions'),
      path: `/vehicles/${vehicle?.uuid}/sessions`,
    },
  ];
  const activeTab = tabs.find((tab) => pathname.includes(tab.path)) ?? tabs[0];

  const getVehicleMenuItems = useGetVehicleMenuItems({
    onVehicleModalOptionSelected: setModalOption,
    isVehicleView: true,
  });
  const vehicleMenuItems = vehicle ? getVehicleMenuItems(vehicle) : [];

  const durationToStartDate: Record<string, moment.Moment> = {
    '24-hours': moment().subtract(1, 'days'),
    '1-week': moment().subtract(1, 'weeks'),
    '1-months': moment().subtract(1, 'months'),
    '6-months': moment().subtract(6, 'months'),
    '1-year': moment().subtract(1, 'years'),
  };

  const periodStartTime = useMemo(
    () =>
      selectedDuration && durationToStartDate[selectedDuration]
        ? moment(durationToStartDate[selectedDuration]).utc().format('YYYY-MM-DDTHH:mm[Z]')
        : null,
    [selectedDuration],
  );

  apiSlice.useGetVehicleQuery(
    { uuid: vehicleUuid },
    {
      skip: !!vehicle?.uuid,
    },
  );

  apiSlice.useGetSmartChargingSessionsQuery(
    {
      carUuid: vehicleUuid,
      withSlots: true,
    },
    { skip: !vehicle?.uuid },
  );

  apiSlice.useGetSmartChargingSessionsSummaryQuery(
    {
      carUuid: vehicleUuid,
      periodStartTime,
    },
    {
      skip: !periodStartTime || !vehicle?.uuid,
    },
  );

  return (
    <>
      <TabsWithMenu activeTab={activeTab} tabs={tabs} menuItems={vehicleMenuItems} />
      <Outlet context={{ vehicle, selectedDuration, setSelectedDuration }} />
      {vehicle && (
        <>
          <VehicleChangelogModal
            modalOpen={modalOption === VehicleModalOption.CHANGELOG}
            vehicle={vehicle}
            closeModal={() => setModalOption(undefined)}
          />
          <DeleteVehicleModal
            vehicle={vehicle}
            isOpen={modalOption === VehicleModalOption.DELETE}
            closeModal={() => setModalOption(undefined)}
          />
        </>
      )}
    </>
  );
};
