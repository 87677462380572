import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import { Spinner } from '@components/atoms/Spinner';
import CardItem from '@components/basicWrappers/CardItem';
import CardItemFooter from '@components/basicWrappers/CardItemFooter';
import CardFilters from '@components/filterComponents/CardFilters';

import MultiEnergyGraph from './MultiEnergyGraph';

const useFilters = () => {
  const { t } = useTranslation();
  return [
    {
      label: t('lastHour', 'Last hour'),
      value: 'hour',
      momentValue: {
        amount: 60,
        unit: 'minutes',
      },
      influxParams: {
        timeFrame: 1,
        timeFrameUnit: 'hours',
        aggregation: '1m',
        polling: true,
      },
    },
    {
      label: `24${t('hoursAbbreviation', 'h')}`,
      value: 'day',
      momentValue: {
        amount: 1,
        unit: 'days',
      },
      influxParams: {
        timeFrame: 24,
        timeFrameUnit: 'hours',
        aggregation: '15m',
      },
    },
    {
      label: `1${t('weeksAbbreviation', 'W')}`,
      value: 'week',
      momentValue: {
        amount: 7,
        unit: 'days',
      },
      influxParams: {
        timeFrame: 7,
        timeFrameUnit: 'days',
        aggregation: '30m',
      },
    },
  ];
};

const MultipleEnergySupplied = ({
  noBorder,
  noInfo,
  noTitle,
  noTools,
  transactionsArray,
  isError,
  isFetching,
  style,
  footer: Footer,
  fetchData,
}) => {
  const { t } = useTranslation();
  const filters = useFilters();
  const [filter, setFilter] = useState('day');
  const [loading, setLoading] = useState(!Object.values(transactionsArray).length);
  const activeFilter = filters.find((f) => f.value === filter);
  const currentArray = transactionsArray.filter((tai) => tai[0].key.indexOf('current_') > -1);

  const totalSupply = currentArray
    .flat()
    .map((ss) => ss.value / 1000)
    .reduce((a, b) => a + b, 0)
    .toFixed(2);

  useEffect(() => {
    fetchData(activeFilter.influxParams);
  }, [filter]);

  useEffect(() => {
    if (!isFetching) {
      setLoading(false);
    }
  }, [isFetching]);

  return (
    <>
      <CardItem
        className={`
          h-full rounded-[16px] p-4 pb-0 pl-0 pr-0 pt-8
          ${noBorder ? 'border-none' : ''}
        `}
        style={{
          ...style,
          ...(noTools && {
            paddingTop: 0,
          }),
        }}
        error={isError}
        loading={loading}
        title={noTitle ? null : t('energySupplied', 'Energy supplied')}
        {...(!noTools && {
          tools: () => <CardFilters onClick={setFilter} activeFilter={filter} filters={filters} />,
        })}
        {...(!noInfo &&
          !Footer && {
            footer: () => (
              <div className="-mb-4">
                <CardItemFooter
                  items={[
                    {
                      label: t('multipleEnergySuppliedFooterText', 'in {{count}} sessions', {
                        count: transactionsArray.flat().length,
                      }),
                      value: `${totalSupply} kWh`,
                    },
                  ]}
                />
              </div>
            ),
          })}
      >
        <div
          className={`
           ${noInfo ? '' : 'pb-8'}
           relative w-full
         `}
        >
          {isFetching && (
            <div className="absolute flex h-full w-full justify-center">
              <Spinner size="14" />
            </div>
          )}
          <div className={isFetching ? 'opacity-50' : ''}>
            <MultiEnergyGraph transactionsArray={transactionsArray} filter={filter} loading={isFetching} />
          </div>
        </div>
      </CardItem>
      {Footer && <Footer totalSupply={totalSupply} />}
    </>
  );
};

MultipleEnergySupplied.propTypes = {
  compact: PropTypes.bool,
  noInfo: PropTypes.bool,
  noTitle: PropTypes.bool,
  noBorder: PropTypes.bool,
  noTools: PropTypes.bool,
  transactionsArray: PropTypes.array.isRequired,
  isError: PropTypes.bool,
  isFetching: PropTypes.bool,
  style: PropTypes.object,
  footer: PropTypes.any,
  fetchData: PropTypes.func.isRequired,
};

MultipleEnergySupplied.defaultProps = {
  compact: false,
  noInfo: false,
  noTitle: false,
  noBorder: false,
  noTools: false,
  isError: false,
  isFetching: false,
  style: {},
  footer: null,
};

export default MultipleEnergySupplied;
