import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChargingAction, ConnectorComputedStatus } from 'models/device.enums';
import { Site } from 'models/site';

import { Tooltip } from '@mui/material';

import { trackAmplitudeEvent } from '@handlers/amplitude';
import { AmplitudeEvent, getAmplitudeEventPropertiesForConnector } from '@handlers/amplitude/data';
import { ReactComponent as CircledQuestionIcon } from '@images/icons/CircledQuestion.svg';
import { selectUser } from '@services/auth/selectors';
import { useStartChargingMutation, useStopChargingMutation } from '@services/devices/endpoints';
import { useAppSelector } from '@services/hooks';
import { UnifiedConnector } from '@views/Devices/connectorsUtil';
import { ConnectorActiveProgramContent } from '@views/Sites/SiteDevicesAndGroups/siteDevicesAndGroupsHelpers/ConnectorActiveChargingProgramContent';
import { BatteryLevelIcon } from '@views/Vehicles/VehiclesTable/vehicleDataComponents';

import { ConnectorTypeIcon } from './ConnectorTypeIcon';
import { useComputedStatusDefs } from './useComputedStatusDefs';

type ChargerButtonProps = {
  connector: UnifiedConnector;
  className?: string;
  smaller?: boolean;
  site: Site;
};

export const ChargerButton = ({ connector, className = '', smaller, site }: ChargerButtonProps) => {
  const { t } = useTranslation();
  const user = useAppSelector(selectUser)!;
  const isVoolAdmin = !!user.admin;

  const [isTakingLongerThanExpected, setIsTakingLongerThanExpected] = useState(false);
  const computedStatusDefs = useComputedStatusDefs(connector.voolDeviceFlag, smaller);

  const [startCharging, { isLoading: isStartLoading }] = useStartChargingMutation();
  const [stopCharging, { isLoading: isStopLoading }] = useStopChargingMutation();

  const connectorStatus = connector.computedStatus;
  const statusDef = computedStatusDefs[connectorStatus];
  const isButtonLoading = isStartLoading || isStopLoading;
  const isDisabled = !statusDef.action || isButtonLoading;
  const statusBackgroundStyle = statusDef.colorClassName;

  const ChargeButtonIcon = () => {
    if (isButtonLoading) {
      return null;
    }
    const buttonIcon = statusDef.buttonIcon;
    return (
      <>
        {typeof buttonIcon === 'string' ? (
          <img src={buttonIcon} alt="statusIcon" className={smaller ? 'h-5 w-5' : 'h-8 w-8'} />
        ) : (
          buttonIcon
        )}
      </>
    );
  };

  const onChargeAction = async () => {
    const connectorId = connector.isTheOnlyConnector ? undefined : connector.connectorId;
    if (statusDef.action === ChargingAction.START) {
      const response = (await startCharging({
        deviceUuid: connector.deviceUuid,
        connectorId,
      })) as { data?: unknown; error?: unknown };
      if (!response.error) {
        trackAmplitudeEvent(
          AmplitudeEvent.START_CHARGING,
          getAmplitudeEventPropertiesForConnector({
            connector,
            site,
            user,
          }),
        );
      }
    } else if (statusDef.action === ChargingAction.STOP) {
      const response = (await stopCharging({
        deviceSerialNumber: connector.deviceSerialNumber,
        connectorId,
        // Note: used only within FE (Redux device slice)
        isCancelling: [ConnectorComputedStatus.STARTING, ConnectorComputedStatus.STOPPING].includes(connectorStatus),
      })) as { data?: unknown; error?: unknown };
      if (!response.error) {
        trackAmplitudeEvent(
          AmplitudeEvent.STOP_CHARGING,
          getAmplitudeEventPropertiesForConnector({
            connector,
            site,
            user,
          }),
        );
      }
    }
  };

  useEffect(() => {
    if ([ConnectorComputedStatus.STARTING, ConnectorComputedStatus.STOPPING].includes(connectorStatus)) {
      const timeoutId = setTimeout(() => {
        setIsTakingLongerThanExpected(true);
      }, 10000);
      return () => clearInterval(timeoutId);
    }
    setIsTakingLongerThanExpected(false);
    return () => {};
  }, [connectorStatus]);

  return (
    <div
      className={`relative flex w-full min-w-[248px] items-center justify-center gap-x-4 rounded-2xl px-4 py-3 ${statusBackgroundStyle} ${className}`}
    >
      <button
        className={`relative flex flex-col items-center justify-center ${
          smaller ? 'h-10 w-10' : 'h-20 w-20 min-w-[80px]'
        }`}
        type="button"
        onClick={onChargeAction}
        disabled={isDisabled}
      >
        {statusDef.buttonIconOutline ? (
          statusDef.buttonIconOutline
        ) : (
          <div className={`h-full w-full border-4 ${statusBackgroundStyle} rounded-full p-5`} />
        )}
        <div className="absolute">
          <ChargeButtonIcon />
        </div>
      </button>
      <div
        className={`flex w-full flex-col items-start justify-center font-poppins ${
          !connector.isTheOnlyConnector ? 'mr-3' : ''
        }`}
      >
        <div className={`font-semibold ${statusDef.buttonStatusLabelColor ?? 'text-black'}`}>
          <div>{statusDef.statusLabel}</div>
        </div>
        <div className={`flex items-center gap-x-1 text-sm ${statusDef.buttonSecondaryLabelColor ?? 'text-gray-600'}`}>
          {isTakingLongerThanExpected ? (
            <div className="text-xs font-normal leading-5 text-gray-600">
              {t('takingLongerThanExpected', 'Taking longer than expected')}
            </div>
          ) : (
            <div className="leading-6">{statusDef.secondaryLabel}</div>
          )}
          {statusDef.tooltipText && (
            <Tooltip title={statusDef.tooltipText}>
              <CircledQuestionIcon className={statusDef.tooltipIconClassName} />
            </Tooltip>
          )}
        </div>
        {!!connector.state_of_charge &&
          [ConnectorComputedStatus.CHARGING, ConnectorComputedStatus.PLUGGED_IN].includes(connectorStatus) && (
            <BatteryLevelIcon batteryLevel={connector.state_of_charge} />
          )}
        {statusDef.actionLinkLabel && (
          <button className="leading-6 text-vermillion underline" onClick={onChargeAction}>
            {statusDef.actionLinkLabel}
          </button>
        )}
        {isVoolAdmin && !!connector.activeProgram && (
          <ConnectorActiveProgramContent activeProgram={connector.activeProgram} />
        )}
      </div>
      {!!connector.connectorId && (
        <div className="absolute right-2 top-2">
          <ConnectorTypeIcon
            className={`${statusBackgroundStyle} flex-col-reverse items-center`}
            connectorId={connector.isTheOnlyConnector ? undefined : connector.connectorId}
            connectorType={connector?.connectorType}
          />
        </div>
      )}
    </div>
  );
};
