import React from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import PropTypes from 'prop-types';

import useActiveCurrency from '@hooks/useActiveCurrency';
import apiSlice from '@services/api';

const SmartChargingGraph = ({ slots }) => {
  const { t } = useTranslation();
  const currency = useActiveCurrency();
  const { data: rates } = apiSlice.useTarifQuery(
    slots.length > 2 && {
      startDate: moment(slots[0].scheduledStartTime).utc().subtract(2, 'hour').toISOString(),
      endDate: moment(slots[slots.length - 1]?.scheduledEndTime)
        .utc()
        .add(2, 'hour')
        .toISOString(),
    },
  );
  const CHART_COLORS = ['#00cd87', '#0776cc'];
  const YAXIS_SERIES_PRICE = t('energyMarketPrice', 'Energy market price');
  const YAXIS_SERIES_KWH = t('smartCharging', 'Smart charging');
  const slotsData = rates
    ? rates.map((rate) => {
        const slot = slots.find(
          (s) => moment.utc(s.scheduledStartTime).format('DD HH') === moment.utc(rate.time).format('DD HH'),
        );
        return {
          value: slot?.kwhAmount > 0 ? slot?.kwhAmount : 0,
          monetaryCost: slot?.monetaryCost > 0 ? slot?.monetaryCost : 0,
          time: slot?.scheduledStartTime || rate.time,
        };
      })
    : slots.map((slot) => ({
        value: slot.kwhAmount > 0 ? slot.kwhAmount : 0,
        monetaryCost: slot.monetaryCost > 0 ? slot.monetaryCost : 0,
        time: slot.scheduledStartTime,
      }));

  const getSeries = () => {
    const series = [
      {
        name: YAXIS_SERIES_KWH,
        data: slotsData.map((v) => v.value || 0),
        type: 'area',
      },
    ];
    if (rates) {
      series.push({
        name: YAXIS_SERIES_PRICE,
        data: rates.map((rate) => rate.value / 10 || 0),
        type: 'line',
      });
    }
    return series;
  };

  const state = {
    series: getSeries(),
    options: {
      legend: {
        show: true,
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
        formatter: (val) => `${Number(val).toFixed(2)}`,
      },
      tooltip: {
        custom: ({ dataPointIndex }) =>
          ` <div class="flex flex-col p-1">
            <span>kWh: ${(slotsData[dataPointIndex]?.value || 0).toFixed(2)}</span>
            <span>Cost: ${currency.symbol} ${(slotsData[dataPointIndex]?.monetaryCost || 0).toFixed(2)}</span>
          </div>`,
      },
      stroke: {
        show: true,
        curve: 'stepline',
        lineCap: 'butt',
        width: 1,
        dashArray: 0,
      },
      chart: {
        foreColor: '#9d9d9d',
        toolbar: {
          show: false,
        },
        type: 'area',
        stacked: false,
        height: 350,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true,
        },
      },
      markers: {
        size: 0,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '50%',
        },
        endingShape: 'rounded',
      },
      yaxis: [
        {
          seriesName: YAXIS_SERIES_KWH,
          labels: {
            formatter: (val) => Number(val || 0).toFixed(0),
            offsetX: -15,
          },
          title: {
            text: 'kWh',
            rotate: 0,
            offsetX: 0,
            style: {
              color: CHART_COLORS[1],
            },
          },
        },
        {
          seriesName: YAXIS_SERIES_PRICE,
          labels: {
            formatter: (val) => Number(val || 0).toFixed(0),
            offsetX: -15,
          },
          show: true,
          title: {
            text: `${t('currencySubunit', '{{currencySubunit}}', {
              currencySubunit: currency.centsName,
            }).toLowerCase()}/kWh`,
            rotate: 0,
            offsetX: 20,
            style: {
              color: CHART_COLORS[0],
            },
          },
          opposite: true,
        },
      ],
      xaxis: {
        categories: (rates || slotsData).map((s) => moment(s.time).local().format('HH')),
        labels: {
          showDuplicates: false,
          rotate: 0,
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          type: 'vertical',
          shadeIntensity: 0.2,
          opacityFrom: 1,
          opacityTo: 0.6,
          stops: [0, 70, 100],
        },
      },
    },
  };

  return <Chart options={state.options} series={state.series} height={200} />;
};

SmartChargingGraph.propTypes = {
  slots: PropTypes.array,
};

SmartChargingGraph.defaultProps = {
  slots: [],
};

export default SmartChargingGraph;
