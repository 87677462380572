import { useTranslation } from 'react-i18next';

import * as yup from 'yup';

const useDualPriceSchema = ({ isTransmissionCost }) => {
  const { t } = useTranslation();
  const testValue = isTransmissionCost
    ? yup.string().test('notNegative', t('valueCannotBeNegative', 'Value cannot be negative'), (v) => v >= 0)
    : yup.string().test('positive', t('mustBePositive', 'Must be positive'), (value) => value > 0);
  return yup.object().shape({
    price: yup.object().shape({
      night: yup.string().required(t('required*', 'Required*')).concat(testValue),
      day: yup.string().required(t('required*', 'Required*')).concat(testValue),
    }),
    dayHours: yup.object().shape({
      start: yup.string().required(t('required*', 'Required*')),
      end: yup.string().required(t('required*', 'Required*')),
    }),
  });
};

const useSiteDetailsSchema = () => {
  const { t } = useTranslation();

  return yup
    .object()
    .shape({
      name: yup
        .string()
        .required(t('required*', 'Required*'))
        .test('nameIsRequired', t('nameIsRequired', 'Name is required'), (value) => value?.trim?.() !== ''),
      address: yup.string().required(t('required*', 'Required*')),
      pricingModel: yup.object().shape({
        isAutomatic: yup.boolean(),
        region: yup.string().when('isAutomatic', {
          is: true,
          then: yup.string().required(t('required*', 'Required*')),
        }),
        isSingleTransmission: yup.boolean(),
        transmissionSinglePrice: yup.string().when('isSingleTransmission', {
          is: true,
          then: yup
            .string()
            .required(t('required*', 'Required*'))
            .test('notNegative', t('valueCannotBeNegative', 'Value cannot be negative'), (v) => v >= 0),
        }),
        transmissionDualPrice: yup.object().when('isSingleTransmission', {
          is: false,
          then: useDualPriceSchema({ isTransmissionCost: true }),
        }),
        isSinglePrice: yup.boolean(),
        singlePrice: yup.string().when('isAutomatic', {
          is: false,
          then: yup.string().when('isSinglePrice', {
            is: true,
            then: yup
              .string()
              .required(t('required*', 'Required*'))
              .test('positive', t('mustBePositive', 'Must be positive'), (value) => value > 0),
          }),
        }),
        dualPrice: yup.object().when('isAutomatic', {
          is: false,
          then: yup.object().when('isSinglePrice', {
            is: false,
            then: useDualPriceSchema({ isTransmissionCost: false }),
          }),
        }),
        extraFees: yup.lazy((value) => {
          if (value?.trim?.() === '') {
            return yup.string();
          }
          return yup
            .number()
            .typeError(t('mustBeNumber', 'Must be a number'))
            .test('notNegative', t('valueCannotBeNegative', 'Value cannot be negative'), (v) => v >= 0);
        }),
      }),
    })
    .required();
};

export default useSiteDetailsSchema;
