import { FunctionComponent, ReactNode, SVGProps } from 'react';

import { Box, Typography } from '@mui/material';

type SettingsSectionItem = {
  label: string;
  subLabel?: string;
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  component?: () => ReactNode;
  subComponent?: () => ReactNode;
  hidden?: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

export const SettingsSectionItem = ({
  Icon,
  label,
  subLabel,
  component,
  subComponent,
  hidden,
  disabled,
  onClick,
}: SettingsSectionItem) => {
  if (hidden) return null;

  return (
    <Box
      component="button"
      display="flex"
      width="100%"
      bgcolor="#F4F4F4"
      gap={2}
      p={2}
      sx={{
        '&:first-of-type': {
          paddingTop: '20px',
        },
        '&:last-of-type': {
          paddingBottom: '20px',
        },
        '&:disabled': {
          color: '#C1C1C1',
        },
        '&:enabled:hover': {
          opacity: onClick ? 0.7 : 1,
        },
        cursor: onClick ? 'pointer' : 'default',
      }}
      disabled={disabled}
      onClick={onClick}
    >
      {!!Icon && <Icon className={!component ? '[&_path]:fill-gray-400' : ''} />}
      <Box display="flex" width="100%" flexDirection="column" gap={1}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="p14">{label}</Typography>
          <Typography variant="p14b">{component?.() ?? '-'}</Typography>
        </Box>
        {subLabel && subComponent && (
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="p14" color="#5E5E5E">
              {subLabel}
            </Typography>
            <Typography variant="p14" color="#5E5E5E">
              {subComponent()}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
