import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { sortBy } from 'lodash';
import { Device } from 'models/device';
import { DeviceType } from 'models/device.enums';
import { Site } from 'models/site';

import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { Box, Typography } from '@mui/material';
import { TreeView } from '@mui/x-tree-view';

import { selectActiveCompany } from '@services/companies/selectors';
import { useGetDevicesQuery } from '@services/devices/endpoints';
import { selectDevices } from '@services/devices/selectors';
import { useAppSelector } from '@services/hooks';
import { useGetSitesQuery } from '@services/sites/endpoints';
import { selectActiveCompanySites } from '@services/sites/selectors';

import { AssignmentTreeItem } from './AssignmentTreeItem';

type GroupAssignmentProps = {
  siteUuids: string[];
  deviceUuids: string[];
  isSaving?: boolean;
  onSiteUuidsChange: (siteUuids: string[]) => void;
  onDeviceUuidsChange: (deviceUuids: string[]) => void;
};

export const GroupAssignment = ({
  siteUuids,
  deviceUuids,
  isSaving,
  onSiteUuidsChange,
  onDeviceUuidsChange,
}: GroupAssignmentProps) => {
  const { t } = useTranslation();
  const [expandedAssignmentNodeIds, setExpandedAssignmentNodeIds] = useState<string[]>([]);

  const activeCompany = useAppSelector(selectActiveCompany);
  const sites: Site[] = useAppSelector(selectActiveCompanySites);
  const devices: Device[] = useAppSelector(selectDevices);

  const sortedSites = useMemo<Site[]>(() => sortBy(sites, (site) => site.name.toLowerCase()), [sites]);
  const sortedDevices = useMemo<Device[]>(
    () => sortBy(devices, (device) => (device.name || device.serialNumber)?.toLowerCase()),
    [devices],
  );

  useGetSitesQuery({});
  useGetDevicesQuery({});

  const toggleItem = (item: string, list: string[]) => {
    if (list.includes(item)) {
      return list.filter((listItem) => listItem !== item);
    }
    return [...list, item];
  };

  const companyItemChecked = sites.length > 0 && siteUuids.length === sites.length;

  const handleCompanyToggle = () => {
    if (companyItemChecked) {
      onSiteUuidsChange([]);
    } else {
      onSiteUuidsChange(sites.map((site) => site.uuid));
      onDeviceUuidsChange([]);
    }
  };

  const handleSiteToggle = (site: Site) => {
    if (!siteUuids.includes(site.uuid)) {
      onDeviceUuidsChange(
        devices
          .filter((device) => device.siteUuid !== site.uuid && deviceUuids.includes(device.uuid))
          .map((device) => device.uuid),
      );
    }
    onSiteUuidsChange(toggleItem(site.uuid, siteUuids));
  };

  const handleDeviceToggle = (device: Device) => {
    if (device.siteUuid && siteUuids.includes(device.siteUuid)) {
      onDeviceUuidsChange(
        deviceUuids.concat(
          devices.filter((d) => d.siteUuid === device.siteUuid && d.uuid !== device.uuid).map((d) => d.uuid),
        ),
      );
      onSiteUuidsChange(toggleItem(device.siteUuid, siteUuids));
    } else {
      onDeviceUuidsChange(toggleItem(device.uuid, deviceUuids));
    }
  };

  return (
    <div className="mt-6 space-y-6">
      <Typography variant="p16" color="#5E5E5E">
        {t(
          'selectSitesDevicesGroupText',
          'Select the sites or devices members of this group will be able to see and use.',
        )}
      </Typography>
      <Box border="2px solid #D6D6D6" borderRadius={2}>
        <Box display="flex" flexDirection="row" px={3} py={1} color="#5E5E5E" borderBottom="1px solid #D6D6D6">
          <Typography variant="p14" flex="1 1 0">
            {t('item', 'Item')}
          </Typography>
          <Typography variant="p14" width="128px">
            {t('type', 'Type')}
          </Typography>
        </Box>
        <TreeView
          expanded={['company', ...expandedAssignmentNodeIds]}
          defaultCollapseIcon={<ChevronDownIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          onNodeToggle={(e, nodeIds) => nodeIds.includes('company') && setExpandedAssignmentNodeIds(nodeIds)}
        >
          <AssignmentTreeItem
            nodeId="company"
            label={activeCompany?.name ?? t('workspace', 'Workspace')}
            typeLabel={t('workspace', 'Workspace')}
            checked={companyItemChecked}
            omitCollapseIcon
            onClick={handleCompanyToggle}
            disabled={isSaving}
          >
            {sortedSites.map((site) => (
              <AssignmentTreeItem
                key={site.uuid}
                nodeId={`site-${site.uuid}`}
                label={site.name}
                typeLabel={t('site', 'Site')}
                checked={siteUuids.includes(site.uuid)}
                indeterminate={devices.some(
                  (device) => device.siteUuid === site.uuid && deviceUuids.includes(device.uuid),
                )}
                onClick={() => handleSiteToggle(site)}
                disabled={isSaving}
              >
                {sortedDevices
                  .filter((device) => device.siteUuid === site.uuid)
                  .map((device) => (
                    <AssignmentTreeItem
                      key={device.uuid}
                      nodeId={`device-${device.uuid}`}
                      label={device.name || device.serialNumber || ''}
                      typeLabel={device.type === DeviceType.LMC ? t('lmc', 'LMC') : t('charger', 'Charger')}
                      checked={deviceUuids.includes(device.uuid) || siteUuids.includes(site.uuid)}
                      onClick={() => handleDeviceToggle(device)}
                      disabled={isSaving}
                    />
                  ))}
              </AssignmentTreeItem>
            ))}
          </AssignmentTreeItem>
        </TreeView>
      </Box>
    </div>
  );
};
