import { createAppSelector } from '@services/hooks';
import { RootState } from '@services/store';

const selectSlice = (state: RootState) => state.rfidCards;

export const selectRfidCards = createAppSelector(selectSlice, (slice) => slice.rfidCards);

export const selectRfidCardsByCompanyUuid = createAppSelector(
  selectRfidCards,
  (state: RootState, companyUuid?: string) => companyUuid,
  (rfidCards, companyUuid) => (companyUuid ? rfidCards.filter((r) => r.companyUuid === companyUuid) : []),
);

export const makeSelectRfidCardByUuid = () =>
  createAppSelector(
    selectRfidCards,
    (state: RootState, uuid: string) => uuid,
    (rfidCards, uuid) => rfidCards.find((r) => r.uuid === uuid),
  );
