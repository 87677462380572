import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SummaryCard } from '@components/cards/SummaryCard';
import TimePeriodSelectorHeader from '@components/filterComponents/TimePeriodSelectorHeader';
import { selectInverterSummary } from '@services/inverters/selectors';

type InverterSummaryProps = {
  selectedDuration: string;
  setSelectedDuration: (duration: string) => void;
};

export const InverterSummary = ({ selectedDuration, setSelectedDuration }: InverterSummaryProps) => {
  const { t } = useTranslation();
  const inverterSummary = useSelector(selectInverterSummary);

  const summaryData = {
    maximum: {
      label: t('maximum', 'Maximum'),
      unit: 'kW',
      value: inverterSummary.summary?.maxProductionKw ?? 0,
      trend: inverterSummary.summary?.maxProductionKwTrend,
    },
    energyProduced: {
      label: t('energyProduced', 'Energy produced'),
      unit: 'kWh',
      value: inverterSummary.summary?.totalProductionKwh ?? 0,
      trend: inverterSummary.summary?.totalProductionKwhTrend,
    },
  };

  return (
    <div className="relative">
      <TimePeriodSelectorHeader
        selectedDuration={selectedDuration}
        setSelectedDuration={setSelectedDuration}
        labelPrefix={t('energyProducedFor', 'Energy produced for')}
        storageKey="inverterSummaryDuration"
        withoutAll
      />
      <div className="mb-4 flex flex-wrap items-stretch gap-4">
        {Object.entries(summaryData).map(([key, data]) => (
          <SummaryCard key={key} label={data.label} unit={data.unit} value={data.value} trend={data.trend} />
        ))}
      </div>
    </div>
  );
};
