import { Context, Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReportingTransaction } from 'models/reporting-transaction';

import { TableCell, Typography } from '@mui/material';

import { ColumnData, MaterialTable } from '@components/atoms/MaterialTable';
import { StyledTableRow } from '@components/atoms/StyledTableRow';
import { FilterMenuOption } from '@components/filters';
import { FiltersBaseContextType, FiltersDataContextType } from '@components/filters/context/FiltersContext';
import { ReactComponent as SettingsIcon } from '@images/icons/reporting/settings.svg';

import { DurationCell } from './DurationCell';
import { LocationCell } from './LocationCell';
import { MenuCell } from './MenuCell';
import { ReportingTableRowSkeleton, ReportingTableSkeleton } from './ReportingTableSkeleton';
import { StartTimeCell } from './StartTimeCell';
import { StartedByCell } from './StartedByCell';
import { TransactionDetailsRow } from './TransactionDetailsRow';

type ReportingTransactionsColumnData = ColumnData<ReportingTransaction>;

const useColumns = (hiddenColumns?: (keyof ReportingTransaction | 'menu')[]): ReportingTransactionsColumnData[] => {
  const { t } = useTranslation();

  const columns: ReportingTransactionsColumnData[] = [
    {
      width: 180,
      label: t('startTime', 'Start time'),
      dataKey: 'startTime',
      sortable: true,
    },
    {
      width: 160,
      label: t('site', 'Site'),
      subtitle: t('charger', 'Charger'),
      dataKey: 'siteName',
      sortable: true,
    },
    {
      width: 160,
      label: t('startedBy', 'Started by'),
      dataKey: 'userName',
      sortable: true,
    },
    {
      width: 100,
      label: t('duration', 'Duration'),
      dataKey: 'duration',
      numeric: true,
      sortable: true,
    },
    {
      width: 120,
      unit: 'kWh',
      label: t('consumption', 'Consumption'),
      dataKey: 'amount',
      numeric: true,
      sortable: true,
    },
    {
      width: 100,
      label: t('revenue', 'Revenue'),
      dataKey: 'monetaryRevenue',
      numeric: true,
      currency: true,
      sortable: true,
      tooltip: t('vatNotIncluded', 'VAT not included'),
    },
    {
      width: 60,
      label: t('profit', 'Profit'),
      dataKey: 'monetaryProfit',
      numeric: true,
      currency: true,
      sortable: true,
      tooltip: t('vatNotIncluded', 'VAT not included'),
    },
    {
      width: 60,
      label: t('cost', 'Cost'),
      dataKey: 'monetaryCost',
      numeric: true,
      currency: true,
      sortable: true,
      tooltip: t('vatNotIncluded', 'VAT not included'),
    },
    {
      width: 60,
      label: <SettingsIcon />,
      dataKey: 'menu',
    },
  ];

  return hiddenColumns ? columns.filter((column) => !hiddenColumns?.includes(column.dataKey)) : columns;
};

const renderRows = (rows: ReportingTransaction[], columns: ReportingTransactionsColumnData[]) => {
  return rows.map((row) => <TableRow key={row.id} row={row} columns={columns} />);
};

type TableRowProps = {
  row: ReportingTransaction;
  columns: ReportingTransactionsColumnData[];
};

const TableRow = ({ row, columns }: TableRowProps) => {
  const [open, setOpen] = useState(false);

  return (
    <Fragment key={row.id}>
      <StyledTableRow onClick={() => setOpen((prevValue) => !prevValue)} sx={{ cursor: 'pointer' }}>
        {columns.map((column) => {
          switch (column.dataKey) {
            case 'startTime':
              return (
                <StartTimeCell
                  key={column.dataKey}
                  startTime={row.startTime}
                  transactionType={row.transactionType}
                  status={row.status}
                  width={column.width}
                />
              );
            case 'siteName':
              return (
                <LocationCell
                  key={column.dataKey}
                  siteName={row.siteName}
                  deviceName={row.deviceName}
                  width={column.width}
                />
              );
            case 'duration':
              return <DurationCell key={column.dataKey} duration={row.duration} width={column.width} />;
            case 'userName':
              return <StartedByCell key={column.dataKey} row={row} width={column.width} />;
            case 'amount':
              return (
                <TableCell key={column.dataKey} align="right" width={column.width}>
                  <Typography variant="p14">{(row[column.dataKey] / 1000).toFixed(2)}</Typography>
                </TableCell>
              );
            case 'monetaryRevenue':
            case 'monetaryProfit':
            case 'monetaryCost':
              return (
                <TableCell key={column.dataKey} align="right" width={column.width}>
                  <Typography variant="p14">{row[column.dataKey].toFixed(2)}</Typography>
                </TableCell>
              );
            case 'menu':
              return <MenuCell key={column.dataKey} invoiceId={row.invoiceId} align="center" width={column.width} />;
            default:
              return (
                <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                  <Typography variant="p14">{String(row[column.dataKey])}</Typography>
                </TableCell>
              );
          }
        })}
      </StyledTableRow>
      <TransactionDetailsRow transaction={row} colSpan={columns.length} open={open} />
    </Fragment>
  );
};

type ReportingMaterialTableProps<ContextType, ExtractedType extends FilterMenuOption> = {
  context: Context<ContextType & FiltersDataContextType<ReportingTransaction> & FiltersBaseContextType<ExtractedType>>;
  hiddenColumns?: (keyof ReportingTransaction | 'menu')[];
};

export const ReportingMaterialTable = <ContextType, ExtractedType extends FilterMenuOption>({
  context,
  hiddenColumns,
}: ReportingMaterialTableProps<ContextType, ExtractedType>) => {
  const { data, dataLoading, loadMore, order, orderBy, handleRequestSort } = useContext(context);

  return (
    <MaterialTable
      context={context}
      data={data}
      isLoading={dataLoading}
      headerProps={{
        columns: useColumns(hiddenColumns),
        onRequestSort: handleRequestSort,
        order,
        orderBy,
      }}
      renderRows={renderRows}
      loadMore={loadMore}
      loaders={{ TableLoader: ReportingTableSkeleton, RowLoader: ReportingTableRowSkeleton }}
    />
  );
};
