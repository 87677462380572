import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { Site } from 'models/site';

import { Box, Link, Typography } from '@mui/material';

import { ChargerAutocomplete } from '@components/atoms/ChargerAutocomplete';
import { Checkbox } from '@components/atoms/Checkbox';
import { Input } from '@components/atoms/Input';
import ButtonSwitch from '@components/buttons/ButtonSwitch';
import useActiveCurrency from '@hooks/useActiveCurrency';
import warningIcon from '@images/icons/Warning.svg';

import { SiteAvailabilityForm } from './useSiteAvailabilitySchema';

type SiteAvailabilitySidebarProps = {
  form: UseFormReturn<SiteAvailabilityForm>;
};

export const SiteAvailability = ({ form }: SiteAvailabilitySidebarProps) => {
  const { t } = useTranslation();
  const { site } = useOutletContext<{ site: Site }>();

  const currency = useActiveCurrency();
  const navigate = useNavigate();

  const { watch, setValue, register, formState, control } = form;
  const [isPublic, isFixed, isReservationEnabled, isParkingEnabled] = watch([
    'isPublic',
    'isFixed',
    'isReservationEnabled',
    'isParkingEnabled',
  ]);
  return (
    <>
      <Box display="flex" flexDirection="column" marginBottom={4} gap={2} color="#5E5E5E">
        <Typography variant="p14">{t('makeThisSitePublicOn', 'Make this site available to the public on')}</Typography>
        <Link href="https://charge.vool.com" target="_blank" rel="noreferrer" style={{ textDecoration: 'underline' }}>
          <Typography variant="p14"> charge.vool.com</Typography>
        </Link>
        <Typography variant="p14">
          {t('siteVisibleText', 'This site will only be visible when at least one of its chargers is online.')}
        </Typography>
        <Typography variant="p14">{t('pricesWithoutVatText', 'All prices entered should exclude VAT.')}</Typography>
      </Box>
      {!site.pricingModel && (
        <Box
          display="flex"
          marginBottom={8}
          gap={2}
          style={{ cursor: 'pointer' }}
          justifyContent="start"
          alignItems="start"
          color="#eb4e20"
          className="rounded-2xl text-sm"
          onClick={() => navigate(`/workspace?siteUuid=${site.uuid}`)}
          aria-hidden
        >
          <img src={warningIcon} alt="warning" />
          <Typography variant="p12">
            {t('siteMissingPricingModel', 'This site is missing information about electricity costs.')}{' '}
            {t('publicSiteUnavailable', 'Site can not be public until this is added.')}{' '}
            <span className="underline">{t('addThemHere', 'You can add them here')}</span>
          </Typography>
        </Box>
      )}

      <Typography
        marginBottom={2}
        display="block"
        variant="p16b"
        fontFamily="Poppins"
        color={!site.pricingModel ? 'gray' : ''}
      >
        <Checkbox
          className="float-right mt-1 rounded text-forest focus:ring-0"
          disabled={!site.pricingModel}
          {...register('isPublic')}
        />
        {t('enable', 'Enable')}
      </Typography>
      <Box sx={{ opacity: isPublic ? 1 : 0.4 }} marginBottom={4}>
        <ButtonSwitch
          options={[
            { label: t('fixed', 'Fixed'), value: true },
            { label: t('marketPlusMargin', 'Market+margin'), value: false },
          ]}
          value={isFixed}
          onChange={(value) => setValue('isFixed', value, { shouldDirty: true })}
          disabled={!isPublic}
        />
        <Input
          label={
            isFixed
              ? t('setFixedPriceLabel', `Set fixed {{currencySubunit}}/kWh`, { currencySubunit: currency.centsName })
              : t('setMarginPriceLabel', `Set margin {{currencySubunit}}/kWh`, { currencySubunit: currency.centsName })
          }
          disabled={!isPublic}
          error={Boolean(formState.errors?.priceInput)}
          helpText={formState.errors?.priceInput?.message}
          isInteger
          {...register('priceInput')}
        />
      </Box>
      <Box marginBottom={2}>
        <ChargerAutocomplete control={control} disabled={!isPublic} name="publicChargers" />
      </Box>
      <Typography
        sx={{ opacity: isPublic ? 1 : 0.4 }}
        marginBottom={2}
        fontFamily={'Poppins'}
        display="block"
        variant="p16b"
      >
        <Checkbox
          className="float-right mt-1 rounded text-forest focus:ring-0"
          disabled={!isPublic}
          {...register('isReservationEnabled')}
        />
        {t('allowReservations', 'Allow reservations')}
      </Typography>
      <Box
        marginBottom={2}
        gap={2}
        sx={{
          opacity: !isPublic || !isReservationEnabled ? 0.4 : 1,
        }}
      >
        <Typography fontFamily={'Poppins'} color={'#5E5E5E'} variant="p14" lineHeight={2}>
          {t(
            'freeReservationDurationText',
            'You can specify the duration of the free reservation period and the cost per minute, thereafter.',
          )}
        </Typography>
        <Input
          label={`${t('freeReservationPeriod', 'Free reservation period')} (${t(
            'optional',
            'Optional',
          ).toLowerCase()})`}
          trailingText={t('minutes', 'minutes')}
          disabled={!isPublic || !isReservationEnabled}
          error={Boolean(formState.errors?.freeReservationMinutes)}
          helpText={formState.errors?.freeReservationMinutes?.message}
          placeholder="0"
          isInteger
          {...register('freeReservationMinutes')}
        />
        <Input
          label={t('reservationCost', 'Reservation cost')}
          trailingText={t('currencySubunit/min', '{{currencySubunit}}/min', { currencySubunit: currency.centsName })}
          disabled={!isPublic || !isReservationEnabled}
          error={Boolean(formState.errors?.reservationCentsPerMinute)}
          helpText={formState.errors?.reservationCentsPerMinute?.message}
          isInteger
          {...register('reservationCentsPerMinute')}
        />
      </Box>
      <Typography
        sx={{ opacity: isPublic ? 1 : 0.4 }}
        marginBottom={2}
        fontFamily={'Poppins'}
        display="block"
        variant="p16b"
      >
        <Checkbox
          className="float-right mt-1 rounded text-forest focus:ring-0"
          disabled={!isPublic}
          {...register('isParkingEnabled')}
        />
        {t('parkingAfterTheChargeText', 'Parking after the charge')}
      </Typography>
      <Box
        marginBottom={2}
        gap={2}
        sx={{
          opacity: !isPublic || !isParkingEnabled ? 0.4 : 1,
        }}
      >
        <Typography fontFamily={'Poppins'} color={'#5E5E5E'} variant="p14" lineHeight={2}>
          {t(
            'freeParkingDurationText',
            'You can specify the duration of the free parking period and the cost per minute, thereafter.',
          )}
        </Typography>
        <Input
          label={`${t('freeParkingPeriod', 'Free parking period')} (${t('optional', 'Optional').toLowerCase()})`}
          trailingText={t('minutes', 'minutes')}
          disabled={!isPublic || !isParkingEnabled}
          error={Boolean(formState.errors?.freeParkingMinutes)}
          helpText={formState.errors?.freeParkingMinutes?.message}
          placeholder="0"
          {...register('freeParkingMinutes')}
        />
        <Input
          label={t('parkingCost', 'Parking cost')}
          trailingText={t('currencySubunit/min', '{{currencySubunit}}/min', { currencySubunit: currency.centsName })}
          disabled={!isPublic || !isParkingEnabled}
          error={Boolean(formState.errors?.parkingCentsPerMinute)}
          helpText={formState.errors?.parkingCentsPerMinute?.message}
          isInteger
          {...register('parkingCentsPerMinute')}
        />
      </Box>
    </>
  );
};
