import { useCallback } from 'react';

import { useGetAllDevicesQuery, useGetDevicesQuery } from '@services/devices/endpoints';
import { useGetSiteDevicesQuery } from '@services/sites/endpoints';

import { useChargersWithStats } from './useChargersWithStats';
import { useInvertersWithStats } from './useInvertersWithStats';
import { useLmcMultipointsWithStats } from './useLmcMultipointsWithStats';

type UseDevicesAndGroupsProps = {
  siteUuid?: string;
  companyUuid?: string;
  selectedDuration?: string;
};

export const useDevicesAndGroups = ({ siteUuid, companyUuid, selectedDuration }: UseDevicesAndGroupsProps) => {
  const { refetch: refetchSiteDevices, isLoading: isLoadingGetSiteDevices } = useGetSiteDevicesQuery(siteUuid!, {
    refetchOnMountOrArgChange: true,
    skip: !siteUuid,
  });

  const { refetch: refetchCompanyDevices, isLoading: isLoadingGetCompanyDevices } = useGetDevicesQuery(
    { companyUuid },
    {
      refetchOnMountOrArgChange: true,
      skip: !companyUuid,
    },
  );

  const { refetch: refetchAllDevices, isLoading: isLoadingAllDevices } = useGetAllDevicesQuery(
    { forBackoffice: true },
    {
      refetchOnMountOrArgChange: true,
      skip: !!siteUuid || !!companyUuid,
    },
  );

  const refetchDevices = useCallback(() => {
    if (siteUuid) {
      refetchSiteDevices();
    }
    if (companyUuid) {
      refetchCompanyDevices();
    }
    if (!siteUuid && !companyUuid) {
      refetchAllDevices();
    }
  }, [siteUuid, companyUuid]);

  const commonProps = { siteUuid, companyUuid };

  const {
    lmcMultipointsWithStats,
    selectedLmcMultipoint,
    isLoading: isLoadingLmcMultipoints,
  } = useLmcMultipointsWithStats(commonProps);

  const { chargersWithStats, selectedCharger } = useChargersWithStats({
    ...commonProps,
    selectedDuration,
    lmcMultipointsWithStats,
  });

  const {
    invertersWithStats,
    isLoading: isInvertersLoading,
    isFetchingGetSiteInvertersSummary,
    refetchInverters,
  } = useInvertersWithStats({
    ...commonProps,
    selectedDuration,
  });

  const isLoading =
    isLoadingGetSiteDevices ||
    isLoadingGetCompanyDevices ||
    isLoadingLmcMultipoints ||
    isInvertersLoading ||
    isLoadingAllDevices;

  return {
    lmcMultipointsWithStats,
    chargersWithStats,
    invertersWithStats,
    selectedLmcMultipoint,
    selectedCharger,
    isLoading,
    isFetchingGetSiteInvertersSummary,
    refetchDevices,
    refetchInverters,
  };
};
