import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Device } from 'models/device';
import { DeviceModalOption, DeviceOcppActionOption, DeviceStatus, DeviceType } from 'models/device.enums';
import { Permission } from 'models/permission.enum';

import { SettingsSection } from '@components/settings/SettingsSection';
import { SettingsSectionItem } from '@components/settings/SettingsSectionItem';
import spinnerIcon from '@images/icons/Spinner_2.svg';
import { ReactComponent as ArrowLeftIcon } from '@images/icons/arrow_left.svg';
import { selectPermissionByName } from '@services/auth/selectors';
import { useAppSelector } from '@services/hooks';
import { DeviceOcppFirmwareModal } from '@views/Devices/DeviceOcppFirmwareModal';
import { DeviceOcppMessage } from '@views/Devices/DeviceOcppMessage';
import { isVoolDevice } from '@views/Sites/SiteDevicesAndGroups/siteDevicesAndGroupsHelpers/siteDevicesUtils';

type DeviceActionsProps = {
  device: Device;
};

export const DeviceActions = ({ device }: DeviceActionsProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [modalOption, setModalOption] = useState<DeviceModalOption>();
  const [deviceOcppAction, setDeviceOcppAction] = useState<DeviceOcppActionOption>();

  const canRestartDevices = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_RESTART_DEVICES));
  const canUpdateDeviceFirmware = useAppSelector((state) =>
    selectPermissionByName(state, Permission.CAN_UPDATE_DEVICE_FIRMWARE),
  );

  const navigateToDevice = () => navigate(`/devices/${device.uuid}`);
  const navigateToDeviceLogs = () => navigate(`/logs/?deviceUuid=${device.uuid}`);

  const isLmc = device.type === DeviceType.LMC;
  const isDeviceView = location.pathname.includes('devices');

  const updateAndRestartDisabled = !device?.status || device?.status === DeviceStatus.OFFLINE;

  useEffect(() => {
    if (device.status === DeviceStatus.ONLINE) {
      setDeviceOcppAction(undefined);
    }
  }, [device.status]);

  return (
    <>
      <SettingsSection title={t('actions', 'Actions')}>
        <SettingsSectionItem
          label={t('goToDevice', 'Go to device')}
          component={() => <ArrowLeftIcon className="rotate-180" />}
          hidden={isDeviceView}
          onClick={navigateToDevice}
        />
        <SettingsSectionItem
          label={t('viewLogs', 'View logs')}
          component={() => <ArrowLeftIcon className="rotate-180" />}
          onClick={navigateToDeviceLogs}
        />
        <SettingsSectionItem
          label={t('updateDevice', 'Update device')}
          component={() => (
            <div className="flex items-center gap-x-2">
              {device.firmwareVersion}
              <img src={spinnerIcon} alt="update device" className={updateAndRestartDisabled ? 'opacity-30' : ''} />
            </div>
          )}
          hidden={!canUpdateDeviceFirmware || !isVoolDevice(device) || isLmc}
          disabled={updateAndRestartDisabled}
          onClick={() => setModalOption(DeviceModalOption.OCPP_UPDATE)}
        />
        <SettingsSectionItem
          label={t('restartDevice', 'Restart device')}
          component={() => (
            <img src={spinnerIcon} alt="restart device" className={updateAndRestartDisabled ? 'opacity-30' : ''} />
          )}
          hidden={!canRestartDevices}
          disabled={updateAndRestartDisabled}
          onClick={() => setModalOption(DeviceModalOption.OCPP_RESET)}
        />
      </SettingsSection>
      <DeviceOcppMessage
        device={device}
        deviceOcppAction={deviceOcppAction}
        onClose={() => setDeviceOcppAction(undefined)}
        onUpdateAgain={() => setModalOption(DeviceModalOption.OCPP_UPDATE)}
      />
      <DeviceOcppFirmwareModal
        device={device}
        isOpen={!!modalOption && [DeviceModalOption.OCPP_RESET, DeviceModalOption.OCPP_UPDATE].includes(modalOption)}
        option={modalOption}
        closeModal={() => setModalOption(undefined)}
        onSuccess={(option) => {
          setDeviceOcppAction(
            option === DeviceModalOption.OCPP_RESET
              ? DeviceOcppActionOption.OCPP_RESET
              : DeviceOcppActionOption.OCPP_UPDATE,
          );
        }}
      />
    </>
  );
};
