import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { isEmpty, sortBy } from 'lodash';
import { Car } from 'models/car';
import { RFIDCard, RFIDCardDto } from 'models/rfid-card';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import { Input } from '@components/atoms/Input';
import Select from '@components/atoms/Select';
import EditingSidebarBase from '@components/sidebars/EditingSidebarBase';
import useForm from '@hooks/useForm';
import { selectActiveCompanyUuid } from '@services/companies/selectors';
import { useAppSelector } from '@services/hooks';
import { selectUsers } from '@services/users/selectors';
import { selectPrivateVehiclesOrByCompanyUuid } from '@services/vehicles/selectors';

const useSchema = () => {
  const { t } = useTranslation();
  return yup
    .object()
    .shape({
      identifier: yup
        .string()
        .required(t('required*', 'Required*'))
        // TODO: keep this in sync with identifier pattern in BE
        .matches(/^[0-9a-f:]+$/i, t('mayOnlyContainDigitsAndA-F', 'May only contain digits and letters A-F'))
        .matches(
          /^([0-9a-f]+|[0-9a-f]{1,2}(:[0-9a-f]{1,2})+)$/i,
          t('eachGroupMayOnlyContainOneOrTwoCharacters', 'Each group may only contain one or two characters'),
        ),
      ownerType: yup.string(),
      name: yup
        .string()
        .when('ownerType', {
          is: 'none',
          then: (s) =>
            s
              .required(t('required*', 'Required*'))
              .test('nameIsRequired', t('nameIsRequired', 'Name is required'), (value) => value?.trim?.() !== ''),
        })
        .nullable(),
      carUuid: yup
        .string()
        .when('ownerType', { is: 'vehicle', then: (s) => s.required(t('required*', 'Required*')) })
        .nullable(),
      userUuid: yup
        .string()
        .when('ownerType', { is: 'user', then: (s) => s.required(t('required*', 'Required*')) })
        .nullable(),
    })
    .required();
};

type RfidCardDetailsSidebarBaseProps = {
  rfidCard: RFIDCard;
  isIdentifierImmutable: boolean;
  title: string;
  saveLabel?: string;
  discardLabel?: string;
  isSaveLoading: boolean;
  onSaveValues: (attrs: RFIDCardDto) => Promise<boolean>;
};

const RfidCardDetailsSidebarBase = ({
  rfidCard,
  isIdentifierImmutable,
  title,
  saveLabel,
  discardLabel,
  isSaveLoading,
  onSaveValues,
}: RfidCardDetailsSidebarBaseProps) => {
  const { t } = useTranslation();
  const { companyUuid: companyUuidFromUrl } = useParams();
  const activeCompanyUuid = useAppSelector(selectActiveCompanyUuid);
  const companyUuid = companyUuidFromUrl ?? activeCompanyUuid;

  const [updateFailed, setUpdateFailed] = useState(false);
  const schema = useSchema();
  const { register, handleSubmitAndResolve, formState, reset, setValue, watch } = useForm({
    schema,
    defaultValues: { ownerType: 'none' },
  });

  const formatVehicle = (v: Car) => `${v.brand} ${v?.name || v.model}`;

  const vehicles = useAppSelector((state) => selectPrivateVehiclesOrByCompanyUuid(state, companyUuid));
  const sortedVehicles = useMemo(
    () =>
      sortBy(
        vehicles.filter((v) => v.connectionType !== 'guest-car'),
        (v) => formatVehicle(v)?.toLowerCase(),
      ),
    [vehicles],
  );

  const users = useAppSelector(selectUsers);
  const sortedUsers = useMemo(() => sortBy(users, (u) => u.name?.toLowerCase() || ''), [users]);

  const ownerTypeOptions = [
    { label: t('none', 'None'), value: 'none' },
    { label: t('member', 'Member'), value: 'user' },
    { label: t('vehicle', 'Vehicle'), value: 'vehicle' },
  ];

  const ownerType = watch('ownerType');
  const carUuid = watch('carUuid');
  const userUuid = watch('userUuid');

  const vehicleOptions = sortedVehicles.map((v) => ({ label: formatVehicle(v), value: v.uuid }));
  const userOptions = sortedUsers.map((u) => ({ label: u.name || u.email, value: u.uuid, italic: !!u.inviteFlag }));

  const existingOwnerType = (() => {
    if (rfidCard?.userUuid) {
      return 'user';
    }
    if (rfidCard?.carUuid) {
      return 'vehicle';
    }
    return 'none';
  })();

  const loadValues = () => {
    if (isEmpty(rfidCard)) {
      reset();
    } else {
      reset({
        identifier: rfidCard.normalizedIdentifier ?? rfidCard.identifier,
        ownerType: existingOwnerType,
        name: rfidCard.name,
        userUuid: rfidCard.userUuid,
        carUuid: rfidCard.carUuid,
      });
    }
  };

  const submit = async (attrs: RFIDCardDto) => {
    const success = await onSaveValues({
      ...(!!rfidCard?.uuid && { uuid: rfidCard.uuid }),
      ...(!isIdentifierImmutable && { identifier: attrs.identifier }),
      ...(ownerType === 'none' && { name: attrs.name?.trim() }),
      ...(ownerType === 'user' && { userUuid: attrs.userUuid }),
      ...(ownerType === 'vehicle' && { carUuid: attrs.carUuid }),
    });
    setUpdateFailed(!success);
    return success;
  };

  useEffect(loadValues, [rfidCard?.uuid]);

  return (
    <EditingSidebarBase
      title={title}
      {...(saveLabel && { saveLabel })}
      {...(discardLabel && { discardLabel })}
      onSaveValues={handleSubmitAndResolve(submit)}
      anyDataChanged={formState.isDirty}
      updateLoading={isSaveLoading}
      updateFailed={updateFailed}
      containerClassName="space-y-5 font-poppins"
    >
      <>
        <Input
          label={t('rfidCardNumber', 'RFID card number')}
          type="text"
          error={Boolean(formState.errors?.identifier)}
          helpText={formState.errors?.identifier?.message}
          disabled={isIdentifierImmutable}
          placeholder={t('rfidCardExampleText', 'For example: 12:34:AB:CD or 1234ABCD')}
          {...register('identifier')}
        />
        <Select
          label={t('assignTo', 'Assign to')}
          required
          options={ownerTypeOptions}
          value={ownerType}
          onChange={(newType) => {
            setValue('ownerType', newType, { shouldDirty: true });
          }}
        />
        {ownerType === 'none' && (
          <Input
            label={t('name', 'Name')}
            type="text"
            error={Boolean(formState.errors?.name)}
            helpText={formState.errors?.name?.message}
            {...register('name')}
          />
        )}
        {ownerType === 'user' && (
          <Select
            label={t('member', 'Member')}
            required
            options={userOptions}
            value={userUuid}
            error={Boolean(formState.errors?.userUuid)}
            helpText={formState.errors?.userUuid?.message}
            onChange={(newUserUuid) => {
              setValue('userUuid', newUserUuid, { shouldDirty: true });
            }}
          />
        )}
        {ownerType === 'vehicle' && (
          <Select
            label={t('vehicle', 'Vehicle')}
            required
            options={vehicleOptions}
            value={carUuid}
            error={Boolean(formState.errors?.carUuid)}
            helpText={formState.errors?.carUuid?.message}
            onChange={(newCarUuid) => {
              setValue('carUuid', newCarUuid, { shouldDirty: true });
            }}
          />
        )}
      </>
    </EditingSidebarBase>
  );
};

RfidCardDetailsSidebarBase.propTypes = {
  rfidCard: PropTypes.object,
  isIdentifierImmutable: PropTypes.bool,
  title: PropTypes.string.isRequired,
  saveLabel: PropTypes.string,
  discardLabel: PropTypes.string,
  isSaveLoading: PropTypes.bool,
  onSaveValues: PropTypes.func.isRequired,
};

RfidCardDetailsSidebarBase.defaultProps = {
  rfidCard: {},
  isIdentifierImmutable: false,
  saveLabel: null,
  discardLabel: null,
  isSaveLoading: false,
};

export default RfidCardDetailsSidebarBase;
