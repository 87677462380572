import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import Autocomplete from '@components/atoms/Autocomplete';
import { Input } from '@components/atoms/Input';
import ButtonSwitch from '@components/buttons/ButtonSwitch';
import GoogleMapsAddressInput from '@components/google/GoogleMapsAddressInput';
import EditingSidebarBase from '@components/sidebars/EditingSidebarBase';
import { useForm } from '@hooks';
import useActiveCurrency from '@hooks/useActiveCurrency';
import apiSlice from '@services/api';
import { selectCountries } from '@services/countries/selectors';

import FixedPriceInputs from './sidebarHelpers/FixedPriceInputs';
import useSiteDetailsSchema from './sidebarHelpers/siteDetailsSchema';
import usePricingModel from './sidebarHelpers/usePricingModel';
import '/node_modules/flag-icons/css/flag-icons.min.css';

const SiteDetailsSidebarBase = ({
  site,
  title,
  saveLabel,
  discardLabel,
  isSaveLoading,
  onSaveValues,
  getReturnPath,
}) => {
  const { t } = useTranslation();
  const currency = useActiveCurrency();
  const countries = useSelector(selectCountries);
  const [addressObj, setAddressObj] = useState({ address: '', addressJson: null });
  const [updateFailed, setUpdateFailed] = useState(false);
  const schema = useSiteDetailsSchema();

  const { pricingModel, getPricingModelToSave, setPricingModelFromSite, setPricingModelAttr, hasPricingModelChanged } =
    usePricingModel();

  const { formState, reset, setValue, clearErrors, setError, register, handleSubmitAndResolve } = useForm({
    schema,
  });

  const regionOptions = useMemo(
    () =>
      countries.flatMap(({ electricityPriceAreas, code: countryCode }) =>
        electricityPriceAreas
          .filter(({ activeFlag }) => activeFlag)
          .map(({ name, code }) => ({
            label: name,
            value: code,
            extraLabel: code,
            icon: <span className={`fi fi-${countryCode.toLowerCase()}`}></span>,
          })),
      ),
    [countries],
  );

  const anySettingsChanged = hasPricingModelChanged || formState.isDirty;

  const handleSaveValues = async ({ name }) => {
    const success = await onSaveValues({
      ...(site?.uuid && { uuid: site.uuid }),
      name: name.trim(),
      ...addressObj,
      pricingModel: getPricingModelToSave(),
    });
    setUpdateFailed(!success);
    return success;
  };

  const handleSetPricingModelValue = (attr, value) => {
    const path = `pricingModel.${attr}`;
    setPricingModelAttr(attr, value);
    clearErrors(path);
  };

  const loadValues = () => {
    if (isEmpty(site)) {
      reset();
    } else {
      const siteAddress = { address: site.address, addressJson: site.addressJson };
      setAddressObj(siteAddress);
      reset({
        name: site?.name,
        address: siteAddress.address,
      });
      setPricingModelFromSite(site);
    }
  };

  apiSlice.useGetCountriesQuery();

  useEffect(loadValues, [site?.uuid]);

  useEffect(() => {
    setValue('pricingModel', pricingModel);
  }, [pricingModel]);

  return (
    <EditingSidebarBase
      title={title}
      {...(saveLabel && { saveLabel })}
      {...(discardLabel && { discardLabel })}
      onSaveValues={handleSubmitAndResolve(handleSaveValues)}
      anyDataChanged={anySettingsChanged}
      updateLoading={isSaveLoading}
      updateFailed={updateFailed}
      getReturnPath={getReturnPath}
    >
      <div className="mb-8 space-y-2 font-poppins">
        <div className="mb-4 font-semibold">{t('details', 'Details')}</div>
        <Input
          name="name"
          label={t('name', 'Name')}
          type="text"
          error={Boolean(formState.errors?.name)}
          helpText={formState.errors?.name?.message}
          {...register('name')}
        />
        <GoogleMapsAddressInput
          name="address"
          label={t('address', 'Address')}
          setFormValue={(attr, value) => {
            if (attr === 'address') {
              setValue('address', value, { shouldDirty: true });
            }
            setAddressObj((prev) => ({ ...prev, [attr]: value }));
          }}
          required
          {...addressObj}
          isError={Boolean(formState.errors?.address)}
          helpText={formState.errors?.address?.message}
          clearError={(errorToClear) => clearErrors(errorToClear)}
          setError={(errorToSet, newError) => setError(errorToSet, newError)}
        />
      </div>
      <div className="mb-10 space-y-3 font-poppins">
        <div className="mb-4 font-semibold">{t('pricingModel', 'Pricing model')}</div>
        <ButtonSwitch
          options={[
            { label: t('market', 'Market'), value: true },
            { label: t('fixed', 'Fixed'), value: false },
          ]}
          value={pricingModel.isAutomatic}
          onChange={(value) => handleSetPricingModelValue('isAutomatic', value)}
        />
        {pricingModel.isAutomatic ? (
          <>
            <div className="text-sm leading-6 text-gray-600">
              {t(
                'pricingModelMarketText',
                'Is your electricity charged at market prices? Add your country or region so we can estimate your charge costs accurately.',
              )}
            </div>
            <Autocomplete
              label={t('country', 'Country')}
              options={regionOptions}
              value={pricingModel.region}
              onChange={(region) => handleSetPricingModelValue('region', region || '')}
              isError={Boolean(formState.errors?.pricingModel?.region)}
              helpText={formState.errors?.pricingModel?.region?.message}
            />
          </>
        ) : (
          <>
            <div className="text-sm leading-6 text-gray-600">
              {t(
                'pricingModelFixedText',
                'Is your electric contract is based on fixed rates? Add your rate(s) here so we can estimate your charge costs accurately.',
              )}
            </div>
            <FixedPriceInputs
              name={pricingModel.isSinglePrice ? 'singlePrice' : 'dualPrice'}
              costItemLabel={t('energy', 'Energy')}
              isSinglePrice={pricingModel.isSinglePrice}
              singlePrice={pricingModel.singlePrice}
              dualPrice={pricingModel.dualPrice}
              onIsSinglePriceChange={(value) => handleSetPricingModelValue('isSinglePrice', value)}
              onSinglePriceChange={(value) => handleSetPricingModelValue('singlePrice', value)}
              onDualPriceChange={(value) => handleSetPricingModelValue('dualPrice', value)}
              errors={formState.errors?.pricingModel}
            />
          </>
        )}
      </div>
      <div className="mb-4 font-poppins">
        <FixedPriceInputs
          name={pricingModel.isSingleTransmission ? 'transmissionSinglePrice' : 'transmissionDualPrice'}
          costItemLabel={t('transmission', 'Transmission')}
          isSinglePrice={pricingModel.isSingleTransmission}
          singlePrice={pricingModel.transmissionSinglePrice}
          dualPrice={pricingModel.transmissionDualPrice}
          onIsSinglePriceChange={(value) => handleSetPricingModelValue('isSingleTransmission', value)}
          onSinglePriceChange={(value) => handleSetPricingModelValue('transmissionSinglePrice', value)}
          onDualPriceChange={(value) => handleSetPricingModelValue('transmissionDualPrice', value)}
          errors={formState.errors?.pricingModel}
        />
      </div>
      <Input
        name="extraFees"
        type="number"
        label={`${t('otherFeesAndTaxes', 'Other fees and taxes')} (${t('optional', 'Optional').toLowerCase()})`}
        value={pricingModel.extraFees}
        trailingText={`${t('currencySubunit', '{{currencySubunit}}', {
          currencySubunit: currency.centsName,
        }).toLowerCase()}/kWh`}
        onChange={(e) => handleSetPricingModelValue('extraFees', e.target.value)}
        error={Boolean(formState.errors?.pricingModel?.extraFees)}
        helpText={formState.errors?.pricingModel?.extraFees?.message}
      />
    </EditingSidebarBase>
  );
};

SiteDetailsSidebarBase.propTypes = {
  site: PropTypes.object,
  title: PropTypes.string.isRequired,
  saveLabel: PropTypes.string,
  discardLabel: PropTypes.string,
  isSaveLoading: PropTypes.bool,
  getReturnPath: PropTypes.func,
  onSaveValues: PropTypes.func.isRequired,
};

SiteDetailsSidebarBase.defaultProps = {
  site: {},
  saveLabel: null,
  discardLabel: null,
  isSaveLoading: false,
  getReturnPath: () => null,
};

export default SiteDetailsSidebarBase;
