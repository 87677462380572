import { useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';

import moment from 'moment';
import PropTypes from 'prop-types';

import CardItem from '@components/basicWrappers/CardItem';
import dataParser from '@services/transactions/dataParser';

export const CHART_COLORS = ['#EB4E20', '#386957', '#407090', '#5E5E5E'];

const GRAPH_CATEGORIES = {
  today: 'MMM DD HH:mm',
  week: 'MMM DD HH:mm',
  month: 'MMM DD',
  sixMonths: 'MMM DD',
  year: 'YYYY MMM',
};

const LineChart = ({ noBorder, title, series, isError, isFetching, filter }) => {
  const [loading, setLoading] = useState(!series[0]?.data?.length);

  const filteredSeries = useMemo(
    () =>
      series.map((t, index) => ({
        color: CHART_COLORS[index % CHART_COLORS.length],
        ...t,
        data: dataParser[filter](t.data),
      })),
    [filter, series],
  );

  useEffect(() => {
    setLoading(isFetching);
  }, [isFetching]);

  if (!series) {
    return null;
  }

  return (
    <CardItem
      wrapperClass="w-full"
      className={`
        h-full min-h-[360px] w-full rounded-[16px]
        ${noBorder ? 'border-none' : ''}
     `}
      error={isError}
      loading={loading}
      title={title}
    >
      <div className="w-full">
        <Chart
          type="area"
          width="100%"
          height="300px"
          options={{
            legend: {
              show: true,
              showForSingleSeries: true,
              markers: {
                width: 8,
                height: 8,
                radius: 100,
                offsetX: -5,
              },
              labels: {
                colors: ['#999898'],
              },
            },
            responsive: [
              {
                breakpoint: undefined,
                options: {},
              },
            ],
            tooltip: {
              y: {
                formatter: (value) => `${value || 0}`,
              },
            },
            stroke: {
              show: true,
              lineCap: 'butt',
              width: 1,
              dashArray: 0,
            },
            dataLabels: {
              enabled: false,
            },
            chart: {
              id: 'basic-bar',
              zoom: {
                enabled: false,
              },
              toolbar: {
                show: false,
              },
              animations: {
                enabled: true,
                easing: 'easeout',
                animateGradually: {
                  enabled: false,
                  delay: 150,
                },
                dynamicAnimation: {
                  enabled: true,
                },
              },
            },
            xaxis: {
              tooltip: {
                enabled: true,
              },
              categories: (filteredSeries?.[0]?.data || []).map((t) =>
                moment(t.update_time).format(GRAPH_CATEGORIES[filter]),
              ),
              tickAmount: 4,
              labels: {
                offsetY: -5,
                showDuplicates: false,
                rotate: 0,
                style: {
                  fontSize: 10,
                  fontFamily: 'Roboto, sans-serif',
                  colors: '#999898',
                },
              },
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
            },
            yaxis: [
              {
                seriesName: title,
                showAlways: true,
                decimalsInFloat: 0,
                labels: {
                  formatter: (number) => number.toFixed(),
                  offsetX: 5,
                  rotate: 0,
                  style: {
                    fontSize: 9,
                    fontFamily: 'Roboto, sans-serif',
                    colors: '#999898',
                  },
                },
                axisBorder: {
                  show: false,
                },
                tickAmount: filter === 'year' ? 4 : 3,
              },
            ],
            grid: {
              show: true,
              position: 'back',
              borderColor: '#f4f4f4',
              yaxis: {
                lines: {
                  show: true,
                },
              },
              xaxis: {
                lines: {
                  show: false,
                },
              },
              padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 10,
              },
            },
            fill: {
              type: 'solid',
              opacity: 0,
            },
          }}
          series={filteredSeries.map((t) => ({
            color: t.color,
            name: t.name,
            data: t.data.map((d) => d.length || 0),
          }))}
        />
      </div>
    </CardItem>
  );
};

LineChart.propTypes = {
  title: PropTypes.string,
  noBorder: PropTypes.bool,
  series: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      data: PropTypes.array,
    }),
  ).isRequired,
  isError: PropTypes.bool,
  isFetching: PropTypes.bool,
  filter: PropTypes.string,
};

LineChart.defaultProps = {
  title: null,
  noBorder: false,
  isError: false,
  isFetching: false,
  filter: 'week',
};

export default LineChart;
