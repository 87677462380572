import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useOutletContext } from 'react-router-dom';

import { DeviceStatus } from 'models/device.enums';

import { useUpdateDeviceConfigurationMutation, useUpdateDeviceMutation } from '@services/devices/endpoints';
import { addToastMessage } from '@services/toastMessages';

import { areAllParametersAccepted } from '../deviceConfigurationUtils';
import ChargerSettingsFormSidebar from './ChargerSettingsFormSidebar';
import LmcSettingsFormSidebar from './LmcSettingsFormSidebar';

const DeviceSettingsFormSidebar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { device } = useOutletContext();

  const [updateFailedMessage, setUpdateFailedMessage] = useState(null);

  const [updateDevice, { isLoading: isUpdateDeviceLoading, isError: isUpdateDeviceError }] = useUpdateDeviceMutation();
  const [
    updateDeviceConfiguration,
    { isLoading: isUpdateDeviceConfigurationLoading, isError: isUpdateDeviceConfigurationError },
  ] = useUpdateDeviceConfigurationMutation();

  const isUpdateLoading = isUpdateDeviceLoading || isUpdateDeviceConfigurationLoading;
  const isUpdateError = isUpdateDeviceError || isUpdateDeviceConfigurationError;

  const formOptions = {
    charger: ChargerSettingsFormSidebar,
    lmc: LmcSettingsFormSidebar,
  };
  const FormComponent = formOptions[device?.type] ?? null;

  const saveValues = async ({ deviceUpdateData, configurationUpdateData }) => {
    const promises = [updateDevice(deviceUpdateData)];
    if (configurationUpdateData && device.status !== DeviceStatus.OFFLINE) {
      promises.push(updateDeviceConfiguration(configurationUpdateData));
    }
    const [updateDeviceResponse, updateDeviceConfigurationResponse] = await Promise.all(promises);

    const someParametersWereRejected = !areAllParametersAccepted(
      updateDeviceConfigurationResponse?.data?.variables ?? [],
    );

    const isError =
      updateDeviceResponse.error || updateDeviceConfigurationResponse?.error || someParametersWereRejected;

    if (isError) {
      setUpdateFailedMessage(
        someParametersWereRejected
          ? t('someParameterWasRejectedPleaseTryAgain', 'Some parameters were rejected. Please try again')
          : t('savingFailed', 'Saving failed'),
      );
    }

    const toastMessage = isError
      ? {
          type: 'error',
          title: t('updatingTheDeviceFailed', 'Updating the device {{deviceName}} failed', {
            deviceName: device.name || device.serialNumber,
          }),
          message: t('pleaseTryAgain', 'Please try again.'),
        }
      : {
          type: 'success',
          title: t('deviceUpdated', 'The device {{deviceName}} was updated', {
            deviceName: device.name || device.serialNumber,
          }),
        };

    dispatch(addToastMessage(toastMessage));

    return !isError;
  };

  return (
    FormComponent && (
      <FormComponent
        device={device}
        isUpdateLoading={isUpdateLoading}
        isUpdateError={isUpdateError}
        updateFailedMessage={updateFailedMessage}
        onSaveValues={saveValues}
      />
    )
  );
};

export default DeviceSettingsFormSidebar;
