import { Helmet } from 'react-helmet';

import { tail } from 'lodash';

import { Box, useMediaQuery, useTheme } from '@mui/material';

import { ReactComponent as SidebarToggleIcon } from '@images/icons/nav/Sidebar_toggle.svg';

import { BreadcrumbItem, BreadcrumbItemProps } from './BreadcrumbItem';
import { StyledBreadcrumbs } from './StyledBreadCrumbs';

type BreadcrumbsBaseProps = {
  breadcrumbsItems: BreadcrumbItemProps[];
};

export const BreadcrumbsBase = ({ breadcrumbsItems }: BreadcrumbsBaseProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const pageTitle = (breadcrumbsItems.length > 2 ? tail(breadcrumbsItems) : breadcrumbsItems)
    .map((item) => item.label)
    .join(' ► ');

  const getFormattedBreadcrumbs = (breadcrumbsItems: BreadcrumbItemProps[]) => {
    if ((breadcrumbsItems.length > 2 && isMobile) || breadcrumbsItems.length > 3) {
      const [first, ...rest] = breadcrumbsItems;
      const last = rest.pop() as BreadcrumbItemProps;
      return [first, { label: '...', path: '', options: rest }, last];
    }
    return breadcrumbsItems;
  };
  const formattedBreadcrumbs = getFormattedBreadcrumbs(breadcrumbsItems);

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-start" py={isMobile ? 2 : 3} overflow="hidden">
      <Helmet>
        <title>{pageTitle} - VOOL</title>
      </Helmet>
      <StyledBreadcrumbs separator={<SidebarToggleIcon className="h-5 w-5 rotate-180 object-cover sm:h-8 sm:w-8" />}>
        {formattedBreadcrumbs.map((item, index) => (
          <BreadcrumbItem
            key={item.path}
            color={index !== breadcrumbsItems.length - 1 ? '#5E5E5E' : '#282828'}
            isMobile={isMobile}
            {...item}
          />
        ))}
      </StyledBreadcrumbs>
    </Box>
  );
};
