import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Device } from 'models/device';
import { DeviceType } from 'models/device.enums';
import { Permission } from 'models/permission.enum';

import DeviceOcppSetupInfoModal from '@components/devices/DeviceOcppSetupInfoModal';
import { SettingsSection } from '@components/settings/SettingsSection';
import { SettingsSectionItem } from '@components/settings/SettingsSectionItem';
import { ReactComponent as ArrowLeftIcon } from '@images/icons/arrow_left.svg';
import { selectPermissionByName } from '@services/auth/selectors';
import { useAppSelector } from '@services/hooks';
import { isVoolDevice } from '@views/Sites/SiteDevicesAndGroups/siteDevicesAndGroupsHelpers/siteDevicesUtils';

import { VoolChargerSettings } from './VoolChargerSettings';
import { VoolLmcSettings } from './VoolLmcSettings';

export type DeviceSettingsProps = {
  device: Device;
  isLoading: boolean;
};

export const DeviceSettings = ({ device, isLoading }: DeviceSettingsProps) => {
  const { t } = useTranslation();

  const canEditDevices = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_EDIT_DEVICES));

  const [ocppSetupModalVisible, setOcppSetupModalVisible] = useState(false);

  const isCharger = device.type === DeviceType.CHARGER;
  const isLmc = device.type === DeviceType.LMC;

  return (
    <>
      <SettingsSection title={t('settings', 'Settings')} editPath={canEditDevices ? './settings' : undefined}>
        <SettingsSectionItem
          label={t('deviceName', 'Device name')}
          component={() => device.name || device.serialNumber}
        />
        <SettingsSectionItem
          label={isCharger ? t('currentLimit', 'Current limit') : t('gridConnection', 'Grid connection')}
          component={() => device.gridConnection && `${device.gridConnection} A`}
        />
        {isVoolDevice(device) && isCharger && <VoolChargerSettings device={device} isLoading={isLoading} />}
        {isVoolDevice(device) && isLmc && <VoolLmcSettings device={device} isLoading={isLoading} />}
        <SettingsSectionItem
          label={t('ocppHost', 'OCPP host')}
          component={() => <ArrowLeftIcon className="rotate-180 [&_path]:fill-gray-400" />}
          hidden={!device.ocppPassword}
          onClick={() => setOcppSetupModalVisible(true)}
        />
        <SettingsSectionItem
          label={t('ocppSource', 'OCPP source')}
          component={() => (device.communicatesViaLmc ? t('lmc', 'LMC') : t('internal', 'Internal'))}
          hidden={!device.lmcMultipointUuid}
        />
      </SettingsSection>
      <DeviceOcppSetupInfoModal
        device={device}
        open={ocppSetupModalVisible}
        closeModal={() => setOcppSetupModalVisible(false)}
      />
    </>
  );
};
