import { Outlet, useParams } from 'react-router-dom';

import { DevicesAndGroups } from '@views/Sites/SiteDevicesAndGroups/DevicesAndGroups';

export const PartnerWorkspaceDevicesView = () => {
  const { companyUuid } = useParams();

  return (
    <>
      <DevicesAndGroups companyUuid={companyUuid} />
      <Outlet />
    </>
  );
};
