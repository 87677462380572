import { Box, Divider, useMediaQuery, useTheme } from '@mui/material';

import { Breadcrumbs } from '@components/breadcrumbs';
import { ReactComponent as VoolLogo } from '@images/icons/nav/Vool_logo.svg';

import { UserHeaderItems } from './UserProfile/UserHeaderItems';

export const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box height={isMobile ? 112 : 80}>
      <Box display="flex" flexDirection="column" position="absolute" top={0} left={0} width="100%">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={1.5}
          sx={
            isMobile
              ? {
                  bgcolor: '#FFFFFF',
                  px: 2,
                  py: 1.5,
                }
              : {
                  px: 5,
                }
          }
        >
          {isMobile ? <VoolLogo /> : <Breadcrumbs />}
          <UserHeaderItems />
        </Box>
        {isMobile && (
          <Box px={2}>
            <Breadcrumbs />
          </Box>
        )}
        <Divider />
      </Box>
    </Box>
  );
};
