import { useTranslation } from 'react-i18next';

import { Site } from 'models/site';
import { ChargingMode } from 'models/site.enum';

import useActiveCurrency from '@hooks/useActiveCurrency';

export const useGetChargingRulesLabelsForSite = () => {
  const { t } = useTranslation();
  const getChargingRulesLabelsForSite = (site?: Site, { alwaysIncludeTiming } = { alwaysIncludeTiming: false }) => {
    const chargingMode = site?.settings?.chargingMode ?? ChargingMode.MANUAL;

    const chargingModeLabel = {
      manual: t('manual', 'Manual'),
      smartCharging: t('smartCharging', 'Smart charging'),
    }[chargingMode];

    const getChargingTimingLabel = () => {
      const isSmartCharging = chargingMode === ChargingMode.SMART_CHARGING;
      if (isSmartCharging) {
        return t('readyBy', 'Ready by {{time}}', { time: site?.settings?.smartCharging?.scheduledEndTime });
      }
      if (alwaysIncludeTiming) {
        return t('24hEveryDay', '24h, every day');
      }
      return undefined;
    };

    return { chargingModeLabel, chargingTimingLabel: getChargingTimingLabel() };
  };

  return getChargingRulesLabelsForSite;
};

export const useGetRevenueModeLabelForSite = () => {
  const { t } = useTranslation();
  const currency = useActiveCurrency();
  const getRevenueModelLabelForSite = (site?: Site) => {
    const revenuePricingModel = site?.revenuePricingModel;
    if (revenuePricingModel?.setting === 'market' && revenuePricingModel?.margin) {
      return `${t('market', 'Market')} + ${(revenuePricingModel.margin * 100).toFixed(2)} ${t(
        'currencySubunit',
        '{{currencySubunit}}',
        { currencySubunit: currency.centsName },
      ).toLowerCase()}/kWh`;
    }
    if (revenuePricingModel?.setting === 'fixed' && revenuePricingModel?.fixed) {
      return `${t('fixed', 'Fixed')}: ${(revenuePricingModel.fixed * 100).toFixed(2)} ${t(
        'currencySubunit',
        '{{currencySubunit}}',
        { currencySubunit: currency.centsName },
      ).toLowerCase()}/kWh`;
    }
    return undefined;
  };

  return getRevenueModelLabelForSite;
};
