import { createSlice } from '@reduxjs/toolkit';

import api from '@services/api';

const setCountries = (state, { payload }) => {
  state.countries = payload.data;
};

const initialState = {
  countries: [],
};

export const dataSlice = createSlice({
  name: 'countries',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.getCountries.matchFulfilled, setCountries);
  },
});

export default dataSlice.reducer;
