import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import AppleAuth from '@components/apple/AppleAuth';
import Button from '@components/atoms/Button';
import GoogleAuth from '@components/google/GoogleAuth';
import { DEFAULT_PATH } from '@routesConfig/routesConst';

import { useInviteTokenFromQueryParam } from './inviteTokenHook';

export const Welcome = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { inviteData, outcomeComponent: OutcomeComponent, inviteHeader: InviteHeader } = useInviteTokenFromQueryParam();
  if (OutcomeComponent) {
    return <OutcomeComponent />;
  }

  const handleAuthSuccess = () => {
    if (!inviteData) {
      navigate(DEFAULT_PATH);
    }
  };

  return (
    <>
      <div className="w-11/12 max-w-md space-y-2 rounded-lg bg-white p-9 text-left font-poppins font-normal text-black md:w-full">
        {InviteHeader ? (
          <InviteHeader />
        ) : (
          <>
            <div className="font-everett text-3xl">{t('hiThere!', 'Hi there!')}</div>
            <h2 className="pb-4 text-base">
              {t('letsGetChargingText', 'Let’s get charging — just log in.')} <br />
              {t('firstTimeHereText', 'First time here? Choose your preferred registration method.')}
            </h2>
          </>
        )}
        <div className="w-full space-y-4">
          {!inviteData && (
            <Button variant="primary" bigger className="w-full" onClick={() => navigate('/login')}>
              {t('continueViaEmail', 'Continue via Email')}
            </Button>
          )}
          <GoogleAuth inviteData={inviteData} onSuccess={handleAuthSuccess} />
          {!inviteData?.googleVerified && <AppleAuth inviteData={inviteData} onSuccess={handleAuthSuccess} />}
        </div>
      </div>
    </>
  );
};
