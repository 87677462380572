import { rangeRight, sumBy } from 'lodash';
import moment from 'moment';

const setDayData = (data) => {
  const hoursToGet = 24;
  const hours = rangeRight(hoursToGet).map((hoursToSubtract) => moment().subtract(hoursToSubtract, 'hours').format());

  if (!data.length) {
    return hours.map((wd) => ({
      update_time: wd,
      value: 0,
      cost: 0,
      filler: true,
      length: 0,
    }));
  }

  const sortedData = data
    .filter((s) => !!s.startTime)
    .sort((a, b) => new Date(a.endTime || new Date()) - new Date(b.endTime || new Date()));

  const filledHours = hours.map((wd) => {
    const availableData = sortedData.filter(
      (sd) => moment(sd.endTime || undefined).format('l HH') === moment(wd).format('l HH'),
    );

    if (availableData.length) {
      return {
        update_time: wd,
        value: sumBy(availableData, (adi) => adi.amount),
        cost: sumBy(availableData, (adi) => adi.monetaryCost),
        length: availableData.length,
      };
    }

    return {
      update_time: wd,
      value: 0,
      cost: 0,
      filler: true,
      length: 0,
    };
  });

  return filledHours.flat();
};

const setWeekData = (data) => {
  const daysToGet = 7;
  const weekDays = new Array(daysToGet).fill(undefined).map((val, idx) => {
    const daysToSubtract = Math.abs(idx + 1 - daysToGet);
    return moment().subtract(daysToSubtract, 'days').format();
  });

  if (!data.length) {
    return weekDays.map((wd) => ({
      update_time: wd,
      value: 0,
      cost: 0,
      filler: true,
      length: 0,
    }));
  }

  const sortedData = data
    .filter((s) => !!s.startTime)
    .sort((a, b) => new Date(a.endTime || new Date()) - new Date(b.endTime || new Date()));

  const filledWeekdays = weekDays.map((wd) => {
    const availableData = sortedData.filter(
      (sd) => moment(sd.endTime || undefined).format('l') === moment(wd).format('l'),
    );

    if (availableData.length) {
      return {
        update_time: wd,
        value: sumBy(availableData, (adi) => adi.amount),
        cost: sumBy(availableData, (adi) => adi.monetaryCost),
        length: availableData.length,
      };
    }

    return {
      update_time: wd,
      value: 0,
      cost: 0,
      filler: true,
      length: 0,
    };
  });

  return filledWeekdays.flat();
};

const setMonthData = (data) => {
  const daysToGet = 31;
  const weekDays = new Array(daysToGet).fill(undefined).map((val, idx) => {
    const daysToSubtract = Math.abs(idx + 1 - daysToGet);
    return moment().subtract(daysToSubtract, 'days').format();
  });

  if (!data.length) {
    return weekDays.map((wd) => ({
      update_time: wd,
      value: 0,
      cost: 0,
      filler: true,
      length: 0,
    }));
  }

  const sortedData = data
    .filter((s) => !!s.startTime)
    .sort((a, b) => new Date(a.endTime || new Date()) - new Date(b.endTime || new Date()));

  const filledWeekdays = weekDays.map((wd) => {
    const availableData = sortedData.filter(
      (sd) => moment(sd.endTime || undefined).format('l') === moment(wd).format('l'),
    );

    if (availableData.length) {
      return {
        update_time: wd,
        value: sumBy(availableData, (adi) => adi.amount),
        cost: sumBy(availableData, (adi) => adi.monetaryCost),
        length: availableData.length,
      };
    }

    return {
      update_time: wd,
      value: 0,
      cost: 0,
      filler: true,
      length: 0,
    };
  });

  return filledWeekdays.flat();
};

const setSixMonthData = (data) => {
  const monthsToGet = 7;
  const months = new Array(monthsToGet).fill(undefined).map((val, idx) => {
    const daysToSubtract = Math.abs(idx + 1 - monthsToGet);
    return moment().subtract(daysToSubtract, 'months').format();
  });

  if (!data.length) {
    return months.map((wd) => ({
      update_time: wd,
      value: 0,
      cost: 0,
      filler: true,
      length: 0,
    }));
  }

  const sortedData = data
    .filter((s) => !!s.startTime)
    .sort((a, b) => new Date(a.endTime || new Date()) - new Date(b.endTime || new Date()));

  const filledMonths = months.map((m) => {
    const availableData = sortedData.filter(
      (sd) => moment(sd.endTime || undefined).format('YYYY/M') === moment(m).format('YYYY/M'),
    );

    if (availableData.length) {
      return {
        update_time: m,
        value: sumBy(availableData, (adi) => adi.amount),
        cost: sumBy(availableData, (adi) => adi.monetaryCost),
        length: availableData.length,
      };
    }

    return {
      update_time: m,
      value: 0,
      cost: 0,
      filler: true,
      length: 0,
    };
  });

  return filledMonths.flat();
};

const setYearData = (data) => {
  const monthsToGet = 13;
  const months = new Array(monthsToGet).fill(undefined).map((val, idx) => {
    const monthsToSubtract = Math.abs(idx + 1 - monthsToGet);

    return moment().subtract(monthsToSubtract, 'months').format();
  });

  if (!data.length) {
    return months.map((m) => ({
      update_time: m,
      value: 0,
      cost: 0,
      filler: true,
      length: 0,
    }));
  }

  const sortedData = data
    .filter((s) => !!s.startTime)
    .sort((a, b) => new Date(a.endTime || new Date()) - new Date(b.endTime || new Date()));

  const filledMonths = months.map((m) => {
    const availableData = sortedData.filter(
      (sd) => moment(sd.endTime || undefined).format('YYYY/M') === moment(m).format('YYYY/M'),
    );

    if (availableData.length) {
      return {
        update_time: m,
        value: sumBy(availableData, (adi) => adi.amount),
        cost: sumBy(availableData, (adi) => adi.monetaryCost),
        length: availableData.length,
      };
    }

    return {
      update_time: m,
      value: 0,
      cost: 0,
      filler: true,
      length: 0,
    };
  });

  return filledMonths.flat();
};

export default {
  today: setDayData,
  week: setWeekData,
  month: setMonthData,
  sixMonths: setSixMonthData,
  year: setYearData,
};
