import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { t } from 'i18next';
import { Device } from 'models/device';
import { Site } from 'models/site';

import { Autocomplete, Box, Typography, createFilterOptions } from '@mui/material';

import { ReactComponent as VoolChargerIcon } from '@images/icons/reporting/vool-charger.svg';
import { selectChargersBySiteUuid } from '@services/devices/selectors';
import { useAppSelector } from '@services/hooks';
import { GroupMemberChip } from '@views/Workspace/sidebars/groups/GroupMemberChip';

import { NonVoolChargerIcon } from './NonVoolChargerIcon';
import { StyledMenuItem } from './StyledMenuItem';
import { StyledTextField } from './StyledTextField';

export type ChargerOption = {
  label: string;
  uuid: string;
  serialNumber?: string;
  name?: string;
  Icon: React.FC;
  message?: string;
};

type ChargersAutocompleteProps<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  disabled?: boolean;
};

export const makeChargerOption = (device: Device): ChargerOption => {
  return {
    label: device.name || device.serialNumber || t('unknown', 'Unknown'),
    name: device.name,
    uuid: device.uuid,
    Icon: device.voolDeviceFlag ? VoolChargerIcon : NonVoolChargerIcon,
  };
};

export const ChargerAutocomplete = <T extends FieldValues>({
  control,
  name,
  disabled,
}: ChargersAutocompleteProps<T>) => {
  const { t } = useTranslation();
  const { site } = useOutletContext<{ site: Site }>();
  const chargers = useAppSelector((state) => selectChargersBySiteUuid(state, site.uuid));
  const options = chargers.map(makeChargerOption);
  const selected = chargers.filter((device) => device.publicFlag).map(makeChargerOption);
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          color={disabled ? 'gray' : 'black'}
          multiple
          disabled={disabled}
          options={options}
          value={field.value ?? []}
          defaultValue={selected}
          renderTags={(value, getTagProps) =>
            value.map((option, index: number) => (
              <GroupMemberChip label={option.label} {...getTagProps({ index })} key={index} />
            ))
          }
          renderInput={(params) => (
            <StyledTextField
              {...params}
              label={<Typography variant="p14">{t('serialNumberOrName', 'Serial number or name')}</Typography>}
              autoComplete="off"
              error={Boolean(error)}
              helperText={error?.message}
              InputProps={{
                ...params.InputProps,
                style: { padding: '5px 8px' },
              }}
              sx={{
                '& .MuiInputLabel-root': {
                  transform: 'translate(14px, 12px)',
                },
                '& .MuiOutlinedInput-root': {
                  height: 'auto',
                },
              }}
            />
          )}
          renderOption={(props, device, { selected }) => {
            return (
              <Box {...props} key={device.uuid} component="li">
                <StyledMenuItem checked={selected} {...device} />
              </Box>
            );
          }}
          isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
          limitTags={2}
          disableClearable
          disableCloseOnSelect
          forcePopupIcon={false}
          filterOptions={createFilterOptions({
            stringify: (option: ChargerOption) => `${option.label || ''} ${option.serialNumber || ''}`,
          })}
          onKeyDown={(event) => {
            if (event.key === ' ' || event.key === ',') {
              event.preventDefault();
              const enterEvent = new KeyboardEvent('keydown', { key: 'Enter', bubbles: true });
              const target = event.target as HTMLInputElement;
              target.dispatchEvent(enterEvent);
            }
          }}
          onChange={(event, values, reason) => {
            if (reason === 'blur' && typeof values[values.length - 1] === 'object') return;
            const publicChargers = values.filter((value) => typeof value !== 'string') as ChargerOption[];
            field.onChange?.(publicChargers);
          }}
        />
      )}
    />
  );
};
