import { useTranslation } from 'react-i18next';

import { Permission } from 'models/permission.enum';
import { Site } from 'models/site';

import { Typography } from '@mui/material';

import { isSubdomainPartner } from '@routesConfig/routesUtil';
import { selectPermissionByName } from '@services/auth/selectors';
import { selectChargersBySiteUuid } from '@services/devices/selectors';
import { useAppSelector } from '@services/hooks';
import { useGetRevenueModeLabelForSite } from '@views/Sites/siteLabelUtils';

import { EditableDetailCardBase } from './EditableDetailCardBase';

type SiteAvailabilityCardProps = {
  site?: Site;
};

export const SiteAvailabilityCard = ({ site }: SiteAvailabilityCardProps) => {
  const { t } = useTranslation();
  const canEditSites = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_EDIT_SITES));
  const getRevenueModeLabelForSite = useGetRevenueModeLabelForSite();
  const revenueModeLabel = getRevenueModeLabelForSite(site);
  const chargers = useAppSelector((state) => selectChargersBySiteUuid(state, site?.uuid));

  const publicChargersLabel = chargers
    .filter((charger) => charger.publicFlag)
    .map((charger) => charger.name || charger.serialNumber || t('unknown', 'Unknown'))
    .join(', ');

  return (
    <EditableDetailCardBase
      title={t('availability', 'Availability')}
      editingPath="availability"
      disabled={!canEditSites || isSubdomainPartner()}
    >
      <Typography variant="p16">
        {site?.publicFlag ? (
          <>
            {t('public', 'Public')}
            <br />
            {revenueModeLabel}
            <br />
            <Typography variant="p14">{publicChargersLabel}</Typography>
          </>
        ) : (
          <>{t('private', 'Private')}</>
        )}
      </Typography>
    </EditableDetailCardBase>
  );
};
