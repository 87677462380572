import { useTranslation } from 'react-i18next';

import { User } from 'models/user';
import moment from 'moment';

import { Box, BoxProps, Typography } from '@mui/material';

type MemberInfoProps = BoxProps & {
  member: Pick<User, 'email' | 'name' | 'inviteFlag' | 'inviteTime'>;
};

const MemberInfo = ({ member, ...props }: MemberInfoProps) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" {...props}>
      <Typography variant="p14b">{member.inviteFlag ? member.email : member.name}</Typography>
      <Typography variant="p14" color="#5E5E5E">
        {member.inviteFlag
          ? `${t('invited', 'Invited')} ${!!member.inviteTime && moment(member.inviteTime).format('DD.MM.YYYY')}`
          : member.email}
      </Typography>
    </Box>
  );
};

export default MemberInfo;
