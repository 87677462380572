import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Event } from 'models/event-log';

import { eventLogApi } from './endpoints';

type EventLogState = {
  eventLog: Event[];
};

const initialState: EventLogState = {
  eventLog: [],
};

const setEventLog = (state: EventLogState, { payload }: PayloadAction<{ data: Event[] }>) => {
  state.eventLog = payload.data;
};

export const dataSlice = createSlice({
  name: 'eventLog',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(eventLogApi.endpoints.eventLog.matchFulfilled, setEventLog);
  },
});

export default dataSlice.reducer;
