import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';

import { Box, Drawer, IconButton, InputAdornment, Typography } from '@mui/material';

import { StyledMenuItem, StyledMenuItemProps } from '@components/atoms/StyledMenuItem';
import { StyledTextField } from '@components/atoms/StyledTextField';
import { FilterMenuOption } from '@components/filters';
import { useFilterMenu } from '@components/filters/hooks/useFilterMenu';
import { ReactComponent as ArrowLeftIcon } from '@images/icons/arrow_left.svg';
import { ReactComponent as SearchIcon } from '@images/icons/reporting/search.svg';

import { ReportScheduleContext } from './context/ReportScheduleProvider';
import { ReportingTransactionsFilterMenuOption } from './context/ReportingTransactionsProvider';

export const ReportScheduleFilterSidebar = () => {
  const navigate = useNavigate();
  const { filter: selectedFilter = '' } = useParams();
  const capitalizedFilter = selectedFilter.charAt(0).toUpperCase() + selectedFilter.slice(1);
  const { filterOptionsMap, filter, setFilter } = useContext(ReportScheduleContext);
  const options = filterOptionsMap[selectedFilter as ReportingTransactionsFilterMenuOption];
  const { filterLabel } = useFilterMenu(filterOptionsMap);
  const filterMenuOptions = (options: StyledMenuItemProps[]) => {
    return options?.filter((option) => option.label?.toLowerCase().includes(filter.toLowerCase()));
  };

  const [filteredMenuOptions, setFilteredMenuOptions] = useState(filterMenuOptions(options));

  useEffect(() => {
    setFilteredMenuOptions(filterMenuOptions(options));
  }, [filter, options]);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => setIsOpen(true), []); // slide-in transition

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleClose = () => {
    setIsOpen(false);
    setFilter('');
    timeoutRef.current = setTimeout(() => {
      navigate('../', { replace: true });
    }, 500);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      elevation={0}
      onClose={handleClose}
      slotProps={{ backdrop: { style: { background: 'transparent' } } }}
    >
      <Box display="flex" flexDirection="column" height={1} width={397} p={3} gap={4}>
        <Box display="flex" alignItems="center" gap={2}>
          <IconButton onClick={handleClose} sx={{ color: '#282828' }}>
            <ArrowLeftIcon />
          </IconButton>
          <Typography variant="h3" flex={1}>
            {capitalizedFilter}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={2} height={1}>
          <StyledTextField
            name="vool-filter"
            autoComplete="off"
            placeholder={filterLabel[selectedFilter as FilterMenuOption]}
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ color: '#282828', cursor: 'default' }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Box display="flex" flexDirection="column" height={1}>
            {filteredMenuOptions.length < 30 ? (
              filteredMenuOptions?.map((option) => <StyledMenuItem key={option.uuid} {...option} />)
            ) : (
              <Virtuoso
                style={{ height: '100%' }}
                totalCount={filteredMenuOptions.length}
                itemContent={(index) => (
                  <StyledMenuItem key={filteredMenuOptions[index].uuid} {...filteredMenuOptions[index]} />
                )}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};
