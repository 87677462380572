import { updateDeviceFromPusher } from '@services/devices/actions';

import DataHandler from '../data';
import { ACTIONS_TYPES } from '../pusherData/constHandlersPushers';

export default class PusherDevicesDataHandler {
  data;

  meta;

  action;

  object;

  constructor({ data, meta, object }) {
    this.action = meta.action;
    this.object = object;
    this.data = data;
    this.meta = meta;
  }

  getActions() {
    const actionType = ACTIONS_TYPES(this.object)[this.action];
    if (actionType) {
      return this[actionType]();
    }
    return null;
  }

  updateDevice() {
    const { connectorId, partial } = this.meta;
    const dataFromPusher = this.data.current;
    let device = {};
    if (partial === false) {
      device = dataFromPusher;
    } else {
      device = {
        [this.meta.fieldName]: this.meta.fieldValue,
        visibility: this.meta.visibility,
        ...(connectorId
          ? {
              stateOfConnector: {
                ...dataFromPusher,
                connectorId,
              },
            }
          : dataFromPusher),
      };
    }
    const cleanDeviceData = DataHandler.cleanDataObject(device);
    return [updateDeviceFromPusher({ device: cleanDeviceData })];
  }
}
