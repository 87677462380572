import { Dispatch, SetStateAction } from 'react';

import dayjs, { Dayjs } from 'dayjs';

import { StyledMenuItemProps } from '@components/atoms/StyledMenuItem';
import { ColumnFilter, Order, PagedDataQuery } from '@services/reporting/endpoints';

import { BuildQueryOptions, FilterMenuOption } from '..';

export type FiltersBaseContextType<ExtractedType extends FilterMenuOption> = {
  filter: string;
  setFilter: Dispatch<SetStateAction<string>>;
  startDate: Dayjs;
  setStartDate: Dispatch<SetStateAction<Dayjs>>;
  endDate?: Dayjs;
  setEndDate: Dispatch<SetStateAction<Dayjs | undefined>>;
  anyActiveFilters: boolean;
  activeFiltersLength: number;
  filterOptionsMap: Pick<Record<ExtractedType, StyledMenuItemProps[]>, ExtractedType>;
  optionChecked: (option: StyledMenuItemProps) => boolean;
  reset: (filter?: FilterMenuOption) => void;
  filterInclusivityMap: Pick<Record<ExtractedType, boolean>, ExtractedType>;
  setFilterInclusive: (filter: FilterMenuOption, inclusive: boolean) => void;
  setFilterInclusivityMap: Dispatch<SetStateAction<Record<FilterMenuOption, boolean>>>;
};

export type FiltersDataContextType<ContextDataType> = {
  data: ContextDataType[];
  dataLoading: boolean;
  loadMore: () => void;
  order: Order;
  orderBy: keyof ContextDataType;
  handleRequestSort: (event: React.MouseEvent<unknown>, property: keyof ContextDataType) => void;
  buildQuery: (options?: BuildQueryOptions) => PagedDataQuery<ContextDataType>;
  buildColumnFilters: () => ColumnFilter<ContextDataType>[];
};

export const getFiltersBaseContextDefaultValues = <ContextDataType,>(): Omit<
  FiltersBaseContextType<FilterMenuOption>,
  'filterOptionsMap'
> &
  FiltersDataContextType<ContextDataType> => ({
  filter: '',
  setFilter: () => '',
  startDate: dayjs().startOf('day'),
  setStartDate: () => {},
  setEndDate: () => {},
  anyActiveFilters: false,
  activeFiltersLength: 0,
  optionChecked: () => false,
  reset: () => {},
  filterInclusivityMap: {
    workspace: true,
    site: true,
    charger: true,
    vehicle: true,
    user: true,
    rfid: true,
    type: true,
    category: true,
  },
  setFilterInclusive: () => {},
  data: [],
  dataLoading: false,
  loadMore: () => {},
  order: 'desc',
  orderBy: '' as keyof ContextDataType,
  handleRequestSort: () => {},
  buildQuery: () => ({} as PagedDataQuery<any>),
  buildColumnFilters: () => [],
  setFilterInclusivityMap: () => {},
});
