import { useTranslation } from 'react-i18next';

import { GroupDto } from 'models/group.dto';

import { useCreateGroupMutation } from '@services/groups/endpoints';
import { useAppDispatch } from '@services/hooks';
import { addToastMessage } from '@services/toastMessages';

import { GroupSidebarBase } from './GroupSidebarBase';

export const CreateGroupSidebar = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [createGroup, { isLoading: isSaving }] = useCreateGroupMutation();

  const handleSave = async (group: GroupDto) => {
    try {
      await createGroup(group).unwrap();
    } catch {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('creatingTheGroupFailed', 'Creating the group failed'),
          message: t('pleaseTryAgain', 'Please try again.'),
        }),
      );
      return false;
    }

    dispatch(
      addToastMessage({
        type: 'success',
        title: t('groupCreated', 'Group created'),
        message: t('youCanSeeTheGroupInYourGroupsList', 'You can see the group in your groups list.'),
      }),
    );

    return true;
  };

  return <GroupSidebarBase mode="create" isSaving={isSaving} handleSave={handleSave} />;
};
