import { useTranslation } from 'react-i18next';

import { compact } from 'lodash';
import { Device } from 'models/device';
import { ControlChannel, Network } from 'models/device.enums';

import { DEVICE_CONFIGURATION_PARAMETERS } from '@handlers/device/deviceConst';
import { useUpdateDeviceConfigurationMutation } from '@services/devices/endpoints';
import { useAppDispatch } from '@services/hooks';
import { addToastMessage } from '@services/toastMessages';

import { areAllParametersAccepted, isChargerSupportingConnectivityConf } from '../deviceConfigurationUtils';

type DeviceConfigItem = { key: string; value: string | number | string[] };

export type ChargerConnectivityValues = {
  wifiEnabled: boolean;
  wifiSsid: string;
  password: string;
  cellularEnabled: boolean;
  controlChannels: ControlChannel[];
};

export const useSaveDeviceConfiguration = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [updateDeviceConfiguration, { isLoading, isError }] = useUpdateDeviceConfigurationMutation();

  const saveDeviceConfiguration = async (device: Device, deviceConfig: DeviceConfigItem[]) => {
    try {
      const response = (await updateDeviceConfiguration({
        uuid: device.uuid,
        deviceConfig,
      }).unwrap()) as { data: { variables: any[] } };

      if (!areAllParametersAccepted(response?.data?.variables ?? [])) {
        throw Error('Not all parameters accepted');
      }

      dispatch(
        addToastMessage({
          type: 'success',
          title: t('deviceUpdated', 'The device {{deviceName}} was updated', {
            deviceName: device.name || device.serialNumber,
          }),
        }),
      );

      return true;
    } catch (error) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('updatingTheDeviceFailed', 'Updating the device {{deviceName}} failed', {
            deviceName: device.name || device.serialNumber,
          }),
          message: t('pleaseTryAgain', 'Please try again.'),
        }),
      );
      return false;
    }
  };

  return [saveDeviceConfiguration, { isLoading, isError }] as const;
};

export const useSaveChargerConnectivity = () => {
  const { WIFI_SSID, WIFI_PASSWORD, ENABLED_NETWORKS, ENABLED_CONTROL_CHANNELS } = DEVICE_CONFIGURATION_PARAMETERS;

  const [saveDeviceConfiguration, { isLoading, isError }] = useSaveDeviceConfiguration();

  const saveChargerConnectivity = async (
    device: Device,
    { wifiEnabled, wifiSsid, password, cellularEnabled, controlChannels }: ChargerConnectivityValues,
  ) => {
    const deviceConfiguration = device.configuration ?? {};
    const chargerConnectivityConfig: DeviceConfigItem[] = compact([
      wifiEnabled && wifiSsid !== deviceConfiguration[WIFI_SSID.key]?.value && { key: WIFI_SSID.key, value: wifiSsid },
      wifiEnabled && password && { key: WIFI_PASSWORD.key, value: password },
      isChargerSupportingConnectivityConf(device) && {
        key: ENABLED_NETWORKS.key,
        value: compact([Network.ETHERNET, wifiEnabled && Network.WIFI, cellularEnabled && Network.GSM]),
      },
      { key: ENABLED_CONTROL_CHANNELS.key, value: controlChannels },
    ]);

    return saveDeviceConfiguration(device, chargerConnectivityConfig);
  };

  return [saveChargerConnectivity, { isLoading, isError }] as const;
};
