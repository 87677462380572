import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import { Dialog, Transition } from '@headlessui/react';

import Button from '@components/atoms/Button';
import ToggleSwitch from '@components/buttons/ToggleSwitch';
import copyContent from '@helpers/copyContent';
import clipboardIcon from '@images/icons/Clipboard.svg';
import clipboardCopiedIcon from '@images/icons/ClipboardCopied.svg';
import { ReactComponent as CloseIcon } from '@images/icons/Close.svg';

const OCPP_SERVER_URL = JSON.parse(process.env.OCPP_SERVER_URL)[process.env.REACT_APP_CONF_FILE];

const CopyableCard = ({ title, subtitle, value, copied, onCopied }) => (
  <Button
    variant="secondary"
    className="group relative block w-full rounded-lg border border-gray-100 bg-transparent !px-4 text-left !text-base hover:bg-gray-100"
    onClick={() => copyContent({ text: value, afterCopy: () => onCopied(value) })}
  >
    <img
      src={copied ? clipboardCopiedIcon : clipboardIcon}
      alt="Copy"
      className="invisible absolute right-4 top-1/2 h-6 w-6 -translate-y-1/2 group-hover:visible"
    />
    {title} <span className="text-xs font-normal text-gray-600">{subtitle}</span>
    <br />
    <span className="font-normal">{value}</span>
  </Button>
);

CopyableCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  value: PropTypes.string,
  copied: PropTypes.bool,
  onCopied: PropTypes.func,
};

CopyableCard.defaultProps = {
  title: '',
  subtitle: '',
  value: '',
  copied: false,
  onCopied: () => {},
};

const DeviceOcppSetupInfoModal = ({ open, closeModal, device, isDeviceAdded }) => {
  const { t } = useTranslation();
  const [includeCredentialsInUrl, setIncludeCredentialsInUrl] = useState(false);
  const [copiedValue, setCopiedValue] = useState(null);
  const serverUrl = OCPP_SERVER_URL + (includeCredentialsInUrl ? `${device.serialNumber}/${device.ocppPassword}/` : '');

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={closeModal}>
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 transition-opacity" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block transform overflow-hidden rounded-2xl bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
              <Dialog.Title as="h3" className="border-b border-gray-100 px-6 py-4 font-everett text-2xl">
                <button
                  type="button"
                  className="float-right -mr-4 -mt-2 flex items-center justify-center p-3"
                  onClick={closeModal}
                >
                  <CloseIcon />
                </button>
                {isDeviceAdded ? t('ocppDeviceAdded', 'OCPP device added') : t('ocppDeviceSetup', 'OCPP device setup')}
              </Dialog.Title>
              <div className="space-y-6 p-6 font-poppins text-base">
                {isDeviceAdded && (
                  <p>
                    <strong>{device.name || device.serialNumber}</strong>{' '}
                    {t('ocppDeviceAddedText', 'has been added and is awaiting connection.')}
                  </p>
                )}
                <p className="text-gray-600">
                  {t(
                    'deviceOcppConfigText',
                    'To use your device with VOOL, use the following OCPP configuration in its settings.',
                  )}
                </p>
                <div className="flex items-center justify-between">
                  <span>{t('includeIdAndPasswordInServerUrl', 'Include ID and password in Server URL')}</span>
                  <ToggleSwitch checked={includeCredentialsInUrl} onChange={setIncludeCredentialsInUrl} />
                </div>
                <CopyableCard
                  title={t('server', 'Server')}
                  value={serverUrl}
                  copied={copiedValue === serverUrl}
                  onCopied={setCopiedValue}
                />
                <CopyableCard
                  title={t('id', 'ID')}
                  subtitle={includeCredentialsInUrl ? `(${t('optional', 'Optional').toLowerCase()})` : ''}
                  value={device.serialNumber}
                  copied={copiedValue === device.serialNumber}
                  onCopied={setCopiedValue}
                />
                <CopyableCard
                  title={t('password', 'Password')}
                  subtitle={includeCredentialsInUrl ? `(${t('optional', 'Optional').toLowerCase()})` : ''}
                  value={device.ocppPassword}
                  copied={copiedValue === device.ocppPassword}
                  onCopied={setCopiedValue}
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

DeviceOcppSetupInfoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  device: PropTypes.object.isRequired,
  isDeviceAdded: PropTypes.bool,
};

DeviceOcppSetupInfoModal.defaultProps = {
  isDeviceAdded: false,
};

export default DeviceOcppSetupInfoModal;
