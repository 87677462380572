import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CompanyWithUser } from 'models/company';

import { TableCell, Typography } from '@mui/material';

import { ColumnData, MaterialTable } from '@components/atoms/MaterialTable';
import { StyledTableRow } from '@components/atoms/StyledTableRow';
import { useGetPartnerCompaniesQuery } from '@services/partners/endpoints';

import { PartnersWorkspacesTableRowSkeleton, PartnersWorkspacesTableSkeleton } from './PartnersWorkspacesTableSkeleton';

type PartnerColumnData = ColumnData<CompanyWithUser>;
type RenderRowsProps = {
  rows: CompanyWithUser[];
  columns: PartnerColumnData[];
  selectedCompany?: CompanyWithUser;
};

type TableRowProps = Omit<RenderRowsProps, 'rows'> & {
  row: CompanyWithUser;
};

const useColumns = () => {
  const { t } = useTranslation();

  const columns: PartnerColumnData[] = [
    {
      width: 380,
      label: t('workspace', 'Workspace'),
      dataKey: 'name',
      sortable: true,
    },
  ];

  return columns;
};

const TableRow = ({ row, columns, selectedCompany }: TableRowProps) => {
  const navigate = useNavigate();
  return (
    <StyledTableRow
      key={row.uuid}
      onClick={() => navigate(row.uuid)}
      sx={{
        bgcolor: selectedCompany?.uuid === row.uuid ? '#F4F4F4' : 'background.paper',
        cursor: 'pointer',
      }}
    >
      {columns.map((column) => (
        <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
          {column.dataKey !== 'menu' && <Typography variant="p14">{String(row[column.dataKey])}</Typography>}
        </TableCell>
      ))}
    </StyledTableRow>
  );
};

const renderRows = ({ rows, ...rest }: RenderRowsProps) =>
  rows.map((row) => <TableRow key={row.uuid} row={row} {...rest} />);

export const PartnersWorkspacesMaterialTable = () => {
  const columns = useColumns();
  const { data: partnerCompanies, isFetching } = useGetPartnerCompaniesQuery();

  if (isFetching || !partnerCompanies) {
    return <PartnersWorkspacesTableSkeleton />;
  }

  return (
    <MaterialTable
      sortingStorageKey="partnerCompaniesTable"
      data={partnerCompanies}
      isLoading={isFetching}
      headerProps={{
        columns,
        orderBy: 'name',
      }}
      renderRows={(rows, columns) => renderRows({ rows, columns })}
      loaders={{ TableLoader: PartnersWorkspacesTableSkeleton, RowLoader: PartnersWorkspacesTableRowSkeleton }}
    />
  );
};
