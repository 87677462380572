import { useState } from 'react';

import { FilterMenuOption } from '@components/filters';

export const useFilterInclusivity = () => {
  const [filterInclusivityMap, setFilterInclusivityMap] = useState<Record<FilterMenuOption, boolean>>({
    workspace: true,
    site: true,
    charger: true,
    vehicle: true,
    user: true,
    rfid: true,
    type: true,
    category: true,
  });

  const setFilterInclusive = (filter: FilterMenuOption, inclusive: boolean) => {
    setFilterInclusivityMap((prev) => ({ ...prev, [filter]: inclusive }));
  };

  const reset = () => {
    setFilterInclusivityMap({
      workspace: true,
      site: true,
      charger: true,
      vehicle: true,
      user: true,
      rfid: true,
      type: true,
      category: true,
    });
  };

  return { filterInclusivityMap, setFilterInclusivityMap, setFilterInclusive, reset };
};
