import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Permission } from 'models/permission.enum';
import { Site } from 'models/site';

import { MenuItem } from '@components/dropdown/MenuComponent';
import { isSubdomainPartner } from '@routesConfig/routesUtil';
import { selectPermissionByName } from '@services/auth/selectors';
import { useAppSelector } from '@services/hooks';

type useGetSiteMenuItemsProps = {
  isSiteView?: boolean;
  onOpenDeleteSiteModal: Dispatch<SetStateAction<Site | undefined>>;
};

type GetSiteMenuItemsFunction = (site: Site) => MenuItem[];

export const useGetSiteMenuItems = ({ isSiteView, onOpenDeleteSiteModal }: useGetSiteMenuItemsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const canEditSites = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_EDIT_SITES));
  const canDeleteSites = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_DELETE_SITES));

  const getSiteMenuItems: GetSiteMenuItemsFunction = (site) => [
    {
      title: t('siteDetails', 'Site details'),
      key: 'siteDetails',
      hidden: isSiteView || !canEditSites,
      onClick: () => navigate(`menu-details?siteUuid=${site.uuid}`),
    },
    {
      title: t('publicCharging', 'Public charging'),
      key: 'publicCharging',
      hidden: isSiteView || !canEditSites || isSubdomainPartner(),
      onClick: () => navigate(`menu-availability?siteUuid=${site.uuid}`),
    },
    {
      title: t('deleteSite', 'Delete site'),
      key: 'deleteSite',
      className: 'text-vermillion',
      hidden: !canDeleteSites,
      onClick: () => onOpenDeleteSiteModal(site),
    },
  ];

  return getSiteMenuItems;
};
