import { Link } from 'react-router-dom';

import { Button, Typography } from '@mui/material';

import { trackAmplitudeEvent } from '@handlers/amplitude';
import { getAmplitudeEventPropertiesForUser } from '@handlers/amplitude/data';
import { NavigationItem } from '@routesConfig/useNavigationItems';
import { selectUser } from '@services/auth/selectors';
import { useAppSelector } from '@services/hooks';

import { NavigationIconComponent } from './NavigationIconComponent';

type NavigationItemComponentProps = {
  route: NavigationItem;
  isActive?: boolean;
  hideRouteName?: boolean;
  bgColor?: string;
  borderRadius?: number;
  onCloseDrawer?: () => void;
};

const DEFAULT_BG_COLOR = '#F4F4F4';

export const NavigationItemComponent = ({
  route,
  isActive,
  hideRouteName,
  bgColor = DEFAULT_BG_COLOR,
  borderRadius = 3,
  onCloseDrawer,
}: NavigationItemComponentProps) => {
  const user = useAppSelector(selectUser);

  const handleClick = () => {
    route.amplitudeEvent && trackAmplitudeEvent(route.amplitudeEvent, getAmplitudeEventPropertiesForUser(user!));
    onCloseDrawer?.();
  };

  return (
    <Typography
      component={route.path ? Link : Button}
      display="flex"
      to={route?.path}
      gap={2}
      p={1.5}
      borderRadius={borderRadius}
      onClick={handleClick}
      bgcolor={isActive ? bgColor : 'transparent'}
      variant={isActive ? 'p14b' : 'p14'}
    >
      {route.icon && (
        <NavigationIconComponent
          icon={route.icon}
          className={isActive && bgColor !== DEFAULT_BG_COLOR ? 'max-sm:[&_path]:fill-white' : ''}
        />
      )}
      {!hideRouteName && route.name}
    </Typography>
  );
};
