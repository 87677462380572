import { Context, MouseEvent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Typography } from '@mui/material';

import { OutlinedButton } from '@components/atoms/OutlinedButton';
import { FiltersBaseContextType } from '@components/filters/context/FiltersContext';

import { FilterMenuOption } from '.';
import { FilterMenu } from './FilterMenu';
import { useFilterMenu } from './hooks/useFilterMenu';

type FilterButtonProps<ContextType, ExtractedType extends FilterMenuOption> = {
  context: Context<ContextType & FiltersBaseContextType<ExtractedType>>;
};

export const FilterButton = <ContextType, ExtractedType extends FilterMenuOption>({
  context,
}: FilterButtonProps<ContextType, ExtractedType>) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const { filterOptionsMap } = useContext(context);
  const { selectedFilter, setSelectedFilter, filterMenuOptions } = useFilterMenu(filterOptionsMap);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
    setSelectedFilter(undefined);
  };

  return (
    <>
      <OutlinedButton gap={1} onClick={handleClick}>
        <FilterAltOutlinedIcon sx={{ fontSize: '16px' }} />
        <Typography variant="p14">{t('filter', 'Filter')}</Typography>
      </OutlinedButton>
      <FilterMenu
        context={context}
        anchorEl={anchorEl}
        selectedFilter={selectedFilter}
        options={selectedFilter ? filterOptionsMap[selectedFilter as keyof typeof filterOptionsMap] : filterMenuOptions}
        onClose={handleClose}
      />
    </>
  );
};
