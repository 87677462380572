import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import { TransactionType } from 'models/reporting-transaction';
import { TransactionStatus } from 'models/transaction.enums';

import { Box, TableCell, TableCellProps, Tooltip, Typography } from '@mui/material';

import { ReactComponent as ManualSessionEndedIcon } from '@images/icons/sessionStatus/manual_ended.svg';
import { ReactComponent as ManualSessionOngoingIcon } from '@images/icons/sessionStatus/manual_ongoing.svg';
import { ReactComponent as PublicSessionEndedIcon } from '@images/icons/sessionStatus/public_ended.svg';
import { ReactComponent as PublicSessionOngoingIcon } from '@images/icons/sessionStatus/public_ongoing.svg';
import { ReactComponent as SmartSessionEndedIcon } from '@images/icons/sessionStatus/smart_ended.svg';
import { ReactComponent as SmartSessionOngoingIcon } from '@images/icons/sessionStatus/smart_ongoing.svg';

const iconMap: Record<TransactionType, Partial<Record<TransactionStatus, ReactNode>>> = {
  [TransactionType.PUBLIC]: {
    [TransactionStatus.STARTED]: <PublicSessionOngoingIcon />,
    [TransactionStatus.UPDATED]: <PublicSessionOngoingIcon />,
    [TransactionStatus.ENDED]: <PublicSessionEndedIcon />,
  },
  [TransactionType.SMART_CHARGING]: {
    [TransactionStatus.STARTED]: <SmartSessionOngoingIcon />,
    [TransactionStatus.UPDATED]: <SmartSessionOngoingIcon />,
    [TransactionStatus.ENDED]: <SmartSessionEndedIcon />,
  },
  [TransactionType.PRIVATE]: {
    [TransactionStatus.STARTED]: <ManualSessionOngoingIcon />,
    [TransactionStatus.UPDATED]: <ManualSessionOngoingIcon />,
    [TransactionStatus.ENDED]: <ManualSessionEndedIcon />,
  },
};

const useTooltipMap = () => {
  const { t } = useTranslation();

  const transactionTypeMap: Record<TransactionType, string> = {
    [TransactionType.PUBLIC]: t('publicCharging', 'Public charging'),
    [TransactionType.SMART_CHARGING]: t('smartCharging', 'Smart charging'),
    [TransactionType.PRIVATE]: t('manualCharging', 'Manual charging'),
  };

  const transactionStatusMap: Partial<Record<TransactionStatus, string>> = {
    [TransactionStatus.STARTED]: t('started', 'Started'),
    [TransactionStatus.UPDATED]: t('ongoing', 'Ongoing'),
    [TransactionStatus.ENDED]: t('ended', 'Ended'),
  };

  return { transactionTypeMap, transactionStatusMap };
};

type StartTimeCellProps = TableCellProps & {
  startTime: Date;
  transactionType?: TransactionType;
  status?: TransactionStatus;
};

export const StartTimeCell = ({ startTime, transactionType, status, ...props }: StartTimeCellProps) => {
  const day = dayjs(startTime);
  const { transactionTypeMap, transactionStatusMap } = useTooltipMap();

  return (
    <TableCell align="left" {...props}>
      <Box display="flex" alignItems="center" gap={2}>
        {transactionType && (
          <Tooltip
            title={
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="p12b">{`${transactionTypeMap[transactionType]}`}</Typography>
                {status && (
                  <Typography variant="p12b" textTransform="capitalize">{`${transactionStatusMap[status]}`}</Typography>
                )}
              </Box>
            }
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -24],
                    },
                  },
                ],
              },
            }}
          >
            <Box>{iconMap[transactionType]?.[status ?? TransactionStatus.ENDED]}</Box>
          </Tooltip>
        )}
        <Box display="flex" flexDirection="column">
          <Typography variant="p16b" noWrap>
            {day.format('HH:mm')}
          </Typography>
          <Typography variant="p14" color="#5E5E5E" noWrap>
            {day.format('DD MMMM')}
          </Typography>
        </Box>
      </Box>
    </TableCell>
  );
};
