import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { isFinite } from 'lodash';
import PropTypes from 'prop-types';

import { Tooltip } from '@mui/material';

import { SummaryCard } from '@components/cards/SummaryCard';
import TimePeriodSelectorHeader from '@components/filterComponents/TimePeriodSelectorHeader';
import useActiveCurrency from '@hooks/useActiveCurrency';
import { ReactComponent as InfoIcon } from '@images/icons/Info.svg';
import { selectSmartChargingSessionsSummary, selectVehiclesMileage } from '@services/vehicles/selectors';

const VehiclesSummary = ({ selectedDuration, setSelectedDuration }) => {
  const { t } = useTranslation();
  const currency = useActiveCurrency();
  const { totalKwhAmount, totalMonetaryCost, totalAlternateMonetaryCost, totalSmartMonetaryCost } =
    useSelector(selectSmartChargingSessionsSummary) || {};
  const { totalKilometers, totalDeletedCars } = useSelector(selectVehiclesMileage) || {};
  const consumption = totalKwhAmount || 0;
  const totalCost = totalMonetaryCost || 0;
  const mileage = totalKilometers || 0;
  const avgCostPer100Km = isFinite(totalMonetaryCost / totalKilometers)
    ? (totalMonetaryCost / totalKilometers) * 100
    : 0;
  const savings = totalAlternateMonetaryCost - totalSmartMonetaryCost || 0;

  return (
    <>
      <TimePeriodSelectorHeader
        selectedDuration={selectedDuration}
        setSelectedDuration={setSelectedDuration}
        withoutAll
        storageKey="vehiclesSummaryDuration"
      />
      <div className="-mt-[0.5rem] mb-[1rem] flex items-center gap-1 font-poppins text-xs text-gray-400">
        {t('vehiclesDataDisclaimer', 'The data presented is computed and may be subject to inaccuracies or delays.')}
        {!!totalDeletedCars && (
          <>
            {' '}
            {t('totalsIncludeDataFromDeletedVehicles', 'The totals include data from deleted vehicles.')}
            <Tooltip
              PopperProps={{
                className: '[&>div]:bg-black text-white [&>div]:font-poppins [&>div]:text-xs [&>div]:m-0',
              }}
              title={`${totalDeletedCars} ${t(
                'itemDeletedDuringTheSelectedPeriod',
                '{{item}} deleted during the selected time period.',
                {
                  item: totalDeletedCars > 1 ? t('vehicleWere', 'Vehicles were') : t('vehicleWas', 'Vehicle was'),
                },
              )} ${t(
                'itemMayContributeToTheSummaryInformation',
                '{{item}} may contribute to the information shown in this summary.',
                {
                  item: totalDeletedCars > 1 ? t('theseVehicles', 'These vehicles') : t('thisVehicle', 'This vehicle'),
                },
              )}`}
              placement="top"
            >
              <InfoIcon className="h-4 w-4 text-gray-400 hover:text-gray-600 [&_path]:fill-gray-400" />
            </Tooltip>
          </>
        )}
      </div>
      <div className="mb-10 flex items-stretch gap-4">
        <SummaryCard label={t('consumption', 'Consumption')} unit="kWh" value={consumption} valueDecimalPlaces={2} />
        <SummaryCard
          label={`${t('averageAbbreviation', 'Avg.')} ${currency.symbol}/100km`}
          unit={currency.symbol}
          value={avgCostPer100Km}
          valueDecimalPlaces={2}
          trendFlipped
          tooltip={t('vatNotIncluded', 'VAT not included')}
        />
        <SummaryCard
          label={t('savings', 'Savings')}
          unit={currency.symbol}
          value={savings}
          valueDecimalPlaces={2}
          tooltip={t('vatNotIncluded', 'VAT not included')}
        />
        <SummaryCard
          label={t('totalCost', 'Total cost')}
          unit={currency.symbol}
          value={totalCost}
          valueDecimalPlaces={2}
          tooltip={t('vatNotIncluded', 'VAT not included')}
        />
        <SummaryCard label={t('mileage', 'Mileage')} unit="km" value={mileage} valueDecimalPlaces={0} />
      </div>
    </>
  );
};

VehiclesSummary.propTypes = {
  selectedDuration: PropTypes.string,
  setSelectedDuration: PropTypes.func,
};

VehiclesSummary.defaultProps = {
  selectedDuration: null,
  setSelectedDuration: null,
};

export default VehiclesSummary;
