import { combineReducers } from 'redux';

import apiSlice from '@services/api';
import authReducer from '@services/auth';
import { removeCredentials } from '@services/auth/actions';
import companiesReducer from '@services/companies';
import countriesReducer from '@services/countries';
import dashboardReducer from '@services/dashboard';
import devicesReducer from '@services/devices';
import eventLogReducer from '@services/eventLog';
import invertersReducer from '@services/inverters';
import rfidCardsReducer from '@services/rfidCards';
import sitesReducer from '@services/sites';
import toastMessagesReducer from '@services/toastMessages';
import transactionsReducer from '@services/transactions';
import usersReducer from '@services/users';
import vehiclesReducer from '@services/vehicles';

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
  transactions: transactionsReducer,
  sites: sitesReducer,
  vehicles: vehiclesReducer,
  devices: devicesReducer,
  users: usersReducer,
  dashboard: dashboardReducer,
  toastMessages: toastMessagesReducer,
  companies: companiesReducer,
  rfidCards: rfidCardsReducer,
  eventLog: eventLogReducer,
  countries: countriesReducer,
  inverters: invertersReducer,
});

const reducerValidator = (state, action) => {
  if (removeCredentials.match(action)) {
    state = undefined;
  }

  return rootReducer(state, action);
};

export default reducerValidator;
