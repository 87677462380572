import classNames from 'classnames';

import { Tooltip, Typography } from '@mui/material';

import { CardContainer } from '@components/atoms/CardContainer';
import { ReactComponent as ArrowTrend } from '@images/icons/arrow_trend.svg';

type SummaryCardProps = {
  label: string;
  unit?: string;
  value: number | string;
  valueDecimalPlaces?: number;
  trend?: number;
  trendColorsFlipped?: boolean;
  tooltip?: string;
};

export const SummaryCard = ({
  label,
  unit,
  value,
  valueDecimalPlaces,
  trend,
  trendColorsFlipped,
  tooltip,
}: SummaryCardProps) => {
  const roundedTrend = trend && Math.round(trend);
  const trendColorsClass = classNames(
    trendColorsFlipped
      ? {
          'bg-vermillion/20 text-vermillion': roundedTrend && roundedTrend > 0,
          'bg-forest/20 text-forest': roundedTrend && roundedTrend < 0,
        }
      : {
          'bg-vermillion/20 text-vermillion': roundedTrend && roundedTrend < 0,
          'bg-forest/20 text-forest': roundedTrend && roundedTrend > 0,
        },
  );
  const trendArrowClass = classNames(
    trendColorsFlipped
      ? {
          '[&_path]:fill-vermillion': roundedTrend && roundedTrend > 0,
          'rotate-180 [&_path]:fill-forest': roundedTrend && roundedTrend < 0,
        }
      : {
          'rotate-180 [&_path]:fill-vermillion': roundedTrend && roundedTrend < 0,
          '[&_path]:fill-forest': roundedTrend && roundedTrend > 0,
        },
  );

  const getValueContent = () => {
    if (typeof value === 'number') {
      return value?.toLocaleString('en-US', {
        minimumFractionDigits: valueDecimalPlaces,
        maximumFractionDigits: valueDecimalPlaces,
      });
    }
    return value ?? '-';
  };

  return (
    <Tooltip title={tooltip && <Typography variant="p12b">{tooltip}</Typography>}>
      <CardContainer flex="1" minWidth="166px">
        {roundedTrend !== null &&
          (roundedTrend === 0 ? (
            <div className="float-right -mr-2 -mt-2 flex h-6 w-6 items-center justify-center rounded-full bg-gray-100 px-1 pb-px text-gray-400">
              –
            </div>
          ) : (
            <div
              className={`float-right -mr-2 -mt-2 flex h-6 items-center rounded-full p-1 text-xs leading-none ${trendColorsClass}`}
            >
              <ArrowTrend className={`inline-block ${trendArrowClass}`} />
              {roundedTrend && Math.abs(roundedTrend)}%
            </div>
          ))}
        <h3 className="mb-1 font-poppins text-sm leading-6 text-gray-600">{label}</h3>
        <span className="font-everett text-2xl">{getValueContent()}</span>{' '}
        <span className="font-poppins text-sm text-gray-600">{unit}</span>
      </CardContainer>
    </Tooltip>
  );
};
