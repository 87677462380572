import { Context, useState } from 'react';

import { CalendarTodayOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';

import { MenuContainer } from '@components/atoms/MenuContainer';
import { OutlinedButton } from '@components/atoms/OutlinedButton';

import { FilterMenuOption } from '.';
import { FiltersBaseContextType } from './context/FiltersContext';
import { useDateRangeCalendar } from './hooks/useDateRangeCalendar';

type CalendarOutlinedButtonProps<ContextType, ExtractedType extends FilterMenuOption> = {
  context: Context<ContextType & FiltersBaseContextType<ExtractedType>>;
};

export const CalendarOutlinedButton = <ContextType, ExtractedType extends FilterMenuOption>({
  context,
}: CalendarOutlinedButtonProps<ContextType, ExtractedType>) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const { startDate, endDate, DateRangeCalendar } = useDateRangeCalendar({ context, onClose: handleClose });

  return (
    <>
      <OutlinedButton gap={1} onClick={(e) => setAnchorEl(e.currentTarget)}>
        <Typography variant="p14">
          {startDate.format('MMMM DD')}
          {endDate && ` - ${endDate.format('MMMM DD')}`}
        </Typography>
        <CalendarTodayOutlined
          sx={{
            fontSize: '16px',
          }}
        />
      </OutlinedButton>
      <MenuContainer anchorEl={anchorEl} marginTop="8px" horizontalOrigin="left" onClose={handleClose}>
        {DateRangeCalendar}
      </MenuContainer>
    </>
  );
};
