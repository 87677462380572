import { Fragment } from 'react';

import PropTypes from 'prop-types';

import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';

import Button from '@components/atoms/Button';
import { ReactComponent as CloseIcon } from '@images/icons/Close.svg';

const ErrorModal = ({ open, closeModal, title, children }) => (
  <Transition.Root show={open} as={Fragment}>
    <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={closeModal}>
      <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
          &#8203;
        </span>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            className="relative inline-block w-full transform rounded-2xl bg-white text-left
              align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[480px] sm:align-middle"
          >
            <div className="rounded-2xl pt-4">
              <Dialog.Title
                as="h3"
                className="border-b border-b-gray-100 px-6 pb-4 font-everett text-2xl font-normal leading-8 text-black"
              >
                <button type="button" className="float-right -mr-2 -mt-1 p-2" onClick={closeModal}>
                  <CloseIcon />
                </button>
                {title}
              </Dialog.Title>
              <div className="flex gap-3 px-6 pb-4 pt-6">
                <ExclamationIcon className="h-8 w-8 text-red-600" aria-hidden="true" />
                <div className="mt-1 font-poppins">{children}</div>
              </div>
            </div>
            <div className="w-full rounded-b-2xl border-t border-t-gray-100 px-6 py-4 text-right">
              <Button variant="secondary" className="w-52" onClick={closeModal}>
                Close
              </Button>
            </div>
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition.Root>
);

ErrorModal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ErrorModal;
