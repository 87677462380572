import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';

import { Group } from 'models/group.dto';
import { CompanyRole } from 'models/role.enum';
import { User } from 'models/user';

import { Box, Button, InputAdornment, TextField, Tooltip, Typography } from '@mui/material';

import { MenuContainer } from '@components/atoms/MenuContainer';
import { StyledMenuItem, StyledMenuItemProps } from '@components/atoms/StyledMenuItem';
import { ReactComponent as AlertRoundedIcon } from '@images/icons/AlertRounded.svg';
import { ReactComponent as MenuArrowIcon } from '@images/icons/MenuArrow.svg';
import { ReactComponent as SearchIcon } from '@images/icons/reporting/search.svg';
import { useAppDispatch } from '@services/hooks';
import { addToastMessage } from '@services/toastMessages';
import { useUpdateUserGroupsMutation } from '@services/users/endpoints';

import { useWorkspaceGroupsAndRoles } from './workspaceHooks';

type MemberGroupsAssignmentProps = {
  member: User;
  companyUuid?: string;
};

export const MemberGroupsAssignment = ({ member, companyUuid }: MemberGroupsAssignmentProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { groups } = useWorkspaceGroupsAndRoles({ withUsers: true, companyUuid });

  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [newUserGroupUuids, setNewUserGroupUuids] = useState<string[]>([]);

  const [updateUserGroups, { isLoading }] = useUpdateUserGroupsMutation();

  const toggleGroup = (uuid: string) => {
    setNewUserGroupUuids((prev) =>
      prev.includes(uuid) ? prev.filter((userGroupUuid) => userGroupUuid !== uuid) : [...prev, uuid],
    );
  };

  const userGroups = useMemo<Group[]>(
    () => (groups || []).filter((group) => group.users?.some((user) => user.userUuid === member.uuid)),
    [member, groups],
  );
  const userGroupsLabel = useMemo<string>(
    () =>
      userGroups?.length
        ? userGroups
            .slice(0, 2)
            .map((group) => group.name)
            .concat(
              userGroups.length > 2
                ? t('andMore', 'And {{count}} more', {
                    count: userGroups.length - 2,
                  }).toLowerCase()
                : [],
            )
            .join(', ')
        : `+ ${t('addGroup', 'Add a group')}`,
    [userGroups],
  );

  const filteredGroups = useMemo<StyledMenuItemProps[]>(
    () =>
      (groups || [])
        .filter((group) => group.name.toLowerCase().includes(searchQuery.toLowerCase()))
        .map((group) => ({
          uuid: group.uuid,
          label: group.name,
          checked: newUserGroupUuids.some((userGroupUuid) => userGroupUuid === group.uuid),
          onClick: () => toggleGroup(group.uuid),
        })),
    [searchQuery, groups, newUserGroupUuids],
  );

  const handleClose = async () => {
    if (
      newUserGroupUuids.length === userGroups.length &&
      newUserGroupUuids.every((userGroupUuid) => userGroups.some((userGroup) => userGroup.uuid === userGroupUuid))
    ) {
      return setAnchorEl(undefined);
    }
    try {
      await updateUserGroups({
        uuid: member.uuid,
        groupUuids: newUserGroupUuids,
      }).unwrap();
      dispatch(
        addToastMessage({
          type: 'success',
          title: t('groupsUpdated', 'Groups updated'),
        }),
      );
    } catch (error) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('updatingGroupsFailed', 'Updating groups failed'),
          message: t('pleaseTryAgain', 'Please try again.'),
        }),
      );
    }
    return setAnchorEl(undefined);
  };

  useEffect(() => {
    setNewUserGroupUuids((userGroups || []).map((group) => group.uuid));
  }, [userGroups]);

  return (
    <>
      <Button
        variant="text"
        color="inherit"
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
          borderRadius: '16px',
        }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <Typography
          variant="p14"
          textTransform="none"
          textAlign="left"
          color={userGroups.length ? '#282828' : '#386957'}
        >
          {userGroupsLabel}
        </Typography>
        {userGroups.length ? (
          <MenuArrowIcon className="min-w-fit" />
        ) : (
          <Tooltip
            title={
              member.role === CompanyRole.MEMBER
                ? t('addMemberToGroupsText', 'Add member to groups for them to see devices')
                : t('addAdminToGroupsText', 'Belonging to groups has no effect on admin user visibility')
            }
          >
            <AlertRoundedIcon />
          </Tooltip>
        )}
      </Button>

      <MenuContainer anchorEl={anchorEl} width="343px" horizontalOrigin="left" onClose={handleClose}>
        <TextField
          placeholder={t('groupSearchLabel', 'Group ...')}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          fullWidth
          variant="standard"
          disabled={isLoading}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ color: '#282828', cursor: 'default' }}>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{
            '& .MuiInput-root': {
              '&:hover:not(.Mui-disabled, .Mui-error):before, ::before, ::after': { border: 'none' },
              '&.Mui-disabled:before': { borderBottomStyle: 'none' },
            },
            padding: '8px 12px',
          }}
        />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          px={0.5}
          sx={{ ':not(:empty)': { borderTop: '1px solid #F4F4F4', paddingTop: '4px' } }}
        >
          {filteredGroups.length < 5 ? (
            filteredGroups?.map((option) => <StyledMenuItem key={option.uuid} {...option} disabled={isLoading} />)
          ) : (
            <Virtuoso
              style={{ height: '200px' }}
              totalCount={filteredGroups.length}
              itemContent={(index) => (
                <StyledMenuItem key={filteredGroups[index].uuid} {...filteredGroups[index]} disabled={isLoading} />
              )}
            />
          )}
        </Box>
      </MenuContainer>
    </>
  );
};
