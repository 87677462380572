const buildCountriesEndpoints = (builder) => ({
  getCountries: builder.query({
    query: () => ({
      url: '/countries',
      method: 'GET',
    }),
  }),
});

export default buildCountriesEndpoints;
