import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';

import jsCookie from 'js-cookie';
import { some } from 'lodash';

import { ThemeProvider } from '@mui/material';

import { ErrorBoundaryFallback } from '@components/errors/ErrorBoundaryFallback';
import { datadogLogs } from '@datadog/browser-logs';
import CookieBanner from '@layouts/LoggedinBasicLayout/CookieBanner';
import store from '@services/store';

import Router from '../Router';
import { theme } from './theme';

const Core = () => {
  const browserCookie = jsCookie.get('cu_cookiedone');
  const [cookie, setCookie] = useState(!!browserCookie);
  const intercomAppId = JSON.parse(process.env.INTERCOM_APP_ID)[process.env.REACT_APP_CONF_FILE];

  useEffect(() => {
    if (some([process.env.REACT_APP_CONF_FILE === 'staging', process.env.REACT_APP_CONF_FILE === 'production'])) {
      datadogLogs.init({
        clientToken: 'pub85969a68cad312aa57365aafad9d1617',
        site: 'datadoghq.eu',
        env: process.env.REACT_APP_CONF_FILE,
        forwardErrorsToLogs: true,
        forwardConsoleLogs: ['error'],
        sampleRate: 100,
        service: 'app-frontend:web',
        silentMultipleInit: true,
      });
    }
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
          <IntercomProvider
            appId={intercomAppId}
            shouldInitialize={!!intercomAppId}
            autoBootProps={{ alignment: 'left' }}
            autoBoot
          >
            <BrowserRouter>
              <Router />
              {!cookie && (
                <CookieBanner
                  onClose={() => {
                    setCookie(true);
                    jsCookie.set('cu_cookiedone', true, { expires: 365 });
                  }}
                />
              )}
            </BrowserRouter>
          </IntercomProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </Provider>
  );
};

export default Core;
