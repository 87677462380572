import React from 'react';

import PropTypes from 'prop-types';

const Value = ({ children }) => <div>{children}</div>;

Value.propTypes = {
  children: PropTypes.node.isRequired,
};

const Label = ({ children }) => <div className="text-xs text-gray-400 dark:text-warmgray-400">{children}</div>;

Label.propTypes = {
  children: PropTypes.node.isRequired,
};

const CardItemFooter = ({ items }) => (
  <div className="flex justify-center text-center">
    {items.map((i) => (
      <div key={i.label} className="flex-1 font-light">
        <Value>{i.value}</Value>
        <Label className="flex-1">{i.label}</Label>
      </div>
    ))}
  </div>
);

CardItemFooter.propTypes = {
  items: PropTypes.array,
};

CardItemFooter.defaultProps = {
  items: [],
};

export default CardItemFooter;
