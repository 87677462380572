import { useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import { max, maxBy, sum } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import CardItem from '@components/basicWrappers/CardItem';
import CardItemFooter from '@components/basicWrappers/CardItemFooter';
import dataParser from '@services/transactions/dataParser';

export const CHART_COLORS = ['#EB4E20', '#386957', '#407090', '#5E5E5E'];

const GRAPH_CATEGORIES = {
  today: 'MMM DD HH:mm',
  week: 'MMM DD HH:mm',
  month: 'MMM DD',
  sixMonths: 'MMM DD',
  year: 'YYYY MMM',
};

const EnergySupplied = ({
  compact,
  noBorder,
  noInfo,
  noTitle,
  categorizedTransactions,
  isError,
  isFetching,
  style,
  footer: Footer,
  filter,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(!categorizedTransactions[0].data?.length);

  const filteredTransactions = useMemo(
    () =>
      categorizedTransactions.map((transaction, index) => ({
        ...transaction,
        data:
          dataParser[filter]?.(transaction.data).map((item) => ({
            ...item,
            value: item.value < 0.005 ? 0 : item.value,
          })) ?? [],
        color: CHART_COLORS[index % CHART_COLORS.length],
      })),
    [filter, categorizedTransactions],
  );

  const maxValue = useMemo(
    () =>
      max(
        filteredTransactions.map((ft) =>
          maxBy(
            ft?.data?.filter((d) => !d?.filler),
            'value',
          ),
        ),
      )?.value,
    [filteredTransactions],
  );

  const totalSupply = sum(
    filteredTransactions.map((transaction) => sum(transaction.data.map((d) => d.value || 0))),
  ).toFixed(2);
  const totalSessions = sum(filteredTransactions.map((transaction) => transaction.data.length)).toFixed(2);
  const averageSupply = (totalSupply / totalSessions || 0).toFixed(2);

  useEffect(() => {
    if (!isFetching) {
      setLoading(false);
    }
  }, [isFetching]);

  if (!categorizedTransactions) {
    return null;
  }

  return (
    <>
      <CardItem
        className={`
          h-full min-h-[300px] rounded-[16px] p-4 pb-0 pl-0 pr-0 pt-5
          ${noBorder ? 'border-none' : ''}
        `}
        style={{
          ...style,
          paddingTop: 0,
        }}
        error={isError}
        loading={loading}
        title={noTitle ? null : t('energySupplied', 'Energy supplied')}
        {...(!noInfo &&
          !Footer && {
            footer: () => (
              <div className="-mb-4">
                <CardItemFooter
                  items={[
                    {
                      label: t('sessions', 'Sessions').toLowerCase(),
                      value: `${totalSupply} kWh`,
                    },
                    {
                      label: t('avgPerSession', 'Avg. per session'),
                      value: `${averageSupply} kWh`,
                    },
                  ]}
                />
              </div>
            ),
          })}
      >
        <div
          className={`
          ${noInfo ? '' : 'pb-8'}
          w-full
        `}
        >
          <Chart
            type="bar"
            width="100%"
            {...(compact && {
              height: '300px',
            })}
            options={{
              legend: {
                show: true,
                showForSingleSeries: true,
                markers: {
                  width: 8,
                  height: 8,
                  radius: 100,
                  offsetX: -5,
                },
                labels: {
                  colors: ['#999898'],
                },
              },
              responsive: [
                {
                  breakpoint: undefined,
                  options: {},
                },
              ],
              tooltip: {
                y: {
                  formatter: (value) => `${(value || 0).toFixed(2)} kWh`,
                },
              },
              stroke: {
                show: true,
                lineCap: 'butt',
                width: 1,
                dashArray: 0,
              },
              dataLabels: {
                enabled: false,
              },
              chart: {
                id: 'basic-bar',
                zoom: {
                  enabled: false,
                },
                toolbar: {
                  show: false,
                },
                animations: {
                  enabled: true,
                  easing: 'easeout',
                  animateGradually: {
                    enabled: false,
                    delay: 150,
                  },
                  dynamicAnimation: {
                    enabled: true,
                  },
                },
              },
              xaxis: {
                tooltip: {
                  enabled: true,
                },
                categories: (filteredTransactions?.[0]?.data || []).map((transaction) =>
                  moment(transaction.update_time).format(GRAPH_CATEGORIES[filter]),
                ),
                tickAmount: 4,
                labels: {
                  offsetY: -5,
                  showDuplicates: false,
                  rotate: 0,
                  style: {
                    fontSize: 10,
                    fontFamily: 'Roboto, sans-serif',
                    colors: '#999898',
                  },
                },
                axisBorder: {
                  show: false,
                },
                axisTicks: {
                  show: false,
                },
              },
              yaxis: [
                {
                  seriesName: t('energySupplied', 'Energy supplied'),
                  showAlways: true,
                  max: maxValue < 0.5 ? 1 : undefined,
                  title: {
                    text: 'kWh',
                    rotate: 0,
                    offsetX: -5,
                    style: {
                      color: '#999898',
                    },
                  },
                  decimalsInFloat: 0,
                  labels: {
                    formatter: (number) => number.toFixed(),
                    offsetX: 5,
                    rotate: 0,
                    style: {
                      fontSize: 9,
                      fontFamily: 'Roboto, sans-serif',
                      colors: '#999898',
                    },
                  },
                  axisBorder: {
                    show: false,
                  },
                  ...(compact && {
                    tickAmount: filter === 'year' ? 4 : 3,
                  }),
                },
              ],
              grid: {
                show: true,
                position: 'back',
                borderColor: '#f4f4f4',
                yaxis: {
                  lines: {
                    show: true,
                  },
                },
                xaxis: {
                  lines: {
                    show: false,
                  },
                },
                padding: {
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 10,
                },
              },
              fill: {
                type: 'solid',
                opacity: 1,
              },
              plotOptions: {
                bar: {
                  borderRadius: 4,
                },
              },
            }}
            series={filteredTransactions.map((transaction) => ({
              ...transaction,
              data: transaction.data.map((d) => d.value || 0),
            }))}
          />
        </div>
      </CardItem>
      {Footer && <Footer transactions={categorizedTransactions} />}
    </>
  );
};

EnergySupplied.propTypes = {
  compact: PropTypes.bool,
  noInfo: PropTypes.bool,
  noTitle: PropTypes.bool,
  noBorder: PropTypes.bool,
  categorizedTransactions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      data: PropTypes.array,
    }),
  ).isRequired,
  isError: PropTypes.bool,
  isFetching: PropTypes.bool,
  style: PropTypes.object,
  footer: PropTypes.any,
  filter: PropTypes.string,
};

EnergySupplied.defaultProps = {
  compact: false,
  noInfo: false,
  noTitle: false,
  noBorder: false,
  isError: false,
  isFetching: false,
  style: {},
  footer: null,
  filter: 'week',
};

export default EnergySupplied;
