import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';

import { Device } from 'models/device';
import { DeviceModalOption, DeviceOcppActionOption, DeviceStatus } from 'models/device.enums';
import { Site } from 'models/site';

import DeleteDeviceModal from '@components/devices/DeleteDeviceModal';
import DeviceOcppSetupInfoModal from '@components/devices/DeviceOcppSetupInfoModal';
import { ActionButton, Tab, TabsWithMenu } from '@components/tabs/TabsWithMenu';
import apiSlice from '@services/api';
import { useGetDeviceTransactionsSummaryQuery } from '@services/devices/endpoints';
import { DeviceOcppFirmwareModal } from '@views/Devices/DeviceOcppFirmwareModal';
import { DeviceOcppMessage } from '@views/Devices/DeviceOcppMessage';
import { DeviceOutletContextType } from '@views/Devices/DeviceOutletContextType';
import DeviceOcppVariablesModal from '@views/Devices/OcppVariablesModal';

import { UsageLocation, useGetDeviceMenuItems } from '../../Devices/useGetDeviceMenuItems';

type SingleChargerBaseProps = {
  charger: Device;
  site: Site;
  openEditModal: () => void;
};

export const SingleChargerBase = ({ charger, site, openEditModal }: SingleChargerBaseProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [selectedDuration, setSelectedDuration] = useState<string>();
  const [modalOption, setModalOption] = useState<DeviceModalOption>();
  const [deviceOcppAction, setDeviceOcppAction] = useState<DeviceOcppActionOption>();

  const tabs: Tab[] = [
    {
      label: t('overview', 'Overview'),
      path: 'overview',
    },
    {
      label: t('currentActivity', 'Current activity'),
      path: 'activity',
    },
    {
      label: t('sessions', 'Sessions'),
      path: 'sessions',
    },
  ];
  const activeTab = tabs.find((tab) => pathname.includes(tab.path)) ?? tabs[0];
  const globalActionButton: ActionButton = {
    label: t('configuration', 'Configuration'),
    path: `${activeTab.path}/details`,
  };

  const getDeviceMenuItems = useGetDeviceMenuItems({
    onModalOptionSelected: setModalOption,
    openEditModal,
    usageLocation: UsageLocation.SINGLE_DEVICE,
  });
  const chargerMenuItems = getDeviceMenuItems(charger);

  const durationToTimeFrame: Record<string, string> = {
    '24-hours': 'today',
    '1-week': 'week',
    '1-months': 'month',
    '6-months': 'sixMonths',
    '1-year': 'year',
  };

  const timeFrame = selectedDuration && durationToTimeFrame[selectedDuration];

  const { isFetching: isFetchingTransactions } = apiSlice.useTransactionsQuery(
    { deviceUuid: charger.uuid },
    { refetchOnMountOrArgChange: true, skip: !charger?.uuid },
  );

  const { isFetching: isFetchingDeviceSummary } = useGetDeviceTransactionsSummaryQuery(
    { uuid: charger.uuid, timeFrame },
    { refetchOnMountOrArgChange: true, skip: !charger?.uuid || !timeFrame },
  );

  useEffect(() => {
    if (charger?.status === DeviceStatus.ONLINE) {
      setDeviceOcppAction(undefined);
    }
  }, [charger?.status]);

  const outletContextData: DeviceOutletContextType = {
    device: charger,
    site,
    selectedDuration,
    setSelectedDuration,
    isFetchingDeviceSummary,
    isFetchingTransactions,
    timeFrame,
  };

  return (
    <>
      <TabsWithMenu
        activeTab={activeTab}
        tabs={tabs}
        menuItems={chargerMenuItems}
        globalActionButton={globalActionButton}
      />
      <DeviceOcppMessage
        device={charger}
        deviceOcppAction={deviceOcppAction}
        onClose={() => setDeviceOcppAction(undefined)}
        onUpdateAgain={() => setModalOption(DeviceModalOption.OCPP_UPDATE)}
      />
      <Outlet context={outletContextData} />
      <DeleteDeviceModal
        open={modalOption === DeviceModalOption.DELETE}
        closeModal={() => setModalOption(undefined)}
        device={charger}
      />
      <DeviceOcppSetupInfoModal
        open={modalOption === DeviceModalOption.OCPP_SETUP}
        closeModal={() => setModalOption(undefined)}
        device={charger}
      />
      <DeviceOcppVariablesModal
        isOpen={modalOption === DeviceModalOption.OCPP_VARIABLES}
        closeModal={() => setModalOption(undefined)}
        device={charger}
      />
      <DeviceOcppFirmwareModal
        isOpen={!!modalOption && [DeviceModalOption.OCPP_RESET, DeviceModalOption.OCPP_UPDATE].includes(modalOption)}
        option={modalOption}
        closeModal={() => setModalOption(undefined)}
        onSuccess={(option) => {
          setDeviceOcppAction(
            option === DeviceModalOption.OCPP_RESET
              ? DeviceOcppActionOption.OCPP_RESET
              : DeviceOcppActionOption.OCPP_UPDATE,
          );
        }}
        device={charger}
      />
    </>
  );
};
