import { useState } from 'react';

import { cloneDeep, isEqual, isNil, mapValues, merge, set } from 'lodash';

const emptyPricingModel = {
  region: '',
  isAutomatic: true,
  isSinglePrice: true,
  singlePrice: '',
  dualPrice: {
    price: {
      night: '',
      day: '',
    },
    dayHours: {
      start: '',
      end: '',
    },
  },
  isSingleTransmission: true,
  transmissionSinglePrice: '',
  transmissionDualPrice: {
    price: {
      night: '',
      day: '',
    },
    dayHours: {
      start: '',
      end: '',
    },
  },
  extraFees: '',
};

const uncolonifyDualPrices = (dualPrice) =>
  dualPrice && {
    ...dualPrice,
    dayHours: {
      start: dualPrice.dayHours?.start?.replace(':', '') ?? '',
      end: dualPrice.dayHours?.end?.replace(':', '') ?? '',
    },
  };

const getTrimmedPricingModel = (model) => ({
  isAutomatic: model.isAutomatic,
  isSinglePrice: model.isSinglePrice,
  isSingleTransmission: model.isSingleTransmission,
  ...(model.isAutomatic
    ? {
        region: model.region,
      }
    : {
        ...(model.isSinglePrice
          ? {
              singlePrice: model.singlePrice,
            }
          : {
              dualPrice: uncolonifyDualPrices(model.dualPrice),
            }),
      }),
  ...(model.isSingleTransmission
    ? {
        transmissionSinglePrice: model.transmissionSinglePrice,
      }
    : {
        transmissionDualPrice: uncolonifyDualPrices(model.transmissionDualPrice),
      }),
  extraFees: model.extraFees || null,
});

const processValue = (value) =>
  mapValues(value, (val) => {
    if (isNil(val)) {
      return '';
    }
    const type = typeof val;
    if (type === 'object') {
      return processValue(val);
    }
    if (type === 'number') {
      return val.toString();
    }
    return val;
  });

const usePricingModel = () => {
  const [siteModel, setSiteModel] = useState({});
  const [currentModel, setCurrentModel] = useState(emptyPricingModel);
  const [baselineModel, setBaselineModel] = useState(emptyPricingModel);
  const setPricingModelAttr = (path, value) => setCurrentModel((prev) => set(cloneDeep(prev), path, value));

  const setPricingModelFromSite = (site) => {
    // TODO: should we account for site.pricingModel being invalid?
    setSiteModel(site.pricingModel);
    const processedModel = merge({}, emptyPricingModel, processValue(site.pricingModel));
    setCurrentModel(processedModel);
    setBaselineModel(processedModel);
  };

  const trimmedCurrentModel = getTrimmedPricingModel(currentModel);
  const trimmedBaselineModel = getTrimmedPricingModel(baselineModel);
  const hasPricingModelChanged = !isEqual(trimmedCurrentModel, trimmedBaselineModel);
  const getPricingModelToSave = () => merge({}, siteModel, trimmedCurrentModel);

  return {
    pricingModel: currentModel,
    getPricingModelToSave,
    setPricingModelFromSite,
    setPricingModelAttr,
    hasPricingModelChanged,
  };
};

export default usePricingModel;
