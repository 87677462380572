import { Context, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Collapse, Typography } from '@mui/material';

import { CardContainer } from '@components/atoms/CardContainer';
import { OutlinedButton } from '@components/atoms/OutlinedButton';
import { StyledMenuItemProps } from '@components/atoms/StyledMenuItem';

import { FilterMenuOption } from '.';
import { ActiveFilter } from './ActiveFilter';
import { CalendarOutlinedButton } from './CalendarOutlinedButton';
import { FilterButton } from './FilterButton';
import { FiltersBaseContextType } from './context/FiltersContext';

type FiltersCardProps<ContextType, ExtractedType extends FilterMenuOption> = {
  context: Context<ContextType & FiltersBaseContextType<ExtractedType>>;
};

export const FiltersCard = <ContextType, ExtractedType extends FilterMenuOption>({
  context,
}: FiltersCardProps<ContextType, ExtractedType>) => {
  const { t } = useTranslation();
  const { startDate, setStartDate, endDate, setEndDate, filterOptionsMap, anyActiveFilters, optionChecked, reset } =
    useContext(context);

  const entireMonthSelected =
    startDate?.isSame(startDate?.startOf('month')) && endDate?.isSame(endDate?.endOf('month'));

  const handleNavigationClick = (monthDelta: -1 | 1) => {
    if (entireMonthSelected) {
      setStartDate((prev) => prev.add(monthDelta, 'month').startOf('month'));
      setEndDate((prev) => prev?.add(monthDelta, 'month').endOf('month'));
      return;
    }
    const range = (endDate?.diff(startDate, 'day') ?? 0) + 1;
    setStartDate((prev) => prev.add(range * monthDelta, 'day'));
    setEndDate((prev) => prev?.add(range * monthDelta, 'day'));
  };

  return (
    <CardContainer sx={{ p: 0 }}>
      <Box display="flex" flexWrap="wrap" gap={1} p={2}>
        <Box display="flex" flex={1} gap={1}>
          <OutlinedButton p={1} onClick={() => handleNavigationClick(-1)}>
            <ArrowBackIcon
              sx={{
                fontSize: '16px',
                cursor: 'pointer',
              }}
            />
          </OutlinedButton>
          <OutlinedButton p={1} onClick={() => handleNavigationClick(1)}>
            <ArrowForwardIcon
              sx={{
                fontSize: '16px',
                cursor: 'pointer',
              }}
            />
          </OutlinedButton>
          <CalendarOutlinedButton context={context} />
        </Box>
        <FilterButton context={context} />
      </Box>
      <Collapse in={anyActiveFilters}>
        <Box display="flex" flexWrap="wrap" alignItems="center" gap={1} borderTop="1px solid #F4F4F4" p={2}>
          {Object.entries<StyledMenuItemProps[]>(filterOptionsMap).map(
            ([key, options]) =>
              options.some(optionChecked) && (
                <ActiveFilter key={key} context={context} filter={key as FilterMenuOption} options={options} />
              ),
          )}
          <Typography
            variant="p14"
            color="#EB4E20"
            onClick={() => reset()}
            ml={1}
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
          >
            {t('clearFilters', 'Clear filters')}
          </Typography>
        </Box>
      </Collapse>
    </CardContainer>
  );
};
