import { useTranslation } from 'react-i18next';

import { Input } from '@components/atoms/Input';
import { TimePicker } from '@components/atoms/TimePicker';
import useActiveCurrency from '@hooks/useActiveCurrency';

import { formatTime, hhMmToMoment } from './functions';

type PeriodicalCostInputsProps = {
  periodLabel: string;
  costLabel: string;
  startTime: string;
  endTime: string;
  cost: string;
  periodInputsDisabled?: boolean;
  onStartTimeChange?: (val: string) => void;
  onEndTimeChange?: (val: string) => void;
  onCostChange?: (val: string) => void;
  errors?: any;
};

export const PeriodicalCostInputs = ({
  periodLabel,
  periodInputsDisabled,
  costLabel,
  startTime,
  endTime,
  cost,
  onStartTimeChange,
  onEndTimeChange,
  onCostChange,
  errors,
}: PeriodicalCostInputsProps) => {
  const { t } = useTranslation();
  const currency = useActiveCurrency();
  const getPeriodError = () => {
    const periodError = errors?.dayHours;
    return !periodInputsDisabled ? periodError : null;
  };
  const getPriceError = () => {
    const priceError = errors?.price?.[periodLabel.toLowerCase()];
    return priceError || null;
  };
  return (
    <div>
      <div className="font-semibold">{periodLabel}</div>
      <div className="mb-1 flex items-stretch gap-2">
        <TimePicker
          name={`${periodLabel}_startTime`}
          label={t('start', 'Start')}
          value={startTime ? hhMmToMoment(startTime) : null}
          disabled={periodInputsDisabled}
          noMinutes
          onChange={(val) => onStartTimeChange?.(formatTime(val))}
          error={Boolean(getPeriodError()?.start)}
          helpText={getPeriodError()?.start?.message}
        />
        <TimePicker
          name={`${periodLabel}_endTime`}
          label={t('end', 'End')}
          value={endTime ? hhMmToMoment(endTime) : null}
          disabled={periodInputsDisabled}
          noMinutes
          onChange={(val) => onEndTimeChange?.(formatTime(val))}
          error={Boolean(getPeriodError()?.end)}
          helpText={getPeriodError()?.end?.message}
          {...(!periodInputsDisabled && {
            shouldDisableTime: (time) =>
              !time.isSame(hhMmToMoment('0000')) && time.isBefore(hhMmToMoment(startTime).add(1, 'hour')),
          })}
        />
      </div>
      <Input
        name={`${periodLabel}_${costLabel}`}
        label={costLabel}
        value={cost}
        type="number"
        trailingText={`${t('currencySubunit', '{{currencySubunit}}', {
          currencySubunit: currency.centsName,
        }).toLowerCase()}/kWh`}
        onChange={(e) => onCostChange?.(e.target.value)}
        error={Boolean(getPriceError())}
        helpText={getPriceError()?.message}
      />
    </div>
  );
};
