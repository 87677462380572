import { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { isEmpty, isNil } from 'lodash';
import { Permission } from 'models/permission.enum';
import PropTypes from 'prop-types';

import { Dialog, Transition } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { Autocomplete, TextField } from '@mui/material';

import { Spinner } from '@components/atoms/Spinner';
import { Global } from '@emotion/react';
import { selectPermissionByName } from '@services/auth/selectors';
import {
  useGetOcppVariablesDefQuery,
  useLazyGetOcppVariableQuery,
  useSetOcppVariableMutation,
} from '@services/devices/endpoints';

const useVariablesDef = ({ deviceUuid, componentInput, skip }) => {
  const { data: variablesDef } = useGetOcppVariablesDefQuery(deviceUuid, {
    selectFromResult: (result) => result?.data ?? {},
    skip,
  });
  const [componentOptions, setComponentOptions] = useState([]);
  const [variablesRefreshCounter, setVariablesRefreshCounter] = useState(0);
  const [readOnlyVariables, setReadOnlyVariables] = useState(new Set());

  const variableOptions = useMemo(
    () =>
      [
        ...((componentOptions.length > 0
          ? variablesDef?.find((vd) => vd.component === componentInput)?.variables
          : variablesDef?.flatMap((vd) => vd.variables)) ?? []),
      ].sort(),
    [variablesRefreshCounter, componentInput],
  );

  useEffect(() => {
    const componentOpts = variablesDef?.map((vd) => vd.component).filter(Boolean) ?? [];
    componentOpts.sort();
    setComponentOptions(componentOpts);
    setReadOnlyVariables(new Set(variablesDef?.find((vd) => vd.readonly)?.variables ?? []));
    setVariablesRefreshCounter((prev) => prev + 1);
  }, [variablesDef]);

  return {
    componentOptions,
    variableOptions,
    readOnlyVariables,
  };
};

const useGetSetOcppVariable = ({ deviceUuid, componentInput, variableInput, valueInput, setValueInput }) => {
  const { t } = useTranslation();
  const [fetchValueFromOcppCounter, setFetchValueFromOcppCounter] = useState(0);
  const [status, setStatus] = useState({ message: '', color: '' });
  const [fetchValueFromOcpp, { valueFromOcpp, error: retrievalError }] = useLazyGetOcppVariableQuery({
    selectFromResult: ({ data, error }) => ({ valueFromOcpp: data?.value, error }),
  });
  const [setVariableViaOcpp] = useSetOcppVariableMutation();

  useEffect(() => {
    if ((componentInput === undefined || componentInput) && variableInput) {
      // Delay setting status to avoid the status-clearing useEffect from clearing it
      setTimeout(() => {
        setStatus({
          message: `${t('retrievingVariable', 'Retrieving variable')}...`,
          color: 'text-gray-500',
        });
      }, 10);
      const timeoutId = setTimeout(async () => {
        await fetchValueFromOcpp({ deviceUuid, component: componentInput, variable: variableInput });
        setFetchValueFromOcppCounter((prev) => prev + 1);
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
    return undefined;
  }, [variableInput]);
  useEffect(() => setValueInput(''), [componentInput, variableInput]);
  useEffect(() => setStatus({}), [componentInput, variableInput, valueInput]);
  useEffect(
    () => {
      setValueInput(valueFromOcpp ?? '');
      // Delay setting status to avoid the status-clearing useEffect from clearing it
      setTimeout(() => {
        if (retrievalError) {
          setStatus({
            message: t('retrievalError', 'Retrieval failed: {{error}}', {
              error:
                retrievalError?.data?.message ??
                `${t('unknown', 'Unknown').toLowerCase()} ${t('error', 'Error').toLowerCase()}`,
            }),
            color: 'text-red-500',
          });
        } else if (!isNil(valueFromOcpp)) {
          setStatus({
            message: t('retrievalSuccessful', 'Retrieval successful'),
            color: 'text-green-500',
          });
        }
      }, 10);
    },
    // use counter as the dependency because the value or error can stay the same between retrievals
    [fetchValueFromOcppCounter],
  );

  const triggerSetVariable = async () => {
    setStatus({
      message: `${t('changingVariable', 'Changing variable')}...`,
      color: 'text-gray-500',
    });
    const resp = await setVariableViaOcpp({
      deviceUuid,
      component: componentInput,
      variable: variableInput,
      value: valueInput,
    });
    if (resp?.data?.success) {
      setStatus({
        message: t('changeSuccessful', 'Change successful'),
        color: 'text-green-500',
      });
    } else {
      setStatus({
        message: t('changeFailed', 'Change failed: {{error}}', {
          error:
            resp?.error?.data?.message ??
            `${t('unknown', 'Unknown').toLowerCase()} ${t('error', 'Error').toLowerCase()}`,
        }),
        color: 'text-red-500',
      });
    }
  };

  return { triggerSetVariable, status };
};

const DeviceOcppVariablesModal = ({ isOpen, closeModal, device }) => {
  const { t } = useTranslation();
  const [componentInput, setComponentInput] = useState('');
  const [variableInput, setVariableInput] = useState('');
  const [valueInput, setValueInput] = useState('');

  const canEditDeviceOcppVariables = useSelector((state) =>
    selectPermissionByName(state, Permission.CAN_EDIT_DEVICE_OCPP_VARIABLES),
  );

  const { componentOptions, variableOptions, readOnlyVariables } = useVariablesDef({
    deviceUuid: device.uuid,
    componentInput,
    skip: !isOpen,
  });

  const { triggerSetVariable, status } = useGetSetOcppVariable({
    deviceUuid: device.uuid,
    componentInput: isEmpty(componentOptions) ? undefined : componentInput,
    variableInput,
    valueInput,
    setValueInput,
  });

  const isValueReadOnly = readOnlyVariables.has(variableInput);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={() => {}}>
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all dark:bg-truegray-800 sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
              <div className="bg-white px-4 pb-4 pt-5 dark:bg-truegray-800 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10">
                    <InformationCircleIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                      {device.name || device.serialNumber}
                    </Dialog.Title>
                    <Global
                      styles={{
                        '.DeviceOcppVariablesForm-input .MuiOutlinedInput-input': { boxShadow: 'none !important' },
                      }}
                    />
                    <div className="DeviceOcppVariablesForm-input mt-2 text-sm text-gray-500 dark:text-warmgray-400">
                      <p className="mb-5">
                        {canEditDeviceOcppVariables
                          ? t('ocppVariablesModalText', 'See and change OCPP variable values.')
                          : t('ocppVariablesModalNoEditText', 'See OCPP variable values.')}
                        {readOnlyVariables.size > 0 && (
                          <>
                            <br />
                            {t('ocppVariablesModalSubText', 'Read-only variables are in')} <em>italic</em>.
                          </>
                        )}
                      </p>
                      {isEmpty(componentOptions) && isEmpty(variableOptions) ? (
                        <Spinner size="14" />
                      ) : (
                        <>
                          {componentOptions.length > 0 && (
                            <Autocomplete
                              value={componentInput}
                              onChange={(event, newValue) => {
                                setComponentInput(newValue);
                                if (componentOptions.includes(newValue)) {
                                  setVariableInput('');
                                }
                              }}
                              selectOnFocus
                              autoSelect
                              handleHomeEndKeys
                              options={componentOptions}
                              renderOption={(props, option) => <li {...props}>{option}</li>}
                              sx={{ width: 350 }}
                              freeSolo
                              renderInput={(params) => <TextField {...params} label={t('component', 'Component')} />}
                              className="DeviceOcppVariablesForm-input mb-3"
                            />
                          )}
                          <Autocomplete
                            value={variableInput}
                            onChange={(event, newValue) => setVariableInput(newValue)}
                            selectOnFocus
                            autoSelect
                            handleHomeEndKeys
                            options={variableOptions}
                            renderOption={({ className, ...props }, option) => (
                              <li
                                {...props}
                                className={`${className} ${readOnlyVariables.has(option) ? 'italic' : ''}`}
                              >
                                {option}
                              </li>
                            )}
                            sx={{ width: 350 }}
                            freeSolo
                            renderInput={(params) => <TextField {...params} label={t('variable', 'Variable')} />}
                            className="mb-3"
                          />
                          <TextField
                            value={valueInput}
                            disabled={isValueReadOnly || !canEditDeviceOcppVariables}
                            multiline
                            label={t('value', 'Value')}
                            sx={{ width: 350 }}
                            onChange={(event) => setValueInput(event.target.value)}
                            onKeyDown={(event) => {
                              if (event.key === 'Enter') {
                                // Use multiline only for displaying long values,
                                // while not allowing to enter newlines,
                                // but use Enter to trigger set variable
                                event.preventDefault();
                                triggerSetVariable();
                              }
                            }}
                          />
                          <br />
                          {canEditDeviceOcppVariables && (
                            <button
                              type="button"
                              className="mt-3 inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto sm:text-sm"
                              onClick={triggerSetVariable}
                            >
                              {t('change', 'Change')}
                            </button>
                          )}
                          <span className={`${status.color} ml-3 mt-2`}>{status.message}</span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 dark:bg-truegray-700 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={closeModal}
                >
                  {t('close', 'Close')}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

DeviceOcppVariablesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  device: PropTypes.object.isRequired,
};

export default DeviceOcppVariablesModal;
