import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Permission } from 'models/permission.enum';
import PropTypes from 'prop-types';

import { MenuComponent } from '@components/dropdown/MenuComponent';
import activityInlineIcon from '@images/icons/Activity_inline.svg';
import { ReactComponent as MenuArrowIcon } from '@images/icons/MenuArrow.svg';
import { ReactComponent as CheckIcon } from '@images/icons/check.svg';
import { selectPermissionByName, selectUser } from '@services/auth/selectors';
import { useUpdateUserRoleMutation } from '@services/users/endpoints';

import { useWorkspaceRolesDef } from './workspaceHooks';

export const MemberRoleMenu = ({ member }) => {
  const self = useSelector(selectUser);
  const rolesDef = useWorkspaceRolesDef();

  const [updateUserRole, { isLoading }] = useUpdateUserRoleMutation();
  const [newRole, setNewRole] = useState(null);

  const canEditThisMember =
    useSelector((state) => selectPermissionByName(state, Permission.CAN_EDIT_USERS)) && member.uuid !== self.uuid;

  const menuItems = Object.entries(rolesDef)
    .filter(([, roleDef]) => roleDef.choosable)
    .map(([key, roleDef]) => ({
      key,
      title: roleDef.label,
      onClick: () => {
        setNewRole(key);
        updateUserRole({ uuid: member.uuid, role: key });
      },
    }));

  return (
    <MenuComponent
      menuItems={menuItems.map((menuItem) => ({
        ...menuItem,
        ...(member.role === menuItem.key
          ? { className: 'font-semibold', icon: () => <CheckIcon className="h-5 w-5 [&_path]:fill-black" /> }
          : { className: 'pl-6' }),
      }))}
      buttonIcon={() => (
        <div className="group min-w-[100px] text-left text-black">
          {rolesDef[isLoading ? newRole : member.role]?.label}{' '}
          {canEditThisMember && !isLoading && <MenuArrowIcon className="inline-block" />}
          {isLoading && <img src={activityInlineIcon} alt="Loading" className="inline-block animate-spin" />}
        </div>
      )}
      disabled={!canEditThisMember}
      menuPosition="left-0"
    />
  );
};

MemberRoleMenu.propTypes = {
  member: PropTypes.object.isRequired,
};
