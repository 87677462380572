import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';

import DeleteModal from '@components/modals/DeleteModal';
import apiSlice from '@services/api';
import { addToastMessage } from '@services/toastMessages';

const DeleteVehicleModal = ({ vehicle, isOpen, closeModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(null);

  const [deleteVehicleByUuid, { isLoading }] = apiSlice.useDeleteVehicleMutation();

  const handleNavigateToVehicles = () => window.location.pathname.includes('/vehicles/') && navigate('/vehicles');
  const vehicleLabel = t('vehicle', 'Vehicle');

  const handleDeleteVehicle = async () => {
    try {
      const response = await deleteVehicleByUuid(vehicle.uuid);

      const toastMessage = response?.error
        ? {
            type: 'error',
            title: t('deletingTheItemFailed', 'Deleting the {{item}} failed', {
              item: vehicleLabel.toLowerCase(),
            }),
            message: t('pleaseTryAgain', 'Please try again.'),
          }
        : {
            type: 'success',
            title: t('itemDeleted', '{{item}} deleted', { item: vehicleLabel }),
            message: t('historicalDataIsStillAvailable', 'Historical data is still available in Reporting.'),
          };

      dispatch(addToastMessage(toastMessage));

      if (response?.error) {
        throw new Error(response.error.data.message);
      } else {
        closeModal();
        handleNavigateToVehicles();
      }
    } catch (e) {
      setErrorMessage(e?.message || t('somethingWentWrong', 'Something went wrong'));
    }
  };

  return (
    <DeleteModal
      open={isOpen}
      closeModal={closeModal}
      onDelete={handleDeleteVehicle}
      loading={isLoading}
      deleteButtonText={`${t('yes', 'Yes')}, ${t('deleteTheItem', 'Delete the {{item}}', {
        item: vehicleLabel,
      }).toLowerCase()}`}
      cancelButtonText={t('no', 'No')}
      title={`${t('delete', 'Delete')} ${vehicleLabel}`}
      error={errorMessage}
    >
      <p className="font-poppins">
        {t('areYouSureDeleteItem', ' Are you sure you want to delete the {{item}}', {
          item: vehicleLabel.toLowerCase(),
        })}
        {vehicle?.brand && vehicle?.model && (
          <span className="font-semibold">
            {' '}
            {vehicle?.name || vehicle.model} - {vehicle.brand}
          </span>
        )}
        ?
      </p>
    </DeleteModal>
  );
};

DeleteVehicleModal.propTypes = {
  vehicle: PropTypes.object,
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
};

DeleteVehicleModal.defaultProps = {
  vehicle: {},
  isOpen: false,
  closeModal: () => {},
};

export default DeleteVehicleModal;
