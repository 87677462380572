import { useTranslation } from 'react-i18next';

import EditingSidebarBase from '@components/sidebars/EditingSidebarBase';
import { PayoutDetails } from '@views/Workspace/sidebars/payoutDetails/PayoutDetails';

import { usePayoutDetailsForm } from './usePayoutDetailsForm';

export const PayoutDetailsSidebar = () => {
  const { t } = useTranslation();
  const { form, isSaving, isLoading, saveDisabled, submitPayoutDetails } = usePayoutDetailsForm();

  return (
    <EditingSidebarBase
      title={t('payoutDetails', 'Payout details')}
      containerClassName="space-y-8 font-poppins"
      saveDisabled={saveDisabled}
      updateLoading={isSaving}
      anyDataChanged={form.formState.isDirty}
      onSaveValues={submitPayoutDetails}
    >
      <PayoutDetails form={form} isLoading={isLoading} />
    </EditingSidebarBase>
  );
};
