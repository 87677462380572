import * as React from 'react';
import { useEffect, useState } from 'react';

import classNames from 'classnames';
import moment from 'moment';

import TextField from '@mui/material/TextField';
import { TimeView } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

import './muiStyleOverride.scss';

type TimePickerProps = {
  value: moment.Moment | null;
  name: string;
  label: string;
  disabled?: boolean;
  error?: boolean;
  helpText?: string;
  min?: moment.Moment;
  max?: moment.Moment;
  noMinutes?: boolean;
  onChange?: (value: moment.Moment) => void;
  shouldDisableTime?: (time: moment.Moment) => boolean;
};

export const TimePicker = React.forwardRef(
  (
    {
      value,
      disabled,
      label,
      name,
      error,
      helpText,
      onChange,
      min,
      max,
      noMinutes,
      shouldDisableTime,
    }: TimePickerProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const [time, setTime] = useState<moment.Moment | null>(null);

    const handleChange = (val: moment.Moment | null) => {
      const newValue = moment(val);
      if (noMinutes) {
        newValue.startOf('hour');
      }
      setTime(newValue);
      onChange?.(newValue);
    };
    useEffect(() => {
      setTime(value);
    }, [value]);

    const views: TimeView[] = noMinutes ? ['hours'] : ['hours', 'minutes'];

    return (
      <div className={`group w-full items-center justify-center pt-4 ${error ? 'hour-minute-error' : ''}`}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <MobileTimePicker
            label={label}
            name={name}
            value={time}
            ref={ref}
            onChange={handleChange}
            disabled={disabled}
            minTime={min}
            maxTime={max}
            ampm={false}
            views={views}
            shouldDisableTime={shouldDisableTime}
            slots={{
              textField: (params) => (
                <TextField fullWidth {...params} value={time ? moment(time).format('HH:mm') : ''} />
              ),
            }}
            slotProps={{
              dialog: {
                className: classNames(noMinutes && 'hour-minute-label-zeros'),
              },
            }}
          />
        </LocalizationProvider>
        {helpText && (
          <span
            className={classNames('mt-1 block text-xs', {
              'text-gray-500 dark:text-warmgray-400': !error,
              'text-red-500': error,
            })}
          >
            {helpText}
          </span>
        )}
      </div>
    );
  },
);

TimePicker.displayName = 'TimePicker';
