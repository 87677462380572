import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { Site } from 'models/site';

import EditingSidebarBase from '@components/sidebars/EditingSidebarBase';
import { ReactComponent as ArrowLeftIcon } from '@images/icons/arrow_left.svg';
import { CompanyDetails } from '@views/Workspace/sidebars/companyDetails/CompanyDetails';
import { useCompanyDetailsForm } from '@views/Workspace/sidebars/companyDetails/useCompanyDetailsForm';

export const WorkspaceSetupCompanySidebar = () => {
  const { t } = useTranslation();
  const { site } = useOutletContext<{ site: Site }>();
  const [returnPath, setReturnPath] = useState('');

  const { form, submitCompanyDetails, isLoading, isSaving } = useCompanyDetailsForm();

  const saveLabel = (
    <div className="flex items-center justify-center">
      {t('next', 'Next')}
      <ArrowLeftIcon className="h-4 rotate-180 pr-2 [&_path]:fill-white" />
    </div>
  );

  return (
    <EditingSidebarBase
      title={t('workspaceSetup', 'Workspace setup')}
      discardLabel={t('cancel', 'Cancel')}
      saveLabel={saveLabel}
      containerClassName="space-y-7 font-poppins"
      saveDisabled={isLoading}
      updateLoading={isSaving}
      anyDataChanged={form.formState.isDirty}
      skipDisablingSaveIfNoDataChanged
      getReturnPath={() => returnPath}
      onSaveValues={async () => {
        const success = !!(await submitCompanyDetails());
        if (success) {
          setReturnPath(`/sites/payout?siteUuid=${site.uuid}`);
        }
        return success;
      }}
    >
      <div className="flex">
        <div className="flex w-full justify-center border-b-2 border-primary font-poppins font-semibold">
          1. {t('details', 'Details')}
        </div>
        <div className="flex w-full justify-center border-b-2 font-poppins font-normal">2. {t('payout', 'Payout')}</div>
      </div>
      <CompanyDetails form={form} isLoading={isLoading} />
    </EditingSidebarBase>
  );
};
