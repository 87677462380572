import { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ChargingAction, ConnectorComputedStatus } from 'models/device.enums';

import { Spinner } from '@components/atoms/Spinner';
import { ReactComponent as CloseIcon } from '@images/icons/Close.svg';
import availableIcon from '@images/icons/DeviceStatus/Status_Available.svg';
import chargingIcon from '@images/icons/DeviceStatus/Status_Charging.svg';
import faultIcon from '@images/icons/DeviceStatus/Status_Fault.svg';
import offlineIcon from '@images/icons/DeviceStatus/Status_Offline.svg';
import pluggedInIcon from '@images/icons/DeviceStatus/Status_PluggedIn.svg';
import reservedIcon from '@images/icons/DeviceStatus/Status_Reserved.svg';
import suspendedIcon from '@images/icons/DeviceStatus/Status_Suspended.svg';
import unavailableIcon from '@images/icons/DeviceStatus/Status_Unavailable.svg';
import unknownIcon from '@images/icons/DeviceStatus/Status_Unknown.svg';
import noInternetIcon from '@images/icons/NoInternet.svg';
import playIcon from '@images/icons/Play.svg';
import stopIcon from '@images/icons/Stop.svg';
import timeIcon from '@images/icons/Time.svg';
import circledUnknownIcon from '@images/icons/Unknown.svg';
import { ReactComponent as WarningIcon } from '@images/icons/Warning.svg';
import checkIcon from '@images/icons/check.svg';

export const COLOR_VERMILLION = '#EB4E20';
export const COLOR_FOREST = '#386957';
export const COLOR_QUEEN = '#407090';
export const COLOR_LIGHT_VERMILLION = '#FF7043';
export const COLOR_LIGHT_FOREST = '#4CAF50';
export const COLOR_LIGHT_QUEEN = '#2196F3';
export const CHART_COLORS = [COLOR_VERMILLION, COLOR_FOREST, COLOR_QUEEN];
export const CHART_LIGHT_COLORS = [COLOR_LIGHT_VERMILLION, COLOR_LIGHT_FOREST, COLOR_LIGHT_QUEEN];
export const CHART_BACKGROUND_COLORS = ['bg-vermillion-200', 'bg-forest-200', 'bg-queen-200'];

type DefinitionItem = {
  statusLabel: string;
  buttonStatusLabelColor?: string;
  secondaryLabel?: string;
  buttonSecondaryLabelColor?: string;
  actionLinkLabel?: string;
  action?: ChargingAction;
  tooltipText?: string;
  tooltipIconClassName?: string;
  icon: string;
  buttonIcon?: ReactNode;
  buttonIconOutline?: ReactElement;
  iconClassName: string;
  textClassName: string;
  colorClassName: string; // TODO: better name? Used for buttons and activity
  chartColor: string;
  isDark?: boolean;
};

export const useComputedStatusDefs = (
  voolDeviceFlag?: boolean,
  smallerIcons?: boolean,
): Record<ConnectorComputedStatus, DefinitionItem> => {
  const { t } = useTranslation();

  const vermillionScheme = {
    iconClassName: 'bg-vermillion',
    textClassName: 'text-vermillion',
    colorClassName: 'bg-vermillion-200 border-vermillion text-vermillion [&_.scheme-color_path]:fill-vermillion',
    chartColor: COLOR_VERMILLION,
  };
  const vermillionDarkScheme = {
    buttonStatusLabelColor: 'text-white',
    buttonSecondaryLabelColor: 'text-gray-100',
    tooltipIconClassName: '[&_path]:fill-gray-100',
    iconClassName: 'bg-vermillion',
    textClassName: 'text-vermillion',
    colorClassName: 'bg-vermillion border-white text-white [&_.scheme-color_path]:fill-white',
    chartColor: '#FFFFFF',
    isDark: true,
  };
  const forestScheme = {
    iconClassName: 'bg-forest',
    textClassName: 'text-forest',
    colorClassName: 'bg-forest-200 border-forest text-forest [&_.scheme-color_path]:fill-forest',
    chartColor: COLOR_FOREST,
  };
  const queenScheme = {
    iconClassName: 'bg-queen',
    textClassName: 'text-queen',
    colorClassName: 'bg-queen-200 border-queen text-queen [&_.scheme-color_path]:fill-queen',
    chartColor: COLOR_QUEEN,
  };
  const grayScheme = {
    iconClassName: 'bg-gray-600',
    textClassName: 'text-gray-600',
    colorClassName: 'bg-gray-200 border-gray-600 text-gray-600 [&_.scheme-color_path]:fill-gray-600',
    chartColor: '#5E5E5E',
  };
  const blackScheme = {
    buttonStatusLabelColor: 'text-white',
    buttonSecondaryLabelColor: 'text-gray-100',
    iconClassName: 'bg-black',
    textClassName: 'text-black',
    colorClassName: 'bg-black border-white text-white [&_.scheme-color_path]:fill-white',
    chartColor: '#FFFFFF',
    isDark: true,
  };

  const iconSize = smallerIcons ? 'h-5 w-5' : 'h-8 w-8';

  const statusDefs: Record<ConnectorComputedStatus, DefinitionItem> = {
    AVAILABLE: {
      statusLabel: t('available', 'Available'),
      icon: availableIcon,
      buttonIcon: checkIcon,
      ...forestScheme,
    },
    CHARGING: {
      statusLabel: t('charging', 'Charging'),
      secondaryLabel: t('stopCharging', 'Stop charging'),
      icon: chargingIcon,
      buttonIcon: stopIcon,
      action: ChargingAction.STOP,
      ...vermillionScheme,
    },
    FAULTED: {
      statusLabel: t('faulted', 'Faulted'),
      icon: faultIcon,
      buttonIcon: <WarningIcon className={`${iconSize} [&_path]:fill-white`} />,
      ...blackScheme,
    },
    NEVER_CONNECTED: {
      statusLabel: t('neverConnected', 'Never connected'),
      secondaryLabel: t('checkSettings', 'Check settings'),
      tooltipText: voolDeviceFlag
        ? t(
            'neverConnectedVoolTooltip',
            'This charger has never connected to VOOL system. Please ensure that the charger is powered on and review your network configuration for any potential issues.',
          )
        : t(
            'neverConnectedNonVoolTooltip',
            'This charger has never connected to VOOL system. Please check that the charger is powered on, ensure its OCPP credentials are correctly configured, and check your network configuration.',
          ),
      icon: faultIcon,
      buttonIcon: <WarningIcon className={`${iconSize} [&_path]:fill-white`} />,
      ...vermillionDarkScheme,
    },
    OFFLINE: {
      statusLabel: t('offline', 'Offline'),
      icon: offlineIcon,
      buttonIcon: noInternetIcon,
      ...grayScheme,
    },
    PLUGGED_IN: {
      statusLabel: t('plugged in', 'Plugged in'),
      secondaryLabel: t('startCharging', 'Start charging'),
      icon: pluggedInIcon,
      buttonIcon: playIcon,
      action: ChargingAction.START,
      ...queenScheme,
    },
    STARTING: {
      statusLabel: `${t('starting', 'Starting')}...`,
      secondaryLabel: t('cancel', 'Cancel'),
      icon: unknownIcon,
      buttonIcon: <CloseIcon className={`${iconSize} [&_path]:fill-queen`} />,
      buttonIconOutline: <Spinner size="full" className="[&_path]:fill-queen" />,
      action: ChargingAction.STOP,
      ...queenScheme,
    },
    STOPPING: {
      statusLabel: `${t('stopping', 'Stopping')}...`,
      secondaryLabel: t('cancel', 'Cancel'),
      icon: unknownIcon,
      buttonIcon: <CloseIcon className={`${iconSize} [&_path]:fill-vermillion`} />,
      buttonIconOutline: <Spinner size="full" className="[&_path]:fill-vermillion" />,
      action: ChargingAction.STOP,
      ...vermillionScheme,
    },
    RESERVED: {
      statusLabel: t('reserved', 'Reserved'),
      icon: reservedIcon,
      buttonIcon: timeIcon,
      ...grayScheme,
    },
    SUSPENDED_EV: {
      statusLabel: t('suspended', 'Suspended'),
      secondaryLabel: t('dueToEV', 'Due to EV'),
      actionLinkLabel: t('stop', 'Stop'),
      tooltipText: t(
        'suspendedEvTooltip',
        'EV is connected to the charger and the charger is offering energy but the EV is not taking any energy.',
      ),
      icon: suspendedIcon,
      buttonIcon: stopIcon,
      action: ChargingAction.STOP,
      ...vermillionScheme,
      iconClassName: 'bg-gray-600',
      textClassName: 'text-gray-600',
    },
    SUSPENDED_EVSE: {
      statusLabel: t('suspended', 'Suspended'),
      secondaryLabel: t('dueToCharger', 'Due to charger'),
      actionLinkLabel: t('stop', 'Stop'),
      tooltipText: t(
        'suspendedEvseTooltip',
        'EV is connected to the charger but the charger is not offering energy to the EV, e.g. due to a smart charging restriction, load management etc.',
      ),
      icon: suspendedIcon,
      buttonIcon: stopIcon,
      action: ChargingAction.STOP,
      ...vermillionScheme,
    },
    UNAVAILABLE: {
      statusLabel: t('unavailable', 'Unavailable'),
      icon: unavailableIcon,
      buttonIcon: unavailableIcon,
      ...grayScheme,
    },
    UNKNOWN: {
      statusLabel: t('unknown', 'Unknown'),
      icon: unknownIcon,
      buttonIcon: circledUnknownIcon,
      ...grayScheme,
    },
  };

  return statusDefs;
};
