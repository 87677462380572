import React, { ReactElement } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import { StyledButton } from '@components/atoms/StyledButton';
import { MenuComponent, MenuItem } from '@components/dropdown/MenuComponent';
import kebabIcon from '@images/icons/Kebab.svg';

export type ActionButton =
  | ReactElement
  | {
      label: string;
      path: string;
      hidden?: boolean;
    };

export type Tab = {
  label: string;
  path: string;
  actionButton?: ActionButton;
  hidden?: boolean;
};

type TabsWithMenuProps = {
  tabs: Tab[];
  activeTab: Tab;
  menuItems?: MenuItem[];
  globalActionButton?: ActionButton;
};

const ActionButtonComponent = ({ actionButton }: { actionButton?: ActionButton }) => {
  const navigate = useNavigate();

  if (React.isValidElement(actionButton)) {
    return <Box flex={1}>{actionButton}</Box>;
  }

  if (actionButton && 'label' in actionButton && !actionButton.hidden) {
    return (
      <Box flex={1}>
        <StyledButton color="success" onClick={() => navigate(actionButton.path)} fullWidth>
          {actionButton.label}
        </StyledButton>
      </Box>
    );
  }

  return null;
};

export const TabsWithMenu = ({ tabs, activeTab, menuItems, globalActionButton }: TabsWithMenuProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isXSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box display="flex" flexWrap="wrap" alignItems="center" gap={2} mb={3}>
      <Box
        display="flex"
        alignItems="center"
        gap={2}
        flex={1}
        sx={{
          ...(isSmallScreen && { flexWrap: 'wrap' }),
          ...(isSmallScreen && tabs.length > 2 && { justifyContent: 'space-between' }),
          ...(isXSmallScreen && { flexDirection: 'column' }),
        }}
      >
        {tabs
          .filter((tab) => !tab.hidden)
          .map((tab) => (
            <Typography
              key={tab.label}
              component={Link}
              to={tab.path}
              variant="p14"
              display="inline-block"
              textAlign="center"
              px={1.5}
              py={1}
              sx={{
                borderRadius: '12px',
                ...(isXSmallScreen && { width: '100%' }),
                ...(tab.path === activeTab?.path && { bgcolor: 'black', color: 'white' }),
              }}
            >
              {tab.label}
            </Typography>
          ))}
      </Box>
      <Box
        display="flex"
        alignItems="center"
        gap={isXSmallScreen ? 0.3 : 1}
        sx={{
          ...(isSmallScreen && { flexWrap: 'wrap', justifyContent: 'space-between', minWidth: '100%' }),
        }}
      >
        <ActionButtonComponent actionButton={globalActionButton} />
        <ActionButtonComponent actionButton={activeTab?.actionButton} />
        {menuItems && <MenuComponent menuItems={menuItems} buttonIcon={kebabIcon} />}
      </Box>
    </Box>
  );
};
