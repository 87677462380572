import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { Input } from '@components/atoms/Input';
import SaveModal from '@components/modals/SaveModal';
import { useUpdateActiveCompanyMutation } from '@services/companies/endpoints';
import { selectActiveCompany } from '@services/companies/selectors';
import { addToastMessage } from '@services/toastMessages';

const WorkspaceRenameModal = ({ isOpen, closeModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeCompany = useSelector(selectActiveCompany);

  const [name, setName] = useState('');

  const workspaceLabel = t('workspace', 'Workspace');

  const [updateActiveCompany, { isLoading }] = useUpdateActiveCompanyMutation();

  useEffect(() => {
    if (isOpen) {
      setName(activeCompany?.name || '');
    }
  }, [activeCompany?.name, isOpen]);

  const handleSave = async () => {
    const response = await updateActiveCompany({ name: name.trim() });
    const toastMessage = response?.error
      ? {
          type: 'error',
          title: t('renamingTheItemFailed', 'Renaming the {{item}} failed', {
            item: workspaceLabel.toLowerCase(),
          }),
          message: t('pleaseTryAgain', 'Please try again.'),
        }
      : { type: 'success', title: t('itemRenamed', '{{item}} renamed', { item: workspaceLabel }) };
    dispatch(addToastMessage(toastMessage));
    closeModal();
  };

  const canSave = !isLoading && !!name.trim();

  return (
    <SaveModal
      title={t('renameItem', 'Rename {{item}}', { item: workspaceLabel.toLowerCase() })}
      content={() => ''}
      saveModalContent={handleSave}
      buttonText={t('renameItem', 'Rename {{item}}', { item: workspaceLabel.toLowerCase() })}
      saveButtonDisabled={!canSave}
      closeModal={closeModal}
      open={isOpen}
    >
      <Input
        name="newName"
        label={t('itemName', '{{item}} name', { item: workspaceLabel })}
        value={name}
        onChange={(e) => setName(e.target.value)}
        inputProps={{ onKeyDown: (e) => e.key === 'Enter' && canSave && handleSave() }}
      />
    </SaveModal>
  );
};

WorkspaceRenameModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
};

WorkspaceRenameModal.defaultProps = {
  isOpen: false,
  closeModal: () => {},
};

export default WorkspaceRenameModal;
