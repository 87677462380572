import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Outlet, useOutletContext } from 'react-router-dom';

import { isNil } from 'lodash';
import moment from 'moment';

import { CogIcon, LightBulbIcon } from '@heroicons/react/solid';
import { Tooltip } from '@mui/material';

import TableRow from '@components/atoms/TableRow';
import SessionStatus from '@components/sessions/SessionStatus';
import SortableTable from '@components/tables/SortableTable';
import useActiveCurrency from '@hooks/useActiveCurrency';
import { selectSmartChargingSessions } from '@services/vehicles/selectors';

const VehicleHistory = () => {
  const { t } = useTranslation();
  const currency = useActiveCurrency();
  const { vehicle } = useOutletContext();
  const smartChargingSessions = useSelector(selectSmartChargingSessions);

  const getTransactionDurationByMinutes = (minutes) => {
    if (minutes) {
      return moment.duration(minutes, 'minutes');
    }
    return null;
  };

  const vehicleSmartChargingSessionsWithStats = useMemo(
    () =>
      smartChargingSessions
        ?.filter((st) => st.carUuid === vehicle.uuid && st.scheduledStartTime)
        .map((session) => {
          const summary = session.slotsSummary;
          const slots = session?.slots;
          const startBatteryLevel = slots ? slots[0].startBatteryLevel : null;
          const endBatteryLevel = slots ? slots[slots.length - 1].endBatteryLevel : null;
          return {
            ...session,
            startTime: session.scheduledStartTime,
            startBatteryLevel,
            endBatteryLevel,
            batteryLevel: endBatteryLevel - startBatteryLevel,
            duration: getTransactionDurationByMinutes(summary?.actualMinutes),
            consumption: summary?.actualKwhAmount,
            cost: summary?.actualMonetaryCost,
          };
        }) || [],
    [smartChargingSessions, vehicle?.uuid],
  );

  const smartChargingSessionsDataTableColumns = [
    {
      key: 'startTime',
      label: t('startTime', 'Start time'),
      tdClassName: 'w-1/6',
      reverseSorting: true,
      value: ({ scheduledStartTime, status, smartFlag }) => {
        if (scheduledStartTime) {
          return (
            <SessionStatus
              startTime={scheduledStartTime}
              status={(t(status.toLowerCase(), status) || t('stopped', 'Stopped')).toLowerCase()}
              smartFlag={!!smartFlag}
              includeTime
            />
          );
        }
        return '-';
      },
    },
    {
      key: 'batteryLevel',
      label: t('batteryLevel', 'Battery Level'),
      value: ({ startBatteryLevel, endBatteryLevel }) => {
        const batteryLevelInfo =
          !isNil(startBatteryLevel) && !isNil(endBatteryLevel)
            ? `${startBatteryLevel}% -> ${endBatteryLevel}%`
            : t('unknown', 'Unknown');
        return batteryLevelInfo;
      },
    },
    {
      key: 'smartFlag',
      label: t('chargingSessionType', 'Charging Session Type'),
      value: ({ smartFlag }) => (
        <Tooltip
          placement="top"
          title={
            smartFlag
              ? t('smartChargingSession', 'Smart charging session')
              : t('manualChargingSession', 'Manual charging session')
          }
          className="flex w-full items-center"
        >
          {smartFlag ? (
            <LightBulbIcon className="h-5 w-5 text-gray-400 dark:text-warmgray-400" />
          ) : (
            <CogIcon className="h-5 w-5 text-gray-400 dark:text-warmgray-400" />
          )}
        </Tooltip>
      ),
    },
    {
      key: 'duration',
      label: t('duration', 'Duration'),
      className: 'justify-end text-right',
      tdClassName: 'pr-4 text-right',
      value: ({ duration }) => {
        if (duration) {
          return (
            <div className="flex items-center justify-end space-x-2">
              <div>
                {Math.floor(duration.asHours())}{' '}
                <span className="-ml-1 text-sm text-gray-400">{t('hoursAbbreviation', 'h')}</span>
              </div>
              <div>
                {duration.minutes()}{' '}
                <span className="-ml-1 text-sm text-gray-400">{t('minutesAbbreviation', 'm')}</span>
              </div>
              <div>
                {duration.seconds()}{' '}
                <span className="-ml-1 text-sm text-gray-400">{t('secondsAbbreviation', 's')}</span>
              </div>
            </div>
          );
        }
        return '-';
      },
    },
    {
      key: 'consumption',
      label: t('consumption', 'Consumption'),
      className: 'justify-end text-right',
      tdClassName: 'pr-4 text-right',
      extraData: 'kWh',
      extraDataClassName: 'text-gray-400',
      value: ({ consumption }) => (consumption || 0).toFixed(2),
    },
    {
      key: 'cost',
      label: t('cost', 'Cost'),
      className: 'justify-end text-right',
      tdClassName: 'pr-4 text-right',
      extraData: currency.symbol,
      extraDataClassName: 'text-gray-400',
      value: ({ cost }) => (cost || 0).toFixed(2),
      tooltip: t('vatNotIncluded', 'VAT not included'),
    },
  ];

  return (
    <>
      <div className="flex flex-col items-start">
        <div className="flex w-full items-center justify-between pb-4 pt-2">
          <h1 className="font-poppins text-lg font-semibold">{t('sessionHistory', 'Sessions history')}</h1>
        </div>

        <SortableTable
          columns={smartChargingSessionsDataTableColumns}
          data={vehicleSmartChargingSessionsWithStats}
          tableRow={TableRow}
          keyAttr="uuid"
          sortingStorageKey="vehicleSmartChargingSessionsTable"
          defaultSortingParam="-startTime"
        />
      </div>
      <Outlet context={{ vehicle }} />
    </>
  );
};

export default VehicleHistory;
