import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { useUpdateSiteMutation } from '@services/sites/endpoints';

import SiteDetailsSidebarBase from './SiteDetailsSidebarBase';

const SiteDetailsSidebar = () => {
  const { t } = useTranslation();
  const { site } = useOutletContext() || { site: {} };

  const [updateSite, { isLoading }] = useUpdateSiteMutation();

  const saveValues = async (attributes) => {
    const response = await updateSite(attributes);
    return !response.error;
  };

  return (
    <SiteDetailsSidebarBase
      site={site}
      title={t('siteDetails', 'Site details')}
      isSaveLoading={isLoading}
      onSaveValues={saveValues}
    />
  );
};

export default SiteDetailsSidebar;
