import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Dialog, Typography } from '@mui/material';

import { StyledButton } from '@components/atoms/StyledButton';
import { ReactComponent as AlertIcon } from '@images/icons/Alert_outlined.svg';
import { ReactComponent as CloseIcon } from '@images/icons/Close.svg';

type MuiDeleteModalProps = {
  open: boolean;
  onClose: (event: SyntheticEvent, reason: 'backdropClick' | 'escapeKeyDown' | 'closeClick' | 'cancelClick') => void;
  title: string;
  onDelete: (event: SyntheticEvent) => void;
  cancelText?: string;
  confirmText?: string;
  children?: React.ReactNode;
};

export const MuiDeleteModal = ({
  open,
  onClose,
  title,
  onDelete,
  cancelText,
  confirmText,
  children,
}: MuiDeleteModalProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '.MuiPaper-root': {
          borderRadius: '20px',
          width: '100%',
          minWidth: '480px',
        },
      }}
    >
      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="16px 24px"
          borderBottom="1px solid #F4F4F4"
        >
          <Typography variant="h3">{title}</Typography>
          <CloseIcon cursor="pointer" onClick={(e) => onClose(e, 'closeClick')} />
        </Box>
        <Box display="flex" flexDirection="column" gap={3} p={3} borderBottom="1px solid #F4F4F4">
          {children}
          <Typography display="flex" alignItems="center" gap={1} variant="p16" color="#EB4E20">
            <AlertIcon />
            {t('thisActionCannotBeUndone', 'This action cannot be undone.')}
          </Typography>
        </Box>
        <Box display="flex" gap={2} padding="16px 24px">
          <StyledButton color="secondary" fullWidth onClick={(e) => onClose(e, 'cancelClick')}>
            {cancelText ?? t('cancel', 'Cancel')}
          </StyledButton>
          <StyledButton fullWidth onClick={(e) => onDelete(e)}>
            {confirmText ?? t('confirm', 'Confirm')}
          </StyledButton>
        </Box>
      </Box>
    </Dialog>
  );
};
