import { useTranslation } from 'react-i18next';

import { Inverter } from 'models/inverter';

type InverterStatusProps = {
  inverter: Inverter;
};

export const InverterStatus = ({ inverter }: InverterStatusProps) => {
  const { t } = useTranslation();
  const isProducing = inverter.data?.productionState?.isProducing;
  const statusLabel = isProducing ? t('producing', 'Producing') : t('notProducing', 'Not producing');

  return (
    <div
      className={`flex w-full min-w-[216px] flex-col rounded-2xl p-4 font-poppins md:w-1/3 ${
        isProducing ? 'bg-forest-200' : 'bg-vermillion-200'
      }`}
    >
      <div className="text-2xl">{inverter.name}</div>
      <div className="mb-6 text-sm text-gray-600">{t('status', 'Status')}</div>
      <div
        className={`mt-auto flex w-fit items-center rounded-md px-2 text-sm leading-6 text-white ${
          isProducing ? 'bg-forest' : 'bg-vermillion'
        }`}
      >
        {statusLabel}
      </div>
    </div>
  );
};
