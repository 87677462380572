import { useCallback, useEffect, useState } from 'react';

import { Tooltip } from '@mui/material';

import { ReactComponent as SearchIcon } from '@images/icons/Search.svg';

import SearchModal from './SearchModal';

const SearchOpenButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleKeyPress = useCallback((event) => {
    if ((event.metaKey || event.ctrlKey) && event.key.toLowerCase() === 'k') {
      setIsModalOpen(true);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <>
      <Tooltip placement="bottom" title="Search (Ctrl+K or ⌘+K)">
        <button
          type="button"
          className="rounded-full p-1 hover:bg-gray-300 focus:outline-none active:bg-gray-400"
          onClick={() => setIsModalOpen(true)}
        >
          <SearchIcon />
        </button>
      </Tooltip>
      <SearchModal isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} />
    </>
  );
};

export default SearchOpenButton;
